import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Container, Grid, Card, CardHeader, CardContent, Typography,
  Tooltip, Hidden, Dialog, DialogTitle, DialogContent, DialogActions,
  DialogContentText, Button, IconButton, TextField, Divider, Tabs, Tab, Alert
} from '@mui/material';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import {
  Briefcase as TimeSheetIcon,
  Grid as DetailsIcon,
  CheckSquare as CheckIcon,
  UserCheck as UserCheckIcon,
  Coffee as CoffeeIcon,
  MessageSquare as MessageIcon,
  MapPin as MapIcon,
} from 'react-feather';
import BackIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import NotifyIcon from '@mui/icons-material/Email';
import RefreshIcon from '@mui/icons-material/Refresh';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DataGrid } from '@mui/x-data-grid';
import TabPanel from '../components/TabPanel';
import { hide, show } from '../store/actions/busyIndicatorActions';
import {
  showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts
} from '../store/actions/alertActions';
import {
  formatSuccessMessage, formatErrorMessage, formatDateToLongDate,
  getDateDifference, formatDateToCalendarTime, isDriver, isOwner,
  isManager, getBreakBreach, getDateDifferenceInMS, getJobSiteName, sortByKey
} from '../utils/common';
import LocalStorageService from '../services/LocalStorageService';
import ShiftsService from '../services/ShiftsService';
import EnumService from '../services/EnumService';
import AppConstants from '../constants/AppConstants';
import ConfirmButton from '../components/ConfirmButton';
import Timer from '../components/Timer';
import FormattedShift from '../models/FormattedShift';
import AddCommentModal from '../components/shift/AddCommentModal';
import { EnumRes } from '../proxy/proxy';

const checkInType30Min = 64;
const checkInTypePreCheck = 128;
interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const ShiftDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();

  const [selectedTab, setSelectedTab] = useState(0);
  const [shiftData, setShiftData] = useState<FormattedShift | null>(null);

  const [shiftStartTime, setShiftStartTime] = useState<any>();
  const [shiftEndTime, setShiftEndTime] = useState<any>();
  const [breakStartTime, setBreakStartTime] = useState<any>();
  const [commentTypes, setCommentTypes] = useState<Array<EnumRes>>([]);
  const [shiftStatusTypes, setShiftStatusTypes] = useState<Array<EnumRes>>([]);
  const [checkinTypes, setCheckinTypes] = useState<Array<EnumRes>>([]);

  const [endShiftModal, setEndShiftModal] = useState(false);
  const [submitJotFormModal, setSubmitJotFormModal] = useState(false);
  const [shiftCommentModal, setShiftCommentModal] = useState(false);
  const [confirmBreakEnd, setConfirmBreakEnd] = useState(false);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const refreshData = () => {
    showBusyIndicator();
    Promise.all([
      EnumService.getAllEnums(),
      ShiftsService.getShiftById(Number(id))
    ])
      .then((results) => {
        setCommentTypes(results[0]?.shiftCommentTypes || []);
        setShiftStatusTypes(results[0]?.shiftStatus || []);
        setCheckinTypes(results[0]?.checkinTypes || []);
        setShiftData(results[1]);

        let startTime = results[1].startedOnUTC;
        if (!results[1].endedOnUTC && results[1].scheduledStartUTC && results[1].breachCalculatedUsing === 1) {
          startTime = results[1].scheduledStartUTC;
        }
        setShiftStartTime(startTime);
        setShiftEndTime(results[1].endedOnUTC);

        if (results[1]?.shiftBreaks.find((brk) => !brk.endedOnUTC)) {
          setBreakStartTime(results[1]?.shiftBreaks?.find((brk) => !brk.endedOnUTC)?.startedOnUTC);
        } else {
          setBreakStartTime(null);
        }
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleShiftEnd = (values: any) => {
    setEndShiftModal(false);
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.stopShift(Number(id), {
      endOdometerKms: values?.endOdometerKms,
      shiftComment: {
        text: `Shift: ${values?.comment}`,
        shiftCommentTypeId: 1
      }
    })
      .then(() => {
        showAlert(formatSuccessMessage('Shift ended successfully'));
        refreshData();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleStartBreak = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.startBreak(Number(id))
      .then(() => {
        showAlert(formatSuccessMessage('Break started successfully'));
        refreshData();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleStopBreak = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.stopBreak(Number(id))
      .then((result) => {
        showAlert(formatSuccessMessage('Break stopped successfully'));
        refreshData();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleEndShiftModalOpen = () => {
    setEndShiftModal(true);
  };

  const handleEndShiftClose = () => {
    setEndShiftModal(false);
  };

  const navigateToStartShiftPage = () => {
    navigate(`/employee/shift/${id}/start`);
  };

  const handleAddShiftCommentSubmit = (drop: number, comment: string, isOTDComment: boolean) => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.addShiftComment(`${isOTDComment ? `Drop ${drop}` : 'Shift'}: ${comment}`, Number(id), isOTDComment ? 2 : 1)
      .then(() => {
        setShiftCommentModal(false);
        showAlert(formatSuccessMessage('Comment added successfully.'));
        refreshData();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleSubmitJotFormClose = () => {
    setSubmitJotFormModal(false);
  };

  const markJotFormSubmitted = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.markJotFormSubmitted(Number(id))
      .then((result) => {
        setShiftData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleSubmitJotFormConfirm = () => {
    setSubmitJotFormModal(false);
    markJotFormSubmitted();
  };

  const back = () => {
    let url = '/app/shift/list';
    if (isDriver(roles)) {
      url = '/employee/shift';
    }
    navigate(url);
  };

  const getLastBreak = () => {
    if (shiftData?.shiftBreaks?.length) {
      return shiftData.shiftBreaks[shiftData.shiftBreaks.length - 1];
    }
    return null;
  };

  const handleJotFormSubmit = () => {
    if (!shiftData?.jotFormUrl) {
      return;
    }
    setSubmitJotFormModal(true);
    const win: any = window;
    win.open(shiftData?.jotFormUrl, '_blank').focus();
  };

  const handleAcceptShift = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.acceptShift(Number(id))
      .then((result) => {
        showAlert(formatSuccessMessage('You have accepted the shift'));
        setShiftData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleSubmitShift = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.stopShiftSmsOnly(Number(id))
      .then((result) => {
        showAlert(formatSuccessMessage('You have submitted SMS only shift'));
        setShiftData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleCheckIn = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.preStart30MinuteCheckin(Number(id))
      .then((result) => {
        showAlert(formatSuccessMessage('Check In Successful'));
        setShiftData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  // const rejectShift = () => {
  //   showBusyIndicator();
  //   clearAllAlerts();
  //   ShiftsService.rejectShift(Number(id))
  //     .then(() => {
  //       showAlert(formatSuccessMessage('You have rejected the shift'));
  //       refreshData();
  //     }).catch((err: any) => {
  //       showAlert(formatErrorMessage(JSON.parse(err.response)));
  //     }).then(() => {
  //       hideBusyIndicator();
  //     });
  // };

  const notifyShift = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.notifyShift(Number(id))
      .then(() => {
        showAlert(formatSuccessMessage('Notification sent to Driver'));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const pushNotifyShift = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.pushNotify(Number(id))
      .then(() => {
        showAlert(formatSuccessMessage('Push notification sent to Driver'));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const hasMinimumBreakTime = () => {
    const startTime = shiftData?.shiftBreaks?.find((brk) => !brk.endedOnUTC)?.startedOnUTC;
    const difference = getDateDifferenceInMS(startTime, new Date().toISOString()) / 60000;
    return difference < 0 || difference >= AppConstants.BREAK_MINIMUM_TIME;
  };

  const handleEditShift = () => {
    navigate(`/app/shift/list/${id}/edit`);
  };

  const openShiftNR = (row: any) => {
    navigate(`/app/linfox/${row?.id}`);
  };

  const getCheckInType = (type: number) => {
    switch (type) {
      case 0:
        return 'Other';
      case 1:
        return 'Shift Start';
      case 2:
        return 'Shift End';
      case 4:
        return 'Break Start';
      case 8:
        return 'Break End';
      case 16:
        return 'Comment';
      case 32:
        return 'Last Location';
      case 64:
        return 'Pre Start 30 Minute';
      case 128:
        return 'Precheck';
      default:
        return type;
    }
  };

  const handleDeleteClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.deleteShift(Number(id))
      .then(() => {
        showAlert(formatSuccessMessage('Shift deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();
    refreshData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Shift Details | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton data-auto-id="navigateBackIconButton" aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title={`Shift Details (${shiftData?.userName ?? shiftData?.userEmail ?? '--'})`}
              action={
                (
                  <>
                    <IconButton onClick={refreshData}>
                      <RefreshIcon />
                    </IconButton>
                    {
                      (isOwner(roles) || isManager(roles)) && (shiftData?.shiftStatusId === 1 || shiftData?.shiftStatusId === 0) && (
                        <>
                          <Button
                            variant="text"
                            onClick={handleEditShift}
                          >
                            Edit
                          </Button>
                          <ConfirmButton
                            buttonIcon={
                              (
                                <MarkUnreadChatAltIcon />
                              )
                            }
                            isIconButton
                            dialogTitle="Push notification to Driver"
                            showCancelButton
                            message="Your are about to send notification to the driver on mobile about this shift."
                            onConfirm={pushNotifyShift}
                            sx={{ marginRight: 2 }}
                          />
                          <ConfirmButton
                            buttonIcon={
                              (
                                <NotifyIcon />
                              )
                            }
                            isIconButton
                            dialogTitle="Notify Driver"
                            showCancelButton
                            message="Your are about to notify the driver about this shift."
                            onConfirm={notifyShift}
                            sx={{ marginRight: 2 }}
                          />
                          <ConfirmButton
                            buttonIcon={
                              (
                                <DeleteIcon />
                              )
                            }
                            isIconButton
                            dialogTitle="Are you sure?"
                            showCancelButton
                            message="Shift will be deleted permanently."
                            buttonColor="secondary"
                            onConfirm={handleDeleteClick}
                          />
                        </>
                      )
                    }
                  </>
                )
              }
            />
            <Divider />
            <CardContent>
              <Hidden mdUp>
                <Tabs
                  variant="fullWidth"
                  value={selectedTab}
                  onChange={handleTabChange}
                >
                  {
                    [
                      <Tab icon={<DetailsIcon />} key="Details" />,
                      ...(
                        (shiftData?.shiftStatusId === 2 || shiftData?.shiftStatusId === 3) ? [
                          <Tab icon={<TimeSheetIcon />} key="TimeSheets" />,
                          <Tab icon={<MapIcon />} key="CheckIns" />,
                          <Tab icon={<CoffeeIcon />} key="Breaks" />,
                          <Tab icon={<MessageIcon />} key="Comments" />,
                          <Tab icon={<CheckIcon />} key="PreCheck" />
                        ] : []
                      )
                    ]
                  }
                </Tabs>
              </Hidden>
              <Hidden mdDown>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                >
                  {
                    [
                      <Tab label="Details" key="Details" />,
                      ...(
                        (shiftData?.shiftStatusId === 2 || shiftData?.shiftStatusId === 3) ? [
                          <Tab label="Time sheets" key="TimeSheets" />,
                          <Tab label="Check Ins" key="CheckIns" />,
                          <Tab label="Breaks" key="Breaks" />,
                          <Tab label="Comments" key="Comments" />,
                          <Tab label="Precheck" key="PreCheck" />,
                        ] : []
                      )
                    ]
                  }
                </Tabs>
              </Hidden>
              <Divider />
              <TabPanel value={selectedTab} index={0}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      (shiftData?.shiftStatusId === 2 || shiftData?.shiftStatusId === 3) && (
                        <Container maxWidth="md" sx={{ padding: 0 }}>
                          <Timer
                            title={shiftData.endedOnUTC ? 'Total Shift Time' : 'Shift Timer'}
                            startDateTime={shiftStartTime}
                            endDateTime={shiftEndTime}
                          >
                            {
                              !shiftData?.endedOnUTC && getBreakBreach(shiftData)?.message && (
                                <Alert sx={{ justifyContent: 'center' }} severity={getBreakBreach(shiftData)?.type}>{getBreakBreach(shiftData)?.message}</Alert>
                              )
                            }
                          </Timer>
                        </Container>
                      )
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Status: <b data-auto-id="shiftStatus">{shiftStatusTypes?.find((type) => type.id === shiftData?.shiftStatusId)?.value}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      {
                        (isOwner(roles) || isManager(roles)) && shiftData?.user && (
                          <>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Driver Name:&nbsp;
                                <b>
                                  {shiftData?.userName}
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                External Driver Id:&nbsp;
                                <b>
                                  {shiftData?.userExtDriverId || '--'}
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Driver Email:&nbsp;
                                <b>
                                  {shiftData?.userEmail || '--'}
                                </b>
                              </Typography>
                            </Grid>
                          </>
                        )
                      }
                      {
                        (isOwner(roles) || isManager(roles)) && !shiftData?.user && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              margin="10px"
                            >
                              Driver:&nbsp;
                              <b>
                                Not Assigned
                              </b>
                            </Typography>
                          </Grid>
                        )
                      }
                      {
                        !shiftData?.startedOnUTC && shiftData?.user && (
                          <>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Driver Acceptance:&nbsp;
                                <b>
                                  {
                                    (shiftData?.userHasAccepted === null) && 'Pending'
                                  }
                                  {
                                    (shiftData?.userHasAccepted === true) && 'Accepted'
                                  }
                                  {
                                    (shiftData?.userHasAccepted === false) && 'Rejected'
                                  }
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        )
                      }
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Vehicle: <b> {shiftData?.vehicle?.registrationNumber || `${shiftData?.unlistedVehicleRego} (Not listed)`}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          State: <b>{shiftData?.jobsite?.address?.state?.value ?? '--'}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Store/Jobsite: <b>{shiftData?.jobsite?.name}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Dock: <b>{shiftData?.dock || '--'}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Route Id: <b>{shiftData?.routeId || '--'}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="body1"
                          margin="10px"
                        >
                          Location: <b>{shiftData?.vehicle?.location || '--'}</b>
                        </Typography>
                      </Grid>
                      {
                        shiftData?.scheduledStartUTC && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Check In Time: <b>{formatDateToLongDate(shiftData?.driverArrivalTimeLocal, true)}</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Scheduled Total Work Hours: <b>{shiftData?.scheduledTotalWorkHours?.toFixed(2)}</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Scheduled Start: <b>{formatDateToLongDate(shiftData?.scheduledStartUTC, true)}</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Scheduled End: <b>{formatDateToLongDate(shiftData?.scheduledEndUTC, true)}</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Scheduled Weight: <b>{shiftData?.scheduledWeight} kg</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Scheduled Kms: <b>{shiftData?.scheduledKms} kms</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Scheduled Drops: <b>{shiftData?.scheduledDrops}</b>
                              </Typography>
                            </Grid>
                          </>
                        )
                      }
                      {
                        (shiftData?.shiftStatusId === 2 || shiftData?.shiftStatusId === 3) && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Started: <b>{formatDateToLongDate(shiftData?.startedOnUTC, true)}</b>
                              </Typography>
                            </Grid>
                            {
                              shiftData?.endedOnUTC && (
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    variant="body1"
                                    margin="10px"
                                  >
                                    Ended: <b>{formatDateToLongDate(shiftData?.endedOnUTC, true)}</b>
                                  </Typography>
                                </Grid>
                              )
                            }
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                Start Odometer: <b>{`${shiftData?.startOdometerKms} kms`}</b>
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                              >
                                End Odometer: <b>{shiftData?.endOdometerKms > 0 ? `${shiftData?.endOdometerKms} kms` : '--'}</b>
                              </Typography>
                            </Grid>
                          </>
                        )
                      }
                      {
                        (shiftData?.instructions || shiftData?.extCompany) && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )
                      }
                      {
                        shiftData?.extCompany && (
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              margin="10px"
                            >
                              Shift Supervisor Name: <b>{shiftData?.shiftSupervisorName}</b>
                            </Typography>
                          </Grid>
                        )
                      }
                      {
                        shiftData?.extCompany && (
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              margin="10px"
                            >
                              Shift Supervisor Phone: <b>{shiftData?.shiftSupervisorPhone}</b>
                            </Typography>
                          </Grid>
                        )
                      }
                      {
                        shiftData?.extCompany && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              margin="10px"
                            >
                              External Company: <b>{shiftData?.extCompany?.name}</b>
                            </Typography>
                          </Grid>
                        )
                      }
                      {
                        shiftData?.instructions && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              margin="10px"
                            >
                              Instructions: <b>{shiftData?.instructions}</b>
                            </Typography>
                          </Grid>
                        )
                      }
                    </Grid>
                  </Grid>
                </Grid>
                {
                  isDriver(roles) && shiftData?.shiftStatusId === 2 && (
                    <Divider />
                  )
                }
                {
                  isDriver(roles) && shiftData?.shiftStatusId === 2 && (
                    <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={() => setShiftCommentModal(true)}
                        startIcon={<MessageIcon />}
                      >
                        OTD comment
                      </Button>
                    </Box>
                  )
                }
                {
                  isDriver(roles) && shiftData?.shiftStatusId === 2 && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          margin="10px"
                          paddingTop="10px"
                          align="center"
                        >
                          Breaks
                        </Typography>
                        <Divider />
                        {
                          getLastBreak() && !getLastBreak()?.endedOnUTC && (
                            <Grid item xs={12}>
                              <Box sx={{ py: 2 }}>
                                <Container maxWidth="xs">
                                  <Timer
                                    title="Break Timer"
                                    startDateTime={breakStartTime}
                                  />
                                </Container>
                              </Box>
                            </Grid>
                          )
                        }
                        {
                          getLastBreak()?.endedOnUTC && (
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                margin="10px"
                                paddingTop="10px"
                                align="center"
                              >
                                Last break taken <b>{formatDateToCalendarTime(getLastBreak()?.startedOnUTC)}</b> for <b>{getDateDifference(getLastBreak()?.startedOnUTC, getLastBreak()?.endedOnUTC)}</b>.
                              </Typography>
                            </Grid>
                          )
                        }
                        {
                          (!getLastBreak() || getLastBreak()?.endedOnUTC) && (
                            <Grid item xs={12}>
                              <Box sx={{ p: 2, textAlign: 'center' }}>
                                <Button
                                  variant="contained"
                                  onClick={handleStartBreak}
                                  data-auto-id="startBreakButton"
                                >
                                  Start Break
                                </Button>
                              </Box>
                            </Grid>
                          )
                        }
                        {
                          getLastBreak() && !getLastBreak()?.endedOnUTC && (
                            <Grid item xs={12}>
                              <Box sx={{ p: 2, textAlign: 'center' }}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    if (hasMinimumBreakTime()) {
                                      handleStopBreak();
                                    } else {
                                      setConfirmBreakEnd(true);
                                    }
                                  }}
                                  data-auto-id="stopBreakButton"
                                >
                                  Stop Break
                                </Button>
                              </Box>
                            </Grid>
                          )
                        }
                        <Divider />
                        {
                          !shiftData?.endedOnUTC && (
                            <Grid item xs={12}>
                              <Container maxWidth="xs">
                                <Box sx={{ py: 2 }}>
                                  <Button
                                    color="secondary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    disabled={Boolean(shiftData?.shiftBreaks.find((brk) => !brk.endedOnUTC))}
                                    onClick={handleEndShiftModalOpen}
                                    data-auto-id="endShiftButton"
                                  >
                                    End Shift
                                  </Button>
                                </Box>
                              </Container>
                            </Grid>
                          )
                        }
                      </Grid>
                    </Grid>
                  )
                }
                {
                  isDriver(roles) && shiftData?.jotFormUrl && (
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={handleJotFormSubmit}
                      >
                        Submit JOT Form
                      </Button>
                    </Box>
                  )
                }
                {
                  (!isDriver(roles) && shiftData?.user?.appUsagePattern === 1 && shiftData?.shiftStatusId < 3) && (
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                      <ConfirmButton
                        buttonLabel="Submit shift"
                        dialogTitle="Are you sure?"
                        showCancelButton
                        message="You are about to submit SMS only shift."
                        buttonColor="primary"
                        onConfirm={handleSubmitShift}
                        buttonSize="large"
                        sx={{ marginRight: 2 }}
                      />
                    </Box>
                  )
                }
                {
                  isDriver(roles) && (shiftData?.shiftStatusId === 1 || shiftData?.shiftStatusId === 0) && (
                    <>
                      {
                        !shiftData?.userHasAccepted && (
                          <Grid item xs={12}>
                            <Container maxWidth="xs">
                              <Box sx={{ py: 2 }}>
                                <ConfirmButton
                                  data-auto-id="acceptConfirmButton"
                                  buttonLabel="Accept"
                                  dialogTitle="Accept Shift"
                                  showCancelButton
                                  message="Your are about to accept the shift."
                                  onConfirm={handleAcceptShift}
                                  fullWidth
                                />
                              </Box>
                            </Container>
                          </Grid>
                        )
                      }
                      {
                        shiftData?.userHasAccepted
                        && (shiftData?.shiftStatusId === 1 || shiftData?.shiftStatusId === 0)
                        && !shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInType30Min) && (
                          <Grid item xs={12}>
                            <Container maxWidth="xs">
                              <Box sx={{ py: 2 }}>
                                <Button
                                  fullWidth
                                  size="large"
                                  variant="contained"
                                  onClick={handleCheckIn}
                                  data-auto-id="startShiftButton"
                                >
                                  Check In
                                </Button>
                              </Box>
                            </Container>
                          </Grid>
                        )
                      }
                      {
                        shiftData?.userHasAccepted
                        && (shiftData?.shiftStatusId === 1 || shiftData?.shiftStatusId === 0)
                        && shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInType30Min)
                        && !shiftData?.checkins?.find((checkin) => checkin.checkinType === checkInTypePreCheck) && (
                          <Grid item xs={12}>
                            <Container maxWidth="xs">
                              <Box sx={{ py: 2 }}>
                                <Button
                                  fullWidth
                                  size="large"
                                  variant="contained"
                                  onClick={navigateToStartShiftPage}
                                  data-auto-id="startShiftButton"
                                >
                                  Start Shift
                                </Button>
                              </Box>
                            </Container>
                          </Grid>
                        )
                      }
                      {
                        (shiftData?.userHasAccepted || shiftData?.userHasAccepted === null) && (
                          <Grid item xs={12}>
                            <Container maxWidth="xs">
                              <Box sx={{ py: 2 }}>
                                <ConfirmButton
                                  buttonLabel="Reject"
                                  okButtonLabel="Ok"
                                  data-auto-id="rejectConfirmButton"
                                  dialogTitle="Reject Shift"
                                  showCancelButton={false}
                                  message="Shift rejection is not allowed. Please contact your manager."
                                  buttonColor="secondary"
                                  fullWidth
                                />
                              </Box>
                            </Container>
                          </Grid>
                        )
                      }
                    </>
                  )
                }
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
                  <DataGrid
                    rows={shiftData?.shiftNRs || []}
                    columns={[
                      { field: 'date', headerName: 'Date', type: 'date', width: 120 },
                      {
                        field: 'store',
                        headerName: 'Store',
                        renderCell: (params: any) => {
                          const { value } = params;
                          return (
                            <Tooltip title={value}>
                              <span>{value}</span>
                            </Tooltip>
                          );
                        },
                        width: 200
                      },
                      { field: 'rego', headerName: 'Rego', width: 150 },
                      { field: 'amOrPM', headerName: 'Shift', width: 90 },
                      { field: 'startTime', headerName: 'Start Time', type: 'date', width: 130 },
                      { field: 'endTime', headerName: 'End Time', type: 'date', width: 130 },
                      {
                        field: 'actualWorkingHours',
                        headerName: 'Actual Working Hours',
                        type: 'number',
                        renderCell: (params: any) => {
                          const { value } = params;
                          return value?.toFixed(2);
                        },
                        width: 180,
                        filterable: false
                      },
                      { field: 'totalWorkingHours', headerName: 'Total Working Hours', type: 'number', width: 180, filterable: false },
                      {
                        field: 'comments',
                        headerName: 'Comments',
                        renderCell: (params: any) => {
                          const { value } = params;
                          return (
                            <Tooltip title={value}>
                              <span>{value}</span>
                            </Tooltip>
                          );
                        },
                        width: 300
                      }
                    ]}
                    onRowDoubleClick={!isDriver(roles) ? ({ row }) => openShiftNR(row) : undefined}
                    autoHeight
                    disableColumnMenu
                    hideFooter
                    disableColumnFilter
                  />
                </Container>
              </TabPanel>
              <TabPanel value={selectedTab} index={3}>
                <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
                  {
                    shiftData?.shiftBreaks && (
                      <>
                        <Hidden mdDown>
                          <DataGrid
                            disableColumnFilter
                            rows={shiftData.shiftBreaks}
                            columns={[
                              {
                                field: 'startedOnUTC',
                                headerName: 'Start Time',
                                filterable: false,
                                flex: 1,
                                type: 'date',
                                renderCell: (params) => {
                                  const { value } = params;
                                  return (
                                    <Tooltip title={formatDateToCalendarTime(value?.toString())}>
                                      <span>
                                        {formatDateToLongDate(value?.toString(), true)}
                                      </span>
                                    </Tooltip>
                                  );
                                }
                              },
                              {
                                field: 'endedOnUTC',
                                headerName: 'End Time',
                                flex: 1,
                                filterable: false,
                                type: 'date',
                                renderCell: (params: any) => {
                                  const { value } = params;
                                  return (
                                    value ? (
                                      <Tooltip title={formatDateToCalendarTime(value)}>
                                        <span>
                                          {formatDateToLongDate(value, true)}
                                        </span>
                                      </Tooltip>
                                    ) : <span>Ongoing</span>
                                  );
                                }
                              },
                              {
                                field: 'duration',
                                headerName: 'Duration',
                                sortable: false,
                                filterable: false,
                                flex: 1,
                                renderCell: (params) => {
                                  const { row } = params;
                                  return row.endedOnUTC && getDateDifference(row.startedOnUTC, row.endedOnUTC);
                                }
                              }
                            ]}
                            autoHeight
                            disableColumnMenu
                            hideFooter
                          />
                        </Hidden>
                        <Hidden mdUp>
                          {
                            shiftData.shiftBreaks.map((row) => (
                              <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
                                <CardHeader
                                  avatar={<CoffeeIcon />}
                                  title={formatDateToLongDate(row.startedOnUTC, true)}
                                  subheader={row.endedOnUTC ? `Duration: ${getDateDifference(row.startedOnUTC, row.endedOnUTC)}` : 'Ongoing'}
                                />
                              </Card>
                            ))
                          }
                          {
                            !shiftData?.shiftBreaks?.length && (
                              <Typography
                                variant="body1"
                                margin="10px"
                                paddingTop="10px"
                              >
                                No breaks taken.
                              </Typography>
                            )
                          }
                        </Hidden>
                      </>
                    )
                  }
                </Container>
              </TabPanel>
              <TabPanel value={selectedTab} index={4}>
                <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
                  {
                    sortByKey(shiftData?.shiftComments || [], 'text').map((row) => (
                      <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
                        <CardHeader
                          avatar={<MessageIcon />}
                          title={row.text}
                          subheader={`Type: ${commentTypes?.find((type) => type.id === row.shiftCommentTypeId)?.value}`}
                        />
                      </Card>
                    ))
                  }
                  {
                    !shiftData?.shiftComments?.length && (
                      <Typography
                        variant="body1"
                        margin="10px"
                        paddingTop="10px"
                      >
                        No comments.
                      </Typography>
                    )
                  }
                </Container>
              </TabPanel>
              <TabPanel value={selectedTab} index={5}>
                <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
                  {
                    shiftData?.preCheckAnswers && (
                      <>
                        <Hidden mdDown>
                          <DataGrid
                            disableColumnFilter
                            rows={shiftData.preCheckAnswers}
                            columns={[
                              {
                                field: 'preCheckQuestion',
                                headerName: 'Precheck Question',
                                sortable: false,
                                filterable: false,
                                flex: 1,
                                renderCell: (params) => {
                                  const { row } = params;
                                  return row.preCheckQuestion.question;
                                }
                              },
                              {
                                field: 'answer',
                                headerName: 'Answer',
                                sortable: false,
                                filterable: false,
                                renderCell: (params) => {
                                  const { row } = params;
                                  return <span>{row.answer ? 'Yes' : 'No'}</span>;
                                }
                              }, {
                                field: 'isBlocker',
                                headerName: 'Blocker?',
                                sortable: false,
                                filterable: false,
                                renderCell: (params) => {
                                  const { row } = params;
                                  return <span>{row.isBlocker ? 'Yes' : 'No'}</span>;
                                }
                              }
                            ]}
                            autoHeight
                            disableColumnMenu
                            hideFooter
                          />
                        </Hidden>
                        <Hidden mdUp>
                          {
                            shiftData.preCheckAnswers.map((row) => (
                              <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
                                <CardHeader
                                  avatar={<UserCheckIcon />}
                                  title={row.preCheckQuestion?.question}
                                  subheader={row.answer ? 'Yes' : 'No'}
                                />
                              </Card>
                            ))
                          }
                          {
                            !shiftData?.preCheckAnswers?.length && (
                              <Typography
                                variant="body1"
                                margin="10px"
                                paddingTop="10px"
                              >
                                No precheck answers.
                              </Typography>
                            )
                          }
                        </Hidden>
                      </>
                    )
                  }
                </Container>
              </TabPanel>
              <TabPanel value={selectedTab} index={2}>
                <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
                  {
                    (shiftData?.checkins || []).map((row, index) => {
                      const mapUrl = `https://maps.google.com/maps?q=${row.latitude},%20${row.longitude}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
                      return (
                        <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
                          <CardHeader
                            avatar={<MapIcon />}
                            title={formatDateToLongDate(row.createdOnUtc)}
                            subheader={`Type: ${getCheckInType(row.checkinType)}`}
                          />
                          {
                            (row.latitude ?? 0) !== 0 && (row.longitude ?? 0) !== 0 && (
                              <CardContent>
                                <iframe
                                  title={`google map ${index}`}
                                  width="100%" height="400"
                                  src={mapUrl}
                                  scrolling="no"
                                />
                              </CardContent>
                            )
                          }
                        </Card>
                      );
                    })
                  }
                  {
                    !shiftData?.checkins?.length && (
                      <Typography
                        variant="body1"
                        margin="10px"
                        paddingTop="10px"
                      >
                        No data
                      </Typography>
                    )
                  }
                </Container>
              </TabPanel>
            </CardContent>
          </Card>
        </Container>
      </Box>
      {
        shiftData && (
          <Dialog
            open={endShiftModal}
            onClose={handleEndShiftClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">End Shift</DialogTitle>
            <Formik
              initialValues={{
                endOdometerKms: '',
                comment: ''
              }}
              validationSchema={Yup.object().shape({
                comment: Yup.string().required('Comment is required'),
                endOdometerKms: Yup.number().min((shiftData.startOdometerKms + 1), `Odometer reading must be greater than ${shiftData.startOdometerKms} kms`).required('Odometer reading is required')
              })}
              onSubmit={handleShiftEnd}
            >
              {
                ({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  dirty,
                  isValid
                }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Typography variant="body1">
                        Start Odometer: <b>{shiftData?.startOdometerKms} kms</b>
                      </Typography>
                      <TextField
                        type="number"
                        error={Boolean(touched.endOdometerKms && errors.endOdometerKms)}
                        fullWidth
                        helperText={touched.endOdometerKms && errors.endOdometerKms}
                        label="End Odometer Kms"
                        data-auto-id="endOdometerKmsTextField"
                        margin="normal"
                        name="endOdometerKms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.endOdometerKms}
                        variant="outlined"
                        required
                        placeholder={`Started at ${shiftData?.startOdometerKms} kms`}
                      />
                      <TextField
                        error={Boolean(touched.comment && errors.comment)}
                        fullWidth
                        helperText={touched.comment && errors.comment}
                        label="Comment"
                        data-auto-id="commentTextField"
                        margin="normal"
                        name="comment"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.comment}
                        variant="outlined"
                        required
                        multiline
                      />
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                      <Button
                        onClick={handleEndShiftClose}
                        color="primary"
                        variant="outlined"
                        data-auto-id="dialogEndShiftCancelButton"
                      >
                        Cancel
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={!isValid || !dirty}
                        data-auto-id="dialogEndShiftButton"
                      >
                        End Shift
                      </Button>
                    </DialogActions>
                  </form>
                )
              }
            </Formik>
          </Dialog>
        )
      }
      {
        shiftData?.shiftStatusId === 2 && (
          <AddCommentModal
            comments={shiftData?.shiftComments}
            addComment={handleAddShiftCommentSubmit}
            drops={shiftData?.scheduledDrops ?? 0}
            isOpen={shiftCommentModal}
            closeModal={() => {
              setShiftCommentModal(false);
            }}
          />
        )
      }
      {
        shiftData && shiftData.jotFormUrl && (
          <Dialog
            open={submitJotFormModal}
            onClose={handleSubmitJotFormClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm JOT form submission</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please confirm if you have submitted the JOT form.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus variant="outlined" onClick={handleSubmitJotFormClose} color="primary">
                No
              </Button>
              <Button variant="contained" onClick={handleSubmitJotFormConfirm} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      <Dialog
        open={confirmBreakEnd}
        onClose={() => setConfirmBreakEnd(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Break Stop</DialogTitle>
        <DialogContent>
          <Alert severity="warning">Break should be at least 30mins</Alert>
          <DialogContentText id="alert-dialog-description">
            Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="outlined" onClick={() => setConfirmBreakEnd(false)} color="primary">
            No
          </Button>
          <Button
            variant="contained"
            onClick={
              () => {
                setConfirmBreakEnd(false);
                handleStopBreak();
              }
            }
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetail);

import AppConstants from '../constants/AppConstants';
import FormattedUserProfile from '../models/FormattedUserProfile';
import configureAxios from '../proxy/ConfigureAxios';
import { Client, UserProfileReq } from '../proxy/proxy';

const UserProfileService = {
  get: () => configureAxios().get15()
    .then((resp) => new FormattedUserProfile(resp)),
  update: (data: UserProfileReq) => configureAxios().updatePOST12(data)
    .then((resp) => new FormattedUserProfile(resp)),
  checkGST: () => configureAxios().updateGST2()
    .then((resp) => new FormattedUserProfile(resp))
};

export default UserProfileService;

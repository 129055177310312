import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Hidden, Dialog, DialogActions, DialogContent, DialogTitle, Container, Box, Card, CardHeader, Divider, CardContent, Button, TextField, IconButton, MenuItem, ListItemText } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import {
  Edit as EditIcon,
  DollarSign as PayrollIcon
} from 'react-feather';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Table from '../components/Table';
import MobileDataList from '../components/MobileDataList';
import PayrollService from '../services/PayrollService';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatISODate, formatDateToOnlyDate, formatInputDate, addDaysToDate, convertToLocalDate, sortByKey } from '../utils/common';
import { refreshData, reset, changePage, changeSortModel } from '../store/actions/payrollPeriodActions';
import { RootState } from '../store/store';
import EnumService from '../services/EnumService';
import { EnumRes, PayrollPeriodRes } from '../proxy/proxy';

interface Props {
  allRows: Array<PayrollPeriodRes>,
  totalRowCount: number,
  currentPage: number,
  sortModel: any,
  fetchAllRows: any,
  resetPage: any,
  onPageChange: any,
  onSort: any,

  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const PayrollPeriod: React.FC<Props> = ({
  allRows,
  totalRowCount,
  currentPage,
  sortModel,

  fetchAllRows,
  resetPage,
  onPageChange,
  onSort,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const navigate = useNavigate();

  const [hasUnprocessedPeriods, setHasUnprocessedPeriods] = useState(false);
  const [addPayrollPeriodModal, setAddPayrollPeriodModal] = useState(false);
  const [allStates, setAllStates] = useState<EnumRes[]>([]);

  const fetchAllStates = () => {
    // Get States
    const promises: Array<Promise<any>> = [
      EnumService.getEnums('states')
    ];
    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then((result) => {
        setAllStates(sortByKey(result[0], 'value'));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        showBusyIndicator();
      });
  };

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  const addPayrollPeriod = () => {
    setAddPayrollPeriodModal(true);
  };

  const closeAddPayrollPeriodModal = () => {
    setAddPayrollPeriodModal(false);
  };

  const handleAddPayrollPeriod = (values: any) => {
    const payload = {
      startTimeLocal: convertToLocalDate(values.startTimeLocal),
      endTimeLocal: convertToLocalDate(values.endTimeLocal),
      applicableStateId: values.stateId === '' ? null : values.stateId,
    };
    clearAllAlerts();
    showBusyIndicator();
    PayrollService.addPayrollPeriod(payload)
      .then(() => {
        showAlert(formatSuccessMessage('Payroll Period added successfully.'));
        closeAddPayrollPeriodModal();
        refresh();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const onRowClick = ({ id }: any) => {
    navigate(`/app/payroll/periods/${id}`);
  };

  const columns = [
    {
      field: 'applicableState',
      headerName: 'State',
      flex: 1,
      type: 'string',
      renderCell: (params: any) => {
        const { value } = params;
        return value?.value;
      }
    },
    {
      field: 'startTimeLocal',
      headerName: 'From',
      flex: 1,
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyDate(value);
      }
    },
    {
      field: 'endTimeLocal',
      headerName: 'To',
      flex: 1,
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyDate(value);
      }
    },
    {
      field: 'payrollPeriodStatus',
      headerName: 'Status',
      flex: 1
    },
    {
      field: 'queuedOnLocal',
      headerName: 'Processed On',
      flex: 1,
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return value ? formatDateToOnlyDate(value) : '';
      }
    },
    {
      field: 'createdByUser',
      headerName: 'Created By',
      flex: 1,
      type: 'string',
      renderCell: (params: any) => {
        const { value } = params;
        return value === null ? '' : `${value?.firstName} ${value?.lastName}`;
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return `$ ${(value ?? 0).toFixed(2)}`;
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;

        return (
          <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
            <EditIcon />
          </IconButton>
        );
      },
      renderHeader: () => (<span />)
    }
  ];

  useEffect(() => {
    fetchAllStates();
    fetchAllRows();
  }, []);

  useEffect(() => {
    setHasUnprocessedPeriods(Boolean(allRows?.find((period) => false)));
  }, [allRows]);

  const renderEachMobileCard = (row: PayrollPeriodRes) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={<PayrollIcon />}
        action={
          (
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>
          )
        }
        title={`${formatDateToOnlyDate(row.startTimeLocal)} to ${formatDateToOnlyDate(row.endTimeLocal)}`}
        subheader={
          [
            row.payrollPeriodStatus && `State: ${row.applicableState?.value ?? ''}`,
            row.payrollPeriodStatus && `Status: ${row.payrollPeriodStatus}`,
            row.queuedOnLocal && `Processed On: ${row.queuedOnLocal ? formatDateToOnlyDate(row.queuedOnLocal) : ''}`
          ].filter(Boolean).join(', ')
        }
        onClick={() => onRowClick(row)}
      />
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>Pay Periods | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              action={
                (
                  <>
                    <IconButton onClick={refresh} data-auto-id="refreshIconButton">
                      <RefreshIcon />
                    </IconButton>
                    <IconButton disabled={hasUnprocessedPeriods} onClick={addPayrollPeriod} data-auto-id="addIconButton">
                      <AddIcon />
                    </IconButton>
                  </>
                )
              }
              title="Pay Periods"
            />
            <Divider />
            <CardContent>
              <Hidden mdDown>
                <Table
                  rows={allRows}
                  columns={columns}
                  onRowDoubleClick={onRowClick}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                  sortModel={sortModel}
                  onSortModelChange={onSort}
                />
              </Hidden>
              <Hidden mdUp>
                <MobileDataList
                  rows={allRows}
                  renderEachRow={renderEachMobileCard}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Dialog
        open={addPayrollPeriodModal}
        onClose={closeAddPayrollPeriodModal}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Add Payroll Period</DialogTitle>
        <Formik
          initialValues={{
            startTimeLocal: '',
            endTimeLocal: '',
            stateId: ''
          }}
          validationSchema={Yup.object().shape({
            startTimeLocal: Yup.date().required('Start Date is required'),
            endTimeLocal: Yup.date().required('End Date is required')
          })}
          onSubmit={handleAddPayrollPeriod}
        >
          {
            ({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    fullWidth
                    label="State"
                    name="stateId"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.stateId}
                    variant="outlined"
                    error={Boolean(touched.stateId && errors.stateId)}
                    helperText={touched.stateId && errors.stateId}
                  >
                    <MenuItem aria-label="None" value="">
                      <ListItemText primary="&nbsp;" />
                    </MenuItem>
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={Boolean(touched.startTimeLocal && errors.startTimeLocal)}
                    fullWidth
                    helperText={touched.startTimeLocal && errors.startTimeLocal}
                    label="Start Date (In Local)"
                    margin="normal"
                    name="startTimeLocal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.startTimeLocal}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  <TextField
                    error={Boolean(touched.endTimeLocal && errors.endTimeLocal)}
                    fullWidth
                    helperText={touched.endTimeLocal && errors.endTimeLocal}
                    label="End Date (In Local)"
                    margin="normal"
                    name="endTimeLocal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.endTimeLocal}
                    variant="outlined"
                    type="date"
                    InputProps={{ inputProps: { min: values.startTimeLocal ? formatInputDate(values.startTimeLocal) : '' } }}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button
                    onClick={closeAddPayrollPeriodModal}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )
          }
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  allRows: state.payrollPeriod?.allRows,
  totalRowCount: state.payrollPeriod?.allRows?.length,
  currentPage: state.payrollPeriod?.currentPage,
  sortModel: state.payrollPeriod?.sortModel,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => {
    // Get Payroll Periods
    dispatch(refreshData());
  },
  resetPage: () => dispatch(reset()),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),

  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollPeriod);

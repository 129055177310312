import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Alert, Typography } from '@mui/material';
import ShiftTable from './ShiftTable';
import { searchByTextScheduled, searchByWarningScheduled, changePageScheduled, changeSortModelScheduled, changeFilterByColumnScheduled, updateSelectionStatusScheduled, refreshData } from '../../store/actions/shiftActions';
import FormattedShift from '../../models/FormattedShift';
import { RootState } from '../../store/store';
import ShiftsService from '../../services/ShiftsService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatErrorMessage, formatSuccessMessage, isDriver } from '../../utils/common';
import LocalStorageService from '../../services/LocalStorageService';

interface Props {
  allRows: Array<FormattedShift>,
  visibleRows: Array<FormattedShift>,
  totalRowCount: number,
  currentPage: number;
  searchedText: string,
  sortModel: any,
  selectedWarning: string,
  filterByText: any,
  filterByWarning: any,
  filterByCol: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any,
  onSelectionModelChange: any,
  fetchAllRows: any,

  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const ScheduledShifts: React.FC<Props> = ({
  allRows,
  visibleRows,
  totalRowCount,
  currentPage,
  searchedText,
  sortModel,
  selectedWarning,
  filterByText,
  filterByWarning,
  filterByCol,
  onPageChange,
  onSort,
  onChangeFilterByColumn,
  onSelectionModelChange,
  fetchAllRows,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [open, setOpen] = useState(false);
  const selectionModel = allRows?.filter((row: any) => row.selected).map((row) => row.id) ?? [];
  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();
  const bulkDelete = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.bulkDeleteShifts(selectionModel)
      .then(() => {
        showAlert(formatSuccessMessage(`${selectionModel?.length} shifts deleted successfully`));
        fetchAllRows();
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onConfirm = () => {
    handleClose();
    bulkDelete();
  };

  return (
    <>
      <ShiftTable
        shifts={visibleRows}
        totalRowCount={totalRowCount}
        shiftStatus={1}
        currentPage={currentPage}
        searchedText={searchedText}
        sortModel={sortModel}
        selectedWarning={selectedWarning}
        filterByCol={filterByCol}
        onFilterByText={filterByText}
        onFilterByWarning={filterByWarning}
        onPageChange={onPageChange}
        onSort={onSort}
        onChangeFilterByColumn={onChangeFilterByColumn}
        selectableRows={!isDriver(roles)}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        onBulkDelete={() => setOpen(true)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Delete Scheduled Shifts</DialogTitle>
        <DialogContent>

          <Alert severity="error">Are you sure?. All selected shits will be deleted permanently.</Alert>
          <Typography variant="h2" sx={{ marginTop: 1, marginBottom: 1 }}>Selected Shifts: {selectionModel?.length}</Typography>

        </DialogContent>
        <DialogActions>
          <Button autoFocus
            variant="outlined"
            onClick={handleClose} color="primary"
            data-auto-id="confirmDialogCancelButton"
          >
            Cancel
          </Button>
          <Button variant="contained"
            onClick={onConfirm}
            color="primary"
            data-auto-id="confirmDialogOkButton"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  allRows: state.shift?.scheduled?.allRows,
  visibleRows: state.shift?.scheduled?.filteredRows,
  totalRowCount: state.shift?.scheduled?.allRows?.length,
  currentPage: state.shift?.scheduled?.currentPage,
  searchedText: state.shift?.scheduled?.searchedText,
  selectedWarning: state.shift?.scheduled?.filterBy,
  sortModel: state.shift?.scheduled?.sortModel,
  filterByCol: state.shift?.scheduled?.filterByCol,
});

const mapDispatchToProps = (dispatch: any) => ({
  filterByText: (text: string) => dispatch(searchByTextScheduled(text)),
  filterByWarning: (text: string) => dispatch(searchByWarningScheduled(text)),
  onPageChange: (page: number) => dispatch(changePageScheduled(page)),
  onSort: (sortModel: any) => dispatch(changeSortModelScheduled(sortModel)),
  onChangeFilterByColumn: (col: string) => dispatch(changeFilterByColumnScheduled(col)),
  onSelectionModelChange: (visibleRows: Array<any>, selectedRowIds: Array<number>) => dispatch(updateSelectionStatusScheduled(visibleRows, selectedRowIds)),
  fetchAllRows: () => dispatch(refreshData()),

  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledShifts);

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import BackIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Container,
  Typography,
  CardActions,
  Button,
  IconButton
} from '@mui/material';
import AppConstants from '../constants/AppConstants';

const Support = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Support | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container>
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title="Support"
            />
            <Divider />
            <CardContent>
              <Box sx={{ mb: 3, minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: 'center' }}
                >
                  For any queries write to us @ <a href={`mailto:${AppConstants.SUPPORT_EMAIL}`}>{AppConstants.SUPPORT_EMAIL}</a>
                </Typography>
              </Box>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined" data-auto-id="cancelButton">
                Ok
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Support;

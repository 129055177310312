import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  Divider,
  Box,
  Typography,
  ListItemText,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SubmitFormButton from '../SubmitFormButton';
import CompanyService from '../../services/CompanyService';
import AppConstants from '../../constants/AppConstants';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage } from '../../utils/common';

interface Props {
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const CompanyPayRatesDetails: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const allRoles: any = AppConstants.USER_ROLES;

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    const payload = {
      payPeriodStartDay: Number(values.payPeriodStartDay),
      payRollFrequency: Number(values.payRollFrequency),
      payRate: values.role ? {
        baseRate: Number(values.baseRate),
        publicHolidayRate: Number(values.publicHolidayRate),
        afterHoursRate: Number(values.afterHoursRate),
        saturdayRate: Number(values.saturdayRate),
        sundayRate: Number(values.sundayRate)
      } : null,
      role: values.role || null
    };

    CompanyService.updatePayRates(payload)
      .then(() => {
        showAlert(formatSuccessMessage('Pay rates updated successfully'));
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Typography sx={{ marginTop: 2, marginBottom: 2 }} variant="h4">Reset Role Wise Pay Rates</Typography>
      <Formik
        initialValues={{
          baseRate: 0,
          publicHolidayRate: 0,
          afterHoursRate: 0,
          saturdayRate: 0,
          sundayRate: 0,
          role: ''
        }}
        validationSchema={Yup.object().shape({
          role: Yup.string().required('Role is required'),

          baseRate: Yup.number()
            .when('role', {
              is: (role = '') => role.length > 0,
              then: Yup.number().moreThan(0, 'Base Rate must be greater than 0')
            }),
          publicHolidayRate: Yup.number()
            .when('role', {
              is: (role = '') => role.length > 0,
              then: Yup.number().moreThan(0, 'Public Holiday Rate must be greater than 0')
            }),
          saturdayRate: Yup.number()
            .when('role', {
              is: (role = '') => role.length > 0,
              then: Yup.number().moreThan(0, 'Saturday Rate must be greater than 0')
            }),
          sundayRate: Yup.number()
            .when('role', {
              is: (role = '') => role.length > 0,
              then: Yup.number().moreThan(0, 'Sunday Rate must be greater than 0')
            })
        })}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          dirty,
          isValid
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Role"
                  name="role"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  select
                  InputLabelProps={{ shrink: true }}
                  value={values.role}
                  variant="outlined"
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                >
                  <MenuItem aria-label="None" value="">
                    <ListItemText primary="&nbsp;" />
                  </MenuItem>
                  {Object.keys(allRoles).map((role) => (
                    <MenuItem key={allRoles[role]} value={allRoles[role]}>
                      <ListItemText primary={allRoles[role]} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Divider />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.baseRate && errors.baseRate)}
                  fullWidth
                  helperText={touched.baseRate && errors.baseRate}
                  label="Base Rate"
                  margin="normal"
                  name="baseRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.baseRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  required
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.publicHolidayRate && errors.publicHolidayRate)}
                  fullWidth
                  helperText={touched.publicHolidayRate && errors.publicHolidayRate}
                  label="Public Holiday Rate"
                  margin="normal"
                  name="publicHolidayRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.publicHolidayRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  required
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.saturdayRate && errors.saturdayRate)}
                  fullWidth
                  helperText={touched.saturdayRate && errors.saturdayRate}
                  label="Saturday Rate"
                  margin="normal"
                  name="saturdayRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.saturdayRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  required
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.sundayRate && errors.sundayRate)}
                  fullWidth
                  helperText={touched.sundayRate && errors.sundayRate}
                  label="Sunday Rate"
                  margin="normal"
                  name="sundayRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sundayRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Divider />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 2,
                paddingBottom: 2
              }}
            >
              <SubmitFormButton
                dirty={dirty}
                isValid={isValid}
                onConfirm={handleSubmit}
                message="You are about reset pay rates for all uses matching selected role. Please review your changes before saving."
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPayRatesDetails);

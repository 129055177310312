import AppConstants from '../constants/AppConstants';
import configureAxios from '../proxy/ConfigureAxios';
import { Client } from '../proxy/proxy';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const InvoicesService = {
  findInvoices: (year: number, month: number) => configureAxios().getAllPOST({ year, month }).then((response) => response)
};

export default InvoicesService;

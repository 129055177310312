import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Hidden,
  Tab,
  Tabs
} from '@mui/material';
import AppConstants from '../constants/AppConstants';
import TabPanel from '../components/TabPanel';
import CompanyPayRatesDetails from '../components/company/CompanyPayRatesDetails';
import CompanyPayFrequency from '../components/company/CompanyPayFrequency';

interface Props {}

const PayrollSettings: React.FC<Props> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Settings | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader title="Settings" />
            <Divider />
            <CardContent>
              <Hidden mdUp>
                <Tabs
                  data-auto-id="allShiftTabsMobile"
                  variant="fullWidth"
                  value={selectedTab}
                  onChange={handleTabChange}
                >
                  <Tab label="Pay Frequency" />
                  <Tab label="Pay Rates" />
                </Tabs>
              </Hidden>
              <Hidden mdDown>
                <Tabs
                  data-auto-id="allShiftTabs"
                  value={selectedTab}
                  onChange={handleTabChange}
                >
                  <Tab label="Pay Frequency" />
                  <Tab label="Pay Rates" />
                </Tabs>
              </Hidden>
              <Divider />
              <TabPanel value={selectedTab} index={0}>
                <CompanyPayFrequency />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <CompanyPayRatesDetails />
              </TabPanel>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

export default connect(mapStateToProps)(PayrollSettings);

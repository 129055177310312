import AppConstants from '../constants/AppConstants';
import configureAxios from '../proxy/ConfigureAxios';
import { Client } from '../proxy/proxy';

// const api = new AccountApi(new Configuration({ basePath: AppConstants.BASE_API_URL }));
// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const AccountService = {
  confirmEmail: (email: string, token: string) => configureAxios().confirmEmail({
    code: token,
    email
  }),
  resetPassword: (email: string, password: string, token: string) => configureAxios().resetPassword({
    email,
    newPassword: password,
    passwordResetToken: token
  }),
  sendResetPasswordLink: (email: string) => configureAxios().forgotPassword({
    email
  }),
  changePassword: (oldPassword: string, newPassword: string) => configureAxios().changePassword({
    oldPassword,
    newPassword
  })
};

export default AccountService;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Hidden,
  Container,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  IconButton,
  Toolbar,
  Tooltip,
  Grid
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { CheckSquare as CheckSquareIcon, Eye as EditIcon } from 'react-feather';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../components/Table';
import MobileDataList from '../components/MobileDataList';
import GlobalSearch from '../components/GlobalSearch';
import AppConstants from '../constants/AppConstants';
import {
  refreshData,
  reset,
  searchByText,
  changePage,
  changeSortModel
} from '../store/actions/FormSubmissionsActions';
import { RootState } from '../store/store';
import ConfirmButton from '../components/ConfirmButton';
import { hide, show } from '../store/actions/busyIndicatorActions';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../store/actions/alertActions';
import {
  formatDateToCalendarTime,
  formatDateToLongDate,
  formatErrorMessage,
  formatSuccessMessage
} from '../utils/common';
import PreCheckQuestionsService from '../services/PreCheckQuestionsService';
import {
  FormQuestionMediaReq,
  FormQuestionMultiChoiceReq,
  FormQuestionNumericReq,
  FormQuestionSignatureReq,
  FormQuestionSingleChoiceReq,
  FormQuestionTextualReq,
  FormReq,
  FormRes,
  GetFormSubmissionsReq,
  PreCheckQuestionRes
} from '../proxy/proxy';
import FormsService from '../services/FormsService';
import FormPreview from './FormPreview';
import DateRangeSelector from '../components/DateRangeSelector';
import FormAnswers from './FormAnswers';

interface Props {
  visibleRows?: Array<PreCheckQuestionRes>;
  totalRowCount?: number;
  existingFormId?: number;
  searchedText?: string;
  currentPage?: number;
  sortModel?: any;
  fetchAllRows: any;
  resetPage: any;
  filterByText?: any;
  onPageChange?: any;

  startDate: string;
  endDate: string;

  onSort?: (model: any) => any;

  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const FormSubmissions: React.FC<Props> = ({
  visibleRows = [],
  totalRowCount = 0,
  existingFormId = 0,
  searchedText,
  currentPage = 0,
  sortModel,

  fetchAllRows,
  resetPage,
  filterByText,
  onPageChange,

  startDate,
  endDate,

  onSort = () => null,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const navigate = useNavigate();

  const [start, setStart] = useState<string>(startDate);
  const [end, setEnd] = useState<string>(endDate);

  const [formState, setFormState] = useState<FormReq>({
    name: '',
    description: '',
    onOpenMessage: '',
    onCompleteMessage: '',
    formLinks: [],
    appRoles: []
  });
  type FormQuestionTypes =
    | FormQuestionTextualReq
    | FormQuestionNumericReq
    | FormQuestionMultiChoiceReq
    | FormQuestionSingleChoiceReq
    | FormQuestionMediaReq
    | FormQuestionSignatureReq;
  const [fieldStates, setFieldStates] = useState<FormQuestionTypes[]>([]);
  const [submissionId, setSubmissionId] = useState<number>(0);

  const onRowClick = ({ formId, id }: any) => {
    setSubmissionId(id);
    FormsService.getFormById(Number(formId))
      .then((result) => {
        const receivedForm: FormRes = result;
        setFormState((prevState) => ({
          ...prevState,
          name: receivedForm.name ?? '',
          description: receivedForm.description ?? '',
          onOpenMessage: receivedForm.onOpenMessage ?? '',
          onCompleteMessage: receivedForm.onCompleteMessage ?? '',
          formLinks: receivedForm.formLinks ?? [],
          appRoles: (receivedForm.formToAppRoleMap ?? []).map(
            (item) => item.appRole?.name
          ),
          formType: receivedForm.formType ?? null,
          id: receivedForm.id ?? null
        }));
        setFieldStates(
          receivedForm?.formQuestions?.map((element) =>
            JSON.parse(JSON.stringify(element))) || []
        );
      })
      .catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
    // navigate(`/app/myforms/submit/${formId}/${id}`);
  };

  const columns = [
    {
      field: 'user.firstName',
      headerName: 'User',
      flex: 1,
      valueGetter: (params: any) =>
        `${params.row.user?.firstName} ${params.row.user?.lastName}` || 'N/A'
    },
    {
      field: 'submittedOnUtc',
      headerName: 'Submitted on',
      flex: 1,
      type: 'date',
      filterable: false,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={formatDateToCalendarTime(value, true) || ''}>
            <span>{formatDateToLongDate(value, true)}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <>
            <IconButton
              size="medium"
              aria-label="edit"
              onClick={() => onRowClick(row)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => <span />
    }
  ];

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const refresh = (starting: string = start, ending: string = end) => {
    const data: GetFormSubmissionsReq = {
      dateTimeInterval: {
        startTimeUtc: starting,
        endTimeUtc: ending
      },
      formIds: [existingFormId]
    };
    resetPage();
    fetchAllRows(data);
  };

  const handleDateRangeChange = (starting: string, ending: string) => {
    setStart(starting);
    setEnd(ending);
    refresh(starting, ending);
  };

  useEffect(() => {
    const data: GetFormSubmissionsReq = {
      dateTimeInterval: {
        startTimeUtc: startDate,
        endTimeUtc: endDate
      },
      formIds: [existingFormId]
    };
    fetchAllRows(data);
  }, []);

  const renderEachMobileCard = (row: FormRes) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={<CheckSquareIcon />}
        action={(
          <>
            <IconButton aria-label="edit" onClick={() => onRowClick(row)}>
              <EditIcon />
            </IconButton>
          </>
        )}
        title={row.name} // Displaying the name field
        subheader={(
          <>
            <div>{`Description: ${row.description}`}</div>
          </>
        )}
      />
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>Submissions | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Grid container spacing={1} direction="row" justifyContent="center">
          <Grid
            item
            md={6}
            sx={{
              position: 'sticky',
              top: 0,
              alignSelf: 'flex-start' // Ensures the grid item takes the full height it requires
            }}
          >
            <Container maxWidth="xl">
              <Card>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <CardHeader
                    // action={(
                    //   <IconButton onClick={refresh}>
                    //     <RefreshIcon />
                    //   </IconButton>
                    // )}
                    title="Submissions"
                  />
                  <DateRangeSelector
                    startDate={start}
                    endDate={end}
                    allowFutureDates
                    onChange={handleDateRangeChange}
                  />
                </Box>
                <Divider />
                <CardContent>
                  {/* <Toolbar style={{ justifyContent: 'flex-end' }}>
                    <GlobalSearch
                      searchedText={searchedText}
                      onSearchTextChange={onSearchTextChange}
                      width="300px"
                    />
                  </Toolbar> */}
                  <Hidden mdDown>
                    <Table
                      rows={visibleRows}
                      columns={columns}
                      onRowDoubleClick={onRowClick}
                      totalRows={totalRowCount}
                      page={currentPage}
                      onPageChange={onPageChange}
                      sortModel={sortModel}
                      onSortModelChange={onSort}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <MobileDataList
                      rows={visibleRows}
                      renderEachRow={renderEachMobileCard}
                      totalRows={totalRowCount}
                      page={currentPage}
                      pageSize={8}
                      onPageChange={onPageChange}
                    />
                  </Hidden>
                </CardContent>
              </Card>
            </Container>
          </Grid>
          {submissionId > 0 && (
            // <Grid item md={6}>
            //   <FormPreview
            //     key={submissionId}
            //     currentFormState={{
            //       ...formState,
            //       companyId: 0
            //     }}
            //     currentFieldStates={fieldStates}
            //     submissionId={submissionId}
            //   />
            // </Grid>
            <Grid item md={6}>
              <FormAnswers
                key={submissionId}
                submissionId={submissionId}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.formSubmissions?.filteredRows,
  totalRowCount: state.formSubmissions?.allRows?.length,
  currentPage: state.formSubmissions?.currentPage,
  searchedText: state.formSubmissions?.searchedText,
  sortModel: state.formSubmissions?.sortModel,
  startDate: state.shift?.startDate,
  endDate: state.shift?.endDate,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: (data: GetFormSubmissionsReq) => dispatch(refreshData(data)),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),

  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmissions);

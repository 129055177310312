import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';
import { FormRes, FormSubmissionRes } from '../proxy/proxy';
import FormsService from '../services/FormsService';
import { showAlert } from '../store/actions/alertActions';
import { formatErrorMessage, isDriver } from '../utils/common';
import LocalStorageService from '../services/LocalStorageService';

interface FormAnswersProps {
  submissionId: number;
}

const FormAnswers: React.FC<FormAnswersProps> = ({ submissionId }) => {
  const [formAnswers, setFormAnswers] = useState<FormSubmissionRes>();

  useEffect(() => {
    const localStorageService = LocalStorageService.getService();
    const roles = localStorageService.getUserRoles();

    if (submissionId > 0) {
      if (isDriver(roles)) {
        FormsService.getSubmissionByUser(submissionId)
          .then((result) => {
            setFormAnswers(result);
          })
          .catch((err) => {
            showAlert(formatErrorMessage(JSON.parse(err.response)));
          })
          .then(() => {
            //   hideBusyIndicator();
          });
      } else {
        FormsService.getSubmission(submissionId)
          .then((result) => {
            setFormAnswers(result);
          })
          .catch((err) => {
            showAlert(formatErrorMessage(JSON.parse(err.response)));
          })
          .then(() => {
            //   hideBusyIndicator();
          });
      }
    }
  }, []);

  return (
    <TableContainer component={Paper} elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid #5664d2' }}>
              Question
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid #5664d2' }}>
              Answer
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(formAnswers?.formAnswers ?? []).map((question: any) => (
            <TableRow key={question?.id} hover>
              <TableCell sx={{ verticalAlign: 'top', fontWeight: 500 }}>
                {question?.title}
              </TableCell>
              <TableCell sx={{ backgroundColor: '#f1f8ff', padding: 2 }}>
                {(() => {
                  switch (question?.__discriminator) {
                    case 'MultiChoice':
                      return (
                        <span style={{ whiteSpace: 'pre-line', color: '#1976d2', fontWeight: 600 }}>
                          {question?.answers?.join(', ')}
                        </span>
                      );
                    case 'Media':
                      return (
                        <div>
                          {question?.answerSasUrls.map((answerSasUrl: string, index: number) => (
                            <Button
                              key={index}
                              variant="contained"
                              color="primary"
                              href={answerSasUrl}
                              download
                              sx={{ margin: 0.5 }}
                            >
                              Download File {index + 1}
                            </Button>
                          ))}
                        </div>
                      );
                    case 'Signature':
                      return (
                        <img
                          src={question?.answerSasUrl}
                          alt="signature"
                          style={{
                            width: '180px',
                            height: '100px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            padding: '4px'
                          }}
                        />
                      );
                    default:
                      return (
                        <span style={{ whiteSpace: 'pre-wrap', color: '#1976d2', fontWeight: 600 }}>
                          {question?.answer}
                        </span>
                      );
                  }
                })()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormAnswers;

import { useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  AppBar,
  Typography,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  User as UserIcon,
  LogOut as LogoutIcon,
  Phone as PhoneIcon,
  Globe as GlobeIcon
} from 'react-feather';
import { connect } from 'react-redux';
import { Menu as MenuIcon } from '@mui/icons-material';
import Logo from './Logo';
import LocalStorageService from '../services/LocalStorageService';
import { getMyAccountLink, getSupportLink, isOwner, isManager, isDriver } from '../utils/common';

const AppNavbar = ({ userProfile, companyProfile, onMobileNavOpen = null, ...rest }) => {
  const localStorageService = LocalStorageService.getService();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const roles = localStorageService.getUserRoles();
  const user = {
    avatar: '/static/images/avatars/avatar.svg',
    jobTitle: roles?.join(', '),
    name: userProfile?.name,
    initials: (userProfile?.firstName ? userProfile?.firstName.charAt(0) : '') + (userProfile?.lastName ? userProfile?.lastName.charAt(0) : '')
  };

  const handleLogoutClick = () => {
    localStorageService.clearToken();
    navigate('/login', { replace: true });
  };

  const handleMenuToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const goToHome = () => {
    navigate('/');
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const userMenu = userProfile && (
    <Box>
      <IconButton
        id="userMenuIconButton"
        data-auto-id="navBarUserMenuIconButton"
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'userMenu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuToggle}
      >
        {
          user.initials ? (
            <Avatar
              sx={{
                bgcolor: 'white',
                cursor: 'pointer',
                width: 50,
                height: 50
              }}
            >
              <b style={{ color: 'black' }}>{user.initials}</b>
            </Avatar>
          ) : (
            <UserIcon />
          )
        }
      </IconButton>
      <Popper open={open} placement="bottom-end" anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper sx={{ margin: 1 }}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList autoFocusItem={open} id="userMenu" onKeyDown={handleListKeyDown}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 2
                    }}
                  >
                    <Avatar
                      component={RouterLink}
                      src={user.avatar}
                      sx={{
                        cursor: 'pointer',
                        width: 64,
                        height: 64
                      }}
                      to="/"
                    />
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      {user.name}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      sx={{
                        textTransform: 'uppercase'
                      }}
                    >
                      {user.jobTitle}
                    </Typography>
                  </Box>
                  {
                    isOwner(roles) && (
                      <MenuItem onClick={() => navigate('/app/company')} data-auto-id="myCompanyMenuItem">
                        <ListItemIcon>
                          <GlobeIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Company" />
                      </MenuItem>
                    )
                  }
                  <MenuItem onClick={() => navigate(getMyAccountLink(roles))} data-auto-id="myProfileMenuItem">
                    <ListItemIcon>
                      <UserIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Profile" />
                  </MenuItem>
                  <MenuItem onClick={() => navigate(getSupportLink(roles))}>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Support" />
                  </MenuItem>
                  <MenuItem onClick={handleLogoutClick} data-auto-id="logoutMenuItem">
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );

  const loggedInUserToolbar = (
    <>
      <Hidden lgDown>
        <Toolbar style={{ backgroundColor: '#000000' }}>
          <Box sx={{ height: '100%', marginLeft: '20px', flex: 1 }}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
          <Box alignItems="center" sx={{ flex: 1, padding: '0.5rem 0' }}>
            <Typography
              fontWeight="500"
              color="#ffffff"
              gutterBottom
              align="center"
              variant="h2"
              paddingLeft="10px"
              margin="0"
              fontSize="25px"
              onClick={goToHome}
              sx={{
                cursor: 'pointer'
              }}
            >
              {companyProfile?.name}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            {userMenu}
          </Box>
        </Toolbar>
      </Hidden>
      <Hidden lgUp>
        <Toolbar style={{ backgroundColor: '#000000' }}>
          <Box sx={{ width: '50px' }}>
            {
              (isOwner(roles) || isManager(roles) || isDriver(roles)) && (
                <IconButton
                  color="inherit"
                  onClick={onMobileNavOpen}
                >
                  <MenuIcon />
                </IconButton>
              )
            }
          </Box>
          <Box alignItems="center" sx={{ flex: 1, padding: '0.5rem 0' }}>
            <Box>
              <Typography
                fontWeight="bold!important"
                color="#ffffff"
                gutterBottom
                align="center"
                variant="h2"
                margin="0"
                fontSize="1rem"
                onClick={goToHome}
                sx={{
                  cursor: 'pointer'
                }}
              >
                {companyProfile?.name}
              </Typography>
              <Box sx={{ height: '25px', display: 'flex', justifyContent: 'center' }}>
                <RouterLink to="/">
                  <Logo mobileView={Boolean(companyProfile?.name)} />
                </RouterLink>
              </Box>
            </Box>
          </Box>
          {userMenu}
        </Toolbar>
      </Hidden>
    </>
  );

  const loggedOutUserToolbar = (
    <Toolbar style={{ backgroundColor: '#000000', justifyContent: 'center' }}>
      <Logo />
    </Toolbar>
  );

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      { userProfile ? loggedInUserToolbar : loggedOutUserToolbar }
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.profile.company,
  userProfile: state.profile.user
});

export default connect(mapStateToProps)(AppNavbar);

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  STAFF_LOAD_ALL,
  STAFF_RESET_PAGE,
  STAFF_GRID_SORT,
  STAFF_GRID_CURRENT_PAGE,
  STAFF_GRID_SEARCH_TEXT,
  STAFF_GRID_SEARCH_FILTER_BY,
  STAFF_GRID_SEARCH_FILTER_BY_COLUMN,
  STAFF_GRID_SELECT_ROWS,
  STAFF_GRID_DESELECT_ROWS
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import StaffService from '../../services/StaffService';
import { formatErrorMessage } from '../../utils/common';

export const refreshData = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  StaffService.getAllStaffs()
    .then((result) => {
      dispatch({
        type: STAFF_LOAD_ALL,
        data: result
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: STAFF_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: STAFF_GRID_SEARCH_TEXT,
  data: text
});

export const searchByWarning = (filterBy = 'all') => ({
  type: STAFF_GRID_SEARCH_FILTER_BY,
  data: filterBy
});

export const changeFilterByColumn = (col = 'all_col') => ({
  type: STAFF_GRID_SEARCH_FILTER_BY_COLUMN,
  data: col
});

export const changePage = (page = 1) => ({
  type: STAFF_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: STAFF_GRID_SORT,
  data: sortModel
});

export const updateSelectionStatus = (visibleRows: Array<any>, selectedRowIds: Array<number>): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: STAFF_GRID_SELECT_ROWS,
    visibleRows,
    data: selectedRowIds
  });

  dispatch({
    type: STAFF_GRID_DESELECT_ROWS,
    visibleRows,
    data: visibleRows.filter((row) => !(selectedRowIds.find((id) => id === row.id))).map((row) => row.id)
  });
};

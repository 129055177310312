import {
  Briefcase as BriefCaseIcon,
  Gitlab as LinfoxIcon
  // Globe as GlobeIcon
} from 'react-feather';
import {
  LocalShippingOutlined as TruckIcon,
  ListOutlined as ShiftsListIcon,
  HomeWorkOutlined as JobSiteIcon,
  PeopleAltOutlined as UsersIcon,
  DateRangeOutlined as DateRangeIcon,
  AttachMoneyOutlined as PayrollIcon,
  ReceiptOutlined as InvoiceIcon,
  DynamicForm as DynamicFormIcon,
  FileOpen as FileOpenIcon,
  SmsOutlined as CommentIcon,
  LibraryAddCheckOutlined as CheckListIcon,
  SettingsOutlined as SettingsIcon,
  DepartureBoardOutlined as ShiftsIcon,
  PostAddOutlined as AddOnIcon,
  BeachAccessOutlined as HolidayIcon,
  SummarizeOutlined as PaySummaryIcon,
} from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AppConstants from './AppConstants';

export default [
  // {
  //   href: '/app/tracker',
  //   icon: GlobeIcon,
  //   title: 'Tracker',
  //   role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER],
  // },
  {
    href: '/app/linfox',
    icon: LinfoxIcon,
    title: 'Linfox',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER],
  },
  {
    href: '/app/shift',
    icon: ShiftsIcon,
    title: 'Shifts',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER],
    subMenus: [
      {
        href: '/app/shift/list',
        icon: ShiftsListIcon,
        title: 'All Shifts',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER],
      },
      {
        href: '/app/shift/question',
        icon: CheckListIcon,
        title: 'Precheck Questions',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER]
      },
      {
        href: '/app/shift/comment',
        icon: CommentIcon,
        title: 'Predefined Comments',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER]
      }
    ]
  },
  {
    href: '/app/staff',
    icon: UsersIcon,
    title: 'Staff',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER, AppConstants.USER_ROLES.ACCOUNTANT]
  },
  {
    href: '/app/jobsite',
    icon: JobSiteIcon,
    title: 'Stores / Jobsites',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER]
  },
  {
    href: '/app/vehicle',
    icon: TruckIcon,
    title: 'Vehicles',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER]
  },
  {
    href: '/app/payroll',
    icon: PayrollIcon,
    title: 'Payroll',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT],
    subMenus: [
      {
        href: '/app/payroll/periods',
        icon: DateRangeIcon,
        title: 'Pay Periods',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT],
      },
      {
        href: '/app/payroll/summaries',
        icon: PaySummaryIcon,
        title: 'Pay Summaries',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT],
      },
      {
        href: '/app/payroll/addons',
        icon: AddOnIcon,
        title: 'Add-Ons',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT],
      },
      {
        href: '/app/payroll/holidays',
        icon: HolidayIcon,
        title: 'Public Holidays',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT],
      },
      {
        href: '/app/payroll/settings',
        icon: SettingsIcon,
        title: 'Settings',
        role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT],
      }
    ]
  },
  // {
  //   href: '/app/myforms',
  //   icon: FileOpenIcon,
  //   title: 'Submit Forms',
  //   role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER, AppConstants.USER_ROLES.ACCOUNTANT]
  // },
  {
    href: '/app/forms',
    icon: AssignmentIcon,
    title: 'Forms',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.MANAGER]
  },
  {
    href: '/app/invoice',
    icon: InvoiceIcon,
    title: 'Invoices',
    role: [AppConstants.USER_ROLES.OWNER, AppConstants.USER_ROLES.ACCOUNTANT]
  },
  {
    href: '/employee/shift',
    icon: ShiftsIcon,
    title: 'Shifts',
    role: [AppConstants.USER_ROLES.DRIVER]
  },
  {
    href: '/employee/myforms',
    icon: AssignmentIcon,
    title: 'Forms',
    role: [AppConstants.USER_ROLES.DRIVER]
  },
];

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { Formik, getIn } from 'formik';
import { Phone as PhoneIcon, Mail as MailIcon } from '@mui/icons-material';
import SubmitFormButton from '../SubmitFormButton';
import UserProfileService from '../../services/UserProfileService';
import EnumService from '../../services/EnumService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatISODate, formatInputDate, sortByKey } from '../../utils/common';
import AppConstants from '../../constants/AppConstants';
import { updateUserProfile, getUserProfile } from '../../store/actions/profileActions';
import ConfirmButton from '../ConfirmButton';
import { AllEnumsRes, EnumRes, UserProfileReq } from '../../proxy/proxy';
import FormattedUserProfile from '../../models/FormattedUserProfile';

interface Props {
  profileData: FormattedUserProfile,
  updateProfileData: any,
  loadUserProfile: any,
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const AccountProfileDetails: React.FC<Props> = ({ profileData, updateProfileData, loadUserProfile, showBusyIndicator, hideBusyIndicator, showAlert, clearAllAlerts }) => {
  const navigate = useNavigate();
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);
  const [dlClasses, setDlClasses] = useState<Array<any>>([]);
  const [formData, setFormData] = useState<any>();

  const buildFormValues = (data: FormattedUserProfile) => ({
    firstName: data?.firstName || '',
    middleName: data?.middleName || '',
    lastName: data?.lastName || '',
    phoneNumber: data?.phoneNumber || '',
    emergencyContactFullName: data?.emergencyContactFullName || '',
    emergencyContactPhone: data?.emergencyContactPhone || '',
    email: data?.email || '',
    dateOfBirthUtc: formatInputDate(data?.dateOfBirthUtc),
    role: data?.role || AppConstants.USER_ROLES.DRIVER,
    street: data?.address?.street || '',
    suburb: data?.address?.suburb || '',
    areaCode: data?.address?.areaCode || '',
    stateId: data?.address?.stateId || '',
    extDriverId: data?.extDriverId || '',
    drivingLicenseNumber: data?.drivingLicenseNumber || '',
    drivingLicenseClasses: data?.drivingLicenseClasses || [],
    drivingLicenseIssuedByStateId: data?.drivingLicenseIssuedByStateId || '',
    drivingLicenseRestriction: data?.drivingLicenseRestriction || '',
    drivingLicenseFirstIssuedOnUtc: formatInputDate(data?.drivingLicenseFirstIssuedOnUtc),
    drivingLicenseExpiresOnUTC: formatInputDate(data?.drivingLicenseExpiresOnUTC),
    rsaCertificateNumber: data?.rsaCertificateNumber || '',
    rsaExpiryDateUtc: formatInputDate(data?.rsaExpiryDateUtc),
    rsatCertificateNumber: data?.rsatCertificateNumber || '',
    rsatExpiryDateUtc: formatInputDate(data?.rsatExpiryDateUtc),
    drivingRecordEmissionDateUtc: formatInputDate(data?.drivingRecordEmissionDateUtc),
    australianFederalPoliceCheckEmissionDateUtc: formatInputDate(data?.australianFederalPoliceCheckEmissionDateUtc),

    abn: data?.abn || '',
    isRegisteredForGST: data?.isRegisteredForGST
  });

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    clearAllAlerts();
    showBusyIndicator();
    EnumService.getAllEnums().then((result: AllEnumsRes) => {
      setAllStates(sortByKey(result.states || undefined, 'value'));
      setDlClasses(sortByKey(result.drivingLicenseClass || undefined, 'value'));
    }).catch((err: any) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  }, []);

  useEffect(() => {
    setFormData(null);
    setTimeout(() => {
      setFormData(buildFormValues(profileData));
    }, 0);
  }, [profileData]);

  const checkGSTStatus = () => {
    showBusyIndicator();
    clearAllAlerts();
    UserProfileService.checkGST()
      .then((result: FormattedUserProfile) => {
        showAlert({
          message: 'GST Registration Status updated successfully',
          severity: 'success'
        });
        updateProfileData(result);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload: UserProfileReq = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      dateOfBirthUtc: formatISODate(values.dateOfBirthUtc),
      emergencyContactFullName: values.emergencyContactFullName,
      emergencyContactPhone: values.emergencyContactPhone,
      address: {
        street: values.street || null,
        suburb: values.suburb || null,
        areaCode: values.areaCode?.toString()?.padStart(4, '0'),
        stateId: Number(values.stateId)
      },
      extDriverId: values.extDriverId,
      abn: values.abn,

      drivingLicenseNumber: values.drivingLicenseNumber,
      // appUserLicenseClassMapReq: {
      //   drivingLicenseClasses: values.drivingLicenseClasses
      // },
      drivingLicenseIssuedByStateId: values.drivingLicenseIssuedByStateId ? Number(values.drivingLicenseIssuedByStateId) : undefined,
      drivingLicenseRestriction: values.drivingLicenseRestriction,
      drivingLicenseFirstIssuedOnUtc: formatISODate(values.drivingLicenseFirstIssuedOnUtc),
      drivingLicenseExpiresOnUTC: formatISODate(values.drivingLicenseExpiresOnUTC),
      rsaCertificateNumber: values.rsaCertificateNumber,
      rsaExpiryDateUtc: formatISODate(values.rsaExpiryDateUtc),
      rsatCertificateNumber: values.rsatCertificateNumber,
      rsatExpiryDateUtc: formatISODate(values.rsatExpiryDateUtc),
      drivingRecordEmissionDateUtc: formatISODate(values.drivingRecordEmissionDateUtc),
      australianFederalPoliceCheckEmissionDateUtc: formatISODate(values.australianFederalPoliceCheckEmissionDateUtc)
    };

    UserProfileService.update(payload)
      .then((result: FormattedUserProfile) => {
        showAlert(formatSuccessMessage('Profile updated successfully'));
        if (result.abn !== profileData.abn) {
          checkGSTStatus();
        }
        updateProfileData(result);
      }).catch((err: { response: { data: any; }; }) => {
        showAlert(formatErrorMessage(err.response));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <Card>
      <CardHeader
        avatar={
          (
            <IconButton aria-label="back" size="small" onClick={back}>
              <BackIcon />
            </IconButton>
          )
        }
        title="My Profile"
      />
      <Divider />
      <CardContent>
        {
          formData && (
            <Formik
              initialValues={formData}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required('First name is required'),
                middleName: Yup.string(),
                lastName: Yup.string().required('Last name is required'),
                phoneNumber: Yup.string().matches(AppConstants.MOBILE_NO_REGEXP, 'Phone number is not valid').required('Phone number is required'),
                email: Yup.string().email('Email is not valid').required('Email is required'),
                street: Yup.string(),
                suburb: Yup.string(),
                areaCode: Yup.number().min(0, 'Post Code should be between 0000 and 9999').max(9999, 'Post Code should be between 0000 and 9999').required('Post Code is required'),
                stateId: Yup.number().min(1).required('State is required'),
                extDriverId: Yup.string(),
                dateOfBirthUtc: Yup.date().max(new Date(), 'Can not be future date'),

                emergencyContactFullName: Yup.string(),
                emergencyContactPhone: Yup.string().matches(AppConstants.MOBILE_NO_REGEXP, 'Phone number is not valid'),

                drivingLicenseNumber: Yup.string(),
                drivingLicenseClasses: Yup.array(),
                drivingLicenseIssuedByStateId: Yup.number(),
                drivingLicenseRestriction: Yup.string(),
                drivingLicenseFirstIssuedOnUtc: Yup.date(),
                drivingLicenseExpiresOnUTC: Yup.date(),
                rsaCertificateNumber: Yup.string(),
                rsaExpiryDateUtc: Yup.date(),
                rsatCertificateNumber: Yup.string(),
                rsatExpiryDateUtc: Yup.date(),
                drivingRecordEmissionDateUtc: Yup.date(),
                australianFederalPoliceCheckEmissionDateUtc: Yup.date(),

                abn: Yup.string().matches(/^(\d *?){11}$/gm, 'Incorrect format').max(255),
                isRegisteredForGST: Yup.boolean()
              })}
              onSubmit={handleFormSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                dirty,
                isValid
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label="First Name"
                        margin="normal"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.middleName && errors.middleName)}
                        fullWidth
                        helperText={touched.middleName && errors.middleName}
                        label="Middle Name"
                        margin="normal"
                        name="middleName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.middleName}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label="Last Name"
                        margin="normal"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        type="email"
                        required
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        fullWidth
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        label="Mobile Number"
                        margin="normal"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.dateOfBirthUtc && errors.dateOfBirthUtc)}
                        fullWidth
                        helperText={touched.dateOfBirthUtc && errors.dateOfBirthUtc}
                        label="Date Of Birth"
                        margin="normal"
                        name="dateOfBirthUtc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.dateOfBirthUtc}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.extDriverId && errors.extDriverId)}
                        fullWidth
                        helperText={touched.extDriverId && errors.extDriverId}
                        label="Ext Driver Id"
                        margin="normal"
                        name="extDriverId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.extDriverId}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.abn && errors.abn)}
                        fullWidth
                        helperText={touched.abn && errors.abn}
                        label="ABN"
                        margin="normal"
                        name="abn"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.abn}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <TextField
                        fullWidth
                        label="Is Registered For GST"
                        name="isRegisteredForGST"
                        margin="normal"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        InputLabelProps={{ shrink: true }}
                        value={values.isRegisteredForGST}
                        variant="outlined"
                        error={Boolean(touched.isRegisteredForGST && errors.isRegisteredForGST)}
                        helperText={touched.isRegisteredForGST && errors.isRegisteredForGST}
                        disabled
                      >
                        <MenuItem value="false">
                          <ListItemText primary="No" />
                        </MenuItem>
                        <MenuItem value="true">
                          <ListItemText primary="Yes" />
                        </MenuItem>
                      </TextField>
                      <Tooltip title="Check if registered for GST and update status">
                        {
                          formData.abn ? (
                            <Button color="primary" onClick={checkGSTStatus} sx={{ marginLeft: 2 }} variant="outlined" disabled={dirty}>
                              Check
                            </Button>
                          ) : (
                            <ConfirmButton
                              buttonLabel="Check"
                              buttonVariant="outlined"
                              okButtonLabel="Ok"
                              data-auto-id="rejectConfirmButton"
                              dialogTitle="Check GST"
                              showCancelButton={false}
                              message="ABN is required."
                              sx={{ marginLeft: 2 }}
                              disabled={dirty}
                            />
                          )
                        }
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.emergencyContactFullName && errors.emergencyContactFullName)}
                        fullWidth
                        helperText={touched.emergencyContactFullName && errors.emergencyContactFullName}
                        label="Emergency Contact (Full Name)"
                        margin="normal"
                        name="emergencyContactFullName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.emergencyContactFullName}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.emergencyContactPhone && errors.emergencyContactPhone)}
                        fullWidth
                        helperText={touched.emergencyContactPhone && errors.emergencyContactPhone}
                        label="Emergency Contact Phone Number"
                        margin="normal"
                        name="emergencyContactPhone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.emergencyContactPhone}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.drivingLicenseNumber && errors.drivingLicenseNumber)}
                        fullWidth
                        helperText={touched.drivingLicenseNumber && errors.drivingLicenseNumber}
                        label="Driving License Number"
                        margin="normal"
                        name="drivingLicenseNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.drivingLicenseNumber}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Driving License Classes"
                        name="drivingLicenseClasses"
                        margin="normal"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        select
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected: any) => selected.map((x: any) => dlClasses.find((cls: any) => cls.id === x)?.value).join(', ')
                        }}
                        value={values.drivingLicenseClasses}
                        variant="outlined"
                        error={Boolean(touched.drivingLicenseClasses && errors.drivingLicenseClasses)}
                        helperText={touched.drivingLicenseClasses && errors.drivingLicenseClasses}
                      >
                        {dlClasses.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <Checkbox checked={getIn(values, 'drivingLicenseClasses').indexOf(option.id) > -1} />
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Driving License Issued By State"
                        name="drivingLicenseIssuedByStateId"
                        margin="normal"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        select
                        InputLabelProps={{ shrink: true }}
                        value={values.drivingLicenseIssuedByStateId}
                        variant="outlined"
                        error={Boolean(touched.drivingLicenseIssuedByStateId && errors.drivingLicenseIssuedByStateId)}
                        helperText={touched.drivingLicenseIssuedByStateId && errors.drivingLicenseIssuedByStateId}
                      >
                        <MenuItem aria-label="None" value="">
                          <ListItemText primary="&nbsp;" />
                        </MenuItem>
                        {allStates.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.drivingLicenseRestriction && errors.drivingLicenseRestriction)}
                        fullWidth
                        helperText={touched.drivingLicenseRestriction && errors.drivingLicenseRestriction}
                        label="Driving License Restriction"
                        margin="normal"
                        name="drivingLicenseRestriction"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.drivingLicenseRestriction}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.drivingLicenseFirstIssuedOnUtc && errors.drivingLicenseFirstIssuedOnUtc)}
                        fullWidth
                        helperText={touched.drivingLicenseFirstIssuedOnUtc && errors.drivingLicenseFirstIssuedOnUtc}
                        label="Driving License First Issued On"
                        margin="normal"
                        name="drivingLicenseFirstIssuedOnUtc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.drivingLicenseFirstIssuedOnUtc}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.drivingLicenseExpiresOnUTC && errors.drivingLicenseExpiresOnUTC)}
                        fullWidth
                        helperText={touched.drivingLicenseExpiresOnUTC && errors.drivingLicenseExpiresOnUTC}
                        label="Driving License Expires On"
                        margin="normal"
                        name="drivingLicenseExpiresOnUTC"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.drivingLicenseExpiresOnUTC}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.rsaCertificateNumber && errors.rsaCertificateNumber)}
                        fullWidth
                        helperText={touched.rsaCertificateNumber && errors.rsaCertificateNumber}
                        label="RSA Certificate Number"
                        margin="normal"
                        name="rsaCertificateNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rsaCertificateNumber}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.rsaExpiryDateUtc && errors.rsaExpiryDateUtc)}
                        fullWidth
                        helperText={touched.rsaExpiryDateUtc && errors.rsaExpiryDateUtc}
                        label="RSA Expiry Date"
                        margin="normal"
                        name="rsaExpiryDateUtc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rsaExpiryDateUtc}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.rsatCertificateNumber && errors.rsatCertificateNumber)}
                        fullWidth
                        helperText={touched.rsatCertificateNumber && errors.rsatCertificateNumber}
                        label="RSAT Certificate Number (NSW only)"
                        margin="normal"
                        name="rsatCertificateNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rsatCertificateNumber}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.rsatExpiryDateUtc && errors.rsatExpiryDateUtc)}
                        fullWidth
                        helperText={touched.rsatExpiryDateUtc && errors.rsatExpiryDateUtc}
                        label="RSAT Expiry Date (NSW only)"
                        margin="normal"
                        name="rsatExpiryDateUtc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rsatExpiryDateUtc}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.drivingRecordEmissionDateUtc && errors.drivingRecordEmissionDateUtc)}
                        fullWidth
                        helperText={touched.drivingRecordEmissionDateUtc && errors.drivingRecordEmissionDateUtc}
                        label="Emission Date - Driving Record"
                        margin="normal"
                        name="drivingRecordEmissionDateUtc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.drivingRecordEmissionDateUtc}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.australianFederalPoliceCheckEmissionDateUtc && errors.australianFederalPoliceCheckEmissionDateUtc)}
                        fullWidth
                        helperText={touched.australianFederalPoliceCheckEmissionDateUtc && errors.australianFederalPoliceCheckEmissionDateUtc}
                        label="Emission Date - AFP ( Australian Federal Police Check)"
                        margin="normal"
                        name="australianFederalPoliceCheckEmissionDateUtc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.australianFederalPoliceCheckEmissionDateUtc}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.street && errors.street)}
                        fullWidth
                        helperText={touched.street && errors.street}
                        label="Street"
                        margin="normal"
                        name="street"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.street}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.suburb && errors.suburb)}
                        fullWidth
                        helperText={touched.suburb && errors.suburb}
                        label="Suburb"
                        margin="normal"
                        name="suburb"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.suburb}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.areaCode && errors.areaCode)}
                        fullWidth
                        helperText={touched.areaCode && errors.areaCode}
                        label="Post Code"
                        margin="normal"
                        name="areaCode"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.areaCode}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="State"
                        name="stateId"
                        margin="normal"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        InputLabelProps={{ shrink: true }}
                        value={values.stateId}
                        variant="outlined"
                        error={Boolean(touched.stateId && errors.stateId)}
                        helperText={touched.stateId && errors.stateId}
                      >
                        <MenuItem aria-label="None" value="">
                          <ListItemText primary="&nbsp;" />
                        </MenuItem>
                        {allStates.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined" data-auto-id="cancelButton">
                      Cancel
                    </Button>
                    <SubmitFormButton
                      dirty={dirty}
                      isValid={isValid}
                      onConfirm={handleSubmit}
                    />
                  </Box>
                </form>
              )}
            </Formik>
          )
        }
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  profileData: state.profile.user
});

const mapDispatchToProps = (dispatch: any) => ({
  updateProfileData: (data: FormattedUserProfile) => dispatch(updateUserProfile(data)),
  loadUserProfile: () => dispatch(getUserProfile()),
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfileDetails);

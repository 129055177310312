import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import busyIndicatorReducer, { StateType as BusyIndicatorReducerState } from './reducers/busyIndicatorReducer';
import alertReducer, { StateType as AlertReducerState } from './reducers/alertReducer';
import profileReducer, { StateType as ProfileReducerType } from './reducers/profileReducer';
import payrollPeriodReducer, { StateType as PayrollPeriodReducerType } from './reducers/payrollPeriodReducer';
import payrollAddonReducer, { StateType as PayrollAddonReducerType } from './reducers/payrollAddonsReducer';
import shiftReducer, { StateType as ShiftReducerState } from './reducers/shiftReducer';
import shiftNRReducer, { StateType as ShiftNRReducerState } from './reducers/shiftNRReducer';
import dashboardNRReducer, { StateType as DashboardNRReducerState } from './reducers/dashboardNRReducer';
import staffReducer, { StateType as StaffReducerType } from './reducers/staffReducer';
import storeReducer, { StateType as StoreReducerType } from './reducers/storeReducer';
import vehicleReducer, { StateType as VehicleReducerType } from './reducers/vehicleReducer';
import questionsReducer, { StateType as QuestionsReducerType } from './reducers/precheckQuestionsReducer';
import formsReducer, { StateType as FormsReducerType } from './reducers/FormReducer';
import FormSubmissionsReducer, { StateType as FormSubmissionsReducerType } from './reducers/FormSubmissionsReducer';
import preDefinedShiftCommentsReducer, { StateType as PreDefinedShiftCommentsType } from './reducers/preDefinedShiftCommentsReducer';
import linfoxReducer, { StateType as LinfoxReducerType } from './reducers/linfoxReducer';

export interface RootState {
  busyIndicator: BusyIndicatorReducerState,
  alert: AlertReducerState,
  profile: ProfileReducerType,
  shift: ShiftReducerState,
  shiftNR: ShiftNRReducerState,
  staff: StaffReducerType,
  vehicle: VehicleReducerType,
  store: StoreReducerType,
  questions: QuestionsReducerType,
  forms: FormsReducerType,
  formSubmissions: FormSubmissionsReducerType,
  shiftComments: PreDefinedShiftCommentsType,
  payrollPeriod: PayrollPeriodReducerType,
  payrollAddon: PayrollAddonReducerType,
  dashboardNR: DashboardNRReducerState,
  linfox: LinfoxReducerType
}

const reducers = {
  busyIndicator: busyIndicatorReducer,
  alert: alertReducer,
  profile: profileReducer,
  shift: shiftReducer,
  shiftNR: shiftNRReducer,
  staff: staffReducer,
  vehicle: vehicleReducer,
  store: storeReducer,
  questions: questionsReducer,
  forms: formsReducer,
  formSubmissions: FormSubmissionsReducer,
  shiftComments: preDefinedShiftCommentsReducer,
  payrollPeriod: payrollPeriodReducer,
  payrollAddon: payrollAddonReducer,
  dashboardNR: dashboardNRReducer,
  linfox: linfoxReducer
};

const store = createStore(combineReducers<RootState>(reducers), applyMiddleware(thunk));

export default store;

import React, { useState, Fragment, useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../../store/actions/alertActions';
import { FormQuestionMultiChoice, } from '../../proxy/proxy';
import FormsService from '../../services/FormsService';
import { formatErrorMessage, formatSuccessMessage } from '../../utils/common';
import { hide, show } from '../../store/actions/busyIndicatorActions';

interface MultiChoiceInputPreviewProps {
  currentFieldState: FormQuestionMultiChoice;
  form: number;
  answer: string[];

  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const MultiChoiceInputPreview: React.FC<MultiChoiceInputPreviewProps> = ({
  currentFieldState, form, answer,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionMultiChoice>(currentFieldState);
  const [selectedValues, setSelectedValues] = useState<string[]>(answer);
  const [error, setError] = useState('');
  const [formId, setFormId] = useState<number>(form);

  const validateInput = (values: string[]) => {
    if (fieldState.required && values.length === 0) {
      setError('At least one option must be selected');
    } else {
      setError('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const updatedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((v) => v !== value);

    setSelectedValues(updatedValues);
    validateInput(updatedValues);

    if (!error) {
      // Prepare the payload for FormAnswerNumericReq
      const payload = {
        __discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
        discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
        formId,
        formQuestionId: fieldState.id, // Assuming you have formQuestionId in fieldState
        answers: updatedValues, // Capture the input value as the answer
      };

      console.log(payload);

      const promise = FormsService.answer(payload);

      promise
        .then((response: any) => {
          showAlert(formatSuccessMessage('Answer saved'));
        })
        .catch((err) => {
          if (err?.errors?.length > 0 && !error?.includes(err?.errors[0])) {
            setError(`${error} ${err.errors[0]}`);
          }
          // showAlert(formatErrorMessage(err));
        })
        .then(() => {
          // hideBusyIndicator();
        });
    }
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {fieldState.subTitle}
        </Typography>
        <FormControl component="fieldset" error={!!error} required={fieldState.required}>
          <FormGroup>
            {fieldState.choices?.map((choice, index) => (
              <FormControlLabel
                key={index}
                control={(
                  <Checkbox
                    checked={selectedValues.includes(choice)}
                    onChange={handleChange}
                    value={choice}
                  />
                )}
                label={choice}
              />
            ))}
          </FormGroup>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiChoiceInputPreview);

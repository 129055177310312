import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';
import AppNavbar from './AppNavbar';
import AppSidebar from './AppSidebar';

const DriverLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DriverLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 275
    }
  })
);

const DriverLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DriverLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DriverLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DriverLayoutRoot>
      <AppNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <AppSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DriverLayoutWrapper>
        <DriverLayoutContainer>
          <DriverLayoutContent>
            <Outlet />
          </DriverLayoutContent>
        </DriverLayoutContainer>
      </DriverLayoutWrapper>
    </DriverLayoutRoot>
  );
};

export default DriverLayout;

import AppConstants from '../constants/AppConstants';
import FormattedStaff from '../models/FormattedStaff';
import configureAxios from '../proxy/ConfigureAxios';
import { Client, PayRateReq, StaffReq } from '../proxy/proxy';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const StaffService = {
  getAllStaffs: () => configureAxios().allPOST2()
    .then((resp) => {
      const result = (resp || []).filter((staff: any) => !staff.isSoftDeleted);
      return result.map((staff: any) => new FormattedStaff(staff));
    }),
  getStaffById: (id: number) => configureAxios().get14(id)
    .then((resp) => new FormattedStaff(resp)),
  inviteStaff: (data: StaffReq) => configureAxios().invite(data),
  updateStaff: (id: number, data: StaffReq) => configureAxios().updatePOST11(id, data),
  getStaffPayRatesById: (id: number) => configureAxios().getPayRate(id),
  updatePayRates: (staffIds: Array<number>, payRateReq: PayRateReq) => configureAxios().updatePayRates({ staffIds, payRateReq }),
  deleteStaff: (id: number = 0) => configureAxios().delete10(id),
  checkGST: (id: number = 0) => configureAxios().updateGST(id)
    .then((resp) => new FormattedStaff(resp))
};

export default StaffService;

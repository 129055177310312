import { AxiosResponse } from 'axios';
import AppConstants from '../constants/AppConstants';

import { Client, CreatePayRateJobsiteAddOnReq, CreatePayRateShiftDurationAddOnReq, CreatePayRateShiftTimeAddOnReq, PayRateJobsiteAddOnReq, PayRateJobsiteAddOnRes, PayRateJobsiteAddOnResPage, PayRateShiftDurationAddOnReq, PayRateShiftDurationAddOnRes, PayRateShiftDurationAddOnResPage, PayRateShiftTimeAddOnReq, PayRateShiftTimeAddOnRes, PayRateShiftTimeAddOnResPage } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new AccountApi(new Configuration({ basePath: AppConstants.BASE_API_URL }));
// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const PreCheckQuestionsService = {
  getAllJobSiteAddOns: () => configureAxios().pagePayRateJobsiteAddOns({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp: PayRateJobsiteAddOnResPage) => {
    const result: Array<PayRateJobsiteAddOnRes> = resp?.items || [];
    return result.filter((question: any) => !question.isSoftDeleted);
  }),
  getJobSiteAddOnById: (id: number): Promise<PayRateJobsiteAddOnRes> => configureAxios().getPayRateJobsiteAddOn(id).then((resp) => resp),
  createJobSiteAddOn: (data: CreatePayRateJobsiteAddOnReq): Promise<PayRateJobsiteAddOnRes> =>
    configureAxios().createPayRateJobsiteAddOns(data)
      .then((resp) => {
        const item = resp?.items?.[0];
        if (item !== undefined) return item;
        throw new Error('Response is undefined');
      }),
  updateJobSiteAddOn: (id: number, data: PayRateJobsiteAddOnReq): Promise<PayRateJobsiteAddOnRes> => configureAxios().updatePayRateJobsiteAddOn(id, data).then((resp) => resp),
  assignUsersToJobSiteAddOn: (payRateJobsiteAddOnId: number, userIds: Array<number>): Promise<PayRateJobsiteAddOnRes> => configureAxios().assignUsersToPayRateJobsiteAddOn({
    userIds,
    payRateJobsiteAddOnId
  }).then((resp) => resp),
  unassignUsersToJobSiteAddOn: (payRateJobsiteAddOnId: number, userIds: Array<number>): Promise<PayRateJobsiteAddOnRes> => configureAxios().unassignUsersFromPayRateJobsiteAddOn({
    userIds,
    payRateJobsiteAddOnId
  }).then((resp) => resp),
  deleteJobSiteAddOn: (id: number): Promise<void> => configureAxios().deletePayRateJobsiteAddOns([id]),

  getAllShiftTimeAddOns: () => configureAxios().pagePayRateShiftTimeAddOns({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp: PayRateShiftTimeAddOnResPage) => {
    const result: Array<PayRateShiftTimeAddOnRes> = resp?.items || [];
    return result.filter((question: any) => !question.isSoftDeleted);
  }),
  getShiftTimeAddOnById: (id: number): Promise<PayRateShiftTimeAddOnRes> => configureAxios().getPayRateShiftTimeAddOn(id).then((resp) => resp),
  createShiftTimeAddOn: (data: CreatePayRateShiftTimeAddOnReq): Promise<PayRateShiftTimeAddOnRes> =>
    configureAxios().createPayRateShiftTimeAddOns(data)
      .then((resp) => {
        const item = resp?.items?.[0];
        if (item !== undefined) return item;
        throw new Error('Response is undefined');
      }),
  updateShiftTimeAddOn: (id: number, data: PayRateShiftTimeAddOnReq): Promise<PayRateShiftTimeAddOnRes> => configureAxios().updatePayRateShiftTimeAddOn(id, data).then((resp) => resp),
  assignUsersToShiftTimeAddOn: (payRateShiftTimeAddOnId: number, userIds: Array<number>): Promise<PayRateShiftTimeAddOnRes> => configureAxios().assignUsersToPayRateShiftTimeAddOn({
    userIds,
    payRateShiftTimeAddOnId
  }).then((resp) => resp),
  unassignUsersToShiftTimeAddOn: (payRateShiftTimeAddOnId: number, userIds: Array<number>): Promise<PayRateShiftTimeAddOnRes> => configureAxios().unassignUsersFromPayRateShiftTimeAddOn({
    userIds,
    payRateShiftTimeAddOnId
  }).then((resp) => resp),
  deleteShiftTimeAddOn: (id: number): Promise<void> => configureAxios().deletePayRateShiftTimeAddOns([id]),

  getAllShiftDurationAddOns: () => configureAxios().pagePayRateShiftDurationAddOns({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp: PayRateShiftDurationAddOnResPage) => {
    const result: Array<PayRateShiftDurationAddOnRes> = resp?.items || [];
    return result.filter((question: any) => !question.isSoftDeleted);
  }),
  getShiftDurationAddOnById: (id: number): Promise<PayRateShiftDurationAddOnRes> => configureAxios().getPayRateShiftDurationAddOn(id).then((resp) => resp),
  createShiftDurationAddOn: (data: CreatePayRateShiftDurationAddOnReq): Promise<PayRateShiftDurationAddOnRes> =>
    configureAxios().createPayRateShiftDurationAddOns(data)
      .then((resp) => {
        const item = resp?.items?.[0];
        if (item !== undefined) return item;
        throw new Error('Response is undefined');
      }),
  updateShiftDurationAddOn: (id: number, data: PayRateShiftDurationAddOnReq): Promise<PayRateShiftDurationAddOnRes> => configureAxios().updatePayRateShiftDurationAddOn(id, data).then((resp) => resp),
  assignUsersToShiftDurationAddOn: (payRateShiftDurationAddOnId: number, userIds: Array<number>): Promise<PayRateShiftDurationAddOnRes> => configureAxios().assignUsersToPayRateShiftDurationAddOn({
    userIds,
    payRateShiftDurationAddOnId
  }).then((resp) => resp),
  unassignUsersToShiftDurationAddOn: (payRateShiftDurationAddOnId: number, userIds: Array<number>): Promise<PayRateShiftDurationAddOnRes> => configureAxios().unassignUsersFromPayRateShiftDurationAddOn({
    userIds,
    payRateShiftDurationAddOnId
  }).then((resp) => resp),
  deleteShiftDurationAddOn: (id: number): Promise<void> => configureAxios().deletePayRateShiftDurationAddOns([id]),
};

export default PreCheckQuestionsService;

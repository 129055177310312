import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  CardActions,
  Container,
  MenuItem,
  ListItemText
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import BackIcon from '@mui/icons-material/ChevronLeft';
import SubmitFormButton from '../components/SubmitFormButton';
import AcceptQuestions from '../components/shift/AgreeQuestions';
import ContactManager from '../components/shift/ContactManager';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, getJobSiteName } from '../utils/common';
import ShiftsService from '../services/ShiftsService';
import StoresService from '../services/StoresService';
import EnumService from '../services/EnumService';
import VehiclesService from '../services/VehiclesService';
import AppConstants from '../constants/AppConstants';
import FormattedVehicle from '../models/FormattedVehicle';
import { JobsiteRes, EnumRes, ShiftRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => void,
  clearAllAlerts: () => void,
  showBusyIndicator: () => void,
  hideBusyIndicator: () => void,
}

const StartShift: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const navigate = useNavigate();

  const [answeredToPreCheckQuestions, setAnsweredToPreCheckQuestions] = useState(false);
  const [agreedToPreCheckQuestions, setAgreedToPreCheckQuestions] = useState(false);
  const [acceptedQuestionIds, setAcceptedQuestionIds] = useState<Array<number>>([]);

  const [allStores, setAllStores] = useState<Array<JobsiteRes>>([]);
  const [allVehicles, setAllVehicles] = useState<Array<FormattedVehicle>>([]);
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);

  const getVehicleOdoReading = (vehicleId: number) => allVehicles.find((vehicle) => vehicle.id === Number(vehicleId))?.odometerKms || 0;

  const formInitialValues = {
    routeId: 0,
    unlistedVehicleRego: '',
    startOdometerKms: 0,
    vehicleId: '',
    jobsiteId: '',
    jobsiteName: '',
    jobsiteStreet: '',
    jobsiteSuburb: '',
    jobsiteCity: '',
    jobsiteAreaCode: '',
    jobsiteStateId: '',
  };

  const formValidationSchema = Yup.object().shape({
    routeId: Yup.number(),
    jobsiteId: Yup.number().min(1).required('Store/Jobsite is required'),
    jobsiteName: Yup.string()
      .when('jobsiteId', {
        is: 0,
        then: Yup.string().required('Name is required for not listed Jobsite')
      }),
    jobsiteStreet: Yup.string(),
    jobsiteSuburb: Yup.string(),
    jobsiteCity: Yup.string(),
    jobsiteAreaCode: Yup.number()
      .when('jobsiteId', {
        is: 0,
        then: Yup.number().min(0, 'Post Code should be between 0000 and 9999').max(9999, 'Post Code should be between 0000 and 9999').required('Post Code is required')
      }),
    jobsiteStateId: Yup.string()
      .when('jobsiteId', {
        is: 0,
        then: Yup.string().required('State is required for not listed Jobsite')
      }),
    vehicleId: Yup.number().required('Vehicle is required'),
    unlistedVehicleRego: Yup.string().max(255)
      .when('vehicleId', {
        is: 0,
        then: Yup.string().required('Vehicle rego is required for not listed vehicles')
      }),
    startOdometerKms: Yup.number()
      .required('Odometer reading is required')
      .when(['vehicleId'], (vehicleId, schema) => schema.min(getVehicleOdoReading(vehicleId), `Odometer reading must be greater than or equal to ${getVehicleOdoReading(vehicleId)}`))
  });

  const navigateToShiftPage = ({ id }: ShiftRes) => {
    navigate(`/employee/shift/${id}`);
  };

  const onShiftStart = (newShift: ShiftRes) => {
    navigateToShiftPage(newShift);
  };

  const agreeAll = (questionIds: Array<number>) => {
    setAnsweredToPreCheckQuestions(true);
    setAgreedToPreCheckQuestions(true);
    setAcceptedQuestionIds(questionIds);
  };

  const disAgree = () => {
    setAnsweredToPreCheckQuestions(true);
    setAgreedToPreCheckQuestions(false);
  };

  const handleFormSubmit = (values: any) => {
    const payload: any = {
      routeId: null,
      vehicleId: Number(values.vehicleId) || null,
      unlistedVehicleRego: !Number(values.vehicleId) ? values.unlistedVehicleRego : null,
      startOdometerKms: Number(values.startOdometerKms),
      jobsiteId: Number(values.jobsiteId) || null,
      extCompanyId: Number(values.extCompanyId) || null
    };
    if (!payload.jobsiteId) {
      payload.jobsite = {
        name: values.jobsiteName,
        address: {
          street: values.jobsiteStreet,
          areaCode: values.jobsiteAreaCode?.toString()?.padStart(4, '0') || null,
          suburb: values.jobsiteSuburb,
          city: values.jobsiteCity,
          stateId: Number(values.jobsiteStateId)
        }
      };
    }
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.startAdhocShift(payload, acceptedQuestionIds)
      .then((result) => {
        onShiftStart(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleCancelClick = () => {
    navigate('/employee/shift');
  };

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();

    const promises: Array<Promise<any>> = [
      StoresService.getAllStores(),
      VehiclesService.getAllVehicles(),
      EnumService.getEnums('states')
    ];
    Promise.all(promises)
      .then((resp) => {
        setAllStores(resp[0] || []);
        setAllVehicles(resp[1] || []);
        setAllStates(resp[2] || []);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Start Adhoc Shift | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              title="Start Adhoc Shift"
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={handleCancelClick}>
                    <BackIcon />
                  </IconButton>
                )
              }
            />
            <Divider />
            <CardContent>
              {!answeredToPreCheckQuestions && <AcceptQuestions agreeAll={agreeAll} disAgree={disAgree} />}
              {answeredToPreCheckQuestions && !agreedToPreCheckQuestions && <ContactManager />}
              {(answeredToPreCheckQuestions && agreedToPreCheckQuestions) && (
                <Formik
                  initialValues={formInitialValues}
                  validationSchema={formValidationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                    dirty,
                    isValid
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Vehicle"
                              name="vehicleId"
                              margin="normal"
                              onChange={(e) => {
                                if (e.target.value) {
                                  setFieldValue('startOdometerKms', getVehicleOdoReading(Number(e.target.value)));
                                }
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              required
                              select
                              SelectProps={{ native: true }}
                              value={values.vehicleId}
                              variant="outlined"
                              error={Boolean(touched.vehicleId && errors.vehicleId)}
                              helperText={touched.vehicleId && errors.vehicleId}
                            >
                              <option aria-label="None" value="" />
                              <option value="0">
                                - Rego Not Listed -
                              </option>
                              {allVehicles.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.registrationNumber}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          {
                            values.vehicleId === '0' && (
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  error={Boolean(touched.unlistedVehicleRego && errors.unlistedVehicleRego)}
                                  fullWidth
                                  helperText={touched.unlistedVehicleRego && errors.unlistedVehicleRego}
                                  label="Not Listed Vehicle Rego"
                                  margin="normal"
                                  name="unlistedVehicleRego"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.unlistedVehicleRego}
                                  variant="outlined"
                                />
                              </Grid>
                            )
                          }
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              type="number"
                              error={Boolean(touched.startOdometerKms && errors.startOdometerKms)}
                              fullWidth
                              helperText={touched.startOdometerKms && errors.startOdometerKms}
                              label="Start Odometer Kms"
                              margin="normal"
                              name="startOdometerKms"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.startOdometerKms}
                              variant="outlined"
                              required
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Store/Jobsite"
                              name="jobsiteId"
                              margin="normal"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              select
                              value={values.jobsiteId}
                              variant="outlined"
                              error={Boolean(touched.jobsiteId && errors.jobsiteId)}
                              helperText={touched.jobsiteId && errors.jobsiteId}
                            >
                              <MenuItem aria-label="None" value="">
                                <ListItemText primary="&nbsp;" />
                              </MenuItem>
                              {/* <MenuItem value="0">
                                <ListItemText primary="- Jobsite Not Listed -" />
                              </MenuItem> */}
                              {allStores.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  <ListItemText primary={getJobSiteName(option)} />
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          {
                            values.jobsiteId === '0' && (
                              <>
                                <Grid
                                  item
                                  md={4}
                                  xs={12}
                                >
                                  <TextField
                                    error={Boolean(touched.jobsiteName && errors.jobsiteName)}
                                    fullWidth
                                    helperText={touched.jobsiteName && errors.jobsiteName}
                                    label="Jobsite Name"
                                    margin="normal"
                                    name="jobsiteName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.jobsiteName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={4}
                                  xs={12}
                                >
                                  <TextField
                                    error={Boolean(touched.jobsiteStreet && errors.jobsiteStreet)}
                                    fullWidth
                                    helperText={touched.jobsiteStreet && errors.jobsiteStreet}
                                    label="Jobsite Street"
                                    margin="normal"
                                    name="jobsiteStreet"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.jobsiteStreet}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={4}
                                  xs={12}
                                >
                                  <TextField
                                    error={Boolean(touched.jobsiteSuburb && errors.jobsiteSuburb)}
                                    fullWidth
                                    helperText={touched.jobsiteSuburb && errors.jobsiteSuburb}
                                    label="Jobsite Suburb"
                                    margin="normal"
                                    name="jobsiteSuburb"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.jobsiteSuburb}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={4}
                                  xs={12}
                                >
                                  <TextField
                                    error={Boolean(touched.jobsiteAreaCode && errors.jobsiteAreaCode)}
                                    fullWidth
                                    helperText={touched.jobsiteAreaCode && errors.jobsiteAreaCode}
                                    label="Jobsite Post Code"
                                    margin="normal"
                                    name="jobsiteAreaCode"
                                    type="number"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.jobsiteAreaCode}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={4}
                                  xs={12}
                                >
                                  <TextField
                                    fullWidth
                                    label="Jobsite State"
                                    name="jobsiteStateId"
                                    margin="normal"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    value={values.jobsiteStateId}
                                    variant="outlined"
                                    error={Boolean(touched.jobsiteStateId && errors.jobsiteStateId)}
                                    helperText={touched.jobsiteStateId && errors.jobsiteStateId}
                                  >
                                    <option aria-label="None" value="" />
                                    {allStates.map((option) => (
                                      <option
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.value}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                              </>
                            )
                          }
                        </Grid>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center' }}>
                        <Button
                          sx={{ marginRight: 2 }}
                          variant="outlined"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                        <SubmitFormButton
                          buttonLabel="Start Shift"
                          dirty={dirty}
                          isValid={isValid}
                          message="You are about to start new shift"
                          onConfirm={handleSubmit}
                        />
                      </CardActions>
                    </form>
                  )}
                </Formik>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(StartShift);

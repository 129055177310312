import { SHOW_ALERT, HIDE_ALERT, CLEAR_ALL_ALERTS } from '../actionTypes';

export const showAlert = (messages: Array<any> | any) => ({
  type: SHOW_ALERT,
  messages
});

export const hideAlert = (id: string) => ({
  type: HIDE_ALERT,
  id
});

export const clearAllAlerts = () => ({
  type: CLEAR_ALL_ALERTS,
});

import { AddressRes, UserProfileRes } from '../proxy/proxy';
import { getFullName } from '../utils/common';

class FormattedUserProfile {
  id: number;
  role: string;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  dateOfBirthUtc: any;
  phoneNumber: string | undefined;
  emergencyContactFullName: string | undefined;
  emergencyContactPhone: string | undefined;
  email: string | undefined;
  emailConfirmed: boolean | undefined;
  abn: string | undefined;
  address: AddressRes | undefined;
  extDriverId: string | undefined;
  drivingLicenseNumber: string | undefined;
  drivingLicenseClasses: Array<any> = [];
  drivingLicenseIssuedByStateId: number | undefined;
  drivingLicenseRestriction: string | undefined;
  drivingLicenseFirstIssuedOnUtc: any;
  drivingLicenseExpiresOnUTC: any;
  rsaCertificateNumber: string | undefined;
  rsaExpiryDateUtc: any;
  rsatCertificateNumber: string | undefined;
  rsatExpiryDateUtc: any;
  drivingRecordEmissionDateUtc: any;
  australianFederalPoliceCheckEmissionDateUtc: any;
  isRegisteredForGST: boolean | undefined;

  constructor(rawData: UserProfileRes | null = null) {
    this.id = rawData?.id ?? 0;
    this.role = rawData?.role ?? '';

    this.firstName = rawData?.firstName ?? '';
    this.middleName = rawData?.middleName ?? '';
    this.lastName = rawData?.lastName ?? '';
    this.name = getFullName(rawData?.firstName ?? '', rawData?.middleName ?? '', rawData?.lastName ?? '');

    this.phoneNumber = rawData?.phoneNumber ?? undefined;
    this.email = rawData?.email ?? undefined;
    this.abn = rawData?.abn ?? undefined;
    this.dateOfBirthUtc = rawData?.dateOfBirthUtc;

    this.emergencyContactFullName = rawData?.emergencyContactFullName ?? undefined;
    this.emergencyContactPhone = rawData?.emergencyContactPhone ?? undefined;

    this.address = rawData?.address;
    this.extDriverId = rawData?.extDriverId ?? undefined;
    this.drivingLicenseExpiresOnUTC = rawData?.drivingLicenseExpiresOnUTC;
    this.drivingLicenseNumber = rawData?.drivingLicenseNumber ?? undefined;
    this.drivingLicenseClasses = (rawData?.appUserLicenseClassMap || []).map((x: any) => x.drivingLicenseClass);
    this.drivingLicenseIssuedByStateId = rawData?.drivingLicenseIssuedByStateId ?? undefined;
    this.drivingLicenseRestriction = rawData?.drivingLicenseRestriction ?? undefined;
    this.drivingLicenseFirstIssuedOnUtc = rawData?.drivingLicenseFirstIssuedOnUtc;
    this.drivingLicenseExpiresOnUTC = rawData?.drivingLicenseExpiresOnUTC;
    this.rsaCertificateNumber = rawData?.rsaCertificateNumber ?? undefined;
    this.rsaExpiryDateUtc = rawData?.rsaExpiryDateUtc;
    this.rsatCertificateNumber = rawData?.rsatCertificateNumber ?? undefined;
    this.rsatExpiryDateUtc = rawData?.rsatExpiryDateUtc;
    this.drivingRecordEmissionDateUtc = rawData?.drivingRecordEmissionDateUtc;
    this.australianFederalPoliceCheckEmissionDateUtc = rawData?.australianFederalPoliceCheckEmissionDateUtc;

    this.isRegisteredForGST = rawData?.isRegisteredForGST;
  }
}

export default FormattedUserProfile;

import React from 'react';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Pagination } from '@mui/material';
import AppConstants from '../constants/AppConstants';

interface Props {
  rows: Array<any>,
  columns: Array<any>,
  page: number,
  pageSize?: number,
  hasPagination?: boolean,
  totalRows: number,
  checkboxSelection?: boolean,
  selectionModel?: any,
  sortModel: any,
  getRowClassName?: any,
  getCellClassName?:any,
  showCellRightBorder?: boolean,
  onRowDoubleClick?: (prop: any) => any,
  onCellClick?: (prop: any) => any,
  onSelectionModelChange?: (prop: any) => any,
  onPageChange: (prop: any) => any,
  onSortModelChange: (prop: any) => any,
}

const Table: React.FC<Props> = ({
  rows = [],
  columns = [],
  page,
  pageSize = AppConstants.GRID_PAGE_SIZE,
  hasPagination = true,
  totalRows,
  checkboxSelection = false,
  selectionModel,
  sortModel,
  showCellRightBorder = false,

  getRowClassName,
  getCellClassName,
  onRowDoubleClick,
  onCellClick,
  onSelectionModelChange,
  onPageChange,
  onSortModelChange
}) => {
  const customPagination = () => {
    const apiRef = useGridApiContext();
    const currentPage = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', paddingLeft: '1rem' }}>
          {
            totalRows && rows?.length ? (
              <b>Showing <span data-cy-id="visibleRowCount">{rows?.length}</span> of <span data-cy-id="tableRowCount">{totalRows}</span></b>
            ) : null
          }
        </div>
        {
          rows?.length && hasPagination ? (
            <Pagination
              id="paginationContainer"
              color="primary"
              count={pageCount}
              page={currentPage + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
          ) : null
        }
      </div>
    );
  };

  return (
    <DataGrid
      pagination
      rows={rows}
      columns={columns}
      page={page}
      pageSize={hasPagination ? pageSize : undefined}
      hideFooterSelectedRowCount
      autoHeight
      onRowDoubleClick={onRowDoubleClick}
      onCellClick={onCellClick}
      onPageChange={onPageChange}
      disableColumnFilter
      checkboxSelection={checkboxSelection}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      components={{
        Pagination: customPagination,
      }}
      getRowClassName={getRowClassName}
      getCellClassName={getCellClassName}
      showCellRightBorder={showCellRightBorder}
    />
  );
};

export default Table;

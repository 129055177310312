import moment from 'moment';
import {
  CLEAR_ALL_DATA,

  LOAD_ALL_SHIFTS,
  SHIFT_RESET_PAGE,
  SHIFT_CHANGE_TAB,
  SHIFT_CHANGE_STATE,
  SHIFT_DATE_RANGE,

  SHIFT_SCHEDULED_GRID_SEARCH_TEXT,
  SHIFT_ONGOING_GRID_SEARCH_TEXT,
  SHIFT_COMPLETED_GRID_SEARCH_TEXT,

  SHIFT_SCHEDULED_GRID_SEARCH_FILTER_BY,
  SHIFT_ONGOING_GRID_SEARCH_FILTER_BY,
  SHIFT_COMPLETED_GRID_SEARCH_FILTER_BY,

  SHIFT_SCHEDULED_GRID_CURRENT_PAGE,
  SHIFT_ONGOING_GRID_CURRENT_PAGE,
  SHIFT_COMPLETED_GRID_CURRENT_PAGE,

  SHIFT_SCHEDULED_GRID_SORT,
  SHIFT_ONGOING_GRID_SORT,
  SHIFT_COMPLETED_GRID_SORT,

  SHIFT_SCHEDULED_GRID_FILTER_BY_COL,
  SHIFT_ONGOING_GRID_FILTER_BY_COL,
  SHIFT_COMPLETED_GRID_FILTER_BY_COL,

  SHIFT_SCHEDULED_GRID_SELECT_ROWS,
  SHIFT_SCHEDULED_GRID_DESELECT_ROWS
} from '../actionTypes';
import { formatDateToLongDate, subtractDaysFromDate } from '../../utils/common';
import FormattedShift from '../../models/FormattedShift';

export interface StateType {
  isInitialLoad: boolean,
  currentTab: number,
  states: Array<number>,
  startDate: string,
  endDate: string,
  scheduled: {
    allRows: Array<FormattedShift>,
    filteredRows: Array<FormattedShift>,
    searchedText: string,
    filterBy: string,
    filterByCol: string,
    currentPage: number,
    sortModel: Array<any>
  },
  ongoing: {
    allRows: Array<FormattedShift>,
    filteredRows: Array<FormattedShift>,
    searchedText: string,
    filterBy: string,
    filterByCol: string,
    currentPage: number,
    sortModel: Array<any>
  },
  completed: {
    allRows: Array<FormattedShift>,
    filteredRows: Array<FormattedShift>,
    searchedText: string,
    filterBy: string,
    filterByCol: string,
    currentPage: number,
    sortModel: Array<any>
  }
}
const today = new Date().toISOString();
const INITIAL_STATE: StateType = {
  isInitialLoad: true,
  currentTab: 0,
  states: [],
  startDate: moment().isoWeekday(1).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // subtractDaysFromDate(today, 6),
  endDate: moment(moment().isoWeekday(1).startOf('day')).add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  scheduled: {
    allRows: [],
    filteredRows: [],
    searchedText: '',
    filterBy: 'all',
    filterByCol: 'all_col',
    currentPage: 0,
    sortModel: []
  },
  ongoing: {
    allRows: [],
    filteredRows: [],
    searchedText: '',
    filterBy: 'all',
    filterByCol: 'all_col',
    currentPage: 0,
    sortModel: []
  },
  completed: {
    allRows: [],
    filteredRows: [],
    searchedText: '',
    filterBy: 'all',
    filterByCol: 'all_col',
    currentPage: 0,
    sortModel: []
  }
};

const getRows = (oldRows: Array<FormattedShift>, newRows: Array<FormattedShift>, isInitialLoad: boolean) => {
  if (isInitialLoad) {
    return newRows;
  }

  return newRows.map((row) => {
    row.isNew = !(oldRows.find((item) => item.id === row.id));
    return row;
  });
};

const getDriverAcceptance = (userHasAccepted: boolean) => {
  if (userHasAccepted === true) return 'Accepted';
  if (userHasAccepted === false) {
    return 'Rejected';
  }
  return 'Pending';
};

const getColValue = (row: any, col: string) => {
  if (col === 'isCompleted') {
    return (row[col] ? 'Completed' : 'Ongoing').toLowerCase();
  }

  if (col === 'userHasAccepted') {
    return getDriverAcceptance(row.userHasAccepted).toLowerCase();
  }

  if (col === 'scheduledStartUTC' || col === 'scheduledEndUTC' || col === 'startedOnUTC' || col === 'endedOnUTC') {
    return (row[col] ? formatDateToLongDate(row[col], true) : '').toLowerCase();
  }

  if (col === 'vehicleRego') {
    return ((row[col] || row.unlistedVehicleRego)).toLowerCase();
  }

  return ((row[col] || '').toString()).toLowerCase();
};

const setRowSelectionStatus = (allRows: Array<any>, targetRowIds: Array<number>, status: boolean) => allRows.map((row) => {
  if (targetRowIds.find((id) => id === row.id)) {
    row.selected = status;
  }

  return row;
});

const filterRows = (allRows: Array<FormattedShift> = [], searchText = '', filterBy = 'all', filterByCol = 'all_col', states: Array<number> = []) => {
  const text = searchText.trim().toLowerCase();
  let result = text ? allRows.filter((row) => {
    // Filter only via selected col
    if (filterByCol !== 'all_col') {
      return getColValue(row, filterByCol).indexOf(text) > -1;
    }

    // Filter over all cols
    if (
      getColValue(row, 'timesheetStatus').indexOf(text) > -1
      || getColValue(row, 'isCompleted').indexOf(text) > -1
      || getColValue(row, 'userName').indexOf(text) > -1
      || getColValue(row, 'userExtDriverId').indexOf(text) > -1
      || getColValue(row, 'userHasAccepted').indexOf(text) > -1
      || getColValue(row, 'vehicleRego').indexOf(text) > -1
      || getColValue(row, 'jobsiteName').indexOf(text) > -1
      || getColValue(row, 'scheduledStartUTC').indexOf(text) > -1
      || getColValue(row, 'scheduledEndUTC').indexOf(text) > -1
      || getColValue(row, 'startedOnUTC').indexOf(text) > -1
      || getColValue(row, 'endedOnUTC').indexOf(text) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;
  if (filterBy === 'breach') {
    result = result.filter((shift) => shift?.breach?.type);
  }
  if (states.length > 0) {
    result = result.filter((shift) => states.includes(shift?.jobsite?.address?.stateId ?? 0));
  }
  return result;
};

const reducer = (state = INITIAL_STATE, action: any): StateType => {
  switch (action.type) {
    case LOAD_ALL_SHIFTS:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          allRows: getRows(state.scheduled?.allRows, action.scheduled, state.isInitialLoad),
          filteredRows: filterRows(getRows(state.scheduled?.allRows, action.scheduled, state.isInitialLoad), state.scheduled?.searchedText, state.scheduled?.filterBy, 'all_col', state.states),
        },
        ongoing: {
          ...state.ongoing,
          allRows: getRows(state.ongoing?.allRows, action.ongoing, state.isInitialLoad),
          filteredRows: filterRows(getRows(state.ongoing?.allRows, action.ongoing, state.isInitialLoad), state.ongoing?.searchedText, state.ongoing?.filterBy, 'all_col', state.states),
        },
        completed: {
          ...state.completed,
          allRows: getRows(state.completed?.allRows, action.completed, state.isInitialLoad),
          filteredRows: filterRows(getRows(state.completed?.allRows, action.completed, state.isInitialLoad), state.completed?.searchedText, state.completed?.filterBy, 'all_col', state.states),
        },
        isInitialLoad: false
      };

    case SHIFT_CHANGE_TAB:
      return {
        ...state,
        currentTab: action.data
      };

    case SHIFT_CHANGE_STATE:
      return {
        ...state,
        states: action.data,
        scheduled: {
          ...state.scheduled,
          filteredRows: filterRows(state.scheduled?.allRows, state.scheduled?.searchedText, state.scheduled?.filterBy, state.scheduled?.filterByCol, action.data),
          currentPage: 0
        },
        ongoing: {
          ...state.ongoing,
          filteredRows: filterRows(state.ongoing?.allRows, state.ongoing?.searchedText, state.ongoing?.filterBy, state.ongoing?.filterByCol, action.data),
          currentPage: 0
        },
        completed: {
          ...state.completed,
          filteredRows: filterRows(state.completed?.allRows, state.completed?.searchedText, state.completed?.filterBy, state.completed?.filterByCol, action.data),
          currentPage: 0
        }
      };

    case SHIFT_DATE_RANGE:
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: action.data.endDate
      };

    case SHIFT_SCHEDULED_GRID_SEARCH_TEXT:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          searchedText: action.data,
          filteredRows: filterRows(state.scheduled?.allRows, action.data, state.scheduled?.filterBy, state.scheduled?.filterByCol, state.states),
          currentPage: 0
        },
      };
    case SHIFT_ONGOING_GRID_SEARCH_TEXT:
      return {
        ...state,
        ongoing: {
          ...state.ongoing,
          searchedText: action.data,
          filteredRows: filterRows(state.ongoing?.allRows, action.data, state.ongoing?.filterBy, state.ongoing?.filterByCol, state.states),
          currentPage: 0
        },
      };
    case SHIFT_COMPLETED_GRID_SEARCH_TEXT:
      return {
        ...state,
        completed: {
          ...state.completed,
          searchedText: action.data,
          filteredRows: filterRows(state.completed?.allRows, action.data, state.completed?.filterBy, state.completed?.filterByCol, state.states),
          currentPage: 0
        },
      };

    case SHIFT_SCHEDULED_GRID_SORT:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          sortModel: action.data || []
        },
      };
    case SHIFT_ONGOING_GRID_SORT:
      return {
        ...state,
        ongoing: {
          ...state.ongoing,
          sortModel: action.data || []
        },
      };
    case SHIFT_COMPLETED_GRID_SORT:
      return {
        ...state,
        completed: {
          ...state.completed,
          sortModel: action.data || []
        },
      };

    case SHIFT_SCHEDULED_GRID_CURRENT_PAGE:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          currentPage: action.data
        },
      };
    case SHIFT_ONGOING_GRID_CURRENT_PAGE:
      return {
        ...state,
        ongoing: {
          ...state.ongoing,
          currentPage: action.data
        },
      };
    case SHIFT_COMPLETED_GRID_CURRENT_PAGE:
      return {
        ...state,
        completed: {
          ...state.completed,
          currentPage: action.data
        },
      };

    case SHIFT_SCHEDULED_GRID_SEARCH_FILTER_BY:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          filterBy: action.data,
          filteredRows: filterRows(state.scheduled?.allRows, state.scheduled?.searchedText, action.data, state.scheduled?.filterByCol, state.states),
          currentPage: 0
        },
      };
    case SHIFT_ONGOING_GRID_SEARCH_FILTER_BY:
      return {
        ...state,
        ongoing: {
          ...state.ongoing,
          filterBy: action.data,
          filteredRows: filterRows(state.ongoing?.allRows, state.ongoing?.searchedText, action.data, state.ongoing?.filterByCol, state.states),
          currentPage: 0
        },
      };
    case SHIFT_COMPLETED_GRID_SEARCH_FILTER_BY:
      return {
        ...state,
        completed: {
          ...state.completed,
          filterBy: action.data,
          filteredRows: filterRows(state.completed?.allRows, state.completed?.searchedText, action.data, state.completed?.filterByCol, state.states),
          currentPage: 0
        },
      };

    case SHIFT_SCHEDULED_GRID_FILTER_BY_COL:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          filterByCol: action.data,
          filteredRows: filterRows(state.scheduled?.allRows, state.scheduled?.searchedText, state.scheduled?.filterBy, action.data, state.states),
          currentPage: 0
        },
      };
    case SHIFT_ONGOING_GRID_FILTER_BY_COL:
      return {
        ...state,
        ongoing: {
          ...state.ongoing,
          filterByCol: action.data,
          filteredRows: filterRows(state.ongoing?.allRows, state.ongoing?.searchedText, state.ongoing?.filterBy, action.data, state.states),
          currentPage: 0
        },
      };
    case SHIFT_COMPLETED_GRID_FILTER_BY_COL:
      return {
        ...state,
        completed: {
          ...state.completed,
          filterByCol: action.data,
          filteredRows: filterRows(state.completed?.allRows, state.completed?.searchedText, state.completed?.filterBy, action.data, state.states),
          currentPage: 0
        },
      };

    case SHIFT_SCHEDULED_GRID_SELECT_ROWS: {
      const allRows = setRowSelectionStatus(state.scheduled?.allRows, action.data || [], true);

      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          allRows,
          filteredRows: filterRows(allRows, state.scheduled?.searchedText, state.scheduled?.filterBy, state.scheduled?.filterByCol, state.states),
        }
      };
    }
    case SHIFT_SCHEDULED_GRID_DESELECT_ROWS: {
      const allRows = setRowSelectionStatus(state.scheduled?.allRows, action.data || [], false);

      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          allRows,
          filteredRows: filterRows(allRows, state.scheduled?.searchedText, state.scheduled?.filterBy, state.scheduled?.filterByCol, state.states),
        }
      };
    }
    case CLEAR_ALL_DATA:
    case SHIFT_RESET_PAGE:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Hidden,
  Tab,
  Tabs
} from '@mui/material';
import CompanyProfileDetails from '../components/company/CompanyProfileDetails';
import AppConstants from '../constants/AppConstants';
import TabPanel from '../components/TabPanel';
import CompanyPayRatesDetails from '../components/company/CompanyPayRatesDetails';
import CompanyPayFrequency from '../components/company/CompanyPayFrequency';

interface Props {}

const Company: React.FC<Props> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title>My Company | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader title="My Company" />
            <Divider />
            <CardContent>
              <CompanyProfileDetails />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

export default connect(mapStateToProps)(Company);

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  CardHeader,
  Divider
} from '@mui/material';
import { connect } from 'react-redux';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../store/actions/alertActions';
import { RootState } from '../store/store';
import { formatErrorMessage, formatISODate } from '../utils/common';
import PayrollService from '../services/PayrollService';
import ExportButton from '../components/ExportButton';

interface Props {
  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const PayrollSummary: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  const handleDateChange = (range: any) => {
    setStartDate(Date.parse(range[0]?.toISOString()));
    setEndDate(Date.parse(range[1]?.toISOString()));
  };

  return (
    <>
      <Helmet>
        <title>Pay Summaries | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader title="Pay Summaries" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={4} xs={9}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                      startText="From"
                      endText="To"
                      value={[startDate, endDate]}
                      onChange={handleDateChange}
                      inputFormat="dd-MM-yyyy"
                      renderInput={(startProps, endProps) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            paddingTop: '1rem',
                            paddingBottom: '0.5rem'
                          }}
                        >
                          <Grid item xs={6}>
                            <TextField
                              {...startProps}
                              variant="outlined"
                              color="secondary"
                              size="small"
                              required
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              {...endProps}
                              variant="outlined"
                              color="secondary"
                              size="small"
                              required
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      )}
                      maxDate={new Date()}
                      calendars={1}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <ExportButton
                    dataPromiseProvider={() =>
                      PayrollService.downloadPayMonthlySummary(
                        formatISODate(new Date(startDate).toISOString()) ?? '',
                        formatISODate(new Date(endDate).toISOString()) ?? ''
                      )
                    }
                    fileName="linfox_timesheets"
                    type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSummary);

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import TabPanel from '../TabPanel';
import JobsiteAddOns from './JobsiteAddOns';
import ConfirmButton from '../ConfirmButton';
import ShiftTimeAddOns from './ShiftTimeAddOns';
import ShiftDurationAddOns from './ShiftDurationAddOns';

interface Props {
  isOpen: boolean,
  selectedIds?: Array<number>,
  closeModal: () => any,
  onRowSelect: (row: any, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => any
}

const SelectAddOnModal: React.FC<Props> = ({
  isOpen,
  selectedIds,
  closeModal,
  onRowSelect
}) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const handleRowSelect = (row: any, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => {
    onRowSelect(row, addOnType);
    closeModal();
  };

  const handleNewJobSiteAddOnCreate = () => {
    navigate(`/app/payroll/addons/jobSite/add?staffIds=${selectedIds?.join(',')}`);
  };

  const handleNewShiftTimeAddOnCreate = () => {
    navigate(`/app/payroll/addons/shiftTime/add?staffIds=${selectedIds?.join(',')}`);
  };

  const handleNewShiftDurationAddOnCreate = () => {
    navigate(`/app/payroll/addons/shiftDuration/add?staffIds=${selectedIds?.join(',')}`);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">Select Add-on</DialogTitle>
      <DialogContent>
        <Tabs
          data-auto-id="allShiftTabs"
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab label="Jobsite" />
          <Tab label="Shift Time" />
          <Tab label="Shift Duration" />
        </Tabs>
        <Divider />
        <TabPanel value={selectedTab} index={0}>
          <JobsiteAddOns
            selectMode
            onRowSelect={(row) => handleRowSelect(row, 'jobSite')}
            otherActions={selectedIds && (
              <ConfirmButton
                buttonLabel="Cerate new"
                dialogTitle="Confirm New Jobsite Add-On Creation"
                showCancelButton
                message="You are about to create a new jobsite add-on and assign selected staff to it."
                buttonColor="primary"
                buttonVariant="outlined"
                buttonSize="medium"
                onConfirm={handleNewJobSiteAddOnCreate}
              />
            )}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <ShiftTimeAddOns
            selectMode
            onRowSelect={(row) => handleRowSelect(row, 'shiftTime')}
            otherActions={selectedIds && (
              <ConfirmButton
                buttonLabel="Cerate new"
                dialogTitle="Confirm New Shift Time Add-On Creation"
                showCancelButton
                message="You are about to create a new shift time add-on and assign selected staff to it."
                buttonColor="primary"
                buttonVariant="outlined"
                buttonSize="medium"
                onConfirm={handleNewShiftTimeAddOnCreate}
              />
            )}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <ShiftDurationAddOns
            selectMode
            onRowSelect={(row) => handleRowSelect(row, 'shiftDuration')}
            otherActions={selectedIds && (
              <ConfirmButton
                buttonLabel="Cerate new"
                dialogTitle="Confirm New Shift Duration Add-On Creation"
                showCancelButton
                message="You are about to create a new shift duration add-on and assign selected staff to it."
                buttonColor="primary"
                buttonVariant="outlined"
                buttonSize="medium"
                onConfirm={handleNewShiftDurationAddOnCreate}
              />
            )}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeModal} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectAddOnModal;

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  CardActions,
  Container
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import BackIcon from '@mui/icons-material/ChevronLeft';
import SubmitFormButton from '../components/SubmitFormButton';
import AcceptQuestions from '../components/shift/AgreeQuestions';
import ContactManager from '../components/shift/ContactManager';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';
import ShiftsService from '../services/ShiftsService';
import VehiclesService from '../services/VehiclesService';
import AppConstants from '../constants/AppConstants';
import FormattedShift from '../models/FormattedShift';
import FormattedVehicle from '../models/FormattedVehicle';
import { ShiftRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => void,
  clearAllAlerts: () => void,
  showBusyIndicator: () => void,
  hideBusyIndicator: () => void,
}

const ShiftPreCheck: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const shiftId = useParams()?.id;
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isLinfoxShift = query.get('type')?.toLowerCase() === 'linfox';

  const [answeredToPreCheckQuestions, setAnsweredToPreCheckQuestions] = useState(false);
  const [agreedToPreCheckQuestions, setAgreedToPreCheckQuestions] = useState(false);
  const [acceptedQuestionIds, setAcceptedQuestionIds] = useState<Array<number>>([]);
  const [shiftData, setShiftData] = useState<FormattedShift>();

  const [allVehicles, setAllVehicles] = useState<Array<FormattedVehicle>>([]);

  const getVehicleOdoReading = (vehicleId: number) => allVehicles.find((vehicle) => vehicle.id === Number(vehicleId))?.odometerKms || 0;

  const navigateToShiftPage = ({ id }: ShiftRes) => {
    if (isLinfoxShift) {
      navigate(`/employee/shift/${id}/submit`, { replace: true });
    } else {
      navigate(`/employee/shift/${id}`, { replace: true });
    }
  };

  const agreeAll = (questionIds: Array<number>) => {
    setAnsweredToPreCheckQuestions(true);
    setAgreedToPreCheckQuestions(true);
    setAcceptedQuestionIds(questionIds);
  };

  const disAgree = () => {
    setAnsweredToPreCheckQuestions(true);
    setAgreedToPreCheckQuestions(false);
  };

  const handleFormSubmit = (values: any) => {
    const promise: Promise<any> = ShiftsService.startScheduledShift(Number(shiftId), values.startOdometerKms);

    showBusyIndicator();
    clearAllAlerts();
    promise
      .then((result) => {
        showAlert(formatSuccessMessage('Precheck Successful'));
        navigateToShiftPage(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleCancelClick = () => {
    navigate('/employee/shift');
  };

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();

    const promises: Array<Promise<any>> = [
      ShiftsService.getShiftById(Number(shiftId)),
      VehiclesService.getAllVehicles()
    ];
    Promise.all(promises)
      .then((resp) => {
        setShiftData(resp[0]);
        setAllVehicles(resp[1] || []);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Start Shift | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              title="Start Shift"
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={handleCancelClick}>
                    <BackIcon />
                  </IconButton>
                )
              }
            />
            <Divider />
            <CardContent>
              {shiftData && !answeredToPreCheckQuestions && <AcceptQuestions agreeAll={agreeAll} disAgree={disAgree} />}
              {shiftData && answeredToPreCheckQuestions && !agreedToPreCheckQuestions && <ContactManager />}
              {(answeredToPreCheckQuestions && agreedToPreCheckQuestions) && (
                <Formik
                  initialValues={{
                    startOdometerKms: shiftData?.vehicle ? getVehicleOdoReading(shiftData?.vehicle.id ?? 0) : 0
                  }}
                  validationSchema={Yup.object().shape({
                    startOdometerKms: Yup.number()
                      .required('Odometer reading is required')
                      .min(shiftData?.vehicle ? getVehicleOdoReading(shiftData?.vehicle.id ?? 0) : 0, `Odometer reading must be greater than or equal to ${shiftData?.vehicle ? getVehicleOdoReading(shiftData?.vehicle.id ?? 0) : 0}`)
                  })}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    dirty,
                    isValid
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardContent>
                        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              type="number"
                              error={Boolean(touched.startOdometerKms && errors.startOdometerKms)}
                              fullWidth
                              helperText={touched.startOdometerKms && errors.startOdometerKms}
                              label="Start Odometer Kms"
                              margin="normal"
                              name="startOdometerKms"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.startOdometerKms}
                              variant="outlined"
                              required
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center' }}>
                        <Button
                          sx={{ marginRight: 2 }}
                          variant="outlined"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                        <SubmitFormButton
                          buttonLabel="Start Shift"
                          dirty
                          isValid={isValid}
                          message="You are about to start new shift"
                          onConfirm={handleSubmit}
                        />
                      </CardActions>
                    </form>
                  )}
                </Formik>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftPreCheck);

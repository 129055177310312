import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { AnyAction } from 'redux';
import {
  LOAD_ALL_SHIFTS,
  SHIFT_RESET_PAGE,
  SHIFT_CHANGE_TAB,
  SHIFT_CHANGE_STATE,
  SHIFT_DATE_RANGE,

  SHIFT_SCHEDULED_GRID_SEARCH_TEXT,
  SHIFT_ONGOING_GRID_SEARCH_TEXT,
  SHIFT_COMPLETED_GRID_SEARCH_TEXT,

  SHIFT_SCHEDULED_GRID_SEARCH_FILTER_BY,
  SHIFT_ONGOING_GRID_SEARCH_FILTER_BY,
  SHIFT_COMPLETED_GRID_SEARCH_FILTER_BY,

  SHIFT_SCHEDULED_GRID_CURRENT_PAGE,
  SHIFT_ONGOING_GRID_CURRENT_PAGE,
  SHIFT_COMPLETED_GRID_CURRENT_PAGE,

  SHIFT_SCHEDULED_GRID_SORT,
  SHIFT_ONGOING_GRID_SORT,
  SHIFT_COMPLETED_GRID_SORT,

  SHIFT_SCHEDULED_GRID_FILTER_BY_COL,
  SHIFT_ONGOING_GRID_FILTER_BY_COL,
  SHIFT_COMPLETED_GRID_FILTER_BY_COL,

  SHIFT_SCHEDULED_GRID_SELECT_ROWS,
  SHIFT_SCHEDULED_GRID_DESELECT_ROWS
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import ShiftsService from '../../services/ShiftsService';
import { formatErrorMessage, getDateDifferenceInMS, getDayStart, getDayEnd } from '../../utils/common';

export const refreshData = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  ShiftsService.getAllShifts()
    .then((result) => {
      dispatch({
        type: LOAD_ALL_SHIFTS,
        scheduled: result.filter((shift: any) => shift?.shiftStatusId === 1 || shift?.shiftStatusId === 0).sort((a, b) => getDateDifferenceInMS(b.scheduledStartUTC, a.scheduledStartUTC)),
        ongoing: result.filter((shift: any) => shift?.shiftStatusId === 2),
        completed: result.filter((shift: any) => shift?.shiftStatusId === 3 || shift?.shiftStatusId === 4)
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const refreshDataWithDateRange = (startDate: string | undefined, endDate: string | undefined): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  ShiftsService.getAllShifts2(getDayStart(startDate) ?? moment().startOf('week').add(1, 'day').toDate(), getDayEnd(endDate) ?? moment().endOf('week').add(1, 'day').toDate())
    .then((result) => {
      dispatch({
        type: LOAD_ALL_SHIFTS,
        scheduled: result.filter((shift: any) => shift?.shiftStatusId === 1 || shift?.shiftStatusId === 0).sort((a, b) => getDateDifferenceInMS(b.scheduledStartUTC, a.scheduledStartUTC)),
        ongoing: result.filter((shift: any) => shift?.shiftStatusId === 2),
        completed: result.filter((shift: any) => shift?.shiftStatusId === 3 || shift?.shiftStatusId === 4)
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: SHIFT_RESET_PAGE
});

export const changeTab = (tabIndex: number): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SHIFT_CHANGE_TAB, data: tabIndex });
};
export const selectStates = (states: Array<number> = []): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SHIFT_CHANGE_STATE, data: states });
};

export const searchByTextScheduled = (text: string) => ({
  type: SHIFT_SCHEDULED_GRID_SEARCH_TEXT,
  data: text
});

export const searchByTextOngoing = (text: string) => ({
  type: SHIFT_ONGOING_GRID_SEARCH_TEXT,
  data: text
});

export const searchByTextCompleted = (text: string) => ({
  type: SHIFT_COMPLETED_GRID_SEARCH_TEXT,
  data: text
});

export const searchByWarningScheduled = (filterBy = 'all') => ({
  type: SHIFT_SCHEDULED_GRID_SEARCH_FILTER_BY,
  data: filterBy
});

export const searchByWarningOngoing = (filterBy = 'all') => ({
  type: SHIFT_ONGOING_GRID_SEARCH_FILTER_BY,
  data: filterBy
});

export const searchByWarningCompleted = (filterBy = 'all') => ({
  type: SHIFT_COMPLETED_GRID_SEARCH_FILTER_BY,
  data: filterBy
});

export const changePageScheduled = (page = 1) => ({
  type: SHIFT_SCHEDULED_GRID_CURRENT_PAGE,
  data: page
});

export const changePageOngoing = (page = 1) => ({
  type: SHIFT_ONGOING_GRID_CURRENT_PAGE,
  data: page
});

export const changePageCompleted = (page = 1) => ({
  type: SHIFT_COMPLETED_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModelScheduled = (sortModel = []) => ({
  type: SHIFT_SCHEDULED_GRID_SORT,
  data: sortModel
});

export const changeSortModelOngoing = (sortModel = []) => ({
  type: SHIFT_ONGOING_GRID_SORT,
  data: sortModel
});

export const changeSortModelCompleted = (sortModel = []) => ({
  type: SHIFT_COMPLETED_GRID_SORT,
  data: sortModel
});

export const changeFilterByColumnScheduled = (col = 'all_col') => ({
  type: SHIFT_SCHEDULED_GRID_FILTER_BY_COL,
  data: col
});

export const changeFilterByColumnOngoing = (col = 'all_col') => ({
  type: SHIFT_ONGOING_GRID_FILTER_BY_COL,
  data: col
});

export const changeFilterByColumnCompleted = (col = 'all_col') => ({
  type: SHIFT_COMPLETED_GRID_FILTER_BY_COL,
  data: col
});

export const updateSelectionStatusScheduled = (visibleRows: Array<any>, selectedRowIds: Array<number>): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: SHIFT_SCHEDULED_GRID_SELECT_ROWS,
    visibleRows,
    data: selectedRowIds
  });

  dispatch({
    type: SHIFT_SCHEDULED_GRID_DESELECT_ROWS,
    visibleRows,
    data: visibleRows.filter((row) => !(selectedRowIds.find((id) => id === row.id))).map((row) => row.id)
  });
};

export const changeDateRange = (startDate: string, endDate: string) => ({
  type: SHIFT_DATE_RANGE,
  data: {
    startDate,
    endDate
  }
});

import { useState } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, List, ListItem, Collapse, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import LocalStorageService from '../services/LocalStorageService';

const NavItem = ({
  data,
  ...rest
}) => {
  const location = useLocation();
  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();
  const navigate = useNavigate();
  const getButtonStyles = (isActive) => ({
    display: 'flex',
    color: 'text.secondary',
    fontWeight: 'medium',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    py: 1.25,
    textTransform: 'none',
    width: '100%',
    ...(isActive && {
      color: 'primary.main',
      fontWeight: 'bold'
    }),
    '& svg': {
      mr: 1
    }
  });
  const isMenuActive = (href, exactMatch = false) => {
    if (!href) {
      return false;
    }

    return !!matchPath({ path: href, end: exactMatch }, location.pathname);
  };
  const [expanded, setExpanded] = useState(() => {
    const { subMenus = [], href } = data;

    if (subMenus.length > 0) {
      return isMenuActive(href);
    }

    return false;
  });

  const onMainMenuClick = () => {
    const { subMenus = [], href } = data;
    const isActive = isMenuActive(href);

    // On main menu click, select the first submenu
    if (!isActive) {
      navigate(subMenus[0].href);
    }

    if (!expanded) {
      setExpanded(true);
    }

    if (expanded && isActive) {
      setExpanded(false);
    }

    // On menu expand select the first submenu
    if (!expanded && !isActive) {
      navigate(subMenus[0].href);
    }
  };

  const onToggleIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const hasUserMatchingRoles = (targetRoles) => !targetRoles.some((role) => !!roles.find((x) => x === role));

  const getMenuItem = (item, isSubMenu) => {
    const { href, title, icon: Icon } = item;
    const active = isMenuActive(href);

    return (
      <ListItem
        key={title}
        disableGutters
        sx={{ display: 'flex', py: 0, pl: isSubMenu ? 2 : 0 }}
        {...rest}
      >
        <Button
          component={RouterLink}
          sx={getButtonStyles(active)}
          to={href}
        >
          {Icon && (
            <Icon fontSize={isSubMenu ? 'small' : 'medium'} />
          )}
          <span>{title}</span>
        </Button>
      </ListItem>
    );
  };

  const getExpandableMenuItem = (item) => {
    const { subMenus, title, icon: Icon, role, href } = item;
    const isActive = isMenuActive(href);

    // must have matching role
    if (hasUserMatchingRoles(role)) {
      return null;
    }

    return (
      <>
        <ListItem
          disableGutters
          sx={{ display: 'flex', py: 0 }}
          {...rest}
        >
          <Button
            sx={getButtonStyles(isActive)}
            onClick={onMainMenuClick}
          >
            {Icon && <Icon fontSize="medium" />}
            <span style={{ flex: 1, textAlign: 'left' }}>{title}</span>
            <IconButton size="small" onClick={onToggleIconClick}>
              {expanded === true ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Button>
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List disablePadding>
            { subMenus.map((menu, pos) => getMenuItem(menu, true)).filter(Boolean) }
          </List>
        </Collapse>
      </>
    );
  };

  // must have matching role
  if (hasUserMatchingRoles(data.role)) {
    return null;
  }

  if (data.subMenus?.length > 0) {
    return getExpandableMenuItem(data);
  }

  return getMenuItem(data);
};

NavItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default NavItem;

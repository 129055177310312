import React, { useState } from 'react';
import { Button } from '@mui/material';

const ApiError = ({ error }) => {
  const [showMore, setShowMore] = useState(false);

  const title = error?.title;
  let messages = error?.messages;
  if (messages && error?.messages.length > 0 && title) {
    messages = messages.filter((msg) => title && msg?.toLowerCase() !== title.toLowerCase());
  }
  const errorReference = error?.errorReference;

  return (
    <div>
      {
        title && (
          <p><b>{error?.title}</b></p>
        )
      }
      {
        messages && (
          messages.map((message) => (
            <p key={message}>{message}</p>
          ))
        )
      }
      {
        errorReference && (
          <>
            {
              !showMore && (
                <>
                  <div>
                    <Button sx={{ padding: 0, fontSize: '0.75rem' }} size="small" variant="text" onClick={() => setShowMore(true)}>Show More</Button>
                  </div>
                </>
              )
            }
            {
              showMore && (
                <p>Error Reference: {errorReference}</p>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default ApiError;

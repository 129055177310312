import { JobsiteMinRes, PayrollLineItemRes, UserPayrollRes } from '../proxy/proxy';
import { getFullName } from '../utils/common';

export default class PayrollLineItem implements PayrollLineItemRes {
  id: number | undefined;
  userId: number | undefined;
  user: UserPayrollRes | undefined;
  name: string | undefined;
  extDriverId: string | undefined;
  jobsiteName: string | undefined;
  dateLocal: string | undefined;
  jobsite: JobsiteMinRes | undefined;
  jobsiteId: number | undefined;
  store: string | undefined;
  shift: string | undefined;
  hours: number | undefined = 0;
  timesheetHoursForDay: number | undefined = 0;
  payrollTimesheetHoursMismatchType: number | undefined = 0;
  adjustments: number | undefined = 0;
  pay: number | undefined = 0;
  payCalculationLog: string | undefined;
  payDateLocal: string | undefined;
  paidStartDateTimeLocal: string | undefined;
  paidEndDateTimeLocal: string | undefined;
  comments: string | undefined;
  creditNote: string | undefined;

  constructor(data: PayrollLineItemRes | null = null) {
    if (!data) return;
    this.id = data.id;
    this.userId = data.user?.id;
    this.user = data.user;
    this.dateLocal = data.dateLocal ?? undefined;
    this.name = data?.user ? getFullName(data.user.firstName ?? '', data.user.middleName ?? '', data.user.lastName ?? '') : '';
    this.extDriverId = data?.user?.extDriverId ?? undefined;
    // this.jobsite = {
    //   id: data.jobsiteId
    // };
    this.jobsiteId = data.jobsiteId;
    this.jobsiteName = data.jobsite?.name ?? undefined;
    this.store = data.store ?? undefined;
    this.shift = data.shift ?? undefined;
    this.hours = data.hours;
    this.timesheetHoursForDay = data.timesheetHoursForDay ?? undefined;
    this.payrollTimesheetHoursMismatchType = data.payrollTimesheetHoursMismatchType;
    this.adjustments = data.adjustments;
    this.pay = data.pay;
    this.payCalculationLog = data.payCalculationLog ?? undefined;
    this.payDateLocal = data.payDateLocal ?? undefined;
    this.paidStartDateTimeLocal = data.paidStartDateTimeLocal ?? undefined;
    this.paidEndDateTimeLocal = data.paidEndDateTimeLocal ?? undefined;
    this.comments = data.comments ?? undefined;
    this.creditNote = data.creditNote ?? undefined;
  }
}

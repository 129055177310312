import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import AccountService from '../services/AccountService';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, formatSuccessMessage, getQueryParam } from '../utils/common';
import AppConstants from '../constants/AppConstants';

interface Props {
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const ConfirmEmail: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const navigate = useNavigate();

  const { id = '' } = useParams();

  const token = getQueryParam('token');
  const passwordResetToken = getQueryParam('ResetToken');

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(true);

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();
    AccountService.confirmEmail(id, token)
      .then((resp) => {
        setIsConfirmed(true);
        showAlert(formatSuccessMessage(resp));
      }).catch((err) => {
        setIsConfirmed(false);
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        setIsConfirming(false);
        hideBusyIndicator();
      });
  }, []);

  const navigateToPasswordReset = () => {
    navigate(`/resetpassword/${id}?token=${passwordResetToken}`, { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>Confirm Email | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        {
          isConfirming
          && (
            <Container maxWidth="sm">
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Confirm Email
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 1,
                  pt: 3
                }}
              >
                <Typography variant="h4">
                  Please wait while we confirm your Email.
                </Typography>
              </Box>
            </Container>
          )
        }
        {
          !isConfirming && isConfirmed
          && (
            <Container maxWidth="sm">
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Confirm Email
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 1,
                  pt: 3
                }}
              >
                <Typography variant="h4">
                  Successfully confirmed your Email.
                </Typography>
                {
                  passwordResetToken && (
                    <Typography variant="subtitle2">
                      Please reset your password.
                    </Typography>
                  )
                }
              </Box>
              {
                passwordResetToken && (
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={navigateToPasswordReset}
                    >
                      Reset Password
                    </Button>
                  </Box>
                )
              }
            </Container>
          )
        }
        {
          !isConfirming && !isConfirmed
          && (
            <Container maxWidth="sm">
              <Box sx={{ mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Confirm Email
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 1,
                  pt: 3
                }}
              >
                <Typography variant="h4">
                  Error while confirming your Email.
                </Typography>
                <Typography variant="subtitle2">
                  Please contact your Administrator.
                </Typography>
              </Box>
            </Container>
          )
        }
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);

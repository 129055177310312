import React from 'react';
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  id?: string,
  buttonLabel?: string,
  buttonVariant?: 'contained' | 'text' | 'outlined' | undefined,
  buttonColor?: 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'success' | 'warning' | undefined,
  buttonSize?: 'medium' | 'small' | 'large' | undefined,
  isIconButton?: boolean,
  buttonIcon?: any,
  dialogTitle: string,
  message: string,
  showCancelButton: boolean,
  cancelButtonLabel?: string,
  okButtonLabel?: string,
  okButtonColor?: 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'success' | 'warning' | undefined,
  onConfirm?: () => any,
  disabled?: boolean,
  sx?: any,
  fullWidth?: boolean,
  allowBackdropClick?: boolean
}

const ConfirmButton: React.FC<Props> = ({
  id,
  buttonLabel = 'Confirm',
  buttonVariant = 'contained',
  buttonColor = 'primary',
  buttonSize = 'medium',
  isIconButton = false,
  buttonIcon,
  dialogTitle,
  message,
  showCancelButton = false,
  cancelButtonLabel = 'Cancel',
  okButtonLabel = 'Confirm',
  okButtonColor = 'primary',
  onConfirm,
  disabled = false,
  sx,
  fullWidth,
  allowBackdropClick = true
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event: any) => {
    setOpen(true);
  };

  const handleClose = (event: any = null, reason: string | null = null) => {
    if (!allowBackdropClick && reason && reason === 'backdropClick') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {
        isIconButton && (
          <IconButton id={id} sx={sx} disabled={disabled} size={buttonSize} color={buttonColor} onClick={handleClickOpen}>
            {buttonIcon}
          </IconButton>
        )
      }
      {
        !isIconButton && (
          <Button id={id} fullWidth={fullWidth} sx={sx} startIcon={buttonIcon} disabled={disabled} size={buttonSize} variant={buttonVariant} color={buttonColor} onClick={handleClickOpen}>
            {buttonLabel}
          </Button>
        )
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            showCancelButton && (
              <Button autoFocus
                variant="outlined"
                onClick={handleClose} color="primary"
                data-auto-id="confirmDialogCancelButton"
              >
                {cancelButtonLabel}
              </Button>
            )
          }
          <Button variant="contained"
            onClick={() => { handleClose(); onConfirm?.(); }}
            color={okButtonColor}
            data-auto-id="confirmDialogOkButton"
          >
            {okButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmButton;

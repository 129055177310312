import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IconButton, Box, Container, Card, CardHeader, Divider, CardContent } from '@mui/material';
import { ChevronLeft as BackIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { connect } from 'react-redux';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import JobsiteAddOnDetail from '../components/payroll/JobsiteAddOnDetail';
import ConfirmButton from '../components/ConfirmButton';
import AddOnsService from '../services/AddOnsService';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';
import ShiftTimeAddOnDetail from '../components/payroll/ShiftTimeAddOnDetail';
import ShiftDurationAddOnDetail from '../components/payroll/ShiftDurationAddOnDetail';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any
}

const PayrollAddOnDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const params = useParams();
  const addOnType = params.addOnType?.toLowerCase();
  const { id } = params;
  const addOnId = !Number.isNaN(Number(id)) ? Number(id) : 0;

  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    let promise: Promise<any> = Promise.resolve();
    if (addOnType === 'jobsite') {
      promise = AddOnsService.deleteJobSiteAddOn(addOnId);
    } else if (addOnType === 'shifttime') {
      promise = AddOnsService.deleteShiftTimeAddOn(addOnId);
    } else {
      promise = AddOnsService.deleteShiftDurationAddOn(addOnId);
    }

    showBusyIndicator();
    clearAllAlerts();
    promise
      .then(() => {
        showAlert(formatSuccessMessage('Add-on deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  let addOnTypeName = '';
  if (addOnType === 'jobsite') {
    addOnTypeName = 'Job Site';
  } else if (addOnType === 'shifttime') {
    addOnTypeName = 'Shift Time';
  } else {
    addOnTypeName = 'Shift Duration';
  }

  return (
    <>
      <Helmet>
        <title>{addOnTypeName} AddOn Details | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title={`${addOnTypeName} AddOn Details`}
              action={
                !!addOnId && (
                  <ConfirmButton
                    buttonIcon={
                      (
                        <DeleteIcon />
                      )
                    }
                    isIconButton
                    dialogTitle="Are you sure?"
                    showCancelButton
                    message="AddOn will be deleted permanently."
                    buttonColor="secondary"
                    onConfirm={handleDeleteClick}
                  />
                )
              }
            />
            <Divider />
            <CardContent>
              {
                addOnType === 'jobsite' && (
                  <JobsiteAddOnDetail />
                )
              }
              {
                addOnType === 'shifttime' && (
                  <ShiftTimeAddOnDetail />
                )
              }
              {
                addOnType === 'shiftduration' && (
                  <ShiftDurationAddOnDetail />
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollAddOnDetail);

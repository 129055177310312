import React from 'react';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import AppConstants from '../constants/AppConstants';
import ApiError from '../components/ApiError';
import FormattedShift from '../models/FormattedShift';
import { JobsiteRes } from '../proxy/proxy';

export function formatErrorMessage(error: any) {
  console.log(error);
  let message: any = 'Something went wrong!';
  if (error?.title || error?.messages?.length) {
    message = <ApiError error={error} />;
  }

  return {
    message,
    severity: 'error'
  };
}

export function formatSuccessMessage(result: any) {
  let message = result;
  if (typeof result !== 'string') {
    message = result?.message;
  }
  return {
    message,
    severity: 'success'
  };
}

export function getQueryParam(paramKey: any) {
  if (!paramKey) return '';

  const queryParams = window.location.search.split('&').map((x) => {
    const [key, value] = x.replace('?', '').split('=');
    return { key, value };
  });

  return queryParams.find((x) => x.key === paramKey)?.value || '';
}

export function getFullName(firstName: string, middleName: string, lastName: string) {
  const name = [
    firstName,
    middleName,
    lastName
  ].filter(Boolean);

  return name.join(' ');
}

export function isDriver(roles: Array<string>) {
  if (roles?.find((role) => role === AppConstants.USER_ROLES.DRIVER)) {
    return true;
  }
  return false;
}

export function isManager(roles: Array<string>) {
  if (roles?.find((role) => role === AppConstants.USER_ROLES.MANAGER)) {
    return true;
  }
  return false;
}

export function isAccountant(roles: Array<string>) {
  if (roles?.find((role) => role === AppConstants.USER_ROLES.ACCOUNTANT)) {
    return true;
  }
  return false;
}

export function getRoleBasedLandingPath(roles: Array<string>) {
  if (isDriver(roles)) {
    return '/employee/shift';
  }
  if (isAccountant(roles)) {
    return '/app/staff';
  }

  return '/app/linfox';
}

export function isOwner(roles: Array<string>) {
  if (roles?.find((role) => role === AppConstants.USER_ROLES.OWNER)) {
    return true;
  }
  return false;
}

export function getSupportLink(roles: Array<string>) {
  if (roles?.find((role) => role !== AppConstants.USER_ROLES.DRIVER)) {
    return '/app/support';
  }
  return '/employee/support';
}

export function getMyAccountLink(roles: Array<string>) {
  if (roles?.find((role) => role !== AppConstants.USER_ROLES.DRIVER)) {
    return '/app/profile';
  }
  return '/employee/profile';
}

// Last Wednesday at 7:51 AM
// Yesterday at 7:51 AM
// Today at 7:51 AM
// Tomorrow at 7:52 AM
export function formatDateToCalendarTime(dateString: string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    dateString = dateString.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).calendar();
}

// an hour ago
// in 16 hours
// 9 years ago
export function formatDateToRelativeTime(dateString: string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    dateString = dateString.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).fromNow();
}

export function formatDateToLongDate(dateString: string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    dateString = dateString.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).format(AppConstants.DATE_FORMATS.LONG);
}

export function formatDateToOnlyDate(dateString: string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    dateString = dateString.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).format(AppConstants.DATE_FORMATS.DATE);
}

export function formatDateToOnlyTime(dateString: string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    dateString = dateString.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).format(AppConstants.DATE_FORMATS.TIME);
}

// 2 Years, 23 Minutes, 10 minutes, a few seconds
export function getDateDifference(firstDate: string | null | undefined, secondDate: string | null | undefined, isLocal = false) {
  if (isLocal) {
    firstDate = firstDate?.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${firstDate}Z`))) {
    firstDate = `${firstDate}Z`;
  }
  const a = moment(firstDate);

  if (isLocal) {
    secondDate = secondDate?.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${secondDate}Z`))) {
    secondDate = `${secondDate}Z`;
  }
  const b = moment(secondDate);
  return moment.duration(b.diff(a)).humanize();
}

export function getDateDifferenceInMS(firstDate: string | null | undefined, secondDate: string | null | undefined, isLocal = false) {
  if (isLocal) {
    firstDate = firstDate?.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${firstDate}Z`))) {
    firstDate = `${firstDate}Z`;
  }
  const a = moment(firstDate);

  if (isLocal) {
    secondDate = secondDate?.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${secondDate}Z`))) {
    secondDate = `${secondDate}Z`;
  }
  const b = moment(secondDate);
  return b.diff(a);
}

export function getDaysFromNow(date: string | null | undefined) {
  if (!Number.isNaN(Date.parse(`${date}Z`))) {
    date = `${date}Z`;
  }
  return moment(date).diff(moment(), 'days');
}

export function formatInputDate(dateString: Date | string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    if (typeof dateString === 'string') {
      dateString = dateString.replace('Z', '');
    }
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).format('yyyy-MM-DD');
}

export function formatInputTime(dateString: Date | string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    if (typeof dateString === 'string') {
      dateString = dateString.replace('Z', '');
    }
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }
  return moment(dateString).format('HH:mm:ss');
}

export function getHourFromDate(dateString: Date | string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    if (typeof dateString === 'string') {
      dateString = dateString.replace('Z', '');
    }
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).hour();
}

export function getMinuteFromDate(dateString: Date | string | null | undefined, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    if (typeof dateString === 'string') {
      dateString = dateString.replace('Z', '');
    }
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).minute();
}

export function getNextVisibleMinute(minute: number) {
  if (minute === 0) {
    return minute;
  }
  return Math.ceil(minute / 5) * 5;
}

export function formatISODate(dateString: string | null | undefined, isUtc = false) {
  if (!dateString) {
    return null;
  }

  return isUtc ? moment.utc(dateString).toISOString() : moment(dateString).toISOString();
}

export function addDaysToDate(dateString: string | null | undefined, days: number) {
  if (!dateString) return '';

  if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }
  return moment(dateString).add(days, 'days').toISOString();
}

export function subtractDaysFromDate(dateString: string | null | undefined, days: number, isLocal = false) {
  if (!dateString) return '';

  if (isLocal) {
    dateString = dateString.replace('Z', '');
  } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
    dateString = `${dateString}Z`;
  }

  return moment(dateString).subtract(days, 'days').toISOString();
}

export function setTimeInDate(dateString: Date | string | null | undefined, hours = 0, minutes = 0, isLocal = false) {
  if (!dateString) return '';

  if (typeof dateString === 'string') {
    if (isLocal) {
      dateString = dateString.replace('Z', '');
    } else if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
      dateString = `${dateString}Z`;
    }
  }

  return moment(dateString).set({ hour: hours, minute: minutes, second: 0 }).toISOString();
}

export function convertToLocalDate(dateString: string, hours?: number, minutes?: number, seconds?: number) {
  if (!dateString) return '';

  const momentDate = moment(dateString).local();
  const year = momentDate.year().toString().padStart(2, '0');
  const month = (momentDate.month() + 1).toString().padStart(2, '0');
  const date = momentDate.date().toString().padStart(2, '0');
  const hour = (hours ?? momentDate.hours()).toString().padStart(2, '0');
  const minute = (minutes ?? momentDate.minutes()).toString().padStart(2, '0');
  const second = (seconds ?? momentDate.seconds()).toString().padStart(2, '0');

  return `${year}-${month}-${date}T${hour}:${minute}:${second}`;
}

export function getDayStart(dateString: string | null | undefined) {
  if (!dateString) return moment().isoWeekday(1).startOf('day').format('YYYY-MM-DDTHH:mm:ss');

  // if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
  //   dateString = `${dateString}Z`;
  // }
  const date = new Date(dateString);
  const dateOfMonth = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${dateOfMonth}T00:00:00`;
}

export function getDayEnd(dateString: string | null | undefined) {
  if (!dateString) return moment(moment().isoWeekday(1).startOf('day')).add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss');

  // if (!Number.isNaN(Date.parse(`${dateString}Z`))) {
  //   dateString = `${dateString}Z`;
  // }

  const date = new Date(dateString);
  const dateOfMonth = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${dateOfMonth}T23:59:59`;
}

export function getDatesInRange(startDate: string, endDate: string, format = 'DD-MM-YYYY') {
  const dateArray: Array<string> = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);

  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(format));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function getPageCount(total: number, pageSize = AppConstants.MOBILE_PAGE_SIZE) {
  if (!total) return 0;

  return Math.ceil(total / pageSize);
}

export function getCurrentPageRows(rows: Array<any>, page: number, pageSize = AppConstants.MOBILE_PAGE_SIZE) {
  if (!rows?.length || !page) return [];

  const start = page * pageSize;
  let end = (page + 1) * pageSize;
  if (end > rows.length) {
    end = rows.length;
  }

  return rows.slice(start, end);
}

function getLastBreakBefore(shift: FormattedShift) {
  if (!shift) {
    return 0;
  }

  // If Scheduled start is in future - Not a breach
  if (
    shift.scheduledStartUTC
    && shift.breachCalculatedUsing === 1
    && getDateDifferenceInMS(new Date().toISOString(), shift.scheduledStartUTC) > 0
  ) {
    return 0;
  }

  // Ignore if break is ongoing
  const hasOngoingBreak = Boolean(shift?.shiftBreaks?.find((brk) => !brk.endedOnUTC));
  if (hasOngoingBreak) {
    return 0;
  }

  // Check if there is a break taken before
  const previousBreak = shift?.shiftBreaks?.length && shift.shiftBreaks[shift.shiftBreaks.length - 1];
  if (previousBreak) {
    return Math.round(getDateDifferenceInMS(previousBreak.endedOnUTC, new Date().toISOString()) / 60000);
  }

  // Calculate from shift start
  return Math.round(getDateDifferenceInMS(shift.startedOnUTC, new Date().toISOString()) / 60000);
}

export function getBreakBreach(shift: FormattedShift): {
  message: string,
  type: 'error' | 'warning'
} | null {
  const lastBreakBefore = getLastBreakBefore(shift);
  if (lastBreakBefore >= AppConstants.BREAK_BREACH_TIME) {
    return {
      message: 'Please take a Break.',
      type: 'error'
    };
  }
  if (lastBreakBefore < AppConstants.BREAK_BREACH_TIME && lastBreakBefore > AppConstants.BREAK_BREACH_TIME - AppConstants.BREAK_BREACH_WARN_TIME) {
    return {
      message: `Please take a break in ${AppConstants.BREAK_BREACH_TIME - lastBreakBefore} minutes`,
      type: 'warning'
    };
  }

  return null;
}

export function determineTypeOfValue(value: number, name: string) {
  if (typeof value === 'number') {
    return 'number';
  }

  if (typeof value === 'boolean') {
    return 'boolean';
  }

  if (!Number.isNaN(Date.parse(value))) {
    return 'date';
  }

  if (!value && name.indexOf('DATE') > -1) {
    return 'date';
  }

  return 'string';
}

export function getObjectKeys(obj: any) {
  const excludedColumns = ['id', 'user'];
  return Object.keys(obj)
    .filter((key) => excludedColumns.indexOf(key) === -1)
    .filter((key) => !(obj[key] !== null && typeof obj[key] === 'object'))
    .map((key) => {
      let name = key
        .replace('UTC', '')
        .replace(/([A-Z])/g, ' $1').toUpperCase();
      if (name === 'USER ID') {
        name = 'USER';
      }
      if (name === 'JOBSITE ID') {
        name = 'JOBSITE';
      }
      const type = determineTypeOfValue(obj[key], name);
      const value = obj[key];
      return {
        id: key,
        name,
        type,
        value
      };
    });
}

export function getCellTemplateByType(type: string) {
  return (params: any) => {
    const { value } = params;

    if (value === null) {
      return '';
    }

    if (type === 'dateOnly') {
      return (
        <Tooltip title={formatDateToOnlyDate(value, true) || ''}>
          <span>
            {formatDateToOnlyDate(value, true)}
          </span>
        </Tooltip>
      );
    }

    if (type === 'date') {
      return (
        <Tooltip title={formatDateToLongDate(value, true) || ''}>
          <span>
            {formatDateToLongDate(value, true)}
          </span>
        </Tooltip>
      );
    }

    if (type === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (type === 'number') {
      return value?.toFixed(2);
    }

    return (
      <Tooltip title={value || ''}>
        <span>
          {value}
        </span>
      </Tooltip>
    );
  };
}

export function getGeoLocation() {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
      .then((position: any) => ({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }))
      .catch(() => null);
  }

  return Promise.resolve(null);
}

export function getJobSiteName(jobsite?: JobsiteRes) {
  if (!jobsite) return '--';

  if (jobsite?.address?.state?.value) {
    return `${jobsite.name} (${jobsite?.address?.state?.value})`;
  }

  return jobsite.name;
}

export function sortByKey(array: Array<any> = [], key: string) {
  return array.sort((a, b) => {
    const keyA = a[key];
    const keyB = b[key];

    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;

    return 0;
  });
}

export function getPast20Years() {
  const currentYear = new Date().getFullYear();
  const years = [currentYear];
  for (let index = 1; index < 20; index++) {
    years.push(currentYear - index);
  }

  return years;
}

export function getAllMonths() {
  return [
    { id: 1, label: 'January' },
    { id: 2, label: 'February' },
    { id: 3, label: 'March' },
    { id: 4, label: 'April' },
    { id: 5, label: 'May' },
    { id: 6, label: 'June' },
    { id: 7, label: 'July' },
    { id: 8, label: 'August' },
    { id: 9, label: 'September' },
    { id: 10, label: 'October' },
    { id: 11, label: 'November' },
    { id: 12, label: 'December' }
  ];
}

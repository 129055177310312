import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, Box, Container, Card, CardHeader, Typography, CardContent, Divider, Tooltip, IconButton, Hidden, Toolbar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import {
  User as UserIcon,
  Edit as EditIcon,
  DollarSign as DollarIcon
} from 'react-feather';
import Table from '../components/Table';
import MobileDataList from '../components/MobileDataList';
import GlobalSearch from '../components/GlobalSearch';
import FilterBy from '../components/FilterBy';
import AlertIndicatorIcon from '../components/AlertIndicatorIcon';
import AppConstants from '../constants/AppConstants';
import { refreshData, reset, searchByText, searchByWarning, changePage, changeSortModel, changeFilterByColumn, updateSelectionStatus } from '../store/actions/staffActions';
import { RootState } from '../store/store';
import FormattedStaff from '../models/FormattedStaff';
import { formatErrorMessage, formatSuccessMessage, isManager, isOwner } from '../utils/common';
import LocalStorageService from '../services/LocalStorageService';
import MultiRowActionButton from '../components/MultiRowActionButton';
import StaffPayRates from '../components/staff/StaffPayRates';
import EnumService from '../services/EnumService';
import SelectAddOnModal from '../components/payroll/SelectAddOnModal';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import AddOnsService from '../services/AddOnsService';
import UIStrings from '../UIStrings';
import { EnumRes } from '../proxy/proxy';

interface Props {
  visibleRows: Array<FormattedStaff>,
  selectedRowIds: Array<number>,
  totalRowCount: number,
  searchedText: string,
  selectedWarning: string,
  currentPage: number,
  sortModel: any,
  filterByCol: any,
  staffLimit: number,
  fetchAllRows: any,
  resetPage: any,
  filterByText: any,
  filterByWarning: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any,
  onSelectionModelChange: any,

  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const Staff: React.FC<Props> = ({
  visibleRows,
  selectedRowIds,
  totalRowCount,
  searchedText,
  selectedWarning,
  currentPage,
  sortModel,
  filterByCol,
  staffLimit,

  fetchAllRows,
  resetPage,
  filterByText,
  filterByWarning,
  onPageChange,
  onSort,
  onChangeFilterByColumn,
  onSelectionModelChange,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [employmentTypes, setEmploymentTypes] = React.useState<EnumRes[]>([]);
  const [appUsageTypes, setAppUsageTypes] = React.useState<EnumRes[]>([]);
  const [openEditPayRates, setOpenEditPayRates] = React.useState(false);
  const [openSelectAssignAddOn, setOpenSelectAssignAddOn] = React.useState(false);
  const [openSelectUnAssignAddOn, setOpenSelectUnAssignAddOn] = React.useState(false);
  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();
  const canInviteStaff = isOwner(roles) || isManager(roles);
  const canUpdatePayRates = isOwner(roles);

  const onRowClick = ({ id }: any) => {
    navigate(`/app/staff/${id}`);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 100,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'employmentType',
      headerName: 'Employment Type',
      width: 170,
      renderCell: (params: any) => {
        const value = employmentTypes.find((type) => type.id === params.value)?.value;

        return (
          <Tooltip title={value ?? ''}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'phoneNumber',
      headerName: 'Mobile',
      width: 120,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'extDriverId',
      headerName: 'CSA ID',
      width: 100,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'abn',
      headerName: 'ABN',
      width: 120
    },
    {
      field: 'isRegisteredForGST',
      headerName: 'GST',
      type: 'boolean',
      width: 100,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <span>
            {value ? 'Yes' : 'No'}
          </span>
        );
      }
    },
    {
      field: 'emailConfirmed',
      headerName: 'Confirmed',
      type: 'boolean',
      width: 120,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <span>
            {value ? 'Yes' : 'No'}
          </span>
        );
      }
    },
    {
      field: 'appUsagePattern',
      headerName: 'App Usage Pattern',
      width: 120,
      renderCell: (params: any) => {
        const value = appUsageTypes.find((type) => type.id === params.value)?.value;
        return (
          <span>
            {value}
          </span>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'number',
      width: 60,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
            <EditIcon />
          </IconButton>
        );
      },
      renderHeader: () => (<span />)
    },
  ];

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const onWarningSelectChange = (event: any) => {
    filterByWarning(event.target.value);
  };

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  const getEnums = () => {
    EnumService.getEnums('employmentTypes').then(setEmploymentTypes);
  };

  const getAppUsageEnums = () => {
    EnumService.getEnums('appUsagePatterns').then(setAppUsageTypes);
  };

  useEffect(() => {
    getEnums();
    fetchAllRows();
    getAppUsageEnums();
  }, []);

  const updatePayRates = () => {
    setOpenEditPayRates(true);
  };

  const inviteDriver = () => {
    if (totalRowCount < staffLimit) {
      navigate('/app/staff/invite');
    } else {
      setOpen(true);
    }
  };

  const getRowClassName = (row: FormattedStaff) => {
    if (row.alerts?.length) {
      return row.alerts.find((alert: any) => alert.type === 'error') ? 'error' : 'warning';
    }
    return '';
  };

  const handleAddOnAssign = ({ id, name }: any, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => {
    let promise: Promise<any> = Promise.resolve();
    if (addOnType === 'jobSite') {
      promise = AddOnsService.assignUsersToJobSiteAddOn(id, selectedRowIds);
    } else if (addOnType === 'shiftTime') {
      promise = AddOnsService.assignUsersToShiftTimeAddOn(id, selectedRowIds);
    } else if (addOnType === 'shiftDuration') {
      promise = AddOnsService.assignUsersToShiftDurationAddOn(id, selectedRowIds);
    }

    showBusyIndicator();
    clearAllAlerts();
    promise.then(() => {
      showAlert(formatSuccessMessage(`Selected staff successfully assigned to ${name} add-on`));
    }).catch((err) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  };

  const handleAddOnUnAssign = ({ id, name }: any, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => {
    let promise: Promise<any> = Promise.resolve();
    if (addOnType === 'jobSite') {
      promise = AddOnsService.unassignUsersToJobSiteAddOn(id, selectedRowIds);
    } else if (addOnType === 'shiftTime') {
      promise = AddOnsService.unassignUsersToShiftTimeAddOn(id, selectedRowIds);
    } else if (addOnType === 'shiftDuration') {
      promise = AddOnsService.unassignUsersToShiftDurationAddOn(id, selectedRowIds);
    }

    showBusyIndicator();
    clearAllAlerts();
    promise.then(() => {
      showAlert(formatSuccessMessage(`Selected staff successfully assigned to ${name} add-on`));
    }).catch((err) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  };

  const filterOptions = [
    { value: 'all', text: 'Show All' },
    { value: 'license', text: 'License' }
  ];

  const renderEachMobileCard = (row: FormattedStaff) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={
          <UserIcon />
        }
        action={
          (
            <>
              <AlertIndicatorIcon alerts={row?.alerts} />
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            </>
          )
        }
        title={row.name || row.email}
        subheader={row.role}
        onClick={() => onRowClick(row)}
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Email:</b> {row.email} {!row.emailConfirmed && ' (Unconfirmed)'}
            </Typography>
          </Grid>
          {
            row.phoneNumber && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Mobile Number:</b> {row.phoneNumber}
                </Typography>
              </Grid>
            )
          }
          {
            row.extDriverId && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Ext Driver Id:</b> {row.extDriverId}
                </Typography>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Is Registered For GST?:</b> {row.isRegisteredForGST ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>Staff | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              id="pageHeader"
              action={
                (
                  <>
                    <IconButton id="refreshButton" onClick={refresh}>
                      <RefreshIcon />
                    </IconButton>
                    {
                      canInviteStaff && (
                        <Tooltip title="Invite Driver" aria-label="Invite Driver">
                          <IconButton id="addButton" onClick={inviteDriver}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </>
                )
              }
              title={UIStrings.staff.pageHeader}
            />
            <Divider />
            <CardContent id="pageContent">
              <Hidden mdDown>
                <Toolbar style={{ justifyContent: 'flex-end' }}>
                  {
                    canUpdatePayRates && (
                      <MultiRowActionButton
                        selectedRowsCount={selectedRowIds?.length}
                        options={
                          [
                            {
                              label: 'Update Pay Rates',
                              onClick: updatePayRates
                            },
                            {
                              label: 'Assign Add-on',
                              onClick: () => setOpenSelectAssignAddOn(true)
                            },
                            {
                              label: 'Un-assign Add-on',
                              onClick: () => setOpenSelectUnAssignAddOn(true)
                            }
                          ]
                        }
                        disabled={!selectedRowIds?.length}
                      />
                    )
                  }
                  <div style={{ flex: '1' }} />
                  <FilterBy label="Filter by Alerts" options={filterOptions} value={selectedWarning} onChange={onWarningSelectChange} width="280px" />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} width="400px" />
                </Toolbar>
                <Table
                  rows={visibleRows}
                  columns={columns}
                  onRowDoubleClick={onRowClick}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                  sortModel={sortModel}
                  onSortModelChange={onSort}
                  getRowClassName={(params: any) => getRowClassName(params?.row)}
                  checkboxSelection={canUpdatePayRates}
                  selectionModel={visibleRows?.filter((row: any) => row.selected).map((row) => row.id) ?? []}
                  onSelectionModelChange={(selectedIds) => onSelectionModelChange(visibleRows, selectedIds)}
                />
              </Hidden>
              <Hidden mdUp>
                <Toolbar sx={{ flexDirection: 'column' }}>
                  <div style={{ width: '100%', padding: '5px 0' }}>
                    <FilterBy label="Filter by Alerts" options={filterOptions} value={selectedWarning} onChange={onWarningSelectChange} />
                  </div>
                  <div style={{ width: '100%', padding: '5px 0' }}>
                    <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} />
                  </div>
                </Toolbar>
                <MobileDataList
                  rows={visibleRows}
                  renderEachRow={renderEachMobileCard}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Upgrade Your Subscription</DialogTitle>
        <DialogContent>
          <Alert severity="error">
            You have reached the maximum number of staff for your subscription. To upgrade, please contact <a href={`mailto:${AppConstants.SUPPORT_EMAIL}`}>{AppConstants.SUPPORT_EMAIL}</a>.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={openEditPayRates} onClose={() => setOpenEditPayRates(false)}>
        <DialogTitle id="alert-dialog-title">Update Pay Rates</DialogTitle>
        <DialogContent>
          <Typography variant="h5">Pay rates will be updated for {selectedRowIds?.length} selected staff</Typography>
          <StaffPayRates ids={selectedRowIds} isReadOnly={false} payRates={{}} onCancel={() => setOpenEditPayRates(false)} onSave={() => onSelectionModelChange(visibleRows, [])} />
        </DialogContent>
      </Dialog>
      <SelectAddOnModal isOpen={openSelectAssignAddOn} closeModal={() => setOpenSelectAssignAddOn(false)} onRowSelect={handleAddOnAssign} selectedIds={selectedRowIds} />
      <SelectAddOnModal isOpen={openSelectUnAssignAddOn} closeModal={() => setOpenSelectUnAssignAddOn(false)} onRowSelect={handleAddOnUnAssign} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.staff?.filteredRows,
  selectedRowIds: state.staff?.allRows?.filter((row: any) => row.selected).map((row) => row.id ?? 0) ?? [],
  totalRowCount: state.staff?.allRows?.length,
  currentPage: state.staff?.currentPage,
  searchedText: state.staff?.searchedText,
  selectedWarning: state.staff?.filterBy,
  sortModel: state.staff?.sortModel,
  filterByCol: state.staff?.filterByCol,
  staffLimit: state.profile?.company?.maximumEmployees || Number.MAX_VALUE
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => dispatch(refreshData()),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  filterByWarning: (text: string) => dispatch(searchByWarning(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),
  onChangeFilterByColumn: (col: any) => dispatch(changeFilterByColumn(col)),
  onSelectionModelChange: (visibleRows: Array<any>, selectedRowIds: Array<number>) => dispatch(updateSelectionStatus(visibleRows, selectedRowIds)),

  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Staff);

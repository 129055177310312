import React from 'react';
import { connect } from 'react-redux';
import ShiftTable from './ShiftTable';
import { searchByTextCompleted, searchByWarningCompleted, changePageCompleted, changeSortModelCompleted, changeFilterByColumnCompleted } from '../../store/actions/shiftActions';
import FormattedShift from '../../models/FormattedShift';
import { RootState } from '../../store/store';

interface Props {
  visibleRows: Array<FormattedShift>,
  totalRowCount: number,
  currentPage: number;
  searchedText: string,
  sortModel: any,
  selectedWarning: string,
  filterByText: any,
  filterByWarning: any,
  filterByCol: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any
}

const CompletedShifts: React.FC<Props> = ({
  visibleRows,
  totalRowCount,
  currentPage,
  searchedText,
  sortModel,
  selectedWarning,
  filterByText,
  filterByWarning,
  filterByCol,
  onPageChange,
  onSort,
  onChangeFilterByColumn
}) => (
  <ShiftTable
    shifts={visibleRows}
    totalRowCount={totalRowCount}
    shiftStatus={3}
    currentPage={currentPage}
    searchedText={searchedText}
    sortModel={sortModel}
    selectedWarning={selectedWarning}
    filterByCol={filterByCol}
    onFilterByText={filterByText}
    onFilterByWarning={filterByWarning}
    onPageChange={onPageChange}
    onSort={onSort}
    onChangeFilterByColumn={onChangeFilterByColumn}
  />
);

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.shift?.completed?.filteredRows,
  totalRowCount: state.shift?.completed?.allRows?.length,
  currentPage: state.shift?.completed?.currentPage,
  searchedText: state.shift?.completed?.searchedText,
  selectedWarning: state.shift?.completed?.filterBy,
  sortModel: state.shift?.completed?.sortModel,
  filterByCol: state.shift?.completed?.filterByCol,
});

const mapDispatchToProps = (dispatch: any) => ({
  filterByText: (text: string) => dispatch(searchByTextCompleted(text)),
  filterByWarning: (text: string) => dispatch(searchByWarningCompleted(text)),
  onPageChange: (page: number) => dispatch(changePageCompleted(page)),
  onSort: (sortModel: any) => dispatch(changeSortModelCompleted(sortModel)),
  onChangeFilterByColumn: (col: any) => dispatch(changeFilterByColumnCompleted(col)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompletedShifts);

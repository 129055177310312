import { AxiosResponse } from 'axios';
import AppConstants from '../constants/AppConstants';
import { Client, PreCheckQuestionReq, PreCheckQuestionRes, PreCheckQuestionResPage } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const PreCheckQuestionsService = {
  getAllQuestions: () => configureAxios().page6({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp: PreCheckQuestionResPage) => {
    const result: Array<PreCheckQuestionRes> = resp?.items || [];
    return result.filter((question: any) => !question.isSoftDeleted);
  }),
  getQuestionById: (id: number) => configureAxios().get9(id).then((resp) => resp),
  createQuestion: (data: PreCheckQuestionReq) => configureAxios().create6(data),
  updateQuestion: (id: number, data: PreCheckQuestionReq) => configureAxios().updatePOST7(id, data),
  deleteQuestion: (id: number = 0) => configureAxios().softDelete4(id)
};

export default PreCheckQuestionsService;

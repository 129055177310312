import { AxiosResponse } from 'axios';
import AppConstants from '../constants/AppConstants';
import { Client, PredefinedShiftCommentResPage, PredefinedShiftCommentRes, PredefinedShiftCommentReq } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const PredefinedShiftCommentService = {
  getAllComments: () => configureAxios().page7({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp: PredefinedShiftCommentResPage) => {
    const result: Array<PredefinedShiftCommentRes> = resp?.items || [];
    return result.filter((comment: any) => !comment.isSoftDeleted);
  }),
  getCommentById: (id: number) => configureAxios().get8(id).then((resp) => resp),
  createComment: (data: PredefinedShiftCommentReq) => configureAxios().create7(data),
  updateComment: (id: number, data: PredefinedShiftCommentReq) => configureAxios().updatePOST8(id, data),
  deleteComment: (id: number = 0) => configureAxios().delete4(id)
};

export default PredefinedShiftCommentService;

import FormattedUserProfile from '../../models/FormattedUserProfile';
import { CompanyRes } from '../../proxy/proxy';
import {
  CLEAR_ALL_DATA,
  UPDATE_COMPANY_PROFILE,
  UPDATE_USER_PROFILE
} from '../actionTypes';

export interface StateType {
  user: FormattedUserProfile | null,
  company: CompanyRes | null
}

const INITIAL_STATE: StateType = {
  user: null,
  company: null
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case UPDATE_COMPANY_PROFILE:
      return {
        ...state,
        company: action.data || null
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        user: action.data || null
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

import React, { useState, Fragment, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { connect } from 'react-redux';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../../store/actions/alertActions';
import { FormQuestionMedia, FormSubmissionDeleteMediaFileReq } from '../../proxy/proxy';
import FormsService from '../../services/FormsService';
import { formatErrorMessage, formatSuccessMessage } from '../../utils/common';
import { hide, show } from '../../store/actions/busyIndicatorActions';

interface MediaInputPreviewProps {
  currentFieldState: FormQuestionMedia;
  form: number;
  answer?: string[];
  submissionId?: number;

  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const MediaInputPreview: React.FC<MediaInputPreviewProps> = ({
  currentFieldState,
  form,
  answer,
  submissionId,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [formId, setFormId] = useState<number>(form);
  const [fieldState, setFieldState] = useState<FormQuestionMedia>(currentFieldState);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(answer ?? []);
  const [error, setError] = useState('');

  const uploadFile = async (file: File) => {
    const fileData = {
      data: file,
      fileName: file.name
    };

    FormsService.uploadPhoto(formId, fieldState.id, fieldState.title, fileData)
      .then((result) => {
        setUploadedFiles((prevFiles) => [...prevFiles, result.mediaFileName || '']);
      })
      .catch((err) => {
        if (err?.errors?.length > 0 && !error?.includes(err?.errors[0])) {
          setError(`${error} ${err.errors[0]}`);
        }
      });
  };

  useEffect(() => {
    if (uploadedFiles === undefined || uploadedFiles.length === 0) {
      return;
    }

    // Prepare the payload for FormAnswerNumericReq
    const payload = {
      __discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
      discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
      formId,
      formQuestionId: fieldState.id, // Assuming you have formQuestionId in fieldState
      answers: uploadedFiles // Capture the input value as the answer
    };

    console.log(payload);

    const promise = FormsService.answer(payload);

    promise
      .then((response: any) => {
        // showAlert(formatSuccessMessage('File saved'));
      })
      .catch((err) => {
        if (err?.errors?.length > 0 && !error?.includes(err?.errors[0])) {
          setError(`${error} ${err.errors[0]}`);
        }
      })
      .then(() => {
        // hideBusyIndicator();
      });
  }, [uploadedFiles]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Only handle the first file (one file at a time)
    if (file) {
      setSelectedFiles((prevFiles) => [...prevFiles, file]);
      setError('');
      uploadFile(file);
    }
  };

  const handleRemoveFile = (fileName: string) => {
    const media: FormSubmissionDeleteMediaFileReq = {
      formSubmissionId: submissionId ?? 0,
      formQuestionId: fieldState.id,
      mediaFileName: fileName
    };

    const promise = FormsService.deletePhoto(media);

    promise
      .then((response: any) => {
        showAlert(formatSuccessMessage('File removed successfully'));
      })
      .catch((err) => {
        if (err?.errors?.length > 0 && !error?.includes(err?.errors[0])) {
          setError(`${error} ${err.errors[0]}`);
        }
      })
      .then(() => {
        // hideBusyIndicator();
      });

    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file !== fileName));
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {fieldState.title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {fieldState.subTitle}
          </Typography>

          {/* File Upload Button */}
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ marginBottom: '10px' }}
          >
            Upload File
            {/* Hidden File Input */}
            <input
              type="file"
              name={fieldState.title}
              placeholder={fieldState.subTitle ?? ''}
              required={fieldState.required}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              accept="image/*" // Allows only image files (e.g., .png, .jpg, .jpeg)
            />
          </Button>

          {error && <Typography color="error">{error}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Typography variant="h6" gutterBottom>Selected Files</Typography>
            <List>
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file, index) => (
                  <ListItem key={index} divider>
                    <ListItemText primary={file} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(file)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItem>
                ))
              ) : (
                <Typography>No files selected.</Typography>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaInputPreview);

import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import TokenService from '../services/TokenService';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { clearAllData } from '../store/actions/commonActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, getRoleBasedLandingPath, isDriver, isOwner } from '../utils/common';
import LocalStorageService from '../services/LocalStorageService';
import AppConstants from '../constants/AppConstants';
import { updateCompanyProfile, getCompanyProfile, getUserProfile } from '../store/actions/profileActions';
import ShiftsService from '../services/ShiftsService';
import UIStrings from '../UIStrings';
import { CompanyRes } from '../proxy/proxy';

interface Props {
  loadUserProfile: any,
  loadCompanyProfile: any,
  updateCompanyProfileDetails: any,

  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any,
  clearData: any
}

const Login: React.FC<Props> = ({
  loadUserProfile,
  loadCompanyProfile,
  updateCompanyProfileDetails,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator,
  clearData
}) => {
  const localStorageService = LocalStorageService.getService();
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (event: any) => {
    setRememberMe(event.target.checked);
  };

  const formInitialValues = {
    email: '',
    password: ''
  };
  const formValidationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    password: Yup.string().max(255).required('Password is required')
  });
  const onFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    TokenService.signIn(values.email, values.password, rememberMe)
      .then((resp) => {
        const result = resp;
        localStorageService.setToken({
          [AppConstants.ACCESS_TOKEN]: result?.access_token,
          [AppConstants.REFRESH_TOKEN]: result?.refresh_token,
          [AppConstants.TOKEN_EXPIRES_IN]: result?.expires_in
        });
        localStorageService.setUserRoles(result?.user?.roles);

        loadUserProfile();
        if (!isOwner(result?.user?.roles || [])) {
          updateCompanyProfileDetails({
            ...result?.company || {},
            maxUsers: result?.company?.maximumEmployees
          });
          localStorageService.setCompanyProfile(result?.company);
        } else {
          loadCompanyProfile();
        }

        if (isDriver(result?.user?.roles || [])) {
          ShiftsService.checkIn();
        }

        navigate(getRoleBasedLandingPath(result?.user?.roles || []));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    clearData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={onFormSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {UIStrings.login.pageHeader}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body1"
                  >
                    {UIStrings.login.pageDescription}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  data-auto-id="emailIdTextField"
                  id="emailIdTextField"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  data-auto-id="passwordTextField"
                  id="passwordTextField"
                />
                <FormControlLabel
                  control={
                    (
                      <Checkbox
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                        color="primary"
                      />
                    )
                  }
                  label="Remember me"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    data-auto-id="signInButton"
                    id="signInButton"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {UIStrings.login.forgotPassword}
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/forgotpassword"
                    variant="h6"
                    data-auto-id="forgotPasswordLink"
                    id="forgotPasswordLink"
                  >
                    {UIStrings.login.forgotPasswordLink}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  loadUserProfile: () => dispatch(getUserProfile()),
  loadCompanyProfile: () => dispatch(getCompanyProfile()),
  updateCompanyProfileDetails: (profile: CompanyRes) => dispatch(updateCompanyProfile(profile)),
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts()),
  clearData: () => dispatch(clearAllData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Hidden, Container, Box, Card, CardHeader, Divider, CardContent, IconButton, Toolbar, TextField, MenuItem, Checkbox, ListItemText } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import {
  Package as PackageIcon,
  Edit as EditIcon
} from 'react-feather';
import Table from '../components/Table';
import MobileDataList from '../components/MobileDataList';
import GlobalSearch from '../components/GlobalSearch';
import AppConstants from '../constants/AppConstants';
import { refreshData, reset, searchByText, changePage, changeSortModel, selectStates } from '../store/actions/storeActions';
import { RootState } from '../store/store';
import FormattedJobsite from '../models/FormattedJobsite';
import EnumService from '../services/EnumService';
import { EnumRes } from '../proxy/proxy';

interface Props {
  visibleRows?: Array<FormattedJobsite>,
  totalRowCount?: number,
  searchedText?: string,
  selectedWarning?: string,
  currentPage?: number,
  sortModel?: any,
  filterByCol?: any,
  fetchAllRows?: any,
  resetPage?: any,
  filterByText?: any,
  filterByWarning?: any,
  selectedStates: Array<number>,
  setStates: (states: Array<number>) => void,
  onPageChange?: any,
  onSort?: any
}

const Stores: React.FC<Props> = ({
  visibleRows = [],
  totalRowCount = 0,
  searchedText,
  currentPage = 0,
  sortModel,
  selectedStates,

  fetchAllRows,
  setStates,
  resetPage,
  filterByText,
  onPageChange,
  onSort
}) => {
  const navigate = useNavigate();
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);

  const onRowClick = ({ id }: any) => {
    navigate(`/app/jobsite/${id}`);
  };

  const columns: Array<any> = [
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'linfoxName', headerName: 'Linfox Dock Name', width: 300 },
    { field: 'areaCode', headerName: 'Post Code', width: 200 },
    { field: 'state', headerName: 'State', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <>
            <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => (<span />)
    }
  ];

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  useEffect(() => {
    fetchAllRows();
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  const addStore = () => {
    navigate('/app/jobsite/add');
  };

  const onStatesSelect = (event: any) => {
    setStates(event?.target?.value ?? []);
  };

  const renderEachMobileCard = (row: FormattedJobsite) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={
          <PackageIcon />
        }
        action={
          (
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>
          )
        }
        title={row.name}
        subheader={[row.areaCode, row.state].filter(Boolean).join(', ')}
        onClick={() => onRowClick(row)}
      />
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>Stores / Jobsite | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              action={
                (
                  <>
                    <IconButton onClick={refresh}>
                      <RefreshIcon />
                    </IconButton>
                    <IconButton onClick={addStore}>
                      <AddIcon />
                    </IconButton>
                  </>
                )
              }
              title="Stores / Jobsite"
            />
            <Divider />
            <CardContent>
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <TextField
                  sx={{ width: '200px', marginRight: '1rem' }}
                  size="small"
                  label="States"
                  name="states"
                  onChange={onStatesSelect}
                  onBlur={onStatesSelect}
                  select
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected: any) => selected.map((x: any) => allStates.find((state: any) => state.id === x)?.value).join(', ')
                  }}
                  value={selectedStates}
                >
                  {allStates.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox checked={selectedStates.includes(option.id ?? 0)} />
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </TextField>
                <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
              </Toolbar>
              <Hidden mdDown>
                <Table
                  rows={visibleRows}
                  columns={columns}
                  onRowDoubleClick={onRowClick}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                  sortModel={sortModel}
                  onSortModelChange={onSort}
                />
              </Hidden>
              <Hidden mdUp>
                <MobileDataList
                  rows={visibleRows}
                  renderEachRow={renderEachMobileCard}
                  totalRows={totalRowCount}
                  page={currentPage}
                  pageSize={8}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.store.filteredRows,
  totalRowCount: state.store.allRows?.length,
  currentPage: state.store.currentPage,
  searchedText: state.store.searchedText,
  sortModel: state.store.sortModel,
  selectedStates: state.store.states ?? [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => dispatch(refreshData()),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),
  setStates: (states: Array<number>) => dispatch(selectStates(states)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stores);

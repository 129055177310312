import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert, Button, IconButton, Box, Container, Card, CardHeader, Divider, CardContent, TextField, Grid, CardActions, ListItemText, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import VehiclesService from '../services/VehiclesService';
import EnumService from '../services/EnumService';
import ConfirmButton from '../components/ConfirmButton';
import SubmitFormButton from '../components/SubmitFormButton';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatInputDate, formatISODate, sortByKey } from '../utils/common';
import FormattedVehicle from '../models/FormattedVehicle';
import { AllEnumsRes, EnumRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const VehicleDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const vehicleId = !Number.isNaN(Number(id)) ? Number(id) : 0;
  const navigate = useNavigate();

  const [vehicleData, setVehicleData] = useState<FormattedVehicle | null>(null);
  const [vehicleTypes, setVehicleTypes] = useState<Array<EnumRes>>([]);
  const [vehicleStatuses, setVehicleStatuses] = useState<Array<EnumRes>>([]);
  const [allStates, setAllStates] = useState<EnumRes[]>([]);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
      ...(vehicleId ? [VehiclesService.getVehicleById(vehicleId)] : [])
    ];

    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then((result) => {
        const enums: AllEnumsRes = result[0];
        setVehicleTypes(sortByKey(enums?.vehicleTypes || [], 'value'));
        setVehicleStatuses(sortByKey(enums?.vehicleStatuses || [], 'value'));
        setAllStates(sortByKey(enums?.states ?? [], 'value'));
        if (vehicleId) {
          setVehicleData(result[1]);
        } else {
          setVehicleData(new FormattedVehicle());
        }
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload = {
      registrationNumber: values.registrationNumber,
      registrationExpiryDateUTC: formatISODate(values.registrationExpiryDateUTC) ?? undefined,
      insuranceExpiryDateUTC: formatISODate(values.insuranceExpiryDateUTC) ?? undefined,
      vehicleTypeId: Number(values.vehicleType),
      vehicleStatus: Number(values.vehicleStatusId),
      vin: values.vin,
      odometerKms: Number(values.odometerKms),
      location: values.location,
      nextServiceDueKms: values.nextServiceDueKms || null,
      nextServiceDueDateUTC: formatISODate(values.nextServiceDueDateUTC),
      stateId: values.stateId
    };

    const promise = vehicleId ? VehiclesService.updateVehicle(vehicleId, payload) : VehiclesService.createVehicle(payload);

    promise
      .then((response) => {
        if (vehicleId) {
          refreshData();
        } else {
          navigate(`/app/vehicle/${response.id}`, { replace: true });
        }
        showAlert(formatSuccessMessage(vehicleId ? 'Vehicle updated successfully' : 'Vehicle created successfully'));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    VehiclesService.deleteVehicle(vehicleId)
      .then(() => {
        showAlert(formatSuccessMessage('Vehicle deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Helmet>
        <title>Vehicle Details | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title="Vehicle Details"
              action={
                !!vehicleId && (
                  <ConfirmButton
                    buttonIcon={
                      (
                        <DeleteIcon />
                      )
                    }
                    isIconButton
                    dialogTitle="Are you sure?"
                    showCancelButton
                    message="Vehicle will be deleted permanently."
                    buttonColor="secondary"
                    onConfirm={handleDeleteClick}
                  />
                )
              }
            />
            <Divider />
            <CardContent>
              {
                vehicleData?.alerts?.map((alert) => (
                  <Alert sx={{ marginBottom: 1 }} key={alert.message} severity={alert.type}>{alert.message}</Alert>
                ))
              }
              {
                vehicleData && (
                  <Formik
                    initialValues={{
                      registrationNumber: vehicleData?.registrationNumber || '',
                      registrationExpiryDateUTC: formatInputDate(vehicleData?.registrationExpiryDateUTC),
                      insuranceExpiryDateUTC: formatInputDate(vehicleData?.insuranceExpiryDateUTC),
                      vehicleType: (Number.isInteger(vehicleData?.vehicleTypeId) && vehicleData?.vehicleTypeId !== 0) ? vehicleData?.vehicleTypeId : '',
                      vehicleStatusId: Number.isInteger(vehicleData?.vehicleStatusId) ? vehicleData?.vehicleStatusId : '',
                      vin: vehicleData?.vin || '',
                      stateId: vehicleData?.stateId || '',
                      odometerKms: vehicleData?.odometerKms || '',
                      location: vehicleData?.location || '',
                      nextServiceDueKms: vehicleData?.nextServiceDueKms || '',
                      nextServiceDueDateUTC: formatInputDate(vehicleData?.nextServiceDueDateUTC),
                    }}
                    validationSchema={Yup.object().shape({
                      registrationNumber: Yup.string().max(255).required('Registration Number is required'),
                      vin: Yup.string().max(255),
                      registrationExpiryDateUTC: Yup.date().required('Registration Expiry Date is required'),
                      insuranceExpiryDateUTC: Yup.date().required('Insurance Expiry Date is required'),
                      vehicleType: Yup.number().min(1).required('Vehicle Type is required'),
                      vehicleStatusId: Yup.number().required('Vehicle Status is required'),
                      odometerKms: Yup.number().required('Odometer Reading is required'),
                      nextServiceDueKms: Yup.number(),
                      nextServiceDueDateUTC: Yup.date(),
                      stateId: Yup.number().min(1).required('State is required'),
                    })}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      dirty,
                      isValid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.registrationNumber && errors.registrationNumber)}
                                fullWidth
                                helperText={touched.registrationNumber && errors.registrationNumber}
                                label="Registration Number"
                                margin="normal"
                                name="registrationNumber"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.registrationNumber}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.vin && errors.vin)}
                                fullWidth
                                helperText={touched.vin && errors.vin}
                                label="VIN"
                                margin="normal"
                                name="vin"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.vin}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="State"
                                name="stateId"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.stateId}
                                variant="outlined"
                                error={Boolean(touched.stateId && errors.stateId)}
                                helperText={touched.stateId && errors.stateId}
                              >
                                <MenuItem aria-label="None" value="">
                                  <ListItemText primary="&nbsp;" />
                                </MenuItem>
                                {allStates.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="Vehicle Type"
                                name="vehicleType"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.vehicleType}
                                variant="outlined"
                                error={Boolean(touched.vehicleType && errors.vehicleType)}
                                helperText={touched.vehicleType && errors.vehicleType}
                              >
                                <MenuItem aria-label="None" value="">
                                  <ListItemText primary="&nbsp;" />
                                </MenuItem>
                                {vehicleTypes.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="Vehicle Status"
                                name="vehicleStatusId"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                value={values.vehicleStatusId}
                                variant="outlined"
                                error={Boolean(touched.vehicleStatusId && errors.vehicleStatusId)}
                                helperText={touched.vehicleStatusId && errors.vehicleStatusId}
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem aria-label="None" value="">
                                  <ListItemText primary="&nbsp;" />
                                </MenuItem>
                                {vehicleStatuses.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.odometerKms && errors.odometerKms)}
                                fullWidth
                                helperText={touched.odometerKms && errors.odometerKms}
                                label="Odometer Reading"
                                margin="normal"
                                name="odometerKms"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.odometerKms}
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              md={12}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.location && errors.location)}
                                fullWidth
                                helperText={touched.location && errors.location}
                                label="Vehicle location"
                                margin="normal"
                                name="location"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.location}
                                variant="outlined"
                                type="text"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.registrationExpiryDateUTC && errors.registrationExpiryDateUTC)}
                                fullWidth
                                helperText={touched.registrationExpiryDateUTC && errors.registrationExpiryDateUTC}
                                label="Registration Expiry Date"
                                margin="normal"
                                name="registrationExpiryDateUTC"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.registrationExpiryDateUTC}
                                variant="outlined"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.insuranceExpiryDateUTC && errors.insuranceExpiryDateUTC)}
                                fullWidth
                                helperText={touched.insuranceExpiryDateUTC && errors.insuranceExpiryDateUTC}
                                label="Insurance Expiry Date"
                                margin="normal"
                                name="insuranceExpiryDateUTC"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.insuranceExpiryDateUTC}
                                variant="outlined"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.nextServiceDueKms && errors.nextServiceDueKms)}
                                fullWidth
                                helperText={touched.nextServiceDueKms && errors.nextServiceDueKms}
                                label="Next Service Due Kms"
                                margin="normal"
                                name="nextServiceDueKms"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nextServiceDueKms}
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.nextServiceDueDateUTC && errors.nextServiceDueDateUTC)}
                                fullWidth
                                helperText={touched.nextServiceDueDateUTC && errors.nextServiceDueDateUTC}
                                label="Next Service Due Date"
                                margin="normal"
                                name="nextServiceDueDateUTC"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nextServiceDueDateUTC}
                                variant="outlined"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined">
                            Cancel
                          </Button>
                          <SubmitFormButton
                            dirty={dirty}
                            isValid={isValid}
                            editMode={Boolean(vehicleId)}
                            onConfirm={handleSubmit}
                          />
                        </CardActions>
                      </form>
                    )}
                  </Formik>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetail);

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box, Card, CardHeader, Divider, CardContent, Grid, TextField, MenuItem, ListItemText, Button, Typography, Tooltip } from '@mui/material';
import { connect } from 'react-redux';

import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { RootState } from '../store/store';
import { formatDateToCalendarTime, formatDateToLongDate, formatDateToOnlyDate, formatErrorMessage, getAllMonths, getPast20Years } from '../utils/common';
import PublicHolidaysService from '../services/PublicHolidaysService';
import EnumService from '../services/EnumService';
import Table from '../components/Table';
import GlobalSearch from '../components/GlobalSearch';
import { EnumRes, PublicHolidayRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const PublicHolidays: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);
  const [stateId, setStateId] = useState<any>(0);
  const [publicHolidays, setPublicHolidays] = useState<Array<PublicHolidayRes> | null>();
  const [filteredRows, setFilteredRows] = useState<Array<PublicHolidayRes>>();
  const [currentPage, setCurrentPage] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'dateUTC', sort: 'asc' }]);
  const [searchedText, setSearchedText] = useState('');

  const getUpComingHolidays = (publicHolidays ?? []).filter((holiday) => {
    const today = new Date();
    today.setHours(0); today.setMinutes(0); today.setSeconds(0);
    const date = new Date(holiday.dateUTC ?? '');
    date.setHours(0); date.setMinutes(0); date.setSeconds(0);

    return date >= today;
  }).slice(0, 5);

  const onSearchTextChange = (event: any) => {
    setSearchedText(event?.target?.value);
  };

  useEffect(() => {
    const promises = [
      EnumService.getEnums('states'),
      PublicHolidaysService.search(null)
    ];
    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then(([states, holidays]) => {
        setAllStates(states);
        setPublicHolidays(holidays ?? []);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  }, []);

  const getColValue = (row: any, col: string) => ((row[col] || '').toString()).toLowerCase();

  useEffect(() => {
    const text = (searchedText ?? '').trim().toLowerCase();
    let filtered:Array<PublicHolidayRes> = [];
    if (!text) {
      filtered = publicHolidays ?? [];
    }
    filtered = (publicHolidays ?? []).filter((row) => {
      if (
        formatDateToOnlyDate(row.dateUTC).toLowerCase().indexOf(text) > -1
        || getColValue(row, 'description').indexOf(text) > -1
      ) {
        return true;
      }
      return false;
    });

    setFilteredRows(filtered);
  }, [searchedText, publicHolidays]);

  useEffect(() => {
    let selectedStateId: number | null = Number(stateId);
    if (selectedStateId === 0) {
      selectedStateId = null;
    }
    showBusyIndicator();
    clearAllAlerts();
    PublicHolidaysService.search(selectedStateId)
      .then((data) => {
        setPublicHolidays(data ?? []);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
        setPublicHolidays(null);
      })
      .then(() => {
        hideBusyIndicator();
      });
  }, [stateId]);

  const columns = [
    {
      field: 'dateUTC',
      headerName: 'Date',
      width: 200,
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={formatDateToCalendarTime(value)}>
            <span>
              {formatDateToOnlyDate(value)}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'stateId',
      headerName: 'State',
      renderCell: (params: any) => {
        const { value } = params;
        return allStates.find((state) => state.id === value)?.value ?? '--';
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1
    }
  ];

  return (
    <>
      <Helmet>
        <title>Public Holidays | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              title="Public Holidays"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={4} xs={4}>
                  <TextField
                    sx={{ width: '20rem' }}
                    size="small"
                    label="State"
                    name="state"
                    margin="normal"
                    onChange={(e) => setStateId(e.target.value)}
                    required
                    select
                    value={stateId}
                    variant="outlined"
                  >
                    <MenuItem value="0">
                      <ListItemText primary="All" />
                    </MenuItem>
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="20rem" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  {
                    (publicHolidays ?? []).length > 0 && (
                      <Table
                        rows={filteredRows ?? []}
                        columns={columns}
                        totalRows={publicHolidays?.length ?? 0}
                        page={currentPage}
                        onPageChange={setCurrentPage}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                      />
                    )
                  }
                  {
                    publicHolidays?.length === 0 && (
                      <Card>
                        <CardHeader title="No Public Holidays found" />
                      </Card>
                    )
                  }
                </Grid>
                <Grid item md={4} xs={12}>
                  {
                    getUpComingHolidays.length > 0 && (
                      <>
                        <Typography variant="h3">Upcoming Holidays</Typography>
                        {
                          getUpComingHolidays.map((holiday: PublicHolidayRes, index) => (
                            <Card key={index} sx={{ marginTop: 2, marginBottom: 2 }}>
                              <CardHeader
                                title={holiday.description ?? formatDateToOnlyDate(holiday.dateUTC)}
                                subheader={`${holiday.description && formatDateToOnlyDate(holiday.dateUTC)} - ${allStates.find((state) => state.id === holiday.stateId)?.value ?? '--'}`}
                              />
                            </Card>
                          ))
                        }
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicHolidays);

import { BreachCalculationType, UserRes, JobsiteRes, VehicleRes, ExtCompanyRes, ShiftCommentRes, PreCheckAnswerRes, EnumRes } from '../proxy/proxy';
import { getFullName, getDateDifferenceInMS, getBreakBreach } from '../utils/common';
import FormattedShiftNR from './FormattedShiftNR';

class FormattedShift {
  id: number;
  shiftStatusId: number;
  shiftStatus: string;
  driverArrivalTimeLocal: string;
  scheduledStartUTC: string;
  scheduledEndUTC: string;
  startedOnUTC: string;
  endedOnUTC: string;
  scheduledWeight: number;
  scheduledKms: number;
  scheduledDrops: number;
  scheduledDifficulty: number;
  scheduledTotalWorkHours: number;
  instructions: string;
  breachCalculatedUsing: BreachCalculationType;
  routeId: string;
  driverComments: string = '';
  user: UserRes;
  userName: string;
  userEmail: string;
  userExtDriverId: string;
  userHasAccepted: boolean;
  jobsite: JobsiteRes;
  jobsiteName: string;
  vehicle: VehicleRes;
  vehicleRego: string;
  shiftSupervisorName: string = '--';
  shiftSupervisorPhone: string = '--';
  unlistedVehicleRego: string;
  extCompany: ExtCompanyRes;
  startOdometerKms: number;
  endOdometerKms: number;
  dock: string;
  jotFormUrl: string;
  shiftBreaks: Array<{
    id: number,
    startedOnUTC: string,
    endedOnUTC: string,
    breakTime: number
  }>;
  shiftComments: Array<ShiftCommentRes>;
  preCheckAnswers: Array<PreCheckAnswerRes>;
  totalShiftTime: number | string;
  totalBreakTime: number | string;
  totalTimeWorked: number | string;
  breach: any;
  checkins: Array<any>;
  checkinStatus: string;
  shiftNRs: Array<any>;
  timesheetStatus: string;
  isNew: boolean = false;

  constructor(rawData: any = null, shiftStatusTypes: Array<EnumRes> = []) {
    this.id = rawData?.id;
    this.shiftStatusId = rawData?.shiftStatus;
    this.shiftStatus = shiftStatusTypes?.find((type) => type.id === rawData?.shiftStatus)?.value ?? '';

    this.driverArrivalTimeLocal = rawData?.driverArrivalTimeLocal || '';
    this.scheduledStartUTC = rawData?.scheduledStartLocal || '';
    this.scheduledEndUTC = rawData?.scheduledEndLocal || '';
    this.startedOnUTC = rawData?.startedOnLocal || '';
    this.endedOnUTC = rawData?.endedOnLocal || '';

    this.scheduledWeight = rawData?.scheduledWeight;
    this.scheduledKms = rawData?.scheduledKms;
    this.scheduledDrops = rawData?.scheduledDrops;
    this.scheduledDifficulty = rawData?.scheduledDifficulty;
    this.scheduledTotalWorkHours = rawData?.scheduledTotalWorkHours;

    this.instructions = rawData?.instructions;

    this.breachCalculatedUsing = rawData?.breachCalculatedUsing;

    this.routeId = rawData?.routeId;
    this.driverComments = rawData?.driverComments;

    this.user = rawData?.user;
    this.userName = getFullName(rawData?.user?.firstName, rawData?.user?.middleName, rawData?.user?.lastName);
    this.userEmail = rawData?.user?.email;
    this.userExtDriverId = rawData?.user?.extDriverId;
    this.userHasAccepted = rawData?.userHasAccepted;

    this.jobsite = rawData?.jobsite;
    this.jobsiteName = rawData?.jobsite?.name;

    this.vehicle = rawData?.vehicle;
    this.vehicleRego = rawData?.vehicle?.registrationNumber;
    this.unlistedVehicleRego = rawData?.unlistedVehicleRego;

    this.extCompany = rawData?.extCompany;

    this.startOdometerKms = rawData?.startOdometerKms;
    this.endOdometerKms = rawData?.endOdometerKms;
    this.jotFormUrl = rawData?.jotFormUrl;

    this.shiftBreaks = (rawData?.shiftBreaks || []).map((brk: any) => {
      const breakTime = getDateDifferenceInMS(brk.startedOnUTC, brk.endedOnUTC || new Date().toISOString());
      return {
        id: brk.id,
        startedOnUTC: brk.startedOnLocal,
        endedOnUTC: brk.endedOnLocal,
        breakTime
      };
    });

    // TO BE VERIFIED
    this.shiftComments = rawData?.shiftComments;
    this.preCheckAnswers = rawData?.preCheckAnswers;
    this.checkins = rawData?.checkins;
    this.checkinStatus = this.getCheckinStatus();
    this.shiftNRs = rawData?.shiftNRs?.map((shift: any) => new FormattedShiftNR(shift));
    this.timesheetStatus = this.getTimesheetStatus();

    this.totalShiftTime = rawData?.endedOnUTC ? getDateDifferenceInMS(rawData?.startedOnUTC, rawData?.endedOnUTC) : '';
    this.totalBreakTime = this.shiftBreaks.reduce((a, b) => a + b.breakTime, 0) || '';
    this.totalTimeWorked = rawData?.endedOnUTC ? Number(this.totalShiftTime) - Number(this.totalBreakTime) : '';
    this.dock = rawData?.dock ?? '';

    this.breach = !rawData?.endedOnUTC ? getBreakBreach(this) : null;
    this.isNew = false;
  }

  getTimesheetStatus() {
    if (this.shiftNRs?.length === 0) return 'No';
    if (this.shiftNRs?.length === 1) return 'Yes';
    return 'Duplicate';
  }
  getCheckinStatus() {
    if (this.checkins?.length > 0) return 'Yes';
    return 'No';
  }
}

export default FormattedShift;

import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
  MenuItem,
  ListItemText
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { EnumRes, FormQuestionNumeric, FormQuestionTextualReq, RegularExpressionRes } from '../../proxy/proxy';
import { showAlert } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';
import FormsService from '../../services/FormsService';
import EnumService from '../../services/EnumService';

interface NumberInputProps {
  order: number;
  currentFieldState: FormQuestionNumeric;
  fieldStateChanged: (fieldState: any, order: number) => void;
  deleteEl: (order: number, id: string) => void;
  handleArrowUpClick: (order: number) => void;
  handleArrowDownClick: (order: number) => void;
  duplicateElement: (order: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  order,
  currentFieldState,
  fieldStateChanged,
  deleteEl,
  duplicateElement,
  handleArrowDownClick,
  handleArrowUpClick
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionNumeric>(currentFieldState);
  const [restrictions, setRestrictions] = useState<EnumRes[]>([]);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
    ];

    Promise.all(promises)
      .then((result) => {
        setRestrictions(result[0].formQuestionNumericRestrictionTypes);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      });
  };

  useEffect(() => {
    fieldStateChanged(fieldState, order);
  }, [fieldState]);

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Fragment>
      <Paper elevation={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Numeric question
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={(
                <Switch
                  checked={fieldState.required || false}
                  onChange={() => {
                    setFieldState({ ...fieldState, required: !fieldState.required });
                  }}
                  name="required-field"
                  color="secondary"
                />
              )}
              label="Required"
              sx={{ ml: 2 }}
            />
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => handleArrowUpClick(order)}
            >
              <ArrowUpwardIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => handleArrowDownClick(order)}
            >
              <ArrowDownwardIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => deleteEl(order, currentFieldState.id)}
            >
              <DeleteIcon sx={{ cursor: 'pointer' }} color="primary" />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0'
              }}
              onClick={() => duplicateElement(order)}
            >
              <ContentCopyIcon sx={{ cursor: 'pointer' }} color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                value={fieldState.title}
                variant="filled"
                label="Numeric question title *"
                onChange={(e) => {
                  setFieldState({ ...fieldState, title: e.target.value });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={fieldState.subTitle || ''}
                variant="filled"
                label="Numeric question subtitle"
                onChange={(e) => {
                  setFieldState({ ...fieldState, subTitle: e.target.value });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Restrictions"
                onChange={(e) => {
                  setFieldState({ ...fieldState, formQuestionNumericRestrictionType: Number(e.target.value) });
                }}
                select
                sx={{
                  mb: 2,
                  '& .MuiSelect-select': {
                    paddingTop: '25px',
                  },
                }}
                variant="filled"
                value={fieldState.formQuestionNumericRestrictionType || 0}
              >
                {restrictions.map((restriction) => (
                  <MenuItem
                    key={restriction.id || 0}
                    value={restriction.id || 0}
                  >
                    <ListItemText>{restriction.value}</ListItemText>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {fieldState.formQuestionNumericRestrictionType !== undefined && fieldState.formQuestionNumericRestrictionType > 0 && (
              <>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <TextField
                    variant="filled"
                    value={fieldState.restrictionInput1 ?? ''} // Use empty string when the value is null or undefined
                    fullWidth
                    type="number"
                    label={fieldState.formQuestionNumericRestrictionType === 7 || fieldState.formQuestionNumericRestrictionType === 8 ? 'Lower Bound' : 'Restriction Value'}
                    onChange={(e) => {
                      const value = e.target.value === '' ? null : Number(e.target.value);
                      setFieldState({ ...fieldState, restrictionInput1: value });
                    }}
                    sx={{ mb: 2 }}
                  />
                </Grid>
                {(fieldState.formQuestionNumericRestrictionType === 7 || fieldState.formQuestionNumericRestrictionType === 8) && (
                  <Grid item xs={3}>
                    <TextField
                      variant="filled"
                      value={fieldState.restrictionInput2 ?? ''} // Use empty string when the value is null or undefined
                      fullWidth
                      type="number"
                      label={fieldState.formQuestionNumericRestrictionType === 7 || fieldState.formQuestionNumericRestrictionType === 8 ? 'Lower Bound' : 'Restriction Value'}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : Number(e.target.value);
                        setFieldState({ ...fieldState, restrictionInput2: value });
                      }}
                      sx={{ mb: 2 }}
                    />
                  </Grid>
                )}
              </>
            )}
            {/* <Grid item xs={12}>
              <TextField
                variant="filled"
                fullWidth
                label="Enter your answer"
                required={fieldState.required || false}
                disabled
              />
            </Grid> */}
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default NumberInput;

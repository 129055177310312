import axios from 'axios';
import AppConstants from '../constants/AppConstants';
import { Client, CompanyPayFrequencyReq } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const CompanyService = {
  get: () => configureAxios().get2().then((resp) => resp),
  updatePayRates: (data: any) => configureAxios().updatePayRate(data),
  updatePayFrequency: (data: CompanyPayFrequencyReq) => configureAxios().updatePayFrequency(data).then((resp) => resp),
  update: (data: {
    maximumEmployees: string,
    manualSmsLimit: string,
    manualSmsRemaining: string,
    notificationEmail: string,
    timesheetSourceEmail: string,
    timesheetSourceEmailNsw: string,
    timesheetSourceEmailVic: string,
    timesheetSourceEmailQld: string,
    timesheetSourceEmailTas: string,
    timesheetSourceEmailAct: string,
    timesheetSourceEmailNt: string,
    timesheetSourceEmailSa: string,
    timesheetSourceEmailWa: string,
    addressStreet: string,
    addressAreaCode: string,
    addressSuburb: string,
    addressCity: string,
    addressStateId: number
  }, file: any) => {
    const formData = new FormData();
    formData.append('MaximumEmployees', data.maximumEmployees);
    formData.append('ManualSmsLimit', data.manualSmsLimit);
    formData.append('ManualSmsRemaining', data.manualSmsRemaining);
    formData.append('NotificationEmail', data.notificationEmail);
    formData.append('TimesheetSourceEmail', data.timesheetSourceEmail);
    formData.append('TimesheetSourceEmailNsw', data.timesheetSourceEmailNsw);
    formData.append('TimesheetSourceEmailVic', data.timesheetSourceEmailVic);
    formData.append('TimesheetSourceEmailQld', data.timesheetSourceEmailQld);
    formData.append('TimesheetSourceEmailTas', data.timesheetSourceEmailTas);
    formData.append('TimesheetSourceEmailAct', data.timesheetSourceEmailAct);
    formData.append('TimesheetSourceEmailNt', data.timesheetSourceEmailNt);
    formData.append('TimesheetSourceEmailSa', data.timesheetSourceEmailSa);
    formData.append('TimesheetSourceEmailWa', data.timesheetSourceEmailWa);
    formData.append('Address.Street', data.addressStreet);
    formData.append('Address.AreaCode', data.addressAreaCode);
    formData.append('Address.Suburb', data.addressSuburb);
    formData.append('Address.City', data.addressCity);
    formData.append('Address.StateId', data.addressStateId.toString());
    if (file) {
      formData.append('LogoImageFile', file);
    }
    return axios.post(`${AppConstants.BASE_API_URL}/api/Company/Update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((resp) => resp?.data);
  },
};

export default CompanyService;

import AppConstants from '../constants/AppConstants';
import FormattedShiftNR from '../models/FormattedShiftNR';
import configureAxios from '../proxy/ConfigureAxios';
import { Client } from '../proxy/proxy';

// const api = new ShiftNRApi(new Configuration({ basePath: AppConstants.BASE_API_URL }));
// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const ShiftNRService = {
  getAllShifts: (companyId: number, startDate: string, endDate: string): Promise<Array<FormattedShiftNR>> =>
    configureAxios().page8(
      {
        pageNumber: 1,
        pageSize: AppConstants.MAX_PAGE_SIZE,
        dateTimeInterval: {
          startTimeUtc: startDate,
          endTimeUtc: endDate,
        },
      }
    ).then((resp) => (resp?.items?.map((shift: any) => new FormattedShiftNR(shift)) || [])),

  getShiftById: (id: number, companyId: number): Promise<FormattedShiftNR> => configureAxios().get11(id)
    .then((resp) => new FormattedShiftNR(resp)),

  deleteShiftById: (id: number) => configureAxios().delete7([id]),

  updateRemarks: (id: number, remark: string = ''): Promise<FormattedShiftNR> => configureAxios().updateRemark({
    id,
    remark
  }).then((resp) => new FormattedShiftNR(resp)),
  downloadLinfoxTimesheets: (startDate: string, endDate: string) => {
    const payload = {
      pageNumber: 1,
      pageSize: AppConstants.MAX_PAGE_SIZE,
      dateTimeInterval: {
        startTimeUtc: startDate,
        endTimeUtc: endDate
      }
    };

    return configureAxios().download(payload);
  }
};

export default ShiftNRService;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Divider,
  Grid,
  TextField,
  Hidden,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SubmitFormButton from '../SubmitFormButton';
import CompanyService from '../../services/CompanyService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage } from '../../utils/common';
import ImageSelector from '../ImageSelector';
import { updateCompanyProfile } from '../../store/actions/profileActions';
import EnumService from '../../services/EnumService';
import { CompanyRes, EnumRes } from '../../proxy/proxy';

interface Props {
  profileData?: CompanyRes,
  updateProfileData: any,
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const CompanyProfileDetails: React.FC<Props> = ({
  profileData,
  updateProfileData,
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const [logoFile, setLogoFile] = useState<any>();
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch((err) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  }, []);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    CompanyService.update({
      maximumEmployees: values.maximumEmployees || 0,
      manualSmsLimit: values.manualSmsLimit || 0,
      manualSmsRemaining: values.manualSmsRemaining || 0,
      notificationEmail: values.notificationEmail,
      timesheetSourceEmail: values.timesheetSourceEmail,
      timesheetSourceEmailNsw: values.timesheetSourceEmailNsw,
      timesheetSourceEmailVic: values.timesheetSourceEmailVic,
      timesheetSourceEmailQld: values.timesheetSourceEmailQld,
      timesheetSourceEmailTas: values.timesheetSourceEmailTas,
      timesheetSourceEmailAct: values.timesheetSourceEmailAct,
      timesheetSourceEmailNt: values.timesheetSourceEmailNt,
      timesheetSourceEmailSa: values.timesheetSourceEmailSa,
      timesheetSourceEmailWa: values.timesheetSourceEmailWa,
      addressStreet: values.street,
      addressAreaCode: values.areaCode?.toString()?.padStart(4, '0'),
      addressCity: values.city,
      addressSuburb: values.suburb,
      addressStateId: Number(values.stateId)
    }, logoFile)
      .then((result) => {
        showAlert(formatSuccessMessage('Company Details updated successfully'));
        updateProfileData(result);
        setLogoFile(null);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const renderCompanyLogo = () => (
    <Grid item container md={6} xs={12}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <ImageSelector privImageUrl={profileData?.logoBlobUrl || ''} defaultImageUrl="/static/company_default_logo.png" onChange={setLogoFile} selectButtonText="Change Logo" />
      </Grid>
    </Grid>
  );

  if (!profileData) return null;

  return (
    <>
      <Formik
        initialValues={{
          name: profileData?.name || '',
          abn: profileData?.abn || '',
          maximumEmployees: profileData?.maximumEmployees,
          manualSmsLimit: profileData?.manualSmsLimit,
          manualSmsRemaining: profileData?.manualSmsRemaining,
          automatedSmsLimit: profileData?.automatedSmsLimit,
          automatedSmsRemaining: profileData?.automatedSmsRemaining,
          notificationEmail: profileData?.notificationEmail || '',
          timesheetSourceEmail: profileData?.timesheetSourceEmail || '',
          timesheetSourceEmailNsw: profileData?.timesheetSourceEmailNsw || '',
          timesheetSourceEmailVic: profileData?.timesheetSourceEmailVic || '',
          timesheetSourceEmailQld: profileData?.timesheetSourceEmailQld || '',
          timesheetSourceEmailWa: profileData?.timesheetSourceEmailWa || '',
          timesheetSourceEmailNt: profileData?.timesheetSourceEmailNt || '',
          timesheetSourceEmailSa: profileData?.timesheetSourceEmailSa || '',
          timesheetSourceEmailAct: profileData?.timesheetSourceEmailAct || '',
          timesheetSourceEmailTas: profileData?.timesheetSourceEmailTas || '',
          street: profileData?.address?.street || '',
          areaCode: profileData?.address?.areaCode || '',
          suburb: profileData?.address?.suburb || '',
          city: profileData?.address?.city || '',
          stateId: profileData?.address?.stateId || ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Name is required'),
          abn: Yup.string().matches(/^(\d *?){11}$/gm, 'Incorrect format').max(255),
          notificationEmail: Yup.string().email('Email is not valid').max(255),
          timesheetSourceEmail: Yup.string().email('Email is not valid').max(255),
          payPeriodStartDay: Yup.number(),
          payRollFrequency: Yup.number(),
          street: Yup.string().max(255),
          areaCode: Yup.number().min(0, 'Post Code should be between 0000 and 9999').max(9999, 'Post Code should be between 0000 and 9999').required('Post Code is required'),
          suburb: Yup.string().max(255),
          city: Yup.string().max(255),
          stateId: Yup.number().min(1).required('State is required')
        })}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          dirty,
          isValid
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
            >
              <Hidden mdUp>
                {renderCompanyLogo()}
              </Hidden>
              <Grid item container md={6} xs={12}>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Name"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    required
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.abn && errors.abn)}
                    fullWidth
                    helperText={touched.abn && errors.abn}
                    label="ABN"
                    margin="normal"
                    name="abn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.abn}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.notificationEmail && errors.notificationEmail)}
                    fullWidth
                    helperText={touched.notificationEmail && errors.notificationEmail}
                    label="Notification Email"
                    margin="normal"
                    name="notificationEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notificationEmail}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="email"
                  />
                </Grid>
              </Grid>
              <Hidden mdDown>
                {renderCompanyLogo()}
              </Hidden>
              <Grid
                item
                xs={12}
              >
                <Typography variant="h5" noWrap>
                  Company limits
                </Typography>
                <Divider />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Maximum Employees"
                  margin="normal"
                  name="maximumEmployees"
                  value={values.maximumEmployees}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, disabled: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Automated SMS Limit"
                  margin="normal"
                  name="automatedSmsLimit"
                  value={values.automatedSmsLimit}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, disabled: true }}
                  type="number"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Automated SMS Remaining"
                  margin="normal"
                  name="automatedSmsRemaining"
                  value={values.automatedSmsRemaining}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, disabled: true }}
                  type="number"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Manual SMS Limit"
                  margin="normal"
                  name="manualSmsLimit"
                  value={values.manualSmsLimit}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Manual SMS Remaining"
                  margin="normal"
                  name="manualSmsRemaining"
                  value={values.manualSmsRemaining}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, disabled: true }}
                  type="number"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography variant="h5" noWrap>
                  Timesheet Source Email Ids
                </Typography>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmail && errors.timesheetSourceEmail)}
                  fullWidth
                  helperText={touched.timesheetSourceEmail && errors.timesheetSourceEmail}
                  label="Timesheet Source Email Default"
                  margin="normal"
                  name="timesheetSourceEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmail}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailNsw && errors.timesheetSourceEmailNsw)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailNsw && errors.timesheetSourceEmailNsw}
                  label="Timesheet Source Email NSW"
                  margin="normal"
                  name="timesheetSourceEmailNsw"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailNsw}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailVic && errors.timesheetSourceEmailVic)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailVic && errors.timesheetSourceEmailVic}
                  label="Timesheet Source Email VIC"
                  margin="normal"
                  name="timesheetSourceEmailVic"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailVic}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailQld && errors.timesheetSourceEmailQld)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailQld && errors.timesheetSourceEmailQld}
                  label="Timesheet Source Email QLD"
                  margin="normal"
                  name="timesheetSourceEmailQld"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailQld}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailTas && errors.timesheetSourceEmailTas)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailTas && errors.timesheetSourceEmailTas}
                  label="Timesheet Source Email TAS"
                  margin="normal"
                  name="timesheetSourceEmailTas"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailTas}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailAct && errors.timesheetSourceEmailAct)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailAct && errors.timesheetSourceEmailAct}
                  label="Timesheet Source Email ACT"
                  margin="normal"
                  name="timesheetSourceEmailAct"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailAct}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailNt && errors.timesheetSourceEmailNt)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailNt && errors.timesheetSourceEmailNt}
                  label="Timesheet Source Email NT"
                  margin="normal"
                  name="timesheetSourceEmailNt"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailNt}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailSa && errors.timesheetSourceEmailSa)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailSa && errors.timesheetSourceEmailSa}
                  label="Timesheet Source Email SA"
                  margin="normal"
                  name="timesheetSourceEmailSa"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailSa}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.timesheetSourceEmailWa && errors.timesheetSourceEmailWa)}
                  fullWidth
                  helperText={touched.timesheetSourceEmailWa && errors.timesheetSourceEmailWa}
                  label="Timesheet Source Email WA"
                  margin="normal"
                  name="timesheetSourceEmailWa"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.timesheetSourceEmailWa}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography variant="h5" noWrap>
                  Address
                </Typography>
                <Divider />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.street && errors.street)}
                  fullWidth
                  helperText={touched.street && errors.street}
                  label="Street"
                  margin="normal"
                  name="street"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.street}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.suburb && errors.suburb)}
                  fullWidth
                  helperText={touched.suburb && errors.suburb}
                  label="Suburb"
                  margin="normal"
                  name="suburb"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.suburb}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.areaCode && errors.areaCode)}
                  fullWidth
                  helperText={touched.areaCode && errors.areaCode}
                  label="Post Code"
                  margin="normal"
                  name="areaCode"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.areaCode}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="State"
                  name="stateId"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  select
                  InputLabelProps={{ shrink: true }}
                  value={values.stateId}
                  variant="outlined"
                  error={Boolean(touched.stateId && errors.stateId)}
                  helperText={touched.stateId && errors.stateId}
                >
                  <MenuItem aria-label="None" value="">
                    <ListItemText primary="&nbsp;" />
                  </MenuItem>
                  {allStates.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Divider />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 2,
                paddingBottom: 2
              }}
            >
              <SubmitFormButton
                dirty={dirty || logoFile}
                isValid={isValid}
                onConfirm={handleSubmit}
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  profileData: state.profile.company
});

const mapDispatchToProps = (dispatch: any) => ({
  updateProfileData: (data: CompanyRes) => dispatch(updateCompanyProfile(data)),
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileDetails);

export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';

export const SHOW = 'SHOW_BUSY_INDICATOR';
export const HIDE = 'HIDE_BUSY_INDICATOR';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const CLEAR_ALL_ALERTS = 'CLEAR_ALL_ALERTS';

export const PAYROLL_PERIOD_LOAD_ALL = 'PAYROLL_PERIOD_LOAD_ALL';
export const PAYROLL_PERIOD_RESET_PAGE = 'PAYROLL_PERIOD_RESET_PAGE';
export const PAYROLL_PERIOD_GRID_SORT = 'PAYROLL_PERIOD_GRID_SORT';
export const PAYROLL_PERIOD_GRID_CURRENT_PAGE = 'PAYROLL_PERIOD_GRID_CURRENT_PAGE';

export const PAYROLL_ADDONS_LOAD_ALL = 'PAYROLL_ADDONS_LOAD_ALL';
export const PAYROLL_ADDONS_RESET_PAGE = 'PAYROLL_ADDONS_RESET_PAGE';
export const PAYROLL_ADDONS_GRID_SORT = 'PAYROLL_ADDONS_GRID_SORT';
export const PAYROLL_ADDONS_GRID_CURRENT_PAGE = 'PAYROLL_ADDONS_GRID_CURRENT_PAGE';
export const PAYROLL_ADDONS_GRID_SEARCH_TEXT = 'PAYROLL_ADDONS_GRID_SEARCH_TEXT';

export const QUESTIONS_LOAD_ALL = 'QUESTIONS_LOAD_ALL';
export const QUESTIONS_RESET_PAGE = 'QUESTIONS_RESET_PAGE';
export const QUESTIONS_GRID_SORT = 'QUESTIONS_GRID_SORT';
export const QUESTIONS_GRID_CURRENT_PAGE = 'QUESTIONS_GRID_CURRENT_PAGE';
export const QUESTIONS_GRID_SEARCH_TEXT = 'QUESTIONS_GRID_SEARCH_TEXT';

export const FORMS_LOAD_ALL = 'FORMS_LOAD_ALL';
export const FORMS_RESET_PAGE = 'FORMS_RESET_PAGE';
export const FORMS_GRID_SORT = 'FORMS_GRID_SORT';
export const FORMS_GRID_CURRENT_PAGE = 'FORMS_GRID_CURRENT_PAGE';
export const FORMS_GRID_SEARCH_TEXT = 'FORMS_GRID_SEARCH_TEXT';

export const SHIFT_COMMENTS_LOAD_ALL = 'SHIFT_COMMENTS_LOAD_ALL';
export const SHIFT_COMMENTS_RESET_PAGE = 'SHIFT_COMMENTS_RESET_PAGE';
export const SHIFT_COMMENTS_GRID_SORT = 'SHIFT_COMMENTS_GRID_SORT';
export const SHIFT_COMMENTS_GRID_CURRENT_PAGE = 'SHIFT_COMMENTS_GRID_CURRENT_PAGE';
export const SHIFT_COMMENTS_GRID_SEARCH_TEXT = 'SHIFT_COMMENTS_GRID_SEARCH_TEXT';

export const LOAD_ALL_SHIFTS = 'LOAD_ALL_SHIFTS';
export const SHIFT_RESET_PAGE = 'SHIFT_RESET_PAGE';
export const SHIFT_CHANGE_TAB = 'SHIFT_CHANGE_TAB';
export const SHIFT_CHANGE_STATE = 'SHIFT_CHANGE_STATE';
export const SHIFT_DATE_RANGE = 'SHIFT_DATE_RANGE';

export const SHIFT_SCHEDULED_GRID_SEARCH_TEXT = 'SHIFT_SCHEDULED_GRID_SEARCH_TEXT';
export const SHIFT_ONGOING_GRID_SEARCH_TEXT = 'SHIFT_ONGOING_GRID_SEARCH_TEXT';
export const SHIFT_COMPLETED_GRID_SEARCH_TEXT = 'SHIFT_COMPLETED_GRID_SEARCH_TEXT';

export const SHIFT_SCHEDULED_GRID_SEARCH_FILTER_BY = 'SHIFT_SCHEDULED_GRID_SEARCH_FILTER_BY';
export const SHIFT_ONGOING_GRID_SEARCH_FILTER_BY = 'SHIFT_ONGOING_GRID_SEARCH_FILTER_BY';
export const SHIFT_COMPLETED_GRID_SEARCH_FILTER_BY = 'SHIFT_COMPLETED_GRID_SEARCH_FILTER_BY';

export const SHIFT_SCHEDULED_GRID_CURRENT_PAGE = 'SHIFT_SCHEDULED_GRID_CURRENT_PAGE';
export const SHIFT_ONGOING_GRID_CURRENT_PAGE = 'SHIFT_ONGOING_GRID_CURRENT_PAGE';
export const SHIFT_COMPLETED_GRID_CURRENT_PAGE = 'SHIFT_COMPLETED_GRID_CURRENT_PAGE';

export const SHIFT_SCHEDULED_GRID_SORT = 'SHIFT_SCHEDULED_GRID_SORT';
export const SHIFT_ONGOING_GRID_SORT = 'SHIFT_ONGOING_GRID_SORT';
export const SHIFT_COMPLETED_GRID_SORT = 'SHIFT_COMPLETED_GRID_SORT';

export const SHIFT_SCHEDULED_GRID_FILTER_BY_COL = 'SHIFT_SCHEDULED_GRID_FILTER_BY_COL';
export const SHIFT_ONGOING_GRID_FILTER_BY_COL = 'SHIFT_ONGOING_GRID_FILTER_BY_COL';
export const SHIFT_COMPLETED_GRID_FILTER_BY_COL = 'SHIFT_COMPLETED_GRID_FILTER_BY_COL';

export const SHIFT_SCHEDULED_GRID_SELECT_ROWS = 'SHIFT_SCHEDULED_GRID_SELECT_ROWS';
export const SHIFT_SCHEDULED_GRID_DESELECT_ROWS = 'SHIFT_SCHEDULED_GRID_DESELECT_ROWS';

export const STAFF_LOAD_ALL = 'STAFF_LOAD_ALL';
export const STAFF_RESET_PAGE = 'STAFF_RESET_PAGE';
export const STAFF_GRID_SORT = 'STAFF_GRID_SORT';
export const STAFF_GRID_SELECT_ROWS = 'STAFF_GRID_SELECT_ROWS';
export const STAFF_GRID_DESELECT_ROWS = 'STAFF_GRID_DESELECT_ROWS';
export const STAFF_GRID_CURRENT_PAGE = 'STAFF_GRID_CURRENT_PAGE';
export const STAFF_GRID_SEARCH_TEXT = 'STAFF_GRID_SEARCH_TEXT';
export const STAFF_GRID_SEARCH_FILTER_BY = 'STAFF_GRID_SEARCH_FILTER_BY';
export const STAFF_GRID_SEARCH_FILTER_BY_COLUMN = 'STAFF_GRID_SEARCH_FILTER_BY_COLUMN';

export const STORE_LOAD_ALL = 'STORE_LOAD_ALL';
export const STORE_RESET_PAGE = 'STORE_RESET_PAGE';
export const STORE_GRID_SORT = 'STORE_GRID_SORT';
export const STORE_GRID_CURRENT_PAGE = 'STORE_GRID_CURRENT_PAGE';
export const STORE_GRID_SEARCH_TEXT = 'STORE_GRID_SEARCH_TEXT';
export const STORE_CHANGE_STATE = 'STORE_CHANGE_STATE';

export const VEHICLE_LOAD_ALL = 'VEHICLE_LOAD_ALL';
export const VEHICLE_RESET_PAGE = 'VEHICLE_RESET_PAGE';
export const VEHICLE_GRID_SORT = 'VEHICLE_GRID_SORT';
export const VEHICLE_GRID_CURRENT_PAGE = 'VEHICLE_GRID_CURRENT_PAGE';
export const VEHICLE_GRID_SEARCH_TEXT = 'VEHICLE_GRID_SEARCH_TEXT';
export const VEHICLE_GRID_SEARCH_FILTER_BY = 'VEHICLE_GRID_SEARCH_FILTER_BY';
export const VEHICLE_GRID_SEARCH_FILTER_BY_COLUMN = 'VEHICLE_GRID_SEARCH_FILTER_BY_COLUMN';
export const VEHICLE_CHANGE_STATE = 'VEHICLE_CHANGE_STATE';

export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const SHIFT_NR_LOAD_ALL = 'SHIFT_NR_LOAD_ALL';
export const SHIFT_NR_RESET_PAGE = 'SHIFT_NR_RESET_PAGE';
export const SHIFT_NR_GRID_SORT = 'SHIFT_NR_GRID_SORT';
export const SHIFT_NR_GRID_CURRENT_PAGE = 'SHIFT_NR_GRID_CURRENT_PAGE';
export const SHIFT_NR_GRID_SEARCH_TEXT = 'SHIFT_NR_GRID_SEARCH_TEXT';
export const SHIFT_NR_GRID_SEARCH_FILTER_BY = 'SHIFT_NR_GRID_SEARCH_FILTER_BY';
export const SHIFT_NR_GRID_FILTER_BY_BREACH = 'SHIFT_NR_GRID_FILTER_BY_BREACH';
export const SHIFT_NR_DATE_RANGE = 'SHIFT_NR_DATE_RANGE';
export const SHIFT_NR_CHANGE_STATE = 'SHIFT_NR_CHANGE_STATE';

export const DASHBOARD_NR_LOAD_ALL = 'DASHBOARD_NR_LOAD_ALL';
export const DASHBOARD_NR_RESET_PAGE = 'DASHBOARD_NR_RESET_PAGE';
export const DASHBOARD_NR_GRID_SORT = 'DASHBOARD_NR_GRID_SORT';
export const DASHBOARD_NR_GRID_CURRENT_PAGE = 'DASHBOARD_NR_GRID_CURRENT_PAGE';
export const DASHBOARD_NR_GRID_SEARCH_TEXT = 'DASHBOARD_NR_GRID_SEARCH_TEXT';
export const DASHBOARD_NR_GRID_FILTER_BY_SHIFT = 'DASHBOARD_NR_GRID_FILTER_BY_SHIFT';
export const DASHBOARD_NR_GRID_FILTER_BY_BREACH = 'DASHBOARD_NR_GRID_FILTER_BY_BREACH';
export const DASHBOARD_NR_DATE_RANGE = 'DASHBOARD_NR_DATE_RANGE';
export const DASHBOARD_NR_CHANGE_STATE = 'DASHBOARD_NR_CHANGE_STATE';

export const LINFOX_CURRENT_TAB = 'LINFOX_CURRENT_TAB';

import { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import axios from 'axios';
import { connect } from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
import AppSnackBar from './components/AppSnackBar';
import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import BusyIndicator from './components/BusyIndicator';
import LocalStorageService from './services/LocalStorageService';
import TokenService from './services/TokenService';
import AppConstants from './constants/AppConstants';
import { getUserProfile, updateUserProfile, getCompanyProfile, updateCompanyProfile } from './store/actions/profileActions';
import store from './store/store';
import FormattedUserProfile from './models/FormattedUserProfile';
import { isDriver, isOwner } from './utils/common';
import ShiftsService from './services/ShiftsService';

// LocalstorageService
const localStorageService = LocalStorageService.getService();
let retry = false;

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;

  // Unauthorized : Retry getting token using refresh token
  if (error.response.status === 401 && !retry) {
    retry = true;
    const refreshToken = localStorageService.getRefreshToken();
    const token = localStorageService.getAccessToken();
    if (refreshToken && token) {
      return TokenService.getTokenFromRefreshToken(token, refreshToken)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            const result = res?.data;
            localStorageService.setToken({
              [AppConstants.ACCESS_TOKEN]: result?.access_token,
              [AppConstants.REFRESH_TOKEN]: result?.refresh_token,
              [AppConstants.TOKEN_EXPIRES_IN]: result?.expires_in
            });
            localStorageService.setUserRoles(result?.user?.roles);

            store.dispatch(updateUserProfile(new FormattedUserProfile(result?.user)));
            store.dispatch(updateCompanyProfile(result?.company));

            axios.defaults.headers.common.Authorization = `Bearer ${localStorageService.getAccessToken()}`;
            return axios(originalRequest);
          }
          return null;
        });
    }
  }

  // Unauthorized even with refresh token - clear all tokens
  if (error.response.status === 401) {
    localStorageService.clearToken();
    if (window?.location?.pathname?.startsWith('/app') || window?.location?.pathname?.startsWith('/employee')) {
      window.location.href = '/';
    }
  }
  retry = false;
  return Promise.reject(error);
});

const App = ({
  loadUserProfile,
  loadCompanyProfile,
  updateCompanyProfileDetails
}) => {
  useEffect(() => {
    if (localStorageService.hasValidToken()) {
      try {
        const roles = localStorageService.getUserRoles();

        loadUserProfile();
        if (!isOwner(roles)) {
          setTimeout(() => {
            const company = localStorageService.getCompanyProfile();
            updateCompanyProfileDetails(company);
          }, 0);
        } else {
          loadCompanyProfile();
        }

        if (isDriver(roles)) {
          ShiftsService.checkIn();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppSnackBar />
        <Routes>
          {routes}
        </Routes>
      </ThemeProvider>
      <BusyIndicator />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadUserProfile: () => dispatch(getUserProfile()),
  loadCompanyProfile: () => dispatch(getCompanyProfile()),
  updateCompanyProfileDetails: (profile) => dispatch(updateCompanyProfile(profile)),
});

export default connect(null, mapDispatchToProps)(App);

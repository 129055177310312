import React, { useState, Fragment, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../../store/actions/alertActions';
import { FormQuestionTextual, } from '../../proxy/proxy';
import FormsService from '../../services/FormsService';
import { formatErrorMessage, formatSuccessMessage } from '../../utils/common';
import { hide, show } from '../../store/actions/busyIndicatorActions';

interface TextFieldPreviewProps {
  currentFieldState: FormQuestionTextual;
  form: number;
  answer?: number;

  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const TextFieldPreview: React.FC<TextFieldPreviewProps> = ({
  currentFieldState,
  form,
  answer,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [formId, setFormId] = useState<number>(form);
  const [fieldState, setFieldState] = useState<FormQuestionTextual>(currentFieldState);

  const [inputValue, setInputValue] = useState();
  const [error, setError] = useState('');

  const validateInput = (value: string) => {
    const regex = new RegExp(fieldState.regularExpression ?? '');
    if (!regex.test(value)) {
      setError('Please enter a valid value');
    } else {
      setError('');
    }
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setInputValue(value);
    validateInput(value);

    if (!error) {
      // Prepare the payload for FormAnswerNumericReq
      const payload = {
        __discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
        discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
        formId,
        formQuestionId: fieldState.id, // Assuming you have formQuestionId in fieldState
        answer: value // Capture the input value as the answer
      };

      console.log(payload);

      const promise = FormsService.answer(payload);

      promise
        .then((response: any) => {
          showAlert(formatSuccessMessage('Answer saved'));
        })
        .catch((err) => {
          if (err?.errors?.length > 0 && !error?.includes(err?.errors[0])) {
            setError(`${error} ${err.errors[0]}`);
          }
          // showAlert(formatErrorMessage(err));
        })
        .then(() => {
          // hideBusyIndicator();
        });
    }
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <TextField
          fullWidth
          name={fieldState.title}
          placeholder={fieldState.subTitle ?? ''}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          multiline={fieldState.isMultiline}
          required={fieldState.required}
          value={inputValue ?? answer}
          onChange={handleChange}
          error={!!error}
          helperText={error}
        />
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(TextFieldPreview);

import { Warning as WarningIcon, Error as ErrorIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const AlertIndicatorIcon = ({
  alerts = []
}) => {
  if (!alerts.length) return null;

  return (
    <IconButton>
      {
        alerts.find((alert) => alert.type === 'error') ? (
          <ErrorIcon sx={{ color: '#d32f2f' }} data-auto-id="alertErrorIcon" />
        ) : (
          <WarningIcon sx={{ color: '#f57c00' }} data-auto-id="alertWarningIcon" />
        )
      }
    </IconButton>
  );
};

export default AlertIndicatorIcon;

import React from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { Box, TextField, IconButton, Tooltip } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment';
import { addDaysToDate, formatDateToOnlyDate, getDateDifferenceInMS, getDayStart, subtractDaysFromDate } from '../utils/common';

interface Props {
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  onChange: (startDate: any, endDate: any) => any,
  allowFutureDates?: boolean,
}

const DateRangeSelector: React.FC<Props> = ({
  startDate,
  endDate,
  allowFutureDates = false,
  onChange
}) => {
  const start = startDate ? Date.parse(startDate) : null;
  const end = endDate ? Date.parse(endDate) : null;
  const today = new Date().toISOString();

  const handleDateChange = (range: any) => {
    onChange(range[0]?.toISOString(), range[1]?.toISOString());
  };

  // const handlePreviousWeekClick = () => {
  //   const newEndDate = subtractDaysFromDate(startDate, 1);
  //   const newStartDate = subtractDaysFromDate(newEndDate, 6);

  //   onChange(newStartDate, newEndDate);
  // };

  // const handleNextWeekClick = () => {
  //   // const newEndDate = addDaysToDate(endDate, 7);
  //   // // const dateDiff = getDateDifferenceInMS(newEndDate, today);
  //   // // if (dateDiff < 0) {
  //   // //   newEndDate = today;
  //   // // }
  //   // const newStartDate = subtractDaysFromDate(newEndDate, 6);
  //   alert(endDate);
  //   const newStartDate = moment(endDate, 'YYYY-MM-DDTHH:mm:ss').startOf('day').add(1, 'day').toISOString();
  //   alert(newStartDate);
  //   const newEndDate = addDaysToDate(newStartDate, 6);
  //   onChange(newStartDate, newEndDate);
  // };

  const handlePreviousWeekClick = () => {
    // Subtract 7 days from the current startDate and set it to the previous Monday
    const newStartDate = moment(startDate).subtract(7, 'days').isoWeekday(1).startOf('day')
      .toISOString();
    // Add 7 days to the newStartDate to get the next Monday as the newEndDate and set the time to 23:59:59
    const newEndDate = moment(newStartDate).add(7, 'days').endOf('day').toISOString();

    onChange(newStartDate, newEndDate);
  };

  const handleNextWeekClick = () => {
    // Add 7 days to the current startDate and set it to the next Monday
    const newStartDate = moment(startDate).add(7, 'days').isoWeekday(1).startOf('day')
      .toISOString();
    // Add 7 days to the newStartDate to get the following Monday as the newEndDate and set the time to 23:59:59
    const newEndDate = moment(newStartDate).add(7, 'days').endOf('day').toISOString();

    onChange(newStartDate, newEndDate);
  };

  return (
    <div style={{ display: 'flex' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Tooltip title="Previous week">
          <IconButton color="primary" onClick={handlePreviousWeekClick}>
            <ChevronLeft />
          </IconButton>
        </Tooltip>
        <DateRangePicker
          startText="From"
          endText="To"
          value={[start, end]}
          onChange={handleDateChange}
          inputFormat="dd-MM-yyyy"
          renderInput={(startProps, endProps) => (
            <>
              <TextField sx={{ width: '6rem' }} {...startProps} variant="standard" color="secondary" />
              <Box sx={{ mx: 1 }} />
              <TextField sx={{ width: '6rem' }} {...endProps} variant="standard" color="secondary" />
            </>
          )}
          // maxDate={!allowFutureDates ? new Date() : undefined}
          calendars={1}
        />
        <Tooltip title="Next week">
          <IconButton color="primary" onClick={handleNextWeekClick}>
            <ChevronRight />
          </IconButton>
        </Tooltip>
      </LocalizationProvider>
    </div>
  );
};

export default DateRangeSelector;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  Divider,
  Box,
  Typography,
  ListItemText,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SubmitFormButton from '../SubmitFormButton';
import CompanyService from '../../services/CompanyService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage } from '../../utils/common';
import EnumService from '../../services/EnumService';
import { updateCompanyProfile } from '../../store/actions/profileActions';
import { AllEnumsRes, CompanyPayFrequencyReq, CompanyRes, EnumRes } from '../../proxy/proxy';

interface Props {
  profileData?: CompanyRes,
  updateProfileData: any,
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const CompanyPayRatesDetails: React.FC<Props> = ({
  profileData,
  updateProfileData,

  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const [daysOfWeek, setDaysOfWeek] = useState<Array<EnumRes> | undefined>([]);
  const [payRollFrequencies, setPayRollFrequencies] = useState<Array<EnumRes> | undefined>([]);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    const payload = {
      payPeriodStartDay: Number(values.payPeriodStartDay),
      payRollFrequency: Number(values.payRollFrequency)
    };

    CompanyService.updatePayFrequency(payload)
      .then((data: CompanyPayFrequencyReq) => {
        showAlert(formatSuccessMessage('Pay rates updated successfully'));
        updateProfileData(data);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();
    EnumService.getAllEnums().then((enums: AllEnumsRes) => {
      setDaysOfWeek(enums?.dayOfWeek || undefined);
      setPayRollFrequencies(enums?.payRollFrequency || undefined);
    }).catch((err: any) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  }, []);

  if (!profileData) return null;

  return (
    <>
      <Typography sx={{ marginTop: 2, marginBottom: 2 }} variant="h4">Company Pay Frequency</Typography>
      <Formik
        initialValues={{
          payPeriodStartDay: profileData?.payPeriodStartDay,
          payRollFrequency: profileData?.payRollFrequency
        }}
        validationSchema={Yup.object().shape({
          payPeriodStartDay: Yup.string().required(),
          payRollFrequency: Yup.string().required()
        })}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          dirty,
          isValid
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Pay Roll Day"
                  name="payPeriodStartDay"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  select
                  InputLabelProps={{ shrink: true }}
                  value={values.payPeriodStartDay}
                  variant="outlined"
                  error={Boolean(touched.payPeriodStartDay && errors.payPeriodStartDay)}
                  helperText={touched.payPeriodStartDay && errors.payPeriodStartDay}
                >
                  <MenuItem aria-label="None" value="">
                    <ListItemText primary="&nbsp;" />
                  </MenuItem>
                  {daysOfWeek?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Pay Roll Frequency"
                  name="payRollFrequency"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  select
                  InputLabelProps={{ shrink: true }}
                  value={values.payRollFrequency}
                  variant="outlined"
                  error={Boolean(touched.payRollFrequency && errors.payRollFrequency)}
                  helperText={touched.payRollFrequency && errors.payRollFrequency}
                >
                  <MenuItem aria-label="None" value="">
                    <ListItemText primary="&nbsp;" />
                  </MenuItem>
                  {payRollFrequencies?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Divider />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 2,
                paddingBottom: 2
              }}
            >
              <SubmitFormButton
                dirty={dirty}
                isValid={isValid}
                onConfirm={handleSubmit}
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  profileData: state.profile.company
});

const mapDispatchToProps = (dispatch: any) => ({
  updateProfileData: (data: CompanyRes) => dispatch(updateCompanyProfile(data)),
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPayRatesDetails);

import { SHOW, HIDE } from '../actionTypes';

export interface StateType {
  visible: boolean
}

const INITIAL_STATE: StateType = {
  visible: false,
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case SHOW:
      return {
        ...state, visible: true,
      };

    case HIDE:
      return {
        ...state, visible: false,
      };

    default: return state;
  }
};

export default reducer;

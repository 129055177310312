import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import AccountService from '../services/AccountService';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';
import AppConstants from '../constants/AppConstants';
import UIStrings from '../UIStrings';

interface Props {
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const ForgotPassword: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  const formInitialValues = {
    email: ''
  };
  const formValidationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
  });
  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    AccountService.sendResetPasswordLink(values.email)
      .then((resp) => {
        setIsPasswordResetSuccess(true);
        showAlert(formatSuccessMessage(resp));
      }).catch((err) => {
        showAlert(formatErrorMessage(err?.response));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        {
          !isPasswordResetSuccess && (
            <Container maxWidth="sm">
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        {UIStrings.forgotPassword.pageHeader}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        pb: 1,
                        pt: 3
                      }}
                    >
                      <Typography
                        align="left"
                        color="textSecondary"
                        variant="body1"
                      >
                        Enter your email
                      </Typography>
                    </Box>
                    <TextField
                      id="txtEmail"
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        id="resetButton"
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Reset
                      </Button>
                    </Box>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Click here to
                      {' '}
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                      >
                        Sign in
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </Container>
          )
        }

        {
          isPasswordResetSuccess && (
            <Container maxWidth="sm">
              <Box sx={{ mb: 3 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="h2"
                >
                  {UIStrings.forgotPassword.resetLinkSent}
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 1,
                  pt: 3
                }}
              >
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body1"
                >
                  {UIStrings.forgotPassword.resetLinkSentDescription}
                </Typography>
              </Box>
            </Container>
          )
        }
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

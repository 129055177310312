import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton, Box, Toolbar, Tooltip, Chip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import GlobalSearch from '../components/GlobalSearch';
import FilterBy from '../components/FilterBy';
import Table from '../components/Table';
import { RootState } from '../store/store';
import { refreshData, reset, changeSortModel, searchByText, changeFilterByColumn, changePage, filterByBreach, changeDateRange } from '../store/actions/shiftNRActions';
import FormattedShiftNR from '../models/FormattedShiftNR';
import DateRangeSelector from '../components/DateRangeSelector';
import { formatDateToOnlyTime } from '../utils/common';

interface Props {
  startDate: string,
  endDate: string,
  visibleRows: Array<FormattedShiftNR>,
  totalRowCount: number,
  searchedText: string,
  currentPage: number,
  sortModel: any,
  companyId: number | undefined,
  filterByCol: any,
  selectedBreachType: string,
  fetchAllRows: any,
  resetPage: any,
  filterByText: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any,
  onFilterByBreach: any,
  onDateRangeChange: any
}

const Shift: React.FC<Props> = ({
  startDate,
  endDate,
  visibleRows,
  totalRowCount,
  searchedText,
  currentPage,
  sortModel,
  filterByCol,
  selectedBreachType,
  companyId,

  fetchAllRows,
  resetPage,
  filterByText,
  onPageChange,
  onSort,
  onChangeFilterByColumn,
  onFilterByBreach,
  onDateRangeChange
}) => {
  const navigate = useNavigate();

  const onRowClick = ({ id }: any) => {
    navigate(`/app/linfox/${id}`);
  };

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const handleDateRangeChange = (start: string, end: string) => {
    onDateRangeChange(start, end);
    if (start && end) {
      fetchAllRows(companyId, start, end);
    }
  };

  const getBreach = (type: string) => {
    switch (type) {
      case 'S1':
        return (
          <Tooltip key="segment1" title="Driver worked for more than 5 hours without break on Segment 1">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="S1" />
          </Tooltip>
        );
      case 'S2':
        return (
          <Tooltip key="segment2" title="Driver worked for more than 5 hours without break on Segment 2">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="S2" />
          </Tooltip>
        );
      case 'S3':
        return (
          <Tooltip key="segment3" title="Driver worked for more than 5 hours without a break on Segment 3">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="S3" />
          </Tooltip>
        );
      case '12h':
        return (
          <Tooltip key="12Hour" title="Driver has more than 12 hours driving time in a 13 hour working day - From Start Time to End Time">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="12h" />
          </Tooltip>
        );

      default:
        return null;
    }
  };
  const getBreaches = (row: any) => [
    ...(row.hasSegment1Breach ? [getBreach('S1')] : []),
    ...(row.hasSegment2Breach ? [getBreach('S2')] : []),
    ...(row.hasSegment3Breach ? [getBreach('S3')] : []),
    ...(row.has12HrBreach ? [getBreach('12h')] : [])
  ];

  const columns: Array<any> = [
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      renderCell: (params: any) => {
        const { value, row } = params;
        const breaches = row.hasBreach && getBreaches(row);

        return (
          <div style={breaches.length < 3 ? { display: 'flex', width: '100%', justifyContent: 'space-between' } : undefined}>
            <span>{value}</span>
            <div>
              {breaches}
            </div>
          </div>
        );
      },
      width: 200
    },
    {
      field: 'name',
      headerName: 'Driver Name',
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      width: 200
    },
    {
      field: 'stateCode',
      headerName: 'State',
      width: 100
    },
    {
      field: 'store',
      headerName: 'Store',
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      width: 200
    },
    { field: 'extDriverId', headerName: 'Driver Id', width: 120 },
    { field: 'rego', headerName: 'Rego', width: 150 },
    { field: 'amOrPM', headerName: 'Shift', width: 90 },
    {
      field: 'startTime',
      headerName: 'Start Time',
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyTime(value, true);
      },
      width: 120
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyTime(value, true);
      },
      width: 120
    },
    {
      field: 'actualWorkingHours',
      headerName: 'Actual Working Hours',
      type: 'number',
      renderCell: (params: any) => {
        const { value } = params;
        return value?.toFixed(2);
      },
      width: 180,
      filterable: false
    },
    { field: 'totalWorkingHours', headerName: 'Total Working Hours', type: 'number', width: 180, filterable: false },
    { field: 'restTaken', headerName: 'Rest Taken', width: 150, filterable: false },
    {
      field: 'restTakenAfter',
      headerName: 'Rest Taken After',
      type: 'number',
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <span>{value ? `${value} Hours` : ''}</span>
        );
      },
      width: 170,
      filterable: false
    },
    {
      field: 'rest1StartTime',
      headerName: 'Rest 1 Start Time',
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyTime(value, true);
      },
      width: 170,
      filterable: false
    },
    {
      field: 'rest1EndTime',
      headerName: 'Rest 1 End Time ',
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyTime(value, true);
      },
      width: 170,
      filterable: false
    },
    {
      field: 'rest2StartTime',
      headerName: 'Rest 2 Start Time',
      type: 'date',
      width: 175,
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyTime(value, true);
      },
      filterable: false
    },
    {
      field: 'rest2EndTime',
      headerName: 'Rest 2 End Time ',
      type: 'date',
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyTime(value, true);
      },
      width: 170,
      filterable: false
    },
    {
      field: 'comments',
      headerName: 'Comments',
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      width: 300
    }
  ];

  const filterOptions = [
    { value: 'all', text: 'Show All' },
    { value: 'yes', text: 'Yes' },
    { value: 'no', text: 'No' },
    { value: 'segment1', text: 'Segment 1' },
    { value: 'segment2', text: 'Segment 2' },
    { value: 'segment3', text: 'Segment 3' }
  ];

  useEffect(() => {
    if (!companyId) return;

    fetchAllRows(companyId, startDate, endDate);
  }, [companyId]);

  return (
    <Box>
      <Toolbar style={{ justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '1rem' }}>
        <DateRangeSelector startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} />
        <div style={{ display: 'flex' }}>
          <FilterBy
            label="Breach"
            options={filterOptions}
            value={selectedBreachType}
            onChange={(event: any) => onFilterByBreach(event.target.value)}
            width="280px"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} width="400px" />
          <IconButton
            color="secondary"
            onClick={() => {
              resetPage();
              fetchAllRows(companyId);
            }}
            data-auto-id="shiftRefreshIconButton"
          >
            <RefreshIcon />
          </IconButton>
        </div>
      </Toolbar>
      <Table
        pageSize={15}
        rows={visibleRows}
        columns={columns}
        onRowDoubleClick={onRowClick}
        totalRows={totalRowCount}
        page={currentPage}
        onPageChange={onPageChange}
        sortModel={sortModel}
        onSortModelChange={onSort}
        getRowClassName={(params: any) => params?.row?.hasBreach && 'error'}
      />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  startDate: state.shiftNR?.startDate,
  endDate: state.shiftNR?.endDate,
  visibleRows: state.shiftNR?.filteredRows,
  totalRowCount: state.shiftNR?.allRows?.length,
  currentPage: state.shiftNR?.currentPage,
  searchedText: state.shiftNR?.searchedText,
  sortModel: state.shiftNR?.sortModel,
  filterByCol: state.shiftNR?.filterByCol,
  selectedBreachType: state.shiftNR?.breachType,
  companyId: state.profile?.company?.id
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: (companyId: number, startDate: string, endDate: string) => dispatch(refreshData(companyId, startDate, endDate)),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  onFilterByBreach: (text: string) => dispatch(filterByBreach(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),
  onChangeFilterByColumn: (col: string) => dispatch(changeFilterByColumn(col)),
  onDateRangeChange: (startDate: string, endDate: string) => dispatch(changeDateRange(startDate, endDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(Shift);

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  DASHBOARD_NR_LOAD_ALL,
  DASHBOARD_NR_DATE_RANGE,
  DASHBOARD_NR_RESET_PAGE,
  DASHBOARD_NR_GRID_SORT,
  DASHBOARD_NR_GRID_CURRENT_PAGE,
  DASHBOARD_NR_GRID_SEARCH_TEXT,
  DASHBOARD_NR_GRID_FILTER_BY_SHIFT,
  DASHBOARD_NR_GRID_FILTER_BY_BREACH,
  DASHBOARD_NR_CHANGE_STATE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import ShiftNRService from '../../services/ShiftNRService';
import { formatErrorMessage, getDayStart, getDayEnd } from '../../utils/common';
import FormattedShiftNR from '../../models/FormattedShiftNR';

export const refreshData = (companyId: number, startDate: string | undefined, endDate: string | undefined): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  ShiftNRService.getAllShifts(companyId, getDayStart(startDate ?? getState().dashboardNR?.startDate) ?? new Date(), getDayEnd(endDate ?? getState().dashboardNR?.endDate) ?? new Date())
    .then((response = []) => {
      dispatch({
        type: DASHBOARD_NR_LOAD_ALL,
        data: {
          response,
          startDate: startDate ?? getState().dashboardNR?.startDate,
          endDate: endDate ?? getState().dashboardNR?.endDate
        }
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: DASHBOARD_NR_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: DASHBOARD_NR_GRID_SEARCH_TEXT,
  data: text
});

export const changeShiftType = (col = 'all') => ({
  type: DASHBOARD_NR_GRID_FILTER_BY_SHIFT,
  data: col
});

export const changePage = (page = 1) => ({
  type: DASHBOARD_NR_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: DASHBOARD_NR_GRID_SORT,
  data: sortModel
});

export const filterByBreach = (filterBy = 'all') => ({
  type: DASHBOARD_NR_GRID_FILTER_BY_BREACH,
  data: filterBy
});

export const selectStates = (states: Array<string> = []): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: DASHBOARD_NR_CHANGE_STATE, data: states });
};

export const changeDateRange = (startDate: string, endDate: string) => ({
  type: DASHBOARD_NR_DATE_RANGE,
  data: {
    startDate,
    endDate
  }
});

export const checkForBreach = (shifts: Array<FormattedShiftNR>, breachType = 'all') => {
  if (breachType === 'segment1') {
    return !!shifts.filter((shift) => shift.hasBreach && (shift.segment1WorkedForHours || 0) > 5)?.length;
  }
  if (breachType === 'segment2') {
    return !!shifts.filter((shift) => shift.hasBreach && (shift.segment2WorkedForHours || 0) > 5)?.length;
  }
  if (breachType === 'segment3') {
    return !!shifts.filter((shift) => shift.hasBreach && (shift.segment3WorkedForHours || 0) > 5)?.length;
  }
  return false;
};

import FormattedStaff from '../../models/FormattedStaff';
import {
  CLEAR_ALL_DATA,
  STAFF_LOAD_ALL,
  STAFF_RESET_PAGE,
  STAFF_GRID_SORT,
  STAFF_GRID_SELECT_ROWS,
  STAFF_GRID_DESELECT_ROWS,
  STAFF_GRID_CURRENT_PAGE,
  STAFF_GRID_SEARCH_TEXT,
  STAFF_GRID_SEARCH_FILTER_BY,
  STAFF_GRID_SEARCH_FILTER_BY_COLUMN
} from '../actionTypes';

export interface StateType {
  allRows: Array<FormattedStaff>,
  filteredRows: Array<FormattedStaff>,
  searchedText: string,
  filterBy: string,
  filterByCol: string,
  currentPage: number,
  sortModel: Array<any>
}

const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  filterBy: 'all',
  filterByCol: 'all_col',
  currentPage: 0,
  sortModel: []
};

const getColValue = (row: any, col: string) => {
  if (col === 'emailConfirmed' || col === 'isRegisteredForGST') {
    return row[col] ? 'yes' : 'no';
  }

  return ((row[col] || '').toString()).toLowerCase();
};

const filterRows = (allRows: Array<any> = [], searchText = '', filterBy = 'all', filterByCol = 'all_col') => {
  const text = searchText.trim().toLowerCase();
  let result = text ? allRows.filter((row) => {
    // Filter only via selected col
    if (filterByCol !== 'all_col') {
      return getColValue(row, filterByCol).indexOf(text) > -1;
    }

    // Filter over all cols
    if (
      getColValue(row, 'name').indexOf(text) > -1
      || getColValue(row, 'role').indexOf(text) > -1
      || getColValue(row, 'phoneNumber').indexOf(text) > -1
      || getColValue(row, 'email').indexOf(text) > -1
      || getColValue(row, 'extDriverId').indexOf(text) > -1
      || getColValue(row, 'emailConfirmed').indexOf(text) > -1
      || getColValue(row, 'isRegisteredForGST').indexOf(text) > -1
      || getColValue(row, 'odometerKms').indexOf(text) > -1
      || getColValue(row, 'abn').indexOf(text) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;

  if (filterBy !== 'all') {
    result = result.filter((staff: any) => staff.alerts?.find((alert: any) => alert.category === filterBy));
  }
  return result;
};

const setRowSelectionStatus = (allRows: Array<any>, targetRowIds: Array<number>, status: boolean) => allRows.map((row) => {
  if (targetRowIds.find((id) => id === row.id)) {
    row.selected = status;
  }

  return row;
});

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case STAFF_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.searchedText, state.filterBy, state.filterByCol),
      };
    case STAFF_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case STAFF_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case STAFF_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case STAFF_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, action.data, state.filterBy, state.filterByCol),
        currentPage: 0
      };
    case STAFF_GRID_SEARCH_FILTER_BY_COLUMN:
      return {
        ...state,
        filterByCol: action.data,
        filteredRows: filterRows(state.allRows, state.searchedText, state.filterBy, action.data),
        currentPage: 0
      };
    case STAFF_GRID_SEARCH_FILTER_BY:
      return {
        ...state,
        filterBy: action.data,
        filteredRows: filterRows(state.allRows, state.searchedText, action.data, state.filterByCol),
        currentPage: 0
      };
    case STAFF_GRID_SELECT_ROWS:
      return {
        ...state,
        allRows: setRowSelectionStatus(state.allRows, action.data || [], true)
      };
    case STAFF_GRID_DESELECT_ROWS:
      return {
        ...state,
        allRows: setRowSelectionStatus(state.allRows, action.data || [], false)
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

import {
  Typography,
} from '@mui/material';

const ContactManager = () => (
  <Typography
    variant="h3"
    color="textSecondary"
    align="center"
  >
    Please contact your manager for further actions.
  </Typography>
);

export default ContactManager;

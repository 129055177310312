import React, { useEffect, useState } from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import AppConstants from '../constants/AppConstants';

const PaginationComponent = ({
  rows = [],
  totalRows,
  pageSize = AppConstants.MOBILE_PAGE_SIZE,
  currentPage,
  onPageChange,
  onCurrentPageDataChange
}) => {
  const [pageCount, setPageCount] = useState(0);

  const getPageCount = () => {
    if (!rows.length) return 0;

    return Math.ceil(rows.length / pageSize);
  };

  const getCurrentPageRows = () => {
    if (!rows.length || currentPage < 0) return [];

    const start = currentPage * pageSize;
    let end = (currentPage + 1) * pageSize;
    if (end > rows.length) {
      end = rows.length;
    }

    return rows.slice(start, end);
  };

  useEffect(() => {
    setPageCount(getPageCount());
    if (onCurrentPageDataChange) {
      onCurrentPageDataChange(getCurrentPageRows());
    }
  }, [currentPage, rows]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {
        totalRows ? (
          <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', paddingLeft: '1rem' }}>
            <Typography><b>Total: {rows.length}</b></Typography>
          </div>
        ) : ''
      }
      <Pagination
        color="primary"
        disabled={pageCount < 2}
        count={pageCount}
        page={currentPage + 1}
        onChange={(event, page) => onPageChange && onPageChange(page - 1)}
      />
    </Box>
  );
};

export default PaginationComponent;

import _, { max } from 'lodash';
import moment from 'moment';
import FormattedShiftNR from '../../models/FormattedShiftNR';
import { getDatesInRange, subtractDaysFromDate } from '../../utils/common';
import { checkForBreach } from '../actions/dashboardNRActions';
import {
  CLEAR_ALL_DATA,
  DASHBOARD_NR_LOAD_ALL,
  DASHBOARD_NR_DATE_RANGE,
  DASHBOARD_NR_RESET_PAGE,
  DASHBOARD_NR_GRID_SORT,
  DASHBOARD_NR_GRID_CURRENT_PAGE,
  DASHBOARD_NR_GRID_SEARCH_TEXT,
  DASHBOARD_NR_GRID_FILTER_BY_SHIFT,
  DASHBOARD_NR_GRID_FILTER_BY_BREACH,
  DASHBOARD_NR_CHANGE_STATE
} from '../actionTypes';

export interface StateType {
  allRows: Array<FormattedShiftNR>,
  filteredRows: Array<FormattedShiftNR>,
  totalRowCount: number,
  searchedText: string,
  shiftType: string,
  breachType: string,
  currentPage: number,
  sortModel: Array<any>,
  startDate: string,
  endDate: string,
  states: Array<string>
}

const today = new Date().toISOString();
const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  totalRowCount: 0,
  searchedText: '',
  shiftType: 'all',
  breachType: 'all',
  currentPage: 0,
  sortModel: [],
  startDate: moment().isoWeekday(1).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // subtractDaysFromDate(today, 6),
  endDate: moment(moment().isoWeekday(1).startOf('day')).add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  states: []
};

const formatRecords = (rows: Array<FormattedShiftNR> = [], startDate: string, endDate: string, breachType = 'all') => {
  if (rows.length === 0) return [];

  const allDatesInRange = getDatesInRange(startDate, endDate);
  const groupByDriver = _.chain(rows)
    .groupBy('extDriverId')
    .map((value, key) => ({ id: key, name: value[0].name, shifts: value }))
    .value();

  const groupedData = groupByDriver.map((driver) => {
    const dates = _.chain(driver.shifts)
      .groupBy('date')
      .map((value, key) => ({ date: key, shifts: value }))
      .value();

    return {
      ...driver,
      dates
    };
  });
  const tableData = groupedData.map(({ id, name, dates }) => {
    const row: any = {
      id,
      name
    };
    let totalActualWorkingHours = 0;
    let maxConsecutiveWorkingDays = 0;
    let workingDaysCounter = 0;

    allDatesInRange.forEach((date) => {
      const shifts = dates.find((dt) => dt.date === date)?.shifts || [];
      const actualWorkingHours = _.reduce(shifts, (sum, shift) => sum + Number(shift?.actualWorkingHours || 0), 0);
      const hasWorkedOver12Hours = actualWorkingHours ? actualWorkingHours > 12 : false;
      const hasSegment1Breach = checkForBreach(shifts, 'segment1');
      const hasSegment2Breach = checkForBreach(shifts, 'segment2');
      const hasSegment3Breach = checkForBreach(shifts, 'segment3');

      if (actualWorkingHours) {
        workingDaysCounter++;
      } else {
        if (workingDaysCounter > maxConsecutiveWorkingDays) {
          maxConsecutiveWorkingDays = workingDaysCounter;
        }
        workingDaysCounter = 0;
      }

      if (breachType === '12Hrs' && !hasWorkedOver12Hours) {
        return;
      }

      if ((breachType === 'segment1' || breachType === 'segment2' || breachType === 'segment3') && !checkForBreach(shifts, breachType)) {
        return;
      }

      if (actualWorkingHours) {
        row[`${date}-actualWorkingHours`] = actualWorkingHours;
        totalActualWorkingHours += actualWorkingHours;
      } else {
        row[`${date}-actualWorkingHours`] = null;
      }
      row[`${date}-shifts`] = shifts;
      row[`${date}-hasBreach`] = hasWorkedOver12Hours || hasSegment1Breach || hasSegment2Breach || hasSegment3Breach;
      row[`${date}-hasSegment1Breach`] = hasSegment1Breach;
      row[`${date}-hasSegment2Breach`] = hasSegment2Breach;
      row[`${date}-hasSegment3Breach`] = hasSegment3Breach;
      row[`${date}-has12HrBreach`] = hasWorkedOver12Hours;
    });

    if (Object.keys(row).length === 2) {
      return null;
    }

    row.totalActualWorkingHours = totalActualWorkingHours;
    row.maxConsecutiveWorkingDays = max([maxConsecutiveWorkingDays, workingDaysCounter]);

    return row;
  });

  return tableData.filter(Boolean);
};

const filterRows = (allRows: Array<FormattedShiftNR> = [], searchText = '', breachType = 'all', shiftType = 'all', startDate: string, endDate: string, states: Array<string>) => {
  const text = searchText.trim().toLowerCase();
  let results = allRows;
  if (shiftType !== 'all') {
    results = results.filter((shift) => shift.amOrPM?.toLowerCase() === shiftType.toLowerCase());
  }
  if (states.length > 0) {
    results = results.filter((shift) => states.includes(shift.stateCode ?? ''));
  }

  const formatted = formatRecords(results, startDate, endDate, breachType);

  const filteredRows = text ? formatted.filter((row) => {
    if ((row.name || '').toLowerCase().indexOf(text) > -1 || (row.id || '').toLowerCase().indexOf(text) > -1) {
      return true;
    }
    return false;
  }) : formatted;

  return {
    totalRowCount: formatted.length,
    filteredRows
  };
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case DASHBOARD_NR_LOAD_ALL: {
      const { filteredRows, totalRowCount } = filterRows(action.data.response, state.searchedText, state.breachType, state.shiftType, action.data.startDate || state.startDate, action.data.endDate || state.endDate, state.states);

      return {
        ...state,
        allRows: action.data.response || [],
        startDate: action.data.startDate || state.startDate,
        endDate: action.data.endDate || state.endDate,
        filteredRows,
        totalRowCount
      };
    }
    case DASHBOARD_NR_CHANGE_STATE: {
      const states = action.data ?? [];
      const { filteredRows, totalRowCount } = filterRows(state.allRows, state.searchedText, state.breachType, state.shiftType, state.startDate, state.endDate, states);
      return {
        ...state,
        states,
        filteredRows,
        totalRowCount
      };
    }
    case DASHBOARD_NR_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case DASHBOARD_NR_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case DASHBOARD_NR_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case DASHBOARD_NR_GRID_SEARCH_TEXT: {
      const { filteredRows, totalRowCount } = filterRows(state.allRows, action.data, state.breachType, state.shiftType, state.startDate, state.endDate, state.states);

      return {
        ...state,
        searchedText: action.data,
        filteredRows,
        totalRowCount,
        currentPage: 0
      };
    }
    case DASHBOARD_NR_GRID_FILTER_BY_SHIFT: {
      const { filteredRows, totalRowCount } = filterRows(state.allRows, state.searchedText, state.breachType, action.data, state.startDate, state.endDate, state.states);

      return {
        ...state,
        shiftType: action.data,
        filteredRows,
        totalRowCount,
        currentPage: 0
      };
    }

    case DASHBOARD_NR_GRID_FILTER_BY_BREACH: {
      const { filteredRows, totalRowCount } = filterRows(state.allRows, state.searchedText, action.data, state.shiftType, state.startDate, state.endDate, state.states);

      return {
        ...state,
        breachType: action.data,
        filteredRows,
        totalRowCount,
        currentPage: 0
      };
    }

    case DASHBOARD_NR_DATE_RANGE:
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: action.data.endDate
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

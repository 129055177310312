import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List
} from '@mui/material';
import { connect } from 'react-redux';
import NavItem from './NavItem';
import MenuItems from '../constants/MenuItems';
import AppConstants from '../constants/AppConstants';

const AppSidebar = ({ companyProfile, onMobileClose, openMobile }) => {
  const location = useLocation();

  const showAppVersion = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.type === 'click' && event.shiftKey) {
      // eslint-disable-next-line no-alert
      alert(`VERSION: ${AppConstants.UI_VERSION}`);
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      style={{
        backgroundImage: 'url(/static/sidebar-background-image.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <RouterLink to="/app/company">
          <img
            style={
              {
                maxHeight: '150px',
                maxWidth: '200px',
                boxShadow: '0 3px 10px #888888',
                borderRadius: '10px'
              }
            }
            src={companyProfile?.logoBlobUrl || '/static/company_default_logo.png'}
            alt="company logo"
          />
        </RouterLink>
      </Box>
      <Divider />
      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <List id="appLeftNav">
          {
            MenuItems.map((item) => (
              <NavItem data={item} key={item.title} />
            )).filter(Boolean)
          }
        </List>
      </Box>
      <div role="presentation" onKeyUp={showAppVersion} onClick={showAppVersion} style={{ height: '3rem' }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: '50%',
              minWidth: 220,
              maxWidth: 300
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 275,
              top: 67,
              height: 'calc(100% - 67px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

AppSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

const mapStateToProps = (state) => ({
  userProfile: state.profile?.user,
  companyProfile: state.profile?.company
});

export default connect(mapStateToProps)(AppSidebar);

import React from 'react';
import { InputAdornment, CardContent, TextField, Grid, CardActions, Button } from '@mui/material';
import DollarIcon from '@mui/icons-material/AttachMoney';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import StaffService from '../../services/StaffService';
import SubmitFormButton from '../SubmitFormButton';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage } from '../../utils/common';
import { PayRateReq, PayRateRes } from '../../proxy/proxy';

interface Props {
  ids: Array<number>,
  payRates: PayRateRes,
  isReadOnly: boolean,
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
  onCancel: () => any,
  onSave?: () => any
}

const StaffPayRates: React.FC<Props> = ({
  ids,
  isReadOnly,
  payRates,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator,
  onCancel,
  onSave
}) => {
  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload: PayRateReq = {
      baseRate: Number(values.baseRate),
      publicHolidayRate: Number(values.publicHolidayRate),
      afterHoursRate: Number(values.afterHoursRate),
      saturdayRate: Number(values.saturdayRate),
      sundayRate: Number(values.sundayRate)
    };

    StaffService.updatePayRates(ids, payload)
      .then(() => {
        showAlert(formatSuccessMessage('Pay rates updated successfully.'));
        onCancel?.();
        onSave?.();
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <Formik
      initialValues={{
        baseRate: payRates?.baseRate || 0,
        publicHolidayRate: payRates?.publicHolidayRate || 0,
        afterHoursRate: payRates?.afterHoursRate || 0,
        saturdayRate: payRates?.saturdayRate || 0,
        sundayRate: payRates?.sundayRate || 0
      }}
      validationSchema={Yup.object().shape({
        baseRate: Yup.number().min(0),
        publicHolidayRate: Yup.number().min(0),
        saturdayRate: Yup.number().min(0),
        sundayRate: Yup.number().min(0)
      })}
      onSubmit={handleFormSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isValid,
        dirty
      }) => (
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.baseRate && errors.baseRate)}
                  fullWidth
                  helperText={touched.baseRate && errors.baseRate}
                  label="Base Rate"
                  margin="normal"
                  name="baseRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.baseRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.publicHolidayRate && errors.publicHolidayRate)}
                  fullWidth
                  helperText={touched.publicHolidayRate && errors.publicHolidayRate}
                  label="Public Holiday Rate"
                  margin="normal"
                  name="publicHolidayRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.publicHolidayRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.saturdayRate && errors.saturdayRate)}
                  fullWidth
                  helperText={touched.saturdayRate && errors.saturdayRate}
                  label="Saturday Rate"
                  margin="normal"
                  name="saturdayRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.saturdayRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(touched.sundayRate && errors.sundayRate)}
                  fullWidth
                  helperText={touched.sundayRate && errors.sundayRate}
                  label="Sunday Rate"
                  margin="normal"
                  name="sundayRate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sundayRate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{ marginRight: 2 }} color="secondary" onClick={onCancel} variant="outlined">
              Cancel
            </Button>
            {
              !isReadOnly && (
                <SubmitFormButton
                  buttonLabel="Save"
                  dirty={dirty}
                  isValid={isValid}
                  editMode={Boolean(ids)}
                  onConfirm={handleSubmit}
                />
              )
            }
          </CardActions>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffPayRates);

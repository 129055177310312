export default {
  login: {
    pageHeader: 'Sign in',
    pageDescription: 'Login with email address',
    forgotPassword: 'Forgot password?',
    forgotPasswordLink: 'Reset'
  },
  forgotPassword: {
    pageHeader: 'Forgot Password',
    resetLinkSent: 'Reset Password link sent',
    resetLinkSentDescription: 'Please click on reset link to reset your new password'
  },
  staff: {
    pageHeader: 'Staff'
  },
  staffDetails: {
    pageHeader: 'Staff Details',
    inviteStaff: 'Invite Staff'
  },
  shifts: 'Shifts',
  linfox: 'Linfox',
  jobSites: 'Stores / Jobsites',
  vehicles: 'Vehicles',
  payroll: 'Payroll',
  preCheckQuestions: 'Precheck questions',
  myShifts: 'My Shift',
  logout: 'Logout',
  support: 'Support',
  myProfile: 'My Profile',
  myCompany: 'My Company',
  name: 'Name',
  role: 'Role',
  mobileNumber: 'Mobile Number',
  extDriverId: 'External Driver Id',
  emailConfirmed: 'Email Confirmed?',
  gstRegistered: 'GST Registered',
  actions: 'Actions',
  cancel: 'Cancel',
  save: 'Save',
  invite: 'Invite',
  confirm: 'Confirm'
};

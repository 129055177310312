import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  FORMS_LOAD_ALL,
  FORMS_GRID_CURRENT_PAGE,
  FORMS_GRID_SEARCH_TEXT,
  FORMS_GRID_SORT,
  FORMS_RESET_PAGE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import { formatErrorMessage, isDriver } from '../../utils/common';
import FormsService from '../../services/FormsService';
import LocalStorageService from '../../services/LocalStorageService';

export const refreshData = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());

  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();

  if (isDriver(roles)) {
    FormsService.allFormsByRole()
      .then((result) => {
        dispatch({
          type: FORMS_LOAD_ALL,
          data: result
        });
      }).catch((err) => {
        dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
      }).then(() => {
        dispatch(hideBusyIndicator());
      });
  } else {
    FormsService.getAllForms()
      .then((result) => {
        dispatch({
          type: FORMS_LOAD_ALL,
          data: result
        });
      }).catch((err) => {
        dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
      }).then(() => {
        dispatch(hideBusyIndicator());
      });
  }
};

export const refreshDataByRole = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  FormsService.allFormsByRole()
    .then((result) => {
      dispatch({
        type: FORMS_LOAD_ALL,
        data: result
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: FORMS_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: FORMS_GRID_SEARCH_TEXT,
  data: text
});

export const changePage = (page = 1) => ({
  type: FORMS_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: FORMS_GRID_SORT,
  data: sortModel
});

import React, { useState } from 'react';
import { Button, Box, Menu, MenuItem, Badge } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MultiRowActionButton = ({ options, selectedRowsCount = 0, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Badge badgeContent={selectedRowsCount} color="primary">
        <Button
          id="multiRowActionButton"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          disableElevation
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          disabled={disabled}
        >
          Take Action
        </Button>
      </Badge>
      <Menu
        id="multiRowActionButtonMenu"
        MenuListProps={{
          'aria-labelledby': 'multiRowActionButton',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (option.onClick) {
                  option.onClick();
                }
                handleClose();
              }}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          ))
        }
      </Menu>
    </Box>
  );
};

export default MultiRowActionButton;

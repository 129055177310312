import Button from '@mui/material/Button';
import ConfirmButton from './ConfirmButton';

export default function SubmitFormButton({
  buttonLabel = 'Save',
  isValid = false,
  dirty = false,
  editMode = true,
  dialogTitle = 'Are you sure?',
  message = 'You are about to save the changes.',
  onConfirm,
  onInvalidClick = () => {},
  fullWidth = false,
  alwaysEnabled = false
}) {
  return (
    <>
      {
        !(dirty && isValid) && (
          <Button
            size="medium"
            disabled={!alwaysEnabled && (editMode && !dirty)}
            variant="contained"
            color="primary"
            type="submit"
            data-auto-id="saveButton"
            fullWidth={fullWidth}
            onClick={onInvalidClick}
          >
            {buttonLabel}
          </Button>
        )
      }
      {
        (dirty && isValid) && (
          <ConfirmButton
            buttonLabel={buttonLabel}
            dialogTitle={dialogTitle}
            showCancelButton
            message={message}
            buttonColor="primary"
            onConfirm={onConfirm}
            fullWidth={fullWidth}
          />
        )
      }
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardContent, Divider, IconButton, Box, Container, Checkbox, ListItemText, MenuItem, TextField, Tooltip, Hidden } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import AllShifts from '../components/shift/AllShifts';
import UploadSchedules from '../components/shift/UploadSchedules';
import AppConstants from '../constants/AppConstants';
import { refreshData, refreshDataWithDateRange, reset, selectStates, changeDateRange } from '../store/actions/shiftActions';
import EnumService from '../services/EnumService';
import { RootState } from '../store/store';
import FetchShifts from '../components/shift/FetchShifts';
import DateRangeSelector from '../components/DateRangeSelector';
import { EnumRes } from '../proxy/proxy';

interface Props {
  startDate: string,
  endDate: string,
  selectedStates: Array<number>,
  setStates: (states: Array<number>) => void,
  fetchAllRows: (startDate?: string, endDate?: string) => void,
  resetPage: () => void,
  onDateRangeChange: (startDate: string, endDate: string) => any
}

const Shift: React.FC<Props> = ({
  startDate,
  endDate,
  selectedStates,
  setStates,
  fetchAllRows,
  resetPage,
  onDateRangeChange
}) => {
  const navigate = useNavigate();
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);
  const [uploadSchedulesModal, setUploadSchedulesModal] = useState(false);
  const [fetchShiftsModal, setFetchShiftsModal] = useState(false);

  const closeUploadSchedulesModal = (result: any) => {
    if (result) {
      fetchAllRows();
    }
    setUploadSchedulesModal(false);
  };

  const openUploadSchedulesModal = () => {
    setUploadSchedulesModal(true);
  };

  const openFetchShiftsModal = () => {
    setFetchShiftsModal(true);
  };

  const closeFetchShiftsModal = (result: any) => {
    if (result) {
      fetchAllRows();
    }
    setFetchShiftsModal(false);
  };

  const scheduleAShift = () => {
    navigate('/app/shift/schedule');
  };

  const handleDateRangeChange = (start: string, end: string) => {
    onDateRangeChange(start, end);
    if (start && end) {
      fetchAllRows(start, end);
    }
  };

  const onStatesSelect = (event: any) => {
    setStates(event?.target?.value ?? []);
  };

  useEffect(() => {
    fetchAllRows();
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  return (
    <>
      <Helmet>
        <title>All Shifts | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardHeader
              action={
                (
                  <>
                    <IconButton
                      onClick={() => {
                        resetPage();
                        fetchAllRows();
                      }}
                      data-auto-id="shiftRefreshIconButton"
                    >
                      <RefreshIcon />
                    </IconButton>
                    <Tooltip title="Schedule a shift">
                      <IconButton onClick={scheduleAShift} data-auto-id="shiftAddIconButton">
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Upload CSV">
                      <IconButton onClick={openUploadSchedulesModal} data-auto-id="shiftUploadButton">
                        <UploadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Fetch Shifts">
                      <IconButton onClick={openFetchShiftsModal} data-auto-id="shiftFetchButton">
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              }
              title="All Shifts"
            />
            <Divider />
            <CardContent>
              <Hidden mdDown>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DateRangeSelector startDate={startDate} endDate={endDate} allowFutureDates onChange={handleDateRangeChange} />
                  <TextField
                    sx={{ width: '200px' }}
                    size="small"
                    label="States"
                    name="states"
                    onChange={onStatesSelect}
                    onBlur={onStatesSelect}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected: any) => selected.map((x: any) => allStates.find((state: any) => state.id === x)?.value).join(', ')
                    }}
                    value={selectedStates}
                  >
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedStates.includes(option.id ?? 0)} />
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DateRangeSelector startDate={startDate} endDate={endDate} allowFutureDates onChange={handleDateRangeChange} />
                  <TextField
                    sx={{ marginTop: '1rem' }}
                    fullWidth
                    size="small"
                    label="States"
                    name="states"
                    onChange={onStatesSelect}
                    onBlur={onStatesSelect}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected: any) => selected.map((x: any) => allStates.find((state: any) => state.id === x)?.value).join(', ')
                    }}
                    value={selectedStates}
                  >
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedStates.includes(option.id ?? 0)} />
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Hidden>
              <AllShifts />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <UploadSchedules open={uploadSchedulesModal} handleModalClose={closeUploadSchedulesModal} />
      <FetchShifts open={fetchShiftsModal} handleModalClose={closeFetchShiftsModal} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  startDate: state.shift?.startDate,
  endDate: state.shift?.endDate,
  selectedStates: state.shift.states ?? []
});

const mapDispatchToProps = (dispatch: any) => ({
  setStates: (states: Array<number>) => dispatch(selectStates(states)),
  fetchAllRows: (startDate?: string, endDate?: string) => dispatch(refreshDataWithDateRange(startDate, endDate)),
  onDateRangeChange: (startDate: string, endDate: string) => dispatch(changeDateRange(startDate, endDate)),
  resetPage: () => dispatch(reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(Shift);

import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, TextField, Button, IconButton, Link, TableCell, Table, TableBody, TableContainer, TableHead, TableRow, Typography, MenuItem, ListItemText, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { EnumRes, FormReq } from '../../proxy/proxy';
import EnumService from '../../services/EnumService';
import { showAlert } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';

interface FormLink {
  title: string;
  link: string;
}

interface HeaderProps {
  currentFormState: FormReq;
  handleHeaderChange: (newFormState: FormReq) => void;
}

const Header: React.FC<HeaderProps> = ({
  currentFormState,
  handleHeaderChange
}) => {
  const [formState, setFormState] = useState<FormReq>(currentFormState);
  const [formTypes, setFormTypes] = useState<Array<EnumRes>>([]);
  const [allRoles, setAllRoles] = useState<Array<string>>([]);

  const [newLinkTitle, setNewLinkTitle] = useState<string>('');
  const [newLinkURL, setNewLinkURL] = useState<string>('');

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
    ];

    Promise.all(promises)
      .then((result) => {
        setFormTypes(result[0].formTypes);
        setAllRoles(result[0].staffRoles?.sort());
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      });
  };

  const addFormLink = (formLink: FormLink) => {
    setFormState((prev) => ({
      ...prev,
      formLinks: [...(prev.formLinks || []), formLink],
    }));
  };

  const removeFormLink = (index: number) => {
    setFormState((prev) => ({
      ...prev,
      formLinks: prev.formLinks?.filter((_, i) => i !== index) || [],
    }));
  };

  useEffect(() => {
    handleHeaderChange(formState);
  }, [formState]); // Add handleHeaderChange as a dependency if it might change

  useEffect(() => {
    refreshData();
  }, []); // Add handleHeaderChange as a dependency if it might change

  const handleAddLink = () => {
    if (newLinkTitle.trim() && newLinkURL.trim()) {
      addFormLink({ title: newLinkTitle, link: newLinkURL });
      setNewLinkTitle('');
      setNewLinkURL('');
    }
  };

  return (
    <Box sx={{ mb: 3, mt: 4 }}>
      <Paper elevation={2} sx={{ p: 3, borderTop: '8px solid #5664d2' }}>
        <TextField
          value={formState.name}
          onChange={(e) =>
            setFormState((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
          variant="standard"
          label="Form Title *"
          fullWidth
          InputProps={{
            style: {
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }
          }}
          sx={{
            mb: 2,
            '& .MuiInput-underline:before': { borderBottomColor: '#000000' },
            '& .MuiInput-underline:after': { borderBottomColor: '#000000' },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#000000' }
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              value={formState.onOpenMessage}
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  onOpenMessage: e.target.value,
                }))
              }
              variant="filled"
              label="Open message"
              fullWidth
              InputProps={{
                style: {
                  fontSize: '1rem'
                }
              }}
              sx={{
                mb: 2,
                '& .MuiInput-underline:before': { borderBottomColor: '#000000' },
                '& .MuiInput-underline:after': { borderBottomColor: '#000000' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#000000' }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formState.onCompleteMessage}
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  onCompleteMessage: e.target.value,
                }))
              }
              variant="filled"
              label="Complete message"
              fullWidth
              InputProps={{
                style: {
                  fontSize: '1rem'
                }
              }}
              sx={{
                mb: 2,
                '& .MuiInput-underline:before': { borderBottomColor: '#000000' },
                '& .MuiInput-underline:after': { borderBottomColor: '#000000' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#000000' }
              }}
            />
          </Grid>
        </Grid>
        <TextField
          name="description"
          value={formState.description}
          onChange={(e) =>
            setFormState((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
          variant="filled"
          label="Form Description"
          fullWidth
          multiline
          rows={2}
          InputProps={{
            style: {
              fontSize: '1rem',
              color: '#666',
            }
          }}
          sx={{
            mb: 2,
            '& .MuiInput-underline:before': { borderBottomColor: '#000000' },
            '& .MuiInput-underline:after': { borderBottomColor: '#000000' },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#000000' }
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Form Type"
              onChange={(e) => {
                const selectedValue = e.target.value === '' ? null : Number(e.target.value);
                setFormState((prev) => ({
                  ...prev,
                  formType: selectedValue,
                }));
              }}
              select
              sx={{
                mb: 2,
                '& .MuiSelect-select': {
                  paddingTop: '25px',
                },
              }}
              variant="filled"
              value={formState.formType ?? ''} // Default to empty string if formType is null
            >
              <MenuItem value="">
                <ListItemText primary="None" />
              </MenuItem>
              {formTypes.map((expression) => (
                <MenuItem
                  key={expression.id || 0}
                  value={expression.id || 0}
                >
                  <ListItemText primary={expression.value} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="App roles"
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                setFormState((prev) => ({
                  ...prev,
                  appRoles: typeof value === 'string' ? value.split(',') : value,
                }));
              }}
              select
              SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                  if (Array.isArray(selected)) {
                    return selected.join(', ');
                  }
                  return '';
                },
              }}
              sx={{
                mb: 2,
                '& .MuiSelect-select': {
                  paddingTop: '25px',
                },
              }}
              variant="filled"
              value={formState.appRoles ?? []} // Provide default empty array
            >
              {allRoles.map((role) => (
                <MenuItem key={role || ''} value={role || ''}>
                  <Checkbox checked={(formState.appRoles ?? []).indexOf(role) > -1} />
                  <ListItemText primary={role} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Form Links
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <TextField
              value={newLinkTitle}
              onChange={(e) => setNewLinkTitle(e.target.value)}
              variant="filled"
              label="Link Title"
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              value={newLinkURL}
              onChange={(e) => setNewLinkURL(e.target.value)}
              variant="filled"
              label="Link URL"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleAddLink}>
              Add Link
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Serial No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Link</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(formState?.formLinks ?? []).map((link, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{link.title}</TableCell>
                  <TableCell>
                    <Link
                      href={link.link ?? ''}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        textDecoration: 'none',
                        color: '#5664d2',
                        '&:hover': {
                          textDecoration: 'underline',
                        }
                      }}
                    >
                      {link.link}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeFormLink(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default Header;

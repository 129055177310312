import { UpdatePayrollLineItemReq } from '../proxy/proxy';
import PayrollLineItem from './PayrollLineItem';

export default class EditPayrollLineItemReq implements UpdatePayrollLineItemReq {
  id: number | undefined = 0;
  userId: number | undefined = 0;

  dateLocal: any | undefined = '';
  jobsiteId: number | undefined;
  shift: string | undefined;
  hours: number | undefined = 0;
  adjustments: number | undefined = 0;
  payDateLocal: string | undefined;
  paidStartDateTimeLocal: any | undefined = '';
  paidEndDateTimeLocal: any | undefined = '';
  creditNote: string | undefined;
  comments: string | undefined;

  constructor(data: PayrollLineItem | null = null) {
    if (!data) return;

    this.id = data.id;
    this.userId = data.user?.id;
    this.dateLocal = data.dateLocal;
    this.paidStartDateTimeLocal = data.paidStartDateTimeLocal;
    this.paidEndDateTimeLocal = data.paidEndDateTimeLocal;
    this.jobsiteId = data.jobsiteId;
    this.shift = data.shift;
    this.hours = data.hours;
    this.comments = data.comments;
    this.adjustments = data.adjustments;
    this.payDateLocal = data.payDateLocal;
    this.creditNote = data.creditNote;
  }
}

import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import localStorageService from '../services/LocalStorageService';
import { Client } from './proxy';
import AppConstants from '../constants/AppConstants';

const configureAxios = (): Client => {
  // Create an instance of Axios with custom configuration
  const axiosInstance: AxiosInstance = axios.create({
    transformResponse: [data => data], // Disable Axios JSON parsing
    headers: {
      common: {
        'Authorization': `Bearer ${localStorageService.getAccessToken()}`
      }
    }
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        localStorageService.clearToken();
        if (window?.location?.pathname?.startsWith('/app') || window?.location?.pathname?.startsWith('/employee')) {
          window.location.href = '/';
        }
      }
      return Promise.reject(error);
    }
  );

  const api = new Client(AppConstants.BASE_API_URL, axiosInstance);

  return api;
};

export default configureAxios;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tabs, Tab, Container, Box, Card, CardHeader, Divider, CardContent, Button, TextField, Checkbox, ListItemText, MenuItem } from '@mui/material';
import { connect } from 'react-redux';

import { DownloadCloud as DownloadIcon } from 'react-feather';
import TabPanel from '../components/TabPanel';
import AppConstants from '../constants/AppConstants';
import ShiftNR from './ShiftNR';
import DashboardNR from './DashboardNR';
import { setCurrentTab } from '../store/actions/linfoxActions';
import { selectStates as selectStatesShiftNR } from '../store/actions/shiftNRActions';
import { selectStates as selectStatesDashboardNR } from '../store/actions/dashboardNRActions';
import { RootState } from '../store/store';
import ExportShiftNRModal from '../components/shift/ExportShiftNRModal';
import EnumService from '../services/EnumService';
import { EnumRes } from '../proxy/proxy';

interface Props {
  selectedTab: number,
  startDate: string,
  endDate: string,
  selectedStates: Array<string>,
  setSelectedTab: (tabIndex: number) => any,
  setStatesShiftNR: (states: Array<string>) => void,
  setStatesDashboard: (states: Array<string>) => void,
}

const Linfox: React.FC<Props> = ({
  selectedTab,
  startDate,
  endDate,
  selectedStates,
  setSelectedTab,
  setStatesShiftNR,
  setStatesDashboard
}) => {
  const [exportModal, setExportModal] = useState(false);
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);

  const onStatesSelect = (event: any) => {
    setStatesShiftNR(event?.target?.value ?? []);
    setStatesDashboard(event?.target?.value ?? []);
  };

  useEffect(() => {
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  return (
    <>
      <Helmet>
        <title>Linfox | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardHeader
              title="Linfox"
              action={(
                <>
                  <TextField
                    sx={{ width: '200px', marginRight: 2 }}
                    size="small"
                    label="States"
                    name="states"
                    onChange={onStatesSelect}
                    onBlur={onStatesSelect}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected: any) => selected.join(', ')
                    }}
                    value={selectedStates}
                  >
                    {allStates.map((option) => (
                      <MenuItem key={option.id ?? 0} value={option.value ?? ''}>
                        <Checkbox checked={selectedStates.includes(option.value ?? '-')} />
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    sx={{ marginRight: 2 }}
                    startIcon={<DownloadIcon />}
                    onClick={() => setExportModal(true)}
                  >
                    Export
                  </Button>
                </>
              )}
            />
            <Divider />
            <CardContent>
              <Tabs
                value={selectedTab}
                onChange={(event: any, newValue: any) => setSelectedTab(newValue)}
              >
                <Tab label="Summary" />
                <Tab label="Timesheets" />
              </Tabs>
              <Divider />
              <TabPanel value={selectedTab} index={0}>
                <DashboardNR />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <ShiftNR />
              </TabPanel>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ExportShiftNRModal isOpen={exportModal} closeModal={() => setExportModal(false)} startDate={startDate} endDate={endDate} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedTab: state.linfox?.selectedTab,
  selectedStates: state.shiftNR.states ?? [],
  startDate: state.dashboardNR?.startDate,
  endDate: state.dashboardNR?.endDate
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedTab: (tabIndex: number) => dispatch(setCurrentTab(tabIndex)),
  setStatesShiftNR: (states: Array<string>) => dispatch(selectStatesShiftNR(states)),
  setStatesDashboard: (states: Array<string>) => dispatch(selectStatesDashboardNR(states))
});

export default connect(mapStateToProps, mapDispatchToProps)(Linfox);

import { PreCheckQuestionRes } from '../../proxy/proxy';
import {
  CLEAR_ALL_DATA,
  QUESTIONS_LOAD_ALL,
  QUESTIONS_RESET_PAGE,
  QUESTIONS_GRID_SORT,
  QUESTIONS_GRID_CURRENT_PAGE,
  QUESTIONS_GRID_SEARCH_TEXT
} from '../actionTypes';

export interface StateType {
  allRows: Array<PreCheckQuestionRes>,
  filteredRows: Array<PreCheckQuestionRes>,
  searchedText: string,
  currentPage: number,
  sortModel: Array<any>
}

const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  currentPage: 0,
  sortModel: []
};

const getColValue = (row: any, col: string) => {
  if (col === 'isBlocker') {
    return row[col] ? 'yes' : 'no';
  }

  return ((row[col] || '').toString()).toLowerCase();
};

const filterRows = (allRows: Array<PreCheckQuestionRes> = [], searchText = '') => {
  const text = searchText.trim().toLowerCase();
  return text ? allRows.filter((row) => {
    if (
      getColValue(row, 'question').indexOf(text) > -1
      || getColValue(row, 'isBlocker').indexOf(text) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case QUESTIONS_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.searchedText),
      };
    case QUESTIONS_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case QUESTIONS_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case QUESTIONS_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case QUESTIONS_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, action.data),
        currentPage: 0
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

import moment from 'moment';
import FormattedShiftNR from '../../models/FormattedShiftNR';
import { subtractDaysFromDate } from '../../utils/common';
import {
  CLEAR_ALL_DATA,
  SHIFT_NR_LOAD_ALL,
  SHIFT_NR_RESET_PAGE,
  SHIFT_NR_GRID_SORT,
  SHIFT_NR_GRID_CURRENT_PAGE,
  SHIFT_NR_GRID_SEARCH_TEXT,
  SHIFT_NR_GRID_SEARCH_FILTER_BY,
  SHIFT_NR_GRID_FILTER_BY_BREACH,
  SHIFT_NR_DATE_RANGE,
  SHIFT_NR_CHANGE_STATE
} from '../actionTypes';

export interface StateType {
  allRows: Array<FormattedShiftNR>,
  filteredRows: Array<FormattedShiftNR>,
  searchedText: string,
  filterByCol: string,
  breachType: string,
  currentPage: number,
  sortModel: Array<any>,
  startDate: string,
  endDate: string,
  states: Array<string>
}

const today = new Date().toISOString();
const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  filterByCol: 'all_col',
  breachType: 'all',
  currentPage: 0,
  sortModel: [],
  startDate: moment().isoWeekday(1).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // subtractDaysFromDate(today, 6),
  endDate: moment(moment().isoWeekday(1).startOf('day')).add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  states: []
};

const getColValue = (row: any, col: string) => (row[col] || '').toString().toLowerCase();

const filterRows = (allRows: Array<FormattedShiftNR> = [], searchText = '', breachType = 'all', filterByCol = 'all_col', states: Array<string> = []) => {
  const text = searchText.trim().toLowerCase();
  let result = text ? allRows.filter((row) => {
    // Filter only via selected col
    if (filterByCol !== 'all_col') {
      return getColValue(row, filterByCol).indexOf(text) > -1;
    }

    if (
      (row.date || '').toLowerCase().indexOf(text) > -1
      || (row.name || '').toLowerCase().indexOf(text) > -1
      || (row.store || '').toLowerCase().indexOf(text) > -1
      || (row.extDriverId || '').toLowerCase().indexOf(text) > -1
      || (row.rego || '').toLowerCase().indexOf(text) > -1
      || (row.amOrPM || '').toLowerCase().indexOf(text) > -1
      || (row.restTaken || '').toLowerCase().indexOf(text) > -1
      || (row.rest1StartTime || '').toLowerCase().indexOf(text) > -1
      || (row.rest1EndTime || '').toLowerCase().indexOf(text) > -1
      || (row.rest2StartTime || '').toLowerCase().indexOf(text) > -1
      || (row.rest2EndTime || '').toLowerCase().indexOf(text) > -1
      || (row.comments || '').toLowerCase().indexOf(text) > -1
      || (row.email || '').toLowerCase().indexOf(text) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;

  if (states.length > 0) {
    result = result.filter((entry) => states.includes(entry.stateCode ?? ''));
  }

  if (breachType === 'yes') {
    return result.filter((shift) => shift.hasBreach);
  }
  if (breachType === 'no') {
    return result.filter((shift) => !shift.hasBreach);
  }
  if (breachType === '12hr') {
    return result.filter((shift) => shift.hasBreach && shift.hasWorkedOver12Hours);
  }
  if (breachType === 'segment1') {
    return result.filter((shift) => shift.hasBreach && (shift.segment1WorkedForHours || 0) > 5);
  }
  if (breachType === 'segment2') {
    return result.filter((shift) => shift.hasBreach && (shift.segment2WorkedForHours || 0) > 5);
  }
  if (breachType === 'segment3') {
    return result.filter((shift) => shift.hasBreach && (shift.segment3WorkedForHours || 0) > 5);
  }
  return result;
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case SHIFT_NR_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.searchedText, state.breachType, state.filterByCol, state.states),
      };
    case SHIFT_NR_CHANGE_STATE:
      return {
        ...state,
        states: action.data || [],
        filteredRows: filterRows(state.allRows || [], state.searchedText, state.breachType, state.filterByCol, action.data),
      };
    case SHIFT_NR_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case SHIFT_NR_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case SHIFT_NR_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case SHIFT_NR_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, action.data, state.breachType, state.filterByCol, state.states),
        currentPage: 0
      };
    case SHIFT_NR_GRID_SEARCH_FILTER_BY:
      return {
        ...state,
        filterByCol: action.data,
        filteredRows: filterRows(state.allRows, state.searchedText, state.breachType, action.data, state.states),
        currentPage: 0
      };
    case SHIFT_NR_GRID_FILTER_BY_BREACH:
      return {
        ...state,
        breachType: action.data,
        filteredRows: filterRows(state.allRows, state.searchedText, action.data, state.filterByCol, state.states),
        currentPage: 0
      };
    case SHIFT_NR_DATE_RANGE:
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: action.data.endDate
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

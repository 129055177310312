import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import AppConstants from '../constants/AppConstants';
import TabPanel from '../components/TabPanel';
import JobsiteAddOns from '../components/payroll/JobsiteAddOns';
import ShiftTimeAddOns from '../components/payroll/ShiftTimeAddOns';
import ShiftDurationAddOns from '../components/payroll/ShiftDurationAddOns';

interface Props {}

const PayrollSettings: React.FC<Props> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const addOnType = params.addOnType?.toLowerCase();
  const tabIndexMapping: any = {
    jobsite: 0,
    shifttime: 1,
    shiftduration: 2
  };
  const selectedTabIndex = tabIndexMapping[addOnType];
  const handleTabChange = (event: any, newValue: any) => {
    const match = Object.keys(tabIndexMapping).find((entry) => tabIndexMapping[entry] === newValue);
    navigate(`/app/payroll/addons/${match}`);
  };

  return (
    <>
      <Helmet>
        <title>Add-Ons | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader title="Add-Ons" />
            <Divider />
            <CardContent>
              <Tabs
                data-auto-id="allShiftTabs"
                value={selectedTabIndex}
                onChange={handleTabChange}
              >
                <Tab label="Jobsite" />
                <Tab label="Shift Time" />
                <Tab label="Shift Duration" />
              </Tabs>
              <Divider />
              <TabPanel value={selectedTabIndex} index={0}>
                <JobsiteAddOns />
              </TabPanel>
              <TabPanel value={selectedTabIndex} index={1}>
                <ShiftTimeAddOns />
              </TabPanel>
              <TabPanel value={selectedTabIndex} index={2}>
                <ShiftDurationAddOns />
              </TabPanel>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

export default connect(mapStateToProps)(PayrollSettings);

import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  PAYROLL_ADDONS_LOAD_ALL,
  PAYROLL_ADDONS_RESET_PAGE,
  PAYROLL_ADDONS_GRID_CURRENT_PAGE,
  PAYROLL_ADDONS_GRID_SEARCH_TEXT,
  PAYROLL_ADDONS_GRID_SORT
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import { formatErrorMessage } from '../../utils/common';

import PreCheckQuestionsService from '../../services/AddOnsService';

export const refreshData = (addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration'): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  let promise: Promise<any> = Promise.resolve();

  if (addOnType === 'jobSite') {
    promise = PreCheckQuestionsService.getAllJobSiteAddOns();
  } else if (addOnType === 'shiftTime') {
    promise = PreCheckQuestionsService.getAllShiftTimeAddOns();
  } else if (addOnType === 'shiftDuration') {
    promise = PreCheckQuestionsService.getAllShiftDurationAddOns();
  }

  dispatch(clearAllAlerts());
  dispatch(showBusyIndicator());
  promise
    .then((result) => {
      dispatch({
        type: PAYROLL_ADDONS_LOAD_ALL,
        addOnType,
        data: result
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = (addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => ({
  type: PAYROLL_ADDONS_RESET_PAGE,
  addOnType
});

export const searchByText = (text: string, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => ({
  type: PAYROLL_ADDONS_GRID_SEARCH_TEXT,
  addOnType,
  data: text
});

export const changePage = (page = 1, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => ({
  type: PAYROLL_ADDONS_GRID_CURRENT_PAGE,
  addOnType,
  data: page
});

export const changeSortModel = (sortModel = [], addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration') => ({
  type: PAYROLL_ADDONS_GRID_SORT,
  addOnType,
  data: sortModel
});

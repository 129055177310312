import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert, Hidden, Tabs, Tab, Toolbar, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Container, Box, Card, CardHeader, Divider, CardContent, Button, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import BackIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/InfoRounded';
import HelpIcon from '@mui/icons-material/Help';
import { connect } from 'react-redux';
import {
  Upload as UploadIcon,
  Cpu as CPUIcon,
  Edit as EditIcon,
  Plus as AddIcon
} from 'react-feather';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { chain, sortBy } from 'lodash';
import { DataGrid } from '@mui/x-data-grid';

import TabPanel from '../components/TabPanel';
import Table from '../components/Table';
import EditPayrollLineItem from '../components/payroll/EditPayrollLineItem';
import ConfirmButton from '../components/ConfirmButton';
import PayrollService from '../services/PayrollService';
import GlobalSearch from '../components/GlobalSearch';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, getCellTemplateByType, formatDateToOnlyDate, formatInputDate, formatISODate, getObjectKeys, convertToLocalDate } from '../utils/common';
import PayrollLineItem from '../models/PayrollLineItem';
import PayrollSummaryItem from '../models/PayrollSummaryItem';
import EditPayrollLineItemReq from '../models/EditPayrollLineItemReq';
import StaffService from '../services/StaffService';
import StoresService from '../services/StoresService';
import FormattedStaff from '../models/FormattedStaff';
import ExportButton from '../components/ExportButton';
import { JobsiteRes, PayrollPeriodRes, PayrollLineItemRes, PayrollSummaryRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const Payroll: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const payrollTimesheetHoursMismatchTypes = {
    equal: 0,
    PayrollHoursGreaterThanTimesheetHours: 1,
    PayrollHoursLessThanTimesheetHours: 2
  };

  const [allStaff, setAllStaff] = useState<Array<FormattedStaff>>([]);
  const [allStores, setAllStores] = useState<Array<JobsiteRes>>([]);
  const [payrollPeriod, setPayrollPeriod] = useState<PayrollPeriodRes>();
  const [allLineItems, setAllLineItems] = useState<Array<PayrollLineItem>>([]);
  const [filteredLineItems, setFilteredLineItems] = useState<Array<PayrollLineItem>>([]);
  const [allSummary, setAllSummary] = useState<Array<PayrollSummaryItem>>([]);
  const [filteredSummary, setFilteredSummary] = useState<Array<PayrollSummaryItem>>([]);
  const [allComparison, setAllComparison] = useState<Array<any>>([]);
  const [filteredComparison, setFilteredComparison] = useState<Array<any>>([]);
  const [openUploadFile, setOpenUploadFile] = useState<any>(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFileName, setSelectedFileName] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [lineItemsSelectedRows, setLineItemsSelectedRows] = useState([]);
  const [openEditLineItem, setOpenEditLineItem] = useState(false);
  const [editingLineItem, setEditingLineItem] = useState<EditPayrollLineItemReq | null>(null);
  const [openProcessPayroll, setOpenProcessPayroll] = useState(false);
  const [comparisonRowDetails, setComparisonRowDetails] = useState(false);
  const [selectedComparisonRow, setSelectedComparisonRow] = useState<any>();

  const [searchLineItemsText, setSearchLineItemsText] = useState('');
  const [searchSummaryText, setSearchSummaryText] = useState('');
  const [searchComparisonText, setSearchComparisonText] = useState('');

  const [lineItemsFilterByCol, setLineItemsFilterByCol] = useState('all_col');
  const [summaryFilterByCol, setSummaryFilterByCol] = useState('all_col');
  const [comparisonFilterByCol, setComparisonFilterByCol] = useState('all_col');

  const [lineItemsCurrentPage, setLineItemsCurrentPage] = useState(0);
  const [lineItemsSortModel, setLineItemsSortModel] = useState();

  const [summaryCurrentPage, setSummaryCurrentPage] = useState(0);
  const [summarySortModel, setSummarySortModel] = useState();

  const [comparisonCurrentPage, setComparisonCurrentPage] = useState(0);
  const [comparisonSortModel, setComparisonSortModel] = useState();

  const generateColumnsFromRow = (obj: any) => getObjectKeys(obj)
    .filter((key) => key.id !== 'jobsiteId')
    .map((key) => {
      const col: any = {
        field: key.id,
        headerName: key.name,
        type: key.type,
        renderCell: getCellTemplateByType(key.type),
      };
      if (col.headerName.indexOf('NAME') > -1
        || col.headerName.indexOf('STORE') > -1
        || col.headerName.indexOf('JOBSITE') > -1
        || col.headerName.indexOf('NOTE') > -1
        || col.headerName.indexOf('COMMENT') > -1
      ) {
        col.width = 200;
      } else if (col.type === 'date') {
        col.width = 130;
      } else {
        const charsInHeader = col.headerName.length;
        if (charsInHeader < 5) {
          col.width = 80;
        } if (charsInHeader < 7) {
          col.width = 110;
        } else if (charsInHeader < 10) {
          col.width = 150;
        } else if (charsInHeader < 15) {
          col.width = 170;
        } else {
          col.width = 200;
        }
      }
      return col;
    });

  const refreshData = () => {
    clearAllAlerts();
    showBusyIndicator();
    setSearchLineItemsText('');
    setSearchSummaryText('');
    PayrollService.getPayrollPeriodById(Number(id))
      .then((result) => {
        setPayrollPeriod(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const openEditLineItemDialog = () => {
    setOpenEditLineItem(true);
  };

  const closeEditLineItemDialog = (isSaved: boolean) => {
    setOpenEditLineItem(false);
    if (isSaved) {
      refreshData();
    }
  };

  const openProcessPayrollDialog = () => {
    setOpenProcessPayroll(true);
  };

  const closeProcessPayrollDialog = () => {
    setOpenProcessPayroll(false);
  };

  const createLineItem = () => {
    setEditingLineItem(new EditPayrollLineItemReq());
    openEditLineItemDialog();
  };

  const updateLineItem = ({ row }: { row: PayrollLineItem }) => {
    setEditingLineItem(new EditPayrollLineItemReq(row));
    openEditLineItemDialog();
  };

  const renderTimesheetHoursCell = (params: any) => {
    const { value, row } = params;

    return (
      <>
        <span style={{ paddingRight: '0.5rem' }}>{value?.toFixed(2)}</span>
        {
          row.payrollTimesheetHoursMismatchType === payrollTimesheetHoursMismatchTypes.PayrollHoursGreaterThanTimesheetHours && (
            <Tooltip title="Payroll hours is greater than Timesheet hours">
              <InfoIcon className="success-icon" />
            </Tooltip>
          )
        }
        {
          row.payrollTimesheetHoursMismatchType === payrollTimesheetHoursMismatchTypes.PayrollHoursLessThanTimesheetHours && (
            <Tooltip title="Payroll hours is less than Timesheet hours">
              <InfoIcon className="warning-icon" />
            </Tooltip>
          )
        }
      </>
    );
  };

  const lineItemsColumns = [
    {
      field: 'name',
      headerName: 'NAME',
      width: 200,
      renderCell: getCellTemplateByType('string'),
    },
    {
      field: 'extDriverId',
      headerName: 'EXT DRIVER ID',
      width: 170,
      renderCell: getCellTemplateByType('string')
    },
    {
      field: 'dateLocal',
      headerName: 'DATE',
      type: 'date',
      width: 120,
      renderCell: getCellTemplateByType('dateOnly')
    },
    {
      field: 'jobsite',
      headerName: 'JOBSITE',
      width: 200,
      renderCell: (params: any) => {
        const { value, row } = params;
        const jobsite = row.jobsiteName ?? row.store;
        return (
          <span>{jobsite}</span>
        );
      },
    },
    {
      field: 'shift',
      headerName: 'SHIFT',
      width: 110,
      renderCell: getCellTemplateByType('string')
    },
    {
      field: 'hours',
      headerName: 'HOURS',
      type: 'number',
      width: 110,
      renderCell: getCellTemplateByType('number')
    },
    {
      field: 'adjustments',
      headerName: 'ADJUSTMENTS',
      type: 'number',
      width: 170,
      renderCell: getCellTemplateByType('number')
    },
    {
      field: 'pay',
      headerName: 'PAY',
      type: 'number',
      width: 110,
      renderCell: (params: any) => {
        const { value, row } = params;

        return (
          <>
            <span>$ {value?.toFixed(2)}</span>
            {
              row.payCalculationLog && (
                <Tooltip title={row.payCalculationLog}>
                  <HelpIcon className="success-icon" />
                </Tooltip>
              )
            }
          </>
        );
      }
    },
    {
      field: 'payDateLocal',
      headerName: 'PAY DATE',
      type: 'date',
      width: 200,
      renderCell: getCellTemplateByType('date')
    },
    {
      field: 'comments',
      headerName: 'COMMENTS',
      width: 200,
      renderCell: getCellTemplateByType('string')
    },
    {
      field: 'paidStartDateTimeLocal',
      headerName: 'PAID START DATE',
      type: 'date',
      width: 200,
      renderCell: getCellTemplateByType('date')
    },
    {
      field: 'paidEndDateTimeLocal',
      headerName: 'PAID END DATE',
      type: 'date',
      width: 200,
      renderCell: getCellTemplateByType('date')
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <>
            <IconButton size="medium" aria-label="edit" onClick={
              (event) => {
                updateLineItem({ row });
                event.stopPropagation();
              }
            }
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => (<span />)
    }
  ];

  const summaryColumns = [
    {
      field: 'name',
      headerName: 'NAME',
      width: 200,
      renderCell: getCellTemplateByType('string'),
    },
    {
      field: 'extDriverId',
      headerName: 'EXT DRIVER ID',
      width: 170,
      renderCell: getCellTemplateByType('string'),
    },
    {
      field: 'hours',
      headerName: 'HOURS',
      type: 'number',
      width: 110,
      renderCell: getCellTemplateByType('number'),
    },
    {
      field: 'timeSheetHours',
      headerName: 'TIME SHEET HOURS',
      type: 'number',
      width: 200,
      renderCell: renderTimesheetHoursCell
    },
    {
      field: 'subtotal',
      headerName: 'SUBTOTAL',
      type: 'number',
      width: 150,
      renderCell: getCellTemplateByType('number')
    },
    {
      field: 'adjustments',
      headerName: 'ADJUSTMENTS',
      type: 'number',
      width: 170,
      renderCell: getCellTemplateByType('number')
    },
    {
      field: 'gst',
      headerName: 'GST',
      type: 'number',
      width: 110,
      renderCell: getCellTemplateByType('number')
    },
    {
      field: 'total',
      headerName: 'TOTAL',
      type: 'number',
      width: 110,
      renderCell: getCellTemplateByType('number')
    },
    {
      field: 'invoiceEmailed',
      headerName: 'INVOICE EMAILED',
      type: 'boolean',
      width: 200,
      renderCell: getCellTemplateByType('boolean')
    }
  ];

  const comparisonColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    }, {
      field: 'extDriverId',
      headerName: 'Ext Driver Id',
      flex: 1
    }, {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToOnlyDate(value);
      }
    }, {
      field: 'timeSheetHoursForTheDay',
      headerName: 'Time Sheet Hours',
      flex: 1,
      type: 'number',
      renderCell: renderTimesheetHoursCell
    }, {
      field: 'hours',
      headerName: 'Hours',
      flex: 1,
      type: 'number',
      renderCell: (params: any) => {
        const { value } = params;
        return value?.toFixed(2);
      }
    }
  ];

  const getColValue = (row: any, col: any) => {
    if (col.type === 'date') {
      return (row[col.field] ? formatDateToOnlyDate(row[col.field]) : '').toLowerCase();
    }

    if (col.type === 'boolean') {
      return (row[col.field] ? 'yes' : 'no');
    }

    return ((row[col.field] || '').toString()).toLowerCase();
  };

  const filterLineItems = (searchText = searchLineItemsText, filterCol = lineItemsFilterByCol) => {
    const text = searchText.trim().toLowerCase();

    const result = text ? allLineItems.filter((row) => {
      // Filter only via selected col
      if (filterCol !== 'all_col') {
        const col = lineItemsColumns.find((cl: any) => cl.field === filterCol);
        return getColValue(row, col).indexOf(text) > -1;
      }

      // Filter over all cols
      let found = false;
      lineItemsColumns.forEach((col) => {
        if (getColValue(row, col).indexOf(text) > -1) {
          found = true;
        }
      });
      return found;
    }) : allLineItems;

    setFilteredLineItems(result);
  };

  const filterSummary = (searchText = searchSummaryText, filterCol = summaryFilterByCol) => {
    const text = searchText.trim().toLowerCase();

    const result = text ? allSummary.filter((row) => {
      // Filter only via selected col
      if (filterCol !== 'all_col') {
        const col = summaryColumns.find((cl: any) => cl.field === filterCol);
        return getColValue(row, col).indexOf(text) > -1;
      }

      // Filter over all cols
      let found = false;
      summaryColumns.forEach((col) => {
        if (getColValue(row, col).indexOf(text) > -1) {
          found = true;
        }
      });
      return found;
    }) : allSummary;

    setFilteredSummary(result);
  };

  const filterComparison = (searchText = searchComparisonText, filterCol = comparisonFilterByCol) => {
    const text = searchText.trim().toLowerCase();

    const result = text ? allComparison.filter((row) => {
      // Filter only via selected col
      if (filterCol !== 'all_col') {
        const col = comparisonColumns.find((cl: any) => cl.field === filterCol);
        return getColValue(row, col).indexOf(text) > -1;
      }

      // Filter over all cols
      let found = false;
      comparisonColumns.forEach((col) => {
        if (getColValue(row, col).indexOf(text) > -1) {
          found = true;
        }
      });
      return found;
    }) : allComparison;

    setFilteredComparison(result);
  };

  const onLineItemSearchTextChange = (event: any) => {
    const text = event.target.value;
    setSearchLineItemsText(text);
  };

  const onSummarySearchTextChange = (event: any) => {
    const text = event.target.value;
    setSearchSummaryText(text);
    filterSummary(text);
  };

  const onComparisonSearchTextChange = (event: any) => {
    const text = event.target.value;
    setSearchComparisonText(text);
    filterComparison(text);
  };

  const onLineItemFilterColChange = (col: any) => {
    setLineItemsFilterByCol(col);
    filterLineItems(searchLineItemsText, col);
  };

  const onSummaryFilterColChange = (col: any) => {
    setSummaryFilterByCol(col);
    filterSummary(searchSummaryText, col);
  };

  const onComparisonFilterColChange = (col: any) => {
    setComparisonFilterByCol(col);
    filterComparison(searchComparisonText, col);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const openUploadFileDialog = () => {
    setOpenUploadFile(true);
  };

  const closeUploadFileDialog = () => {
    setOpenUploadFile(false);
    setSelectedFile(null);
    setSelectedFileName('');
  };

  const onFileSelectionChange = (event: any) => {
    setSelectedFile(event?.target?.files[0]);
    setSelectedFileName(event?.target?.files?.[0]?.name);
  };

  const onUploadFile = () => {
    showBusyIndicator();
    clearAllAlerts();
    closeUploadFileDialog();
    setValidationErrors(null);
    PayrollService.uploadLineItems(Number(id), selectedFile)
      .then(() => {
        showAlert(formatSuccessMessage('Line items uploaded successfully'));
        refreshData();
      }).catch((err) => {
        const error = err?.response?.data;
        if (error?.messages?.length) {
          setValidationErrors(error);
        } else {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        }
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const processPayroll = (date: string) => {
    showBusyIndicator();
    clearAllAlerts();
    PayrollService.processPayroll(Number(id), convertToLocalDate(date.toString()))
      .then(() => {
        showAlert(formatSuccessMessage('Summary calculated successfully'));
        closeProcessPayrollDialog();
        refreshData();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const onRefreshClick = () => {
    refreshData();
  };

  const deleteLineItems = () => {
    showBusyIndicator();
    clearAllAlerts();
    PayrollService.deleteLineItems(Number(id), lineItemsSelectedRows)
      .then((result) => {
        showAlert(formatSuccessMessage('Line Items deleted successfully.'));
        setPayrollPeriod(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleDeletePayrollPeriod = () => {
    clearAllAlerts();
    showBusyIndicator();
    PayrollService.deletePayrollPeriod(Number(id))
      .then(() => {
        showAlert(formatSuccessMessage('Payroll Period deleted successfully.'));
        navigate('/app/payroll/periods');
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleImportLineItems = () => {
    clearAllAlerts();
    showBusyIndicator();
    PayrollService.importLineItems(Number(id))
      .then((result) => {
        showAlert(formatSuccessMessage('Payroll line items imported successfully.'));
        setPayrollPeriod(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  const getRowClassName = (row: PayrollSummaryItem) => {
    if (row.payrollTimesheetHoursMismatchType === payrollTimesheetHoursMismatchTypes.PayrollHoursGreaterThanTimesheetHours) {
      return 'success';
    }

    if (row.payrollTimesheetHoursMismatchType === payrollTimesheetHoursMismatchTypes.PayrollHoursLessThanTimesheetHours) {
      return 'error';
    }

    return '';
  };

  const getComparison = (lineItems: Array<PayrollLineItem>) => chain(lineItems)
    .groupBy('userId')
    .map((value1, key1) => (
      {
        user: value1[0]?.user,
        name: value1[0]?.name,
        dates: chain(value1)
          .groupBy('dateLocal')
          .map((value, key) => ({ date: key, shifts: value })).value()
      }
    ))
    .reduce((res: Array<any>, { user, name, dates }) => res.concat(dates.map(({ date, shifts }, index) => ({
      id: `${user?.id}-${index}`,
      userId: user?.id,
      name,
      extDriverId: user?.extDriverId,
      date,
      shifts,
      hours: shifts.reduce((sum, cur) => sum + (cur.hours ?? 0), 0),
      timeSheetHoursForTheDay: shifts[0]?.timesheetHoursForDay,
      payrollTimesheetHoursMismatchType: shifts[0]?.payrollTimesheetHoursMismatchType
    }))), [])
    .value();

  useEffect(() => {
    clearAllAlerts();
    refreshData();
    Promise.all([
      StaffService.getAllStaffs(),
      StoresService.getAllStores()
    ]).then(([staffs, stores]) => {
      setAllStaff(sortBy(staffs || [], (i) => i.name?.toLowerCase()));
      setAllStores(sortBy(stores || [], (i) => i.name?.toLowerCase()));
    }).catch((err) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  }, []);

  useEffect(() => {
    if (payrollPeriod?.payrollLineItems) {
      const lineItems = payrollPeriod?.payrollLineItems.map((row: PayrollLineItemRes) => new PayrollLineItem(row));
      setAllLineItems(lineItems);
      setFilteredLineItems(lineItems);

      const comparisons = getComparison(lineItems);
      setAllComparison(comparisons || []);
      setFilteredComparison(comparisons || []);
    }

    if (payrollPeriod?.payrollSummaries) {
      const summaries = payrollPeriod?.payrollSummaries.map((row: PayrollSummaryRes) => new PayrollSummaryItem(row));
      setAllSummary(summaries || []);
      setFilteredSummary(summaries || []);
    }

    setOpenUploadFile(null);
    setSelectedFileName('');
    setValidationErrors(null);
    setLineItemsSelectedRows([]);
  }, [payrollPeriod]);

  useEffect(() => {
    filterLineItems();
  }, [allLineItems, searchLineItemsText]);

  useEffect(() => {
    filterSummary();
  }, [allSummary, searchSummaryText]);

  useEffect(() => {
    filterComparison();
  }, [allComparison, searchComparisonText]);

  return (
    <>
      <Helmet>
        <title>Payroll | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              action={
                (
                  <IconButton onClick={onRefreshClick}>
                    <RefreshIcon />
                  </IconButton>
                )
              }
              title={`Payroll ${payrollPeriod?.applicableState?.value ?? ''} - ${formatDateToOnlyDate(payrollPeriod?.startTimeLocal?.toString())} to ${formatDateToOnlyDate(payrollPeriod?.endTimeLocal?.toString())}`}
            />
            <Divider />
            <CardContent>
              {
                (payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal) && (
                  <Toolbar
                    style={{
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Hidden mdDown>
                      <Typography variant="h3">Payroll Total Value: ${(payrollPeriod?.total ?? 0).toFixed(2)}</Typography>
                      <div style={{ flex: 1 }} />
                      {/* <ConfirmButton
                        buttonLabel="Import"
                        dialogTitle="Confirm import shifts"
                        sx={{ marginRight: 2 }}
                        showCancelButton
                        message={`You are about to import shifts from ${formatDateToOnlyDate(payrollPeriod.startTimeLocal)} till ${formatDateToOnlyDate(payrollPeriod.endTimeLocal)} as line items.`}
                        buttonColor="primary"
                        buttonVariant="contained"
                        onConfirm={handleImportLineItems}
                        buttonIcon={<ImportIcon />}
                        disabled={Boolean(allLineItems.length)}
                      /> */}
                      <Button
                        variant="contained"
                        sx={{ marginRight: 2 }}
                        onClick={openUploadFileDialog}
                        startIcon={<UploadIcon />}
                      >
                        Upload File
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ marginRight: 2 }}
                        onClick={openProcessPayrollDialog}
                        startIcon={<CPUIcon />}
                        disabled={Boolean(!allLineItems.length)}
                      >
                        Process Payroll
                      </Button>
                      <ExportButton
                        dataPromiseProvider={() => PayrollService.downloadSummary(Number(id))}
                        fileName="payroll_summary"
                        type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                      <ConfirmButton
                        buttonLabel="Delete"
                        dialogTitle="Confirm Delete Payroll Period"
                        showCancelButton
                        message={`You are about to delete payroll period from ${formatDateToOnlyDate(payrollPeriod.startTimeLocal?.toString())} till ${formatDateToOnlyDate(payrollPeriod.endTimeLocal?.toString())}.`}
                        buttonColor="secondary"
                        buttonVariant="outlined"
                        onConfirm={handleDeletePayrollPeriod}
                        buttonIcon={<DeleteIcon />}
                        disabled={Boolean(allLineItems.length)}
                      />
                    </Hidden>
                    <Hidden mdUp>
                      <Typography variant="h3">Payroll Total Value: ${(payrollPeriod?.total ?? 0).toFixed(2)}</Typography>
                      <div style={{ flex: 1 }} />
                      {/* <ConfirmButton
                        isIconButton
                        dialogTitle="Confirm import shifts"
                        sx={{ marginRight: 2 }}
                        showCancelButton
                        message={`You are about to import shifts from ${formatDateToOnlyDate(payrollPeriod.startTimeLocal)} till ${formatDateToOnlyDate(payrollPeriod.endTimeLocal)} as line items.`}
                        buttonColor="primary"
                        buttonVariant="contained"
                        onConfirm={handleImportLineItems}
                        buttonIcon={<ImportIcon />}
                        disabled={Boolean(allLineItems.length)}
                      /> */}
                      <IconButton
                        color="primary"
                        sx={{ marginRight: 2 }}
                        onClick={openUploadFileDialog}
                      >
                        <UploadIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        sx={{ marginRight: 2 }}
                        onClick={openProcessPayrollDialog}
                        disabled={Boolean(!allLineItems.length)}
                      >
                        <CPUIcon />
                      </IconButton>
                      <ExportButton
                        isIconButton
                        dataPromiseProvider={() => PayrollService.downloadSummary(Number(id))}
                        fileName="payroll_summary"
                        type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                      <ConfirmButton
                        buttonIcon={
                          (
                            <DeleteIcon />
                          )
                        }
                        isIconButton
                        dialogTitle="Confirm Delete Payroll Period"
                        showCancelButton
                        message={`You are about to delete payroll period from ${formatDateToOnlyDate(payrollPeriod.startTimeLocal?.toString())} till ${formatDateToOnlyDate(payrollPeriod.endTimeLocal?.toString())}.`}
                        buttonColor="secondary"
                        onConfirm={handleDeletePayrollPeriod}
                        disabled={Boolean(allLineItems.length)}
                      />
                    </Hidden>
                  </Toolbar>
                )
              }
              {
                payrollPeriod?.queuedOnLocal && (
                  <>
                    <Typography textAlign="center" variant="h2">Processed on {formatDateToOnlyDate(payrollPeriod.queuedOnLocal.toString())}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h3">Payroll Total Value: ${payrollPeriod.total?.toFixed(2)}</Typography>
                      <ExportButton
                        dataPromiseProvider={() => PayrollService.downloadSummary(Number(id))}
                        fileName="payroll_summary"
                        type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                    </Box>
                  </>
                )
              }
              {
                validationErrors && (
                  <>
                    <Typography sx={{ paddingBottom: 2 }} variant="body1">{validationErrors?.title}</Typography>
                    {
                      (validationErrors?.messages || []).map((message: any) => (
                        <Alert sx={{ marginBottom: 1 }} key={message} severity="error">
                          {message}
                        </Alert>
                      ))
                    }
                  </>
                )
              }
              {
                (allLineItems || allSummary) && (
                  <>
                    <Hidden mdDown>
                      <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                      >
                        <Tab label="Line Items" />
                        <Tab label="Timesheet Comparison" />
                        <Tab label="Summary" />
                      </Tabs>
                    </Hidden>
                    <Hidden mdUp>
                      <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="fullWidth"
                      >
                        <Tab label="Line Items" />
                        <Tab label="Timesheet Comparison" />
                        <Tab label="Summary" />
                      </Tabs>
                    </Hidden>
                    <Divider />
                    <TabPanel value={selectedTab} index={0}>
                      <Hidden mdDown>
                        <Toolbar
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          {
                            (payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal) && (
                              <Button sx={{ marginRight: 2 }} onClick={createLineItem} variant="outlined" color="primary" size="small" startIcon={<AddIcon />}>
                                Add Row
                              </Button>
                            )
                          }
                          {
                            (payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal) && (
                              <ConfirmButton
                                buttonIcon={<DeleteIcon />}
                                buttonLabel="Delete Rows"
                                disabled={!lineItemsSelectedRows?.length}
                                dialogTitle="Are you sure?"
                                showCancelButton
                                message={`You are about to delete ${lineItemsSelectedRows?.length} line item rows.`}
                                buttonColor="primary"
                                onConfirm={deleteLineItems}
                                buttonSize="small"
                                sx={{ marginRight: 2 }}
                              />
                            )
                          }
                          <GlobalSearch searchedText={searchLineItemsText} onSearchTextChange={onLineItemSearchTextChange} showFilterByColumn columns={lineItemsColumns} filterByColumn={lineItemsFilterByCol} onFilterByColumnChange={onLineItemFilterColChange} width="400px" />
                        </Toolbar>
                      </Hidden>
                      <Hidden mdUp>
                        <Toolbar sx={{ flexDirection: 'column' }}>
                          <div style={{ width: '100%', padding: '5px 0' }}>
                            <GlobalSearch searchedText={searchLineItemsText} onSearchTextChange={onLineItemSearchTextChange} showFilterByColumn columns={lineItemsColumns} filterByColumn={lineItemsFilterByCol} onFilterByColumnChange={onLineItemFilterColChange} />
                          </div>
                          <div style={{ width: '100%', padding: '5px 0', textAlign: 'end' }}>
                            {
                              (payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal) && (
                                <Button sx={{ marginRight: 2 }} onClick={createLineItem} variant="outlined" color="primary" size="small" startIcon={<AddIcon />}>
                                  Add Row
                                </Button>
                              )
                            }
                            {
                              (payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal) && (
                                <ConfirmButton
                                  buttonIcon={<DeleteIcon />}
                                  buttonLabel="Delete Rows"
                                  disabled={!lineItemsSelectedRows?.length}
                                  dialogTitle="Are you sure?"
                                  showCancelButton
                                  message={`You are about to delete ${lineItemsSelectedRows?.length} line item rows.`}
                                  buttonColor="primary"
                                  onConfirm={deleteLineItems}
                                  buttonSize="small"
                                  sx={{ marginRight: 2 }}
                                />
                              )
                            }
                          </div>
                        </Toolbar>
                      </Hidden>
                      <Table
                        columns={lineItemsColumns}
                        rows={filteredLineItems}
                        pageSize={20}
                        checkboxSelection={(payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal)}
                        onSelectionModelChange={setLineItemsSelectedRows}
                        selectionModel={lineItemsSelectedRows}
                        onRowDoubleClick={(payrollPeriod?.payrollPeriodStatus === 0 && !payrollPeriod?.processedOnLocal && !payrollPeriod?.queuedOnLocal) ? updateLineItem : () => false}
                        totalRows={allLineItems?.length}
                        page={lineItemsCurrentPage}
                        onPageChange={({ page }: any) => setLineItemsCurrentPage(page)}
                        sortModel={lineItemsSortModel}
                        onSortModelChange={setLineItemsSortModel}
                      />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                      <Hidden mdDown>
                        <Toolbar
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <GlobalSearch searchedText={searchComparisonText} onSearchTextChange={onComparisonSearchTextChange} showFilterByColumn columns={comparisonColumns} filterByColumn={comparisonFilterByCol} onFilterByColumnChange={onComparisonFilterColChange} width="400px" />
                        </Toolbar>
                      </Hidden>
                      <Hidden mdUp>
                        <Alert sx={{ marginTop: '1rem' }} severity="info">GST is not calculated on adjustments</Alert>
                        <Toolbar
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <GlobalSearch searchedText={searchComparisonText} onSearchTextChange={onComparisonSearchTextChange} showFilterByColumn columns={comparisonColumns} filterByColumn={comparisonFilterByCol} onFilterByColumnChange={onComparisonFilterColChange} />
                        </Toolbar>
                      </Hidden>
                      <Table
                        rows={filteredComparison}
                        columns={comparisonColumns}
                        totalRows={allComparison?.length}
                        pageSize={20}
                        page={comparisonCurrentPage}
                        onPageChange={({ page }: any) => setComparisonCurrentPage(page)}
                        sortModel={comparisonSortModel}
                        onSortModelChange={setComparisonSortModel}
                        getRowClassName={(params: any) => getRowClassName(params.row)}
                        onRowDoubleClick={({ row }: any) => {
                          setSelectedComparisonRow(row);
                          setComparisonRowDetails(true);
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                      <Hidden mdDown>
                        <Toolbar
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Alert sx={{ flex: 1, marginRight: '1rem' }} severity="info">GST is not calculated on adjustments</Alert>
                          <GlobalSearch searchedText={searchSummaryText} onSearchTextChange={onSummarySearchTextChange} showFilterByColumn columns={summaryColumns} filterByColumn={summaryFilterByCol} onFilterByColumnChange={onSummaryFilterColChange} width="400px" />
                        </Toolbar>
                      </Hidden>
                      <Hidden mdUp>
                        <Alert sx={{ marginTop: '1rem' }} severity="info">GST is not calculated on adjustments</Alert>
                        <Toolbar
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <GlobalSearch searchedText={searchSummaryText} onSearchTextChange={onSummarySearchTextChange} showFilterByColumn columns={summaryColumns} filterByColumn={summaryFilterByCol} onFilterByColumnChange={onSummaryFilterColChange} />
                        </Toolbar>
                      </Hidden>
                      <Table
                        rows={filteredSummary}
                        columns={summaryColumns}
                        totalRows={allSummary?.length}
                        pageSize={20}
                        page={summaryCurrentPage}
                        onPageChange={({ page }: any) => setSummaryCurrentPage(page)}
                        sortModel={summarySortModel}
                        onSortModelChange={setSummarySortModel}
                        getRowClassName={(params: any) => getRowClassName(params.row)}
                      />
                    </TabPanel>
                  </>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Dialog open={openUploadFile} onClose={closeUploadFileDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload Line Items</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <DialogContentText sx={{ marginBottom: 4 }}>
            Please select an Excel/CSV file in desired payroll format.
          </DialogContentText>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ExportButton
              buttonType="outlined"
              label="Download template"
              dataPromiseProvider={PayrollService.downloadPayrollUploadFile}
              fileName="payroll_upload_template"
              type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
          <Typography sx={{ paddingBottom: 2 }} variant="h5">{selectedFileName}</Typography>
          <Button variant="contained" component="label" size="small" sx={{ marginBottom: 4 }}>
            Select File
            <input
              type="file"
              hidden
              onChange={onFileSelectionChange}
              accept=".xls,.xlsx,.csv"
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUploadFileDialog} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={onUploadFile} variant="contained" color="primary" disabled={!selectedFile}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openProcessPayroll} onClose={closeProcessPayrollDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Process Payroll : {formatDateToOnlyDate(payrollPeriod?.startTimeLocal?.toString())} to {formatDateToOnlyDate(payrollPeriod?.endTimeLocal?.toString())}</DialogTitle>
        <Formik
          initialValues={{
            processDate: formatInputDate(new Date().toISOString())
          }}
          validationSchema={Yup.object().shape({
            processDate: Yup.date().required()
          })}
          onSubmit={(values) => processPayroll(values.processDate)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            isValid
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  Once the payroll period is processed, emails will be sent out to all Drivers and a copy will be sent to the Notification Email Address. This process may take upto 24 hours.
                </DialogContentText>
                <p style={{ marginBottom: 4 }}>
                  <Typography><b>No further changes can be made to this payroll period.</b></Typography>
                </p>
                <TextField
                  error={Boolean(touched.processDate && errors.processDate)}
                  fullWidth
                  helperText={touched.processDate && errors.processDate}
                  label="Pay Date"
                  margin="normal"
                  name="processDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.processDate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeProcessPayrollDialog} variant="outlined" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog fullWidth maxWidth="xl" open={comparisonRowDetails} onClose={() => setComparisonRowDetails(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Payroll Line Items</DialogTitle>
        <DialogContent sx={{ height: '30rem' }}>
          <DataGrid
            columns={lineItemsColumns.filter((col) => col.field !== 'actions')}
            rows={selectedComparisonRow?.shifts ?? []}
            hideFooter
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setComparisonRowDetails(false)} variant="outlined" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {
        editingLineItem && <EditPayrollLineItem open={openEditLineItem} handleModalClose={closeEditLineItemDialog} lineItem={editingLineItem} payrollId={Number(id)} allStaff={allStaff} allStores={allStores} />
      }
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);

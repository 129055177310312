import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import Webcam from 'react-webcam';

interface Props {
  onCapture: (file: File) => void;
  open: boolean;
  onClose: () => void;
}

const WebcamCaptureModal: React.FC<Props> = ({ onCapture, open, onClose }) => {
  const webcamRef = useRef<Webcam>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
    }
  }, [webcamRef]);

  const handleSave = () => {
    if (capturedImage) {
      fetch(capturedImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });
          onCapture(file);
          onClose();
        });
    }
  };

  const handleRetake = () => {
    setCapturedImage(null);
  };

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  useEffect(() => {
    if (open) {
      setCapturedImage(null); // Clear the captured image when the modal is opened
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6" component="h2" mb={2}>
          Capture Image
        </Typography>
        {!capturedImage ? (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              height={240}
              videoConstraints={{ facingMode }}
            />
            <Button variant="contained" color="primary" onClick={capture} sx={{ mt: 2 }}>
              Capture
            </Button>
            <Button variant="outlined" color="secondary" onClick={toggleFacingMode} sx={{ mt: 2 }}>
              Switch Camera
            </Button>
          </>
        ) : (
          <>
            <img src={capturedImage} alt="Captured" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleRetake} sx={{ mt: 1 }}>
              Retake
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default WebcamCaptureModal;

import { createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiAlert-standardError': {
      color: '#fff4e5!important',
      backgroundColor: '#e86057!important',
      fontSize: '1.2rem!important'
    },
    '.MuiAlert-standardWarning': {
      color: '#663c00!important',
      backgroundColor: '#fff4e5!important',
      fontSize: '1.2rem!important'
    },
    '.MuiAlert-standardInfo': {
      color: '#0d3c61!important',
      backgroundColor: '#e8f4fd!important',
      fontSize: '1.2rem!important'
    },
    '.MuiAlert-standardSuccess': {
      color: '#e8f4fd!important',
      backgroundColor: '#56b756!important',
      fontSize: '1.2rem!important'
    },
    '.MuiAlert-icon': {
      color: 'inherit!important',
      fontSize: '2rem!important'
    },
    '.MuiListItemText-primary': {
      lineHeight: '1!important'
    },
    '.error': {
      backgroundColor: '#f0c5c2!important',
      '&:hover': {
        backgroundColor: '#fab3ae!important',
      }
    },
    '.warning': {
      backgroundColor: '#fff4e5!important',
      '&:hover': {
        backgroundColor: '#f9daaf!important',
      }
    },
    '.info': {
      backgroundColor: '#e8f4fd!important'
    },
    '.success': {
      backgroundColor: '#edf7ed!important'
    },
    '.error-icon': {
      color: '#cb6f69!important',
    },
    '.warning-icon': {
      color: '#c9a16b!important',
    },
    '.info-icon': {
      color: '#6c9ec5!important'
    },
    '.success-icon': {
      color: '#5fb25f!important'
    },
    '.clickableCell': {
      '&:hover': {
        backgroundColor: '#d7d2d2!important',
        boxShadow: '0 0 5px grey',
        cursor: 'pointer'
      }
    },
    '.MuiCardHeader-title': {
      wordBreak: 'break-all!important',
      fontWeight: '500!important',
      fontSize: '1.2rem!important'
    },
    '.MuiDialogTitle-root': {
      wordBreak: 'break-all!important',
      fontWeight: '500!important',
      fontSize: '1.2rem!important'
    },
    '.MuiCardHeader-subheader': {
      wordBreak: 'break-all!important'
    },
    '.MuiDataGrid-cell': {
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    '.MuiDataGrid-row': {
      cursor: 'pointer'
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600!important',
    },
    '.css-cveggr-MuiListItemIcon-root': {
      minWidth: '35px!important'
    },
    '.MuiDataGrid-root .MuiDataGrid-footer': {
      justifyContent: 'flex-end!important'
    },
    '.MuiDataGrid-selectedRowCount': {
      display: 'none!important',
      visibility: 'hidden!important'
    },
    '.MuiSelect-filled.MuiSelect-filled': {
      paddingTop: '6px'
    },
    '.MuiToolbar-root': {
      padding: '0!important'
    },
    '.MuiButton-containedPrimary': {
      minWidth: '10rem!important'
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Avatar,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { LocationOn, Flag, AssignmentTurnedIn, FreeBreakfast, LocalShipping, Comment } from '@mui/icons-material';
import AppConstants from '../constants/AppConstants';
import GoogleMap from '../components/map/GoogleMap';
import ShiftsService from '../services/ShiftsService';
import { show, hide } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatDateToLongDate, formatDateToRelativeTime, getFullName } from '../utils/common';
import { CheckinRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const ShiftsTracker: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  clearAllAlerts,
  showAlert
}) => {
  const [checkIns, setCheckIns] = useState<Array<CheckinRes>>([]);
  const refreshData = () => {
    showBusyIndicator();
    clearAllAlerts();
    try {
      ShiftsService.getAllOngoingShiftCheckIns().then((response) => {
        setCheckIns(response);
      });
    } catch (error) {
      console.log(error);
    } finally {
      hideBusyIndicator();
    }
  };

  const checkInTypes: any = {
    0: {
      icon: <LocationOn />,
      iconText: '',
      type: 'Other'
    },
    1: {
      icon: <Flag />,
      iconText: '\ue153',
      type: 'Shift Start'
    },
    2: {
      icon: <AssignmentTurnedIn />,
      iconText: '\ue862',
      type: 'Shift End'
    },
    4: {
      icon: <FreeBreakfast />,
      iconText: '\ueb44',
      type: 'Break Start'
    },
    8: {
      icon: <LocalShipping />,
      iconText: '\ue558',
      type: 'Break End'
    },
    16: {
      icon: <Comment />,
      iconText: '\ue0b9',
      type: 'Comment'
    },
    32: {
      icon: null,
      iconText: <LocalShipping />,
      type: 'Last Location'
    },
    64: {
      icon: <LocationOn />,
      iconText: '',
      type: 'Pre-start 30 Minute Check-in'
    },
    128: {
      icon: <LocationOn />,
      iconText: '',
      type: 'Precheck Questions Check-in'
    },
  };

  useEffect(refreshData, []);

  const getDetailsNode = (checkIn: CheckinRes) => (
    <Box width="18rem">
      <Grid container>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} item xs={3}>
          <Avatar>{checkInTypes[checkIn?.checkinType || 0]?.icon}</Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6" sx={{ lineHeight: '1rem' }}>{checkInTypes[checkIn?.checkinType || 0]?.type}</Typography>
          <Typography>{getFullName(checkIn?.user?.firstName ?? '', checkIn?.user?.middleName ?? '', checkIn?.user?.lastName ?? '')}</Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <Typography variant="h6">{formatDateToRelativeTime(checkIn?.createdOnLocal, true)}</Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Shift Start:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">{formatDateToLongDate(checkIn?.shift?.startedOnLocal, true)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>Shift Tracker | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader title="Shift Tracker" />
            <Divider />
            <CardContent>
              <GoogleMap
                marks={
                  checkIns?.map((checkIn) => ({
                    ...checkIn,
                    icon: checkInTypes[checkIn?.checkinType || 0]?.iconText ?? checkInTypes[0]?.iconText,
                    info: getDetailsNode(checkIn)
                  })) ?? []
                }
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsTracker);

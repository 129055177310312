import React from 'react';
import { Tabs, Tab, Divider, Hidden } from '@mui/material';
import { connect } from 'react-redux';
import TabPanel from '../TabPanel';
import ScheduledShifts from './ScheduledShifts';
import OngoingShifts from './OngoingShifts';
import CompletedShifts from './CompletedShifts';
import { changeTab } from '../../store/actions/shiftActions';
import { RootState } from '../../store/store';

interface Props {
  selectedTab: number,
  hasOngoingShifts: boolean,
  onTabChange: (tabIndex: number) => void
}

const AllShifts: React.FC<Props> = ({
  selectedTab,
  hasOngoingShifts,
  onTabChange
}) => {
  const handleTabChange = (event: any, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <>
      <Hidden mdUp>
        <Tabs
          data-auto-id="allShiftTabsMobile"
          variant="fullWidth"
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab label="Ongoing" data-auto-id="viewOngoingShiftTab" />
          <Tab label="Scheduled" data-auto-id="viewScheduledShiftTab" />
          <Tab label="Completed" data-auto-id="viewCompletedShiftTab" />
        </Tabs>
      </Hidden>
      <Hidden mdDown>
        <Tabs
          data-auto-id="allShiftTabs"
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab label="Ongoing" data-auto-id="viewOngoingShiftTab" />
          <Tab label="Scheduled" data-auto-id="viewScheduledShiftTab" />
          <Tab label="Completed" data-auto-id="viewCompletedShiftTab" />
        </Tabs>
      </Hidden>
      <Divider />
      <TabPanel value={selectedTab} index={0}>
        <OngoingShifts />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <ScheduledShifts />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <CompletedShifts />
      </TabPanel>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedTab: state.shift.currentTab,
  hasOngoingShifts: !!state.shift?.ongoing?.allRows?.length
});

const mapDispatchToProps = (dispatch: any) => ({
  onTabChange: (tabIndex: number) => dispatch(changeTab(tabIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllShifts);

import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Tooltip,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Container,
  Card,
  CardHeader,
  IconButton,
  Divider,
  CardContent,
  CardActions,
  MenuItem,
  ListItemText,
  Paper,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import BackIcon from '@mui/icons-material/ChevronLeft';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../store/actions/alertActions';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { FormQuestionMediaReq, FormQuestionMultiChoiceReq, FormQuestionNumericReq, FormQuestionSignatureReq, FormQuestionSingleChoiceReq, FormQuestionTextualReq, FormReq, FormRes, FormSubmissionRes } from '../proxy/proxy';
import HeaderPreview from '../components/formpreview/HeaderPreview';
import TextFieldPreview from '../components/formpreview/TextFieldPreview';
import NumberFieldPreview from '../components/formpreview/NumberFieldPreview';
import MultiChoiceInputPreview from '../components/formpreview/MultiChoicePreview';
import SingleChoiceInputPreview from '../components/formpreview/SingleChoicePreview';
import MediaInputPreview from '../components/formpreview/MediaInputPreview';
import SignatureInputPreview from '../components/formpreview/SignatureInputPreview';
import FormsService from '../services/FormsService';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';

interface Props {
  currentFormState: FormRes;
  currentFieldStates: any;
  submissionId: number;
  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const FormPreview: React.FC<Props> = ({
  currentFormState,
  currentFieldStates,
  submissionId,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  type FormQuestionTypes = FormQuestionTextualReq | FormQuestionNumericReq | FormQuestionMultiChoiceReq | FormQuestionSingleChoiceReq | FormQuestionMediaReq | FormQuestionSignatureReq;

  const [formState, setFormState] = useState<FormRes>(currentFormState);
  const [formAnswers, setFormAnswers] = useState<any>();

  const [saveSignal, setSaveSignal] = useState(false); // Track when to save signatures
  const [allSaveResults, setAllSaveResults] = useState<any[]>([]); // Store results of all saves
  // Store references to promises for each signature save
  const signatureSavePromises = useRef<Promise<void>[]>([]);
  const [totalSavePromises, setTotalSavePromises] = useState(0);

  useEffect(() => {
    showAlert(formatSuccessMessage(formState?.onOpenMessage));

    if (submissionId > 0) {
      FormsService.getSubmission(submissionId)
        .then((result) => {
          setFormAnswers(result);
        }).catch((err) => {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        })
        .then(() => {
          hideBusyIndicator();
        });
    } else if (!Number.isNaN(currentFormState.id) && Number(currentFormState.id) > 0) {
      FormsService.getLatestByUser(currentFormState.id ?? 0)
        .then((result) => {
          setFormAnswers(result);
        }).catch((err) => {
          // showAlert(formatErrorMessage(JSON.parse(err.response)));
        })
        .then(() => {
          hideBusyIndicator();
        });
    }
  }, []);

  const handleSubmit = async () => {
    showBusyIndicator(); // Hide busy indicator before starting to avoid flickering
    try {
      setSaveSignal(true); // Trigger save on all signature components

      // Wait for all signature saves to complete
      await Promise.all(signatureSavePromises.current);
    } catch (err) {
      console.error('Error saving signatures or submitting form:', err);
      showAlert(formatErrorMessage(err)); // Ensure proper error alert
    } finally {
      hideBusyIndicator();
      setSaveSignal(false); // Reset save signal for the next operation
    }
  };

  const handleSaveComplete = async (promise: Promise<any>) => {
    signatureSavePromises.current.push(promise);

    if (signatureSavePromises.current.length === totalSavePromises) {
      const response = await FormsService.submit(formAnswers.id);
      showAlert(formatSuccessMessage(response?.message ?? 'Form submitted successfully.'));
      hideBusyIndicator();
    }
  };

  useEffect(() => {
    currentFieldStates.forEach((element: any) => {
      if (element.questionType === 7) {
        setTotalSavePromises((prev) => prev + 1);
      }
    });
  }, [currentFieldStates]);

  const renderElements = (item : any, index: number) => {
    switch (item.questionType) {
      case 0:
        return (
          <TextFieldPreview
            key={formAnswers?.id ?? 0}
            currentFieldState={item}
            form={formState?.id ?? 0}
            answer={formAnswers?.formAnswers?.find((x: any) => x.formQuestionId === item.id)?.answer}
          />
        );
      case 1:
        return (
          <NumberFieldPreview
            key={formAnswers?.id ?? 0}
            currentFieldState={item}
            form={formState?.id ?? 0}
            answer={formAnswers?.formAnswers?.find((x: any) => x.formQuestionId === item.id)?.answer}
          />
        );
      case 2:
        return (
          <MultiChoiceInputPreview
            key={formAnswers?.id ?? 0}
            currentFieldState={item}
            form={formState?.id ?? 0}
            answer={formAnswers?.formAnswers?.find((x: any) => x.formQuestionId === item.id)?.answers ?? []}
          />
        );
      case 3:
        return (
          <SingleChoiceInputPreview
            key={formAnswers?.id ?? 0}
            currentFieldState={item}
            form={formState?.id ?? 0}
            answer={formAnswers?.formAnswers?.find((x: any) => x.formQuestionId === item.id)?.answer}
          />
        );
      case 6:
        return (
          <MediaInputPreview
            key={formAnswers?.id ?? 0}
            submissionId={formAnswers?.id ?? 0}
            currentFieldState={item}
            form={formState?.id ?? 0}
            answer={formAnswers?.formAnswers?.find((x: any) => x.formQuestionId === item.id)?.answers}
          />
        );
      case 7:
        return (
          <SignatureInputPreview
            key={formAnswers?.id ?? 0}
            currentFieldState={item}
            form={formState?.id ?? 0}
            saveSignal={saveSignal} // Pass the save signal to each child
            onSaveComplete={(result) => {
              // Create a resolved promise with the result and add it to the list
              handleSaveComplete(Promise.resolve(result));
            }}
            onSaveFailed={(error) => {
              // Create a rejected promise with the error and add it to the list
              // handleSaveComplete(Promise.reject(error));
            }}
          />
        );
      default:
        return <Fragment />;
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Helmet>
          <title>Question Details</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Grid container spacing={1} direction="row" justifyContent="center">
            <Grid item md={submissionId > 0 ? 12 : 6} sx={{ maxWidth: '100%' }} sm={12}>
              <HeaderPreview key={formState.name} currentFormState={formState} />
              <Card>
                <Divider />
                <CardContent
                  sx={{
                    pointerEvents: submissionId > 0 ? 'none' : 'auto', // Disable pointer events if submissionId is 0
                    opacity: submissionId > 0 ? 0.8 : 1 // Optional: Change opacity to visually indicate disabled state
                  }}
                >
                  <form>
                    <CardContent>
                      <Grid container spacing={6}>
                        {currentFieldStates.map((item: FormQuestionTypes, index: number) => (
                          <React.Fragment key={index}>
                            <Grid item xs={2} sm={1}>
                              {/* Adjust the xs size to ensure it fits better on small screens */}
                              <Typography variant="h4">{index + 1}. {/* Adding the index number */}</Typography>
                            </Grid>
                            <Grid item xs={10} sm={11}>
                              {/* Adjust the xs size to fit remaining space on small screens */}
                              {renderElements(item, item.order ?? 0)}
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                      <Box sx={{ mt: '10px', textAlign: 'right' }}>
                        <Tooltip title="Save form" aria-label="add-element">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            Submit form
                          </Button>
                        </Tooltip>
                      </Box>
                    </CardContent>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPreview);

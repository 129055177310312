import { AxiosResponse } from 'axios';
import AppConstants from '../constants/AppConstants';
import { AllEnumsRes, Client, EnumRes } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const EnumService = (function service() {
  let data: AllEnumsRes;

  const getDataPromise = () => {
    if (data && data !== null && Object.keys(data).length > 0) {
      return Promise.resolve(data);
    }
    return configureAxios().allEnums()
      .then((res: AllEnumsRes) => {
        data = res;
        return res;
      });
  };

  return {
    getAllEnums: () => getDataPromise(),
    getEnums: (key: string): Promise<Array<EnumRes>> => getDataPromise().then((res: any) => res[key])
  };
}());

export default EnumService;

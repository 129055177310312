import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, ListItemText, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ShiftsService from '../../services/ShiftsService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatInputDate, convertToLocalDate } from '../../utils/common';
import EnumService from '../../services/EnumService';
import FormattedShift from '../../models/FormattedShift';
import { EnumRes } from '../../proxy/proxy';

interface Props {
  open: boolean,
  handleModalClose: (data: any) => any,
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const FetchShifts: React.FC<Props> = ({
  open,
  handleModalClose,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);
  const [shiftData, setShiftData] = useState<FormattedShift>();

  useEffect(() => {
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  const fetchShifts = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftsService.shiftsImportFromGoogleSheet({
      stateId: Number(values.stateId),
      startDateUtc: convertToLocalDate(values.startTimeLocal),
      endDateUtc: convertToLocalDate(values.startTimeLocal),
      instructions: values.instructions,
      enforceState: values.enforceState
    })
      .then((result: any) => {
        showAlert(formatSuccessMessage('Shifts successfully fetched from Google Sheets'));
        handleModalClose(result);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    clearAllAlerts();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleModalClose(null)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Fetch Shifts</DialogTitle>
        <Formik
          initialValues={{
            startTimeLocal: '',
            endTimeLocal: '',
            stateId: '',
            enforceState: false,
            instructions: shiftData?.instructions || '',
          }}
          validationSchema={Yup.object().shape({
            startTimeLocal: Yup.date().required('Start Date is required'),
            endTimeLocal: Yup.date(),
            stateId: Yup.number().required('State is required'),
            instructions: Yup.string()
          })}
          onSubmit={fetchShifts}
        >
          {
            ({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              isValid,
              dirty
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    error={Boolean(touched.stateId && errors.stateId)}
                    fullWidth
                    helperText={touched.stateId && errors.stateId}
                    margin="normal"
                    label="State"
                    name="stateId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.stateId}
                  >
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    control={
                      (
                        <Checkbox
                          name="enforceState"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          color="primary"
                        />
                      )
                    }
                    label="Enforce state"
                  />
                  <TextField
                    error={Boolean(touched.startTimeLocal && errors.startTimeLocal)}
                    fullWidth
                    helperText={touched.startTimeLocal && errors.startTimeLocal}
                    label="Date"
                    margin="normal"
                    name="startTimeLocal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.startTimeLocal}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  {/* <TextField
                    error={Boolean(touched.endTimeLocal && errors.endTimeLocal)}
                    fullWidth
                    helperText={touched.endTimeLocal && errors.endTimeLocal}
                    label="End Date (In Local)"
                    margin="normal"
                    name="endTimeLocal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.endTimeLocal}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                    disabled
                  /> */}
                  <TextField
                    error={Boolean(touched.instructions && errors.instructions)}
                    fullWidth
                    helperText={touched.instructions && errors.instructions}
                    label="Instructions"
                    data-auto-id="instructionsTextField"
                    margin="normal"
                    name="instructions"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.instructions}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    multiline
                  />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button
                    onClick={() => handleModalClose(null)}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Fetch
                  </Button>
                </DialogActions>
              </form>
            )
          }
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FetchShifts);

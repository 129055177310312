import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CardHeader,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  MenuItem,
  ListItemText,
  IconButton,
  DialogActions,
  Alert
} from '@mui/material';
import { MessageSquare as MessageIcon } from 'react-feather';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { refreshData } from '../../store/actions/predefinedShiftCommentsActions';
import { RootState } from '../../store/store';
import { PredefinedShiftCommentRes, ShiftCommentRes } from '../../proxy/proxy';

interface Props {
  comments?: Array<ShiftCommentRes>,
  addComment: any,
  deleteComment?: any,
  drops: number,
  isOpen: boolean,
  closeModal: () => any,
  allComments?: Array<PredefinedShiftCommentRes>,
  fetchShiftComments: any,
  showAllComments?: boolean
}

const AddCommentModal: React.FC<Props> = ({
  comments = [],
  addComment,
  deleteComment = null,
  drops,
  isOpen,
  closeModal,
  allComments,
  fetchShiftComments,
  showAllComments = false
}) => {
  const [formattedComments, setFormattedComments] = useState<any>({});
  const groupedComments = comments.reduce((res: any, comment: ShiftCommentRes) => {
    const parts = comment.text?.split(':');
    const drop = comment.shiftCommentTypeId === 2 ? Number(parts?.[0]?.replace('Drop', '')?.trim() ?? 0) : 0;
    const text = parts?.[1]?.trim();

    res[drop] = [...(res[drop] ?? []), text];

    return res;
  }, {});

  useEffect(() => {
    if (!allComments?.length) {
      fetchShiftComments();
    }
  }, []);

  useEffect(() => {
    const res: any = {};
    const keys = Object.keys(groupedComments);

    for (let index = 0; index < keys.length; index++) {
      const drop = keys[index];
      res[drop] = groupedComments[drop].map((comment: any) => {
        if (typeof comment === 'string') {
          return allComments?.find((entry) => entry.comment === comment);
        }
        return comment;
      }).filter(Boolean);
    }

    setFormattedComments(res);
  }, [allComments]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Add Comment</DialogTitle>
      <Formik
        initialValues={{
          drop: '0',
          comment: '',
          manualComment: ''
        }}
        validationSchema={Yup.object().shape({
          drop: Yup.number().required('Required').min(0),
          comment: Yup.string().required('Required'),
          manualComment: Yup.string()
            .when('comment', {
              is: '0',
              then: Yup.string().required('Required')
            }),
        })}
        onSubmit={(values, { resetForm }) => {
          let commentText;
          if (values.comment === '0') {
            commentText = values.manualComment;
          } else {
            commentText = allComments?.find((comment: PredefinedShiftCommentRes) => comment.id?.toString() === values.comment)?.comment;
          }
          addComment(values.drop, commentText, values.drop.toString() !== '0');
          resetForm();
        }}
      >
        {
          ({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            dirty,
            isValid
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                  <Grid item xs={12} md={4} lg={2}>
                    <TextField
                      error={Boolean(touched.drop && errors.drop)}
                      fullWidth
                      select
                      helperText={touched.drop && errors.drop}
                      label="Type"
                      margin="normal"
                      name="drop"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.drop}
                      required
                    >
                      <MenuItem value={0}>
                        <ListItemText primary="Shift" />
                      </MenuItem>
                      {
                        drops > 0 && Array.from(Array(drops).keys()).map((drop) => (
                          <MenuItem key={drop + 1} value={drop + 1}>
                            <ListItemText primary={`Drop ${drop + 1}`} />
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={8} lg={10}>
                    <TextField
                      error={Boolean(touched.comment && errors.comment)}
                      fullWidth
                      select
                      helperText={touched.comment && errors.comment}
                      label="Comment"
                      margin="normal"
                      name="comment"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.comment}
                      variant="outlined"
                      required
                    >
                      <MenuItem value="0">
                        <em>Enter Manually</em>
                      </MenuItem>
                      {
                        (allComments ?? [])
                          .sort((a: PredefinedShiftCommentRes, b: PredefinedShiftCommentRes) => {
                            if (a.comment && b.comment) {
                              if (a.comment < b.comment) return -1;
                              if (a.comment > b.comment) return 1;
                              return 0;
                            }
                            return 0;
                          })
                          .map((comment: PredefinedShiftCommentRes) => (
                            <MenuItem key={comment.id} value={comment.id}>
                              <ListItemText primary={comment.comment} />
                            </MenuItem>
                          ))
                      }
                    </TextField>
                  </Grid>
                  {
                    values.comment === '0' && (
                      <>
                        <Grid item xs={2} />
                        <Grid item xs={10}>
                          <TextField
                            error={Boolean(touched.manualComment && errors.manualComment)}
                            fullWidth
                            helperText={touched.manualComment && errors.manualComment}
                            label="Enter your comment here"
                            margin="normal"
                            name="manualComment"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.manualComment}
                            variant="outlined"
                            required
                            multiline
                          />
                        </Grid>
                      </>
                    )
                  }
                  {
                    dirty && (formattedComments[values.drop] ?? []).find((entry: PredefinedShiftCommentRes) => entry?.id?.toString() === values.comment) && (
                      <Grid item xs={12}>
                        <Alert severity="warning">Currently selected comment already added</Alert>
                      </Grid>
                    )
                  }
                </Grid>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button disabled={!dirty || !isValid} color="primary" variant="contained" type="submit">
                  Add
                </Button>
              </DialogActions>
            </form>
          )
        }
      </Formik>
      {
        showAllComments && Object.keys(formattedComments).map((drop: any) => formattedComments[drop].map((comment: PredefinedShiftCommentRes, index: number) => (
          <Card key={`row-${drop}-${index}`} sx={{ marginBottom: '10px' }} variant="outlined">
            <CardHeader
              avatar={<MessageIcon />}
              title={`${drop === '0' ? 'Shift' : `Drop ${drop}`}: ${comment?.comment}`}
              action={
                deleteComment ? (
                  <IconButton color="primary" onClick={() => deleteComment(drop, index)}>
                    <DeleteIcon />
                  </IconButton>
                ) : null
              }
            />
          </Card>
        )))
      }
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  allComments: state.shiftComments?.allRows
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchShiftComments: () => dispatch(refreshData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentModal);

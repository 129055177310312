import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, IconButton, Box, Container, Card, CardHeader, Divider, CardContent, TextField, Grid, CardActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ConfirmButton from '../components/ConfirmButton';
import SubmitFormButton from '../components/SubmitFormButton';
import PredefinedShiftCommentService from '../services/PredefinedShiftCommentService';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage } from '../utils/common';
import { PredefinedShiftCommentRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const PredefinedShiftCommentDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const commentId = !Number.isNaN(Number(id)) ? Number(id) : 0;
  const navigate = useNavigate();
  const [commentData, setCommentData] = useState<PredefinedShiftCommentRes | null>(null);

  const refreshData = () => {
    showBusyIndicator();
    clearAllAlerts();
    PredefinedShiftCommentService.getCommentById(commentId)
      .then((result) => {
        setCommentData(result);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    if (commentId) {
      refreshData();
    } else {
      setCommentData({});
    }
  }, []);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload = {
      comment: values.comment.trim()
    };

    const promise = commentId ? PredefinedShiftCommentService.updateComment(commentId, payload) : PredefinedShiftCommentService.createComment(payload);

    promise
      .then((response) => {
        showAlert(formatSuccessMessage(commentId ? 'Comment updated successfully' : 'Comment created successfully'));
        setCommentData(null);
        setCommentData(response);
        if (!commentId) {
          navigate(`/app/shift/comment/${response.id}`, { replace: true });
        }
      }).catch((err) => {
        showAlert(formatErrorMessage(err?.response));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    PredefinedShiftCommentService.deleteComment(commentId)
      .then(() => {
        showAlert(formatSuccessMessage('Comment deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Helmet>
        <title>Shift Comment Details | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title="Shift Comment Details"
              action={
                !!commentId && (
                  <ConfirmButton
                    buttonIcon={
                      (
                        <DeleteIcon />
                      )
                    }
                    isIconButton
                    dialogTitle="Are you sure?"
                    showCancelButton
                    message="Shift comment will be deleted permanently."
                    buttonColor="secondary"
                    onConfirm={handleDeleteClick}
                  />
                )
              }
            />
            <Divider />
            <CardContent>
              {
                !!commentData && (
                  <Formik
                    initialValues={{
                      comment: commentData.comment || ''
                    }}
                    validationSchema={Yup.object().shape({
                      comment: Yup.string().max(255).required('Comment is required')
                    })}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      dirty,
                      isValid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.comment && errors.comment)}
                                fullWidth
                                helperText={touched.comment && errors.comment}
                                label="Comment"
                                margin="normal"
                                name="comment"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.comment}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                multiline
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined">
                            Cancel
                          </Button>
                          <SubmitFormButton
                            dirty={dirty}
                            isValid={isValid}
                            editMode={Boolean(commentId)}
                            onConfirm={handleSubmit}
                          />
                        </CardActions>
                      </form>
                    )}
                  </Formik>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedShiftCommentDetail);

const Logo = ({ mobileView = false }) => (
  <>
    {
      !mobileView && (
        <div
          style={{
            height: '64px',
            width: '220px',
            backgroundImage: 'url(/static/logo.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '250px'
          }}
        />
      )
    }
    {
      mobileView && (
        <div
          style={{
            height: '22px',
            width: '150px',
            backgroundImage: 'url(/static/logo.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '170px'
          }}
        />
      )
    }
  </>
);

export default Logo;

import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, IconButton, Toolbar, Hidden, CardContent, Divider, Typography, Grid, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Add as AddIcon, PostAddOutlined as AddOnIcon } from '@mui/icons-material';
import { Edit as EditIcon } from 'react-feather';
import { connect } from 'react-redux';
import { refreshData, reset, changePage, changeSortModel, searchByText } from '../../store/actions/payrollAddonActions';
import { RootState } from '../../store/store';
import GlobalSearch from '../GlobalSearch';
import Table from '../Table';
import MobileDataList from '../MobileDataList';
import EnumService from '../../services/EnumService';
import { formatDateToOnlyTime, getJobSiteName, sortByKey } from '../../utils/common';
import ConfirmButton from '../ConfirmButton';
import { AllEnumsRes, EnumRes, PayRateShiftDurationAddOnRes } from '../../proxy/proxy';

interface Props {
  selectMode?: boolean,
  otherActions?: ReactElement,

  onRowSelect?: (prop: any) => any,
  rowSelectButton?: any,

  visibleRows: Array<PayRateShiftDurationAddOnRes>,
  totalRowCount: number,
  searchedText: string,
  currentPage: number,
  sortModel: any,

  fetchAllRows: any,
  resetPage: any,
  filterByText: any,
  onPageChange: (page: number) => any,
  onSort: (model: any) => any
}

const ShiftDurationAddOns: React.FC<Props> = ({
  selectMode,
  otherActions = null,
  onRowSelect,

  visibleRows,
  totalRowCount,
  searchedText,
  currentPage,
  sortModel,

  fetchAllRows,
  resetPage,
  filterByText,
  onPageChange,
  onSort
}) => {
  const navigate = useNavigate();
  const [allPayRateKinds, setAllPayRateKinds] = useState<Array<EnumRes>>([]);
  const [allPayRateTypes, setAllPayRateTypes] = useState<Array<EnumRes>>([]);

  const addAddOn = () => {
    navigate('/app/payroll/addons/shiftDuration/add');
  };

  const navigateToEditPage = ({ id }: any) => {
    navigate(`/app/payroll/addons/shiftDuration/${id}`);
  };

  const onRowClick = onRowSelect ?? navigateToEditPage;

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const getPayRateDisplayValue = (addOn: any) => {
    const { payRate, payRateKind } = addOn;

    if (payRateKind === 1) {
      return `${payRate?.toFixed(2)} %`;
    }
    return `$ ${payRate?.toFixed(2)}`;
  };

  const getPayRateKindDisplayValue = (addOn: any) => {
    const { payRateKind } = addOn;

    return allPayRateKinds.find((entry) => entry.id === payRateKind)?.value;
  };

  const getPayRateTypeDisplayValue = (addOn: any) => {
    const { payRateType } = addOn;

    return allPayRateTypes.find((entry) => entry.id === payRateType)?.value;
  };

  const getSelectButton = (row: any) => (
    <ConfirmButton
      buttonLabel="Select"
      dialogTitle="Confirm Add-On Assignment"
      showCancelButton
      message={`You are about to assign selected staff to '${row.name}' add-on.`}
      buttonColor="primary"
      buttonVariant="outlined"
      buttonSize="medium"
      onConfirm={() => onRowClick(row)}
    />
  );

  const getEditButton = (row: any) => (
    <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}><EditIcon /></IconButton>
  );

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2
    },
    {
      field: 'payRate',
      headerName: 'Pay Rate',
      flex: 1,
      renderCell: (params: any) => {
        const { row } = params;
        return getPayRateDisplayValue(row);
      }
    },
    {
      field: 'payRateKind',
      headerName: 'Pay Rate Type',
      flex: 1,
      renderCell: (params: any) => {
        const { row } = params;

        return getPayRateKindDisplayValue(row);
      }
    },
    {
      field: 'payRateType',
      headerName: 'Pay Rate Category',
      flex: 1,
      renderCell: (params: any) => {
        const { row } = params;

        return getPayRateTypeDisplayValue(row);
      }
    },
    {
      field: 'shiftDurationLowerLimit',
      headerName: 'From',
      flex: 1
    },
    {
      field: 'shiftDurationUpperLimit',
      headerName: 'To',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params: any) => {
        const { row } = params;

        return selectMode ? getSelectButton(row) : getEditButton(row);
      },
      renderHeader: () => (<span />)
    }
  ];

  const renderEachMobileCard = (row: PayRateShiftDurationAddOnRes) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={<AddOnIcon />}
        action={selectMode ? getSelectButton(row) : getEditButton(row)}
        title={row.name}
        subheader={getPayRateKindDisplayValue(row)}
        onClick={!selectMode ? () => onRowClick(row) : () => {}}
      />
      <Divider />
      <CardContent>
        <Typography variant="body2">
          <b>Pay Rate:</b> {getPayRateDisplayValue(row)}
        </Typography>
        <Typography variant="body2">
          <b>Pay Rate Category:</b> {getPayRateTypeDisplayValue(row)}
        </Typography>
        <Typography variant="body2">
          <b>From:</b> {row.shiftDurationLowerLimit}
        </Typography>
        <Typography variant="body2">
          <b>To:</b> {row.shiftDurationUpperLimit}
        </Typography>
      </CardContent>
    </Card>
  );

  useEffect(() => {
    fetchAllRows();
    EnumService.getAllEnums().then((result: AllEnumsRes) => {
      const payRateKinds = sortByKey(result?.payRateKinds ?? [], 'value').map((entry) => {
        const copy = { ...entry };
        copy.value = copy.value.replace('Absolute', 'Absolute (Dollar)').replace('absolute', 'Absolute (Dollar)');
        return copy;
      });
      const payRateTypes = sortByKey(result?.payRateTypes ?? [], 'value');

      setAllPayRateKinds(payRateKinds);
      setAllPayRateTypes(payRateTypes);
    });
  }, []);

  return (
    <>
      <Toolbar>
        <div style={{ flex: 1 }}>
          {otherActions}
        </div>
        <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
        {
          !selectMode && (
            <IconButton onClick={refresh} data-auto-id="refreshIconButton">
              <RefreshIcon />
            </IconButton>
          )
        }
        {
          !selectMode && (
            <IconButton onClick={addAddOn} data-auto-id="addIconButton">
              <AddIcon />
            </IconButton>
          )
        }
      </Toolbar>
      <Hidden mdDown>
        <Table
          rows={visibleRows}
          columns={columns}
          onRowDoubleClick={!selectMode ? onRowClick : () => {}}
          totalRows={totalRowCount}
          page={currentPage}
          onPageChange={({ page }) => onPageChange(page)}
          sortModel={sortModel}
          onSortModelChange={onSort}
        />
      </Hidden>
      <Hidden mdUp>
        <MobileDataList
          rows={visibleRows}
          renderEachRow={renderEachMobileCard}
          totalRows={totalRowCount}
          page={currentPage}
          pageSize={8}
          onPageChange={onPageChange}
        />
      </Hidden>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.payrollAddon?.shiftDuration?.filteredRows ?? [],
  totalRowCount: state.payrollAddon?.shiftDuration?.allRows?.length ?? 0,
  currentPage: state.payrollAddon?.shiftDuration?.currentPage ?? 0,
  searchedText: state.payrollAddon?.shiftDuration?.searchedText,
  sortModel: state.payrollAddon?.shiftDuration?.sortModel
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => dispatch(refreshData('shiftDuration')),
  resetPage: () => dispatch(reset('shiftDuration')),
  filterByText: (text: string) => dispatch(searchByText(text, 'shiftDuration')),
  onPageChange: (page: number) => dispatch(changePage(page, 'shiftDuration')),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel, 'shiftDuration')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDurationAddOns);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Container,
  Tooltip,
  IconButton
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import PreCheckQuestionsService from '../../services/PreCheckQuestionsService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';
import ShiftsService from '../../services/ShiftsService';
import ImageSelector from '../ImageSelector';
import { FileParameter, PreCheckQuestionRes } from '../../proxy/proxy';
import { FormattedPrecheck } from '../../models/FormattedPrecheck';
import FormattedShift from '../../models/FormattedShift';
import WebcamCaptureModal from '../WebcamCaptureModal';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
  agreeAll: (ids: Array<number>) => any,
  disAgree: () => any
}

const AcceptQuestions: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts,
  agreeAll,
  disAgree
}) => {
  const shiftId = useParams()?.id;
  const [preCheckQuestions, setPreCheckQuestions] = useState<Array<PreCheckQuestionRes>>([]);
  const [formattedPreCheckQuestions, setFormattedPreCheckQuestions] = useState<Array<FormattedPrecheck>>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuesionId] = useState(0);

  const openCaptureModal = (questionId: number) => {
    setSelectedQuesionId(questionId);
    setIsModalOpen(true);
  };

  useEffect(() => {
    showBusyIndicator();
    clearAllAlerts();
    PreCheckQuestionsService.getAllQuestions()
      .then((result: React.SetStateAction<PreCheckQuestionRes[]>) => {
        setPreCheckQuestions(result);
        setFormattedPreCheckQuestions(result);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(err?.response));
      }).then(() => {
        hideBusyIndicator();
      });
  }, []);

  const handleAgreeClick = () => {
    const blockers = formattedPreCheckQuestions.filter((x) => !x.isAnswered && x.isBlocker);
    console.log(blockers);
    if (blockers.length > 0) {
      showAlert({
        message: 'Mandatory questions must be accepted in order to start the shift',
        severity: 'error'
      });
    }
    agreeAll(formattedPreCheckQuestions.map((question) => question.id ?? 0));
  };

  const handleDisAgreeClick = () => {
    disAgree();
  };

  const uploadQuestion = async (preCheckQuestionId?: number, file?: File, answer?: boolean) => {
    const updateQuestionIsAnswered = (questionId: number, isSubmitted: boolean) => {
      setFormattedPreCheckQuestions((prevQuestions) =>
        prevQuestions.map((q) => (q.id === questionId ? { ...q, isSubmitted } : q)));
    };
    const precheck = formattedPreCheckQuestions.find((x) => x.id === preCheckQuestionId);
    if (precheck) {
      if (precheck.requiresPhoto === true && precheck.file === undefined && file === undefined) {
        showAlert({
          message: 'Please capture photo for this question',
          severity: 'error'
        });
        return;
      }

      showBusyIndicator();

      let fileData = {
        data: precheck.file === undefined ? file : precheck.file,
        fileName: 'precheck.png',
      };

      if (precheck.requiresPhoto === false) {
        const base64Response = await fetch('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAHNJREFUKFOdkLEKwCAMRM/JwUFwdPb/v8RPEDcdBQcHJyUt0hQ6hGY6Li8XEhVjXM45aK3xVXNOtNagcs6LRAgB1toX23tHSgkUpEopyxhzGRw+EHljjBv03oM3KJYP1lofkJoHJs3T/4Gi1aJjxO+RPnwDur2EF1gNZukAAAAASUVORK5CYII=');
        const blob = await base64Response.blob();
        fileData = {
          data: blob,
          fileName: 'dummy.png'
        };
      }

      ShiftsService.shiftsShiftPreStartPrecheckAnswerLinfox(precheck.isAnswered ?? answer, fileData, precheck.requiresPhoto, precheck.id, Number(shiftId))
        .then((result: React.SetStateAction<FormattedShift>) => {
          updateQuestionIsAnswered(preCheckQuestionId ?? 0, true);
        }).catch((err: any) => {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        }).then(() => {
          hideBusyIndicator();
        });
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, question: FormattedPrecheck) => {
    setFormattedPreCheckQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === question.id ? { ...q, isAnswered: event.target.value === 'true' } : q)));
    uploadQuestion(question.id, undefined, event.target.value === 'true');
  };

  const getRadioGroupValue = (selectedValue: boolean | undefined): string | null => {
    if (selectedValue === null || selectedValue === undefined) return null;
    return selectedValue ? 'true' : 'false';
  };

  const handleCapture = (file: File) => {
    setFormattedPreCheckQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === selectedQuestionId ? { ...q, file } : q)));

    if (formattedPreCheckQuestions.find((x) => x.id === selectedQuestionId)?.isAnswered !== undefined) {
      uploadQuestion(selectedQuestionId, file);
    }
  };

  return (
    <>
      <CardHeader
        subheader="Please read all questions carefully."
        title="Precheck Questions"
      />
      <Divider />
      <CardContent>
        <List component="nav">
          {formattedPreCheckQuestions.map((question) => (
            <ListItem key={question.id}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText style={{ width: '80%', minWidth: '90px' }} primary={question?.question} />
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={getRadioGroupValue(question.isAnswered)}
                onChange={(event) => handleChange(event, question)}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
              {question.requiresPhoto && question.isAnswered !== undefined ? (
                <Tooltip title="Capture image">
                  <IconButton data-auto-id="shiftUploadButton" onClick={() => openCaptureModal(question.id ?? 0)}>
                    <CameraAltIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton style={{ visibility: 'hidden' }}>
                  <CameraAltIcon />
                </IconButton>
              )}
              {question.isSubmitted && (
                <IconButton data-auto-id="shiftUploadButton">
                  <CheckCircleIcon style={{ color: 'green' }} />
                </IconButton>
              )}
              <WebcamCaptureModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCapture={handleCapture}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginLeft: '10px' }}
          onClick={handleDisAgreeClick}
        >
          Disagree
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: '10px' }}
          onClick={handleAgreeClick}
          data-auto-id="agreeAllButton"
        >
          Submit
        </Button>
      </CardActions>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptQuestions);

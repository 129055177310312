import FormattedJobsite from '../../models/FormattedJobsite';
import {
  CLEAR_ALL_DATA,
  STORE_LOAD_ALL,
  STORE_RESET_PAGE,
  STORE_GRID_SORT,
  STORE_GRID_CURRENT_PAGE,
  STORE_GRID_SEARCH_TEXT,
  STORE_CHANGE_STATE,
} from '../actionTypes';

export interface StateType {
  allRows: Array<FormattedJobsite>,
  filteredRows: Array<FormattedJobsite>,
  searchedText: string,
  currentPage: number,
  sortModel: Array<any>,
  states: Array<number>,
}

const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  currentPage: 0,
  sortModel: [],
  states: []
};

const filterRows = (allRows: Array<FormattedJobsite> = [], states: Array<number> = [], searchText = '') => {
  const text = searchText.trim();
  let result = text ? allRows.filter((row) => {
    if (
      (row.name || '').toLowerCase().indexOf((text || '').toLowerCase()) > -1
      || (row.linfoxName || '').toLowerCase().indexOf((text || '').toLowerCase()) > -1
      || (row.areaCode || '').toLowerCase().indexOf((text || '').toLowerCase()) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;

  if (states.length > 0) {
    result = result.filter((store) => states.includes(store.stateId));
  }

  return result;
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case STORE_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.states, state.searchedText),
      };
    case STORE_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case STORE_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case STORE_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case STORE_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, state.states, action.data),
        currentPage: 0
      };
    case STORE_CHANGE_STATE:
      return {
        ...state,
        states: action.data,
        filteredRows: filterRows(state.allRows, action.data, state.searchedText),
        currentPage: 0
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

import React from 'react';
import { Button, IconButton } from '@mui/material';
import { DownloadCloud as DownloadIcon } from 'react-feather';
import { connect } from 'react-redux';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage } from '../utils/common';

interface Props {
  dataPromiseProvider: () => Promise<any>,
  label?: string,
  buttonType?: 'contained' | 'text' | 'outlined' | undefined,
  fileName: string,
  type?: string,
  isIconButton?: boolean,
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const ExportButton: React.FC<Props> = ({
  dataPromiseProvider,
  label = 'Export',
  type = 'text/csv',
  buttonType = 'contained',
  fileName,
  isIconButton,
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const formattedFileName = `${fileName}_${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}-${new Date().getTime()}`;
  const onExportClick = async () => {
    if (!dataPromiseProvider) return;

    showBusyIndicator();
    clearAllAlerts();
    dataPromiseProvider().then((response) => {
      const blob = new Blob([response.data], { type });
      const a: any = document.createElement('A');
      a.style = 'display: none;';
      a.href = window.URL.createObjectURL(blob);
      a.download = formattedFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }).catch((err) => {
      showAlert(formatErrorMessage({ title: 'No records found.' }));
    }).then(() => {
      hideBusyIndicator();
    });
  };

  if (isIconButton) {
    return (
      <IconButton onClick={onExportClick}>
        <DownloadIcon />
      </IconButton>
    );
  }
  return (
    <Button
      variant={buttonType}
      sx={{ marginRight: 2 }}
      startIcon={<DownloadIcon />}
      onClick={onExportClick}
    >
      { label }
    </Button>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton);

import { Select, MenuItem, Typography, Box } from '@mui/material';

const FilterBy = ({
  label = 'Filter By',
  options = [],
  value,
  onChange,
  width = 'auto'
}) => (
  <Box width={width} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
    <Typography variant="body1" sx={{ marginRight: 1 }}>{label} :</Typography>
    <Select
      value={value}
      onChange={onChange}
      variant="filled"
      size="small"
      sx={{ width: '140px' }}
    >
      { options?.map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>) }
    </Select>
  </Box>
);

export default FilterBy;

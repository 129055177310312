import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box, Card, CardHeader, Divider, CardContent, Grid, TextField, MenuItem, ListItemText, Button, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { Download } from '@mui/icons-material';

import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { RootState } from '../store/store';
import { formatDateToLongDate, formatErrorMessage, getAllMonths, getPast20Years } from '../utils/common';
import InvoicesService from '../services/InvoicesService';
// import { BillRes } from '../proxy/models';
import Table from '../components/Table';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const Invoices: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [invoices, setInvoices] = useState<Array<any> | null>();
  const [currentPage, setCurrentPage] = useState(0);
  const [sortModel, setSortModel] = useState<any>();

  const columns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      width: 200
    },
    {
      field: 'createdOnUtc',
      headerName: 'Date',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return formatDateToLongDate(value);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <IconButton href={row.sasUrl} target="_blank">
            <Download />
          </IconButton>
        );
      },
      renderHeader: () => (<span />)
    }
  ];

  const onFindClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    InvoicesService.findInvoices(year, month)
      .then((data) => {
        const list: Array<any> = (data ?? []).map((inv, index) => {
          const copy: any = { ...inv };
          copy.id = index;

          return copy;
        });
        setInvoices(list);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
        setInvoices(null);
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Helmet>
        <title>Invoices | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              title="Invoices"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={2} xs={5}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Year"
                    name="year"
                    margin="normal"
                    onChange={(e) => setYear(Number(e.target.value))}
                    required
                    select
                    value={year}
                    variant="outlined"
                  >
                    {getPast20Years().map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={5}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Month"
                    name="month"
                    margin="normal"
                    onChange={(e) => setMonth(Number(e.target.value))}
                    required
                    select
                    value={month}
                    variant="outlined"
                  >
                    {getAllMonths().map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Button sx={{ marginTop: '5px' }} disabled={year === 0 || month === 0} variant="contained" color="secondary" onClick={onFindClick}>Find</Button>
                </Grid>
              </Grid>
              {
                <Table
                  rows={invoices ?? []}
                  columns={columns}
                  totalRows={invoices?.length || 0}
                  page={currentPage}
                  onPageChange={({ page }: any) => setCurrentPage(page)}
                  sortModel={sortModel}
                  onSortModelChange={(model: any) => setSortModel(model)}
                />
              }
              {
                invoices?.length === 0 && (
                  <Card>
                    <CardHeader title="No Invoices found" />
                  </Card>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

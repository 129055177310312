import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
  MenuItem,
  ListItemText
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FormQuestionTextual, RegularExpressionRes } from '../../proxy/proxy';
import { showAlert } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';
import FormsService from '../../services/FormsService';

interface TextFieldInputProps {
  order: number;
  currentFieldState: FormQuestionTextual;
  fieldStateChanged: (fieldState: any, order: number) => void;
  deleteEl: (order: number, id: string) => void;
  handleArrowUpClick: (order: number) => void;
  handleArrowDownClick: (order: number) => void;
  duplicateElement: (order: number) => void;
}

const TextFieldInput: React.FC<TextFieldInputProps> = ({
  order,
  currentFieldState,
  fieldStateChanged,
  deleteEl,
  duplicateElement,
  handleArrowDownClick,
  handleArrowUpClick
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionTextual>(currentFieldState);
  const [regularExpressions, setRegularExpressions] = useState<RegularExpressionRes[]>([]);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      FormsService.getAllRegularExpressions(),
    ];

    Promise.all(promises)
      .then((result) => {
        setRegularExpressions(result[0]);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      });
  };

  useEffect(() => {
    fieldStateChanged(fieldState, order);
  }, [fieldState]);

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Fragment>
      <Paper elevation={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Text question
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={(
                <Switch
                  checked={fieldState.required || false}
                  onChange={() => {
                    setFieldState({ ...fieldState, required: !fieldState.required });
                  }}
                  name="required-field"
                  color="secondary"
                />
              )}
              label="Required"
              sx={{ ml: 2 }}
            />
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => handleArrowUpClick(order)}
            >
              <ArrowUpwardIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => handleArrowDownClick(order)}
            >
              <ArrowDownwardIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => deleteEl(order, currentFieldState.id)}
            >
              <DeleteIcon sx={{ cursor: 'pointer' }} color="primary" />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0'
              }}
              onClick={() => duplicateElement(order)}
            >
              <ContentCopyIcon sx={{ cursor: 'pointer' }} color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                value={fieldState.title}
                variant="filled"
                label="Text question title *"
                onChange={(e) => {
                  setFieldState({ ...fieldState, title: e.target.value });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={fieldState.subTitle || ''}
                variant="filled"
                label="Text question subtitle"
                onChange={(e) => {
                  setFieldState({ ...fieldState, subTitle: e.target.value });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Restrictions"
                onChange={(e) => {
                  setFieldState({ ...fieldState, regularExpression: e.target.value });
                }}
                select
                sx={{
                  mb: 2,
                  '& .MuiSelect-select': {
                    paddingTop: '25px',
                  },
                }}
                variant="filled"
                value={fieldState.regularExpression || ''}
              >
                {regularExpressions.map((expression) => (
                  <MenuItem
                    key={expression.expression || ''}
                    value={expression.expression || ''}
                  >
                    <ListItemText>{expression.name}</ListItemText>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Is Multiline"
                onChange={({ target: { value } }) => { // Destructure value from target
                  const isMultiline = value === '1'; // Compare with string '1'
                  setFieldState((prevState) => ({ ...prevState, isMultiline }));
                }}
                select
                sx={{
                  mb: 2,
                  '& .MuiSelect-select': {
                    paddingTop: '25px',
                  },
                }}
                variant="filled"
                value={fieldState.isMultiline ? '1' : '2'} // Should be string '1' or '2'
              >
                <MenuItem key={1} value="1">
                  <ListItemText primary="Yes" />
                </MenuItem>
                <MenuItem key={2} value="2">
                  <ListItemText primary="No" />
                </MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                variant="filled"
                fullWidth
                label="Enter your answer"
                required={fieldState.required || false}
                disabled
              />
            </Grid> */}
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default TextFieldInput;

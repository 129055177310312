import { LINFOX_CURRENT_TAB } from '../actionTypes';

export interface StateType {
  selectedTab: number
}

const INITIAL_STATE: StateType = {
  selectedTab: 0,
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case LINFOX_CURRENT_TAB:
      return {
        ...state, selectedTab: action.data,
      };

    default: return state;
  }
};

export default reducer;

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  VEHICLE_LOAD_ALL,
  VEHICLE_RESET_PAGE,
  VEHICLE_GRID_SORT,
  VEHICLE_GRID_CURRENT_PAGE,
  VEHICLE_GRID_SEARCH_TEXT,
  VEHICLE_GRID_SEARCH_FILTER_BY,
  VEHICLE_GRID_SEARCH_FILTER_BY_COLUMN,
  VEHICLE_CHANGE_STATE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import VehiclesService from '../../services/VehiclesService';
import { formatErrorMessage } from '../../utils/common';

export const refreshData = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  VehiclesService.getAllVehicles()
    .then((result) => {
      dispatch({
        type: VEHICLE_LOAD_ALL,
        data: result
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: VEHICLE_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: VEHICLE_GRID_SEARCH_TEXT,
  data: text
});

export const searchByWarning = (filterBy = 'all') => ({
  type: VEHICLE_GRID_SEARCH_FILTER_BY,
  data: filterBy
});

export const changeFilterByColumn = (col = 'all_col') => ({
  type: VEHICLE_GRID_SEARCH_FILTER_BY_COLUMN,
  data: col
});

export const changePage = (page = 1) => ({
  type: VEHICLE_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: VEHICLE_GRID_SORT,
  data: sortModel
});

export const selectStates = (states: Array<number> = []): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: VEHICLE_CHANGE_STATE, data: states });
};

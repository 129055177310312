import * as moment from 'moment';
import AppConstants from '../constants/AppConstants';

const LocalStorageService = (function service() {
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    localStorage.setItem(AppConstants.ACCESS_TOKEN, tokenObj[AppConstants.ACCESS_TOKEN]);
    localStorage.setItem(AppConstants.REFRESH_TOKEN, tokenObj[AppConstants.REFRESH_TOKEN]);
    localStorage.setItem(AppConstants.TOKEN_VALID_TILL, moment().add(tokenObj[AppConstants.TOKEN_EXPIRES_IN], 's').toString());
  }

  function _setUserRoles(roles) {
    localStorage.setItem(AppConstants.ROLES, JSON.stringify(roles));
  }

  function _hasValidToken() {
    const token = localStorage.getItem(AppConstants.ACCESS_TOKEN);
    const tokenValidTill = localStorage.getItem(AppConstants.TOKEN_VALID_TILL);
    const refreshToken = localStorage.getItem(AppConstants.REFRESH_TOKEN);

    if (moment().isBefore(tokenValidTill) && token) {
      return true;
    }
    if (token && refreshToken) {
      return true;
    }
    return false;
  }

  function _getAccessToken() {
    return localStorage.getItem(AppConstants.ACCESS_TOKEN);
  }

  function _getRefreshToken() {
    return localStorage.getItem(AppConstants.REFRESH_TOKEN);
  }

  function _setCompanyProfile(profile) {
    localStorage.setItem(AppConstants.COMPANY_PROFILE, JSON.stringify(profile));
  }

  function _getCompanyProfile() {
    return JSON.parse(localStorage.getItem(AppConstants.COMPANY_PROFILE) || '{}');
  }

  function _clearToken() {
    localStorage.removeItem(AppConstants.ACCESS_TOKEN);
    localStorage.removeItem(AppConstants.REFRESH_TOKEN);
    localStorage.removeItem(AppConstants.TOKEN_VALID_TILL);
    localStorage.removeItem(AppConstants.USER_PROFILE);
    localStorage.removeItem(AppConstants.ROLES);
    localStorage.removeItem(AppConstants.COMPANY_PROFILE);
  }

  function _getUserRoles() {
    return JSON.parse(localStorage.getItem(AppConstants.ROLES));
  }

  return {
    getService: _getService,

    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,

    setUserRoles: _setUserRoles,
    getUserRoles: _getUserRoles,

    setCompanyProfile: _setCompanyProfile,
    getCompanyProfile: _getCompanyProfile,

    hasValidToken: _hasValidToken
  };
}());

export default LocalStorageService;

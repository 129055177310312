import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  QUESTIONS_LOAD_ALL,
  QUESTIONS_RESET_PAGE,
  QUESTIONS_GRID_SORT,
  QUESTIONS_GRID_CURRENT_PAGE,
  QUESTIONS_GRID_SEARCH_TEXT
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import PreCheckQuestionsService from '../../services/PreCheckQuestionsService';
import { formatErrorMessage } from '../../utils/common';

export const refreshData = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  PreCheckQuestionsService.getAllQuestions()
    .then((result) => {
      dispatch({
        type: QUESTIONS_LOAD_ALL,
        data: result
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: QUESTIONS_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: QUESTIONS_GRID_SEARCH_TEXT,
  data: text
});

export const changePage = (page = 1) => ({
  type: QUESTIONS_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: QUESTIONS_GRID_SORT,
  data: sortModel
});

import AppConstants from '../constants/AppConstants';
import configureAxios from '../proxy/ConfigureAxios';
import { Client } from '../proxy/proxy';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const PublicHolidaysService = {
  search: async (state: number | null) => {
    if (state) {
      return configureAxios().getAllGET(state).then((response) => response);
    }

    return configureAxios().allGET().then((response) => response);
  }
};

export default PublicHolidaysService;

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  STORE_LOAD_ALL,
  STORE_RESET_PAGE,
  STORE_GRID_SORT,
  STORE_GRID_CURRENT_PAGE,
  STORE_GRID_SEARCH_TEXT,
  STORE_CHANGE_STATE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import StoresService from '../../services/StoresService';
import EnumService from '../../services/EnumService';
import { formatErrorMessage } from '../../utils/common';
import FormattedJobsite from '../../models/FormattedJobsite';
import { EnumRes, JobsiteRes } from '../../proxy/proxy';

export const refreshData = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  const promises: Array<Promise<any>> = [
    EnumService.getEnums('states'),
    StoresService.getAllStores()
  ];
  Promise.all(promises)
    .then((result) => {
      const states: Array<EnumRes> = result[0] || [];
      const stores: Array<JobsiteRes> = result[1] || [];
      dispatch({
        type: STORE_LOAD_ALL,
        data: stores.map((store: JobsiteRes) => new FormattedJobsite(store, states))
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: STORE_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: STORE_GRID_SEARCH_TEXT,
  data: text
});

export const changePage = (page = 1) => ({
  type: STORE_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: STORE_GRID_SORT,
  data: sortModel
});

export const selectStates = (states: Array<number> = []): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: STORE_CHANGE_STATE, data: states });
};

import React, { useState, useEffect, useRef, Fragment } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Grid, Button, Typography } from '@mui/material';
import { FormQuestionSignature } from '../../proxy/proxy';
import FormsService from '../../services/FormsService';
import { showAlert } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';

interface SignatureInputPreviewProps {
  currentFieldState: FormQuestionSignature;
  form: number;
  saveSignal: boolean; // Prop to trigger save action
  onSaveComplete?: (result: any) => void;
  onSaveFailed?: (error: any) => void;
}

const SignatureInputPreview: React.FC<SignatureInputPreviewProps> = ({
  currentFieldState,
  form,
  saveSignal, // Watch for changes on this prop to trigger save
  onSaveComplete,
  onSaveFailed,
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionSignature>(currentFieldState);
  const [formId, setFormId] = useState<number>(form);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const signatureRef = useRef<SignatureCanvas>(null);
  const [error, setError] = useState<string>('');

  const handleBeginSigning = () => {
    setShowPlaceholder(false);
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setShowPlaceholder(true);
    }
  };

  const saveSignature = async () => {
    if (signatureRef.current) {
      const dataURL = signatureRef.current.toDataURL();
      const blob = await fetch(dataURL).then((res) => res.blob());

      // Check if the signature is blank
      if (showPlaceholder) {
        setError('Please provide a signature before saving.'); // Show error message
        if (onSaveFailed) {
          onSaveFailed('Empty'); // Notify parent of failure
        }
        return;
      }

      const fileData = {
        data: blob,
        fileName: 'signature.png',
      };

      try {
        const result = await FormsService.uploadPhoto(formId, fieldState.id, fieldState.title, fileData);

        const payload = {
          __discriminator: fieldState.discriminator,
          discriminator: fieldState.discriminator,
          formId,
          formQuestionId: fieldState.id,
          answer: result.mediaFileName,
        };

        const response = await FormsService.answer(payload);

        if (onSaveComplete) {
          onSaveComplete(response); // Notify parent of success
        }
      } catch (errorM) {
        // showAlert(formatErrorMessage(error?.response));
        if (onSaveFailed) {
          onSaveFailed(errorM); // Notify parent of failure
        }
      }
    }
  };

  // Trigger saveSignature when saveSignal changes to true
  useEffect(() => {
    if (saveSignal) {
      saveSignature();
    }
  }, [saveSignal]);

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            width: '100%',
            height: 200,
            position: 'relative',
            backgroundColor: 'white',
            overflow: 'hidden',
          }}
        >
          {showPlaceholder && (
            <img
              src="https://www.vhv.rs/dpng/d/586-5864567_fake-signature-png-transparent-png.png"
              alt="Signature Placeholder"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                opacity: 0.1,
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            />
          )}
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              width: 300,
              height: 200,
              className: 'sigCanvas',
              style: {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2,
              },
            }}
            backgroundColor="transparent"
            onBegin={handleBeginSigning}
          />
        </Box>
        {error !== '' && (
          <Typography color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" color="secondary" onClick={clearSignature}>
            Clear Sign
          </Button>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default SignatureInputPreview;

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  SHIFT_NR_LOAD_ALL,
  SHIFT_NR_RESET_PAGE,
  SHIFT_NR_GRID_SORT,
  SHIFT_NR_GRID_CURRENT_PAGE,
  SHIFT_NR_GRID_SEARCH_TEXT,
  SHIFT_NR_GRID_SEARCH_FILTER_BY,
  SHIFT_NR_GRID_FILTER_BY_BREACH,
  SHIFT_NR_DATE_RANGE,
  SHIFT_NR_CHANGE_STATE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import ShiftNRService from '../../services/ShiftNRService';
import { formatErrorMessage, getDayStart, getDayEnd } from '../../utils/common';

export const refreshData = (companyId: number, startDate: string | undefined, endDate: string | undefined): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());
  ShiftNRService.getAllShifts(companyId, getDayStart(startDate ?? getState().shiftNR?.startDate) ?? new Date(), getDayEnd(endDate ?? getState().shiftNR?.endDate) ?? new Date())
    .then((shifts = []) => {
      dispatch({
        type: SHIFT_NR_LOAD_ALL,
        data: shifts
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: SHIFT_NR_RESET_PAGE
});

export const searchByText = (text: string) => ({
  type: SHIFT_NR_GRID_SEARCH_TEXT,
  data: text
});

export const changeFilterByColumn = (col = 'all_col') => ({
  type: SHIFT_NR_GRID_SEARCH_FILTER_BY,
  data: col
});

export const changePage = (page = 1) => ({
  type: SHIFT_NR_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: SHIFT_NR_GRID_SORT,
  data: sortModel
});

export const filterByBreach = (filterBy = 'all') => ({
  type: SHIFT_NR_GRID_FILTER_BY_BREACH,
  data: filterBy
});

export const selectStates = (states: Array<string> = []): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({ type: SHIFT_NR_CHANGE_STATE, data: states });
};

export const changeDateRange = (startDate: string, endDate: string) => ({
  type: SHIFT_NR_DATE_RANGE,
  data: {
    startDate,
    endDate
  }
});

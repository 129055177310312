import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Divider, IconButton, Box, Toolbar, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Chip, Alert } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import AppConstants from '../constants/AppConstants';
import GlobalSearch from '../components/GlobalSearch';
import FilterBy from '../components/FilterBy';
import Table from '../components/Table';
import DateRangeSelector from '../components/DateRangeSelector';
import { RootState } from '../store/store';
import { refreshData, reset, changeSortModel, searchByText, changePage, filterByBreach, changeDateRange, changeShiftType } from '../store/actions/dashboardNRActions';
import FormattedShiftNR from '../models/FormattedShiftNR';
import { formatDateToOnlyTime, getDatesInRange } from '../utils/common';
import { hide, show } from '../store/actions/busyIndicatorActions';

interface Props {
  startDate: string,
  endDate: string,
  visibleRows: Array<FormattedShiftNR>,
  totalRowCount: number,
  searchedText: string,
  currentPage: number,
  sortModel: any,
  companyId: number | undefined,
  selectedBreachType: string,
  selectedShiftType: string,
  fetchAllRows: any,
  resetPage: any,
  filterByText: any,
  onPageChange: any,
  onSort: any,
  onFilterByBreach: any,
  onFilterByShift: any,
  onDateRangeChange: any,
  showBusyIndicator: any,
  hideBusyIndicator: any
}

const DashboardNR: React.FC<Props> = ({
  startDate,
  endDate,
  visibleRows,
  totalRowCount,
  searchedText,
  currentPage,
  sortModel,
  selectedBreachType,
  selectedShiftType,
  companyId,

  fetchAllRows,
  resetPage,
  filterByText,
  onPageChange,
  onSort,
  onFilterByBreach,
  onFilterByShift,
  onDateRangeChange,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const navigate = useNavigate();
  const [openDriverDetails, setOpenDriverDetails] = React.useState(false);
  const [selectedDriver, setSelectedDriver] = React.useState<any>();

  const [openDriverDaySummary, setOpenDriverDaySummary] = React.useState(false);
  const [selectedCellShifts, setSelectedCellShifts] = React.useState<Array<FormattedShiftNR>>([]);
  const [hasSelectedCell12HrBreach, setHasSelectedCell12HrBreach] = React.useState<boolean>(false);

  const handleDaySummaryClose = () => {
    setOpenDriverDaySummary(false);
    setSelectedCellShifts([]);
  };

  const handleDriverDetailsClose = () => {
    setOpenDriverDetails(false);
    setSelectedDriver(null);
  };

  const onCellClick = ({ field, row }: { field: string, row: any }) => {
    if (['totalActualWorkingHours'].includes(field)) return;

    if (field === 'name') {
      console.log(row);
      setSelectedDriver(row);
      setOpenDriverDetails(true);
      return;
    }

    const shifts = row[field.replace('actualWorkingHours', 'shifts')];
    if (!(shifts?.length)) return;

    showBusyIndicator();
    setSelectedCellShifts(shifts);
    setHasSelectedCell12HrBreach(row[field.replace('actualWorkingHours', 'has12HrBreach')]);
    setOpenDriverDaySummary(true);
    setTimeout(hideBusyIndicator, 500);
  };

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const handleDateRangeChange = (start: string, end: string) => {
    if (start && end) {
      fetchAllRows(companyId, start, end);
    }
    onDateRangeChange(start, end);
  };

  const onRowClick = ({ id }: any) => {
    navigate(`/app/linfox/${id}`);
  };

  const getBreach = (type: string) => {
    switch (type) {
      case 'S1':
        return (
          <Tooltip key="segment1" title="Driver worked for more than 5 hours without break on Segment 1">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="S1" />
          </Tooltip>
        );
      case 'S2':
        return (
          <Tooltip key="segment2" title="Driver worked for more than 5 hours without break on Segment 2">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="S2" />
          </Tooltip>
        );
      case 'S3':
        return (
          <Tooltip key="segment3" title="Driver worked for more than 5 hours without a break on Segment 3">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="S3" />
          </Tooltip>
        );
      case '12h':
        return (
          <Tooltip key="12Hour" title="Driver has more than 12 hours driving time in a 13 hour working day - From Start Time to End Time">
            <Chip sx={{ marginLeft: '0.25rem' }} size="small" color="error" variant="outlined" label="12h" />
          </Tooltip>
        );

      default:
        return null;
    }
  };
  const getBreaches = (row: any, date: string) => [
    ...(row[`${date}-hasSegment1Breach`] ? [getBreach('S1')] : []),
    ...(row[`${date}-hasSegment2Breach`] ? [getBreach('S2')] : []),
    ...(row[`${date}-hasSegment3Breach`] ? [getBreach('S3')] : []),
    ...(row[`${date}-has12HrBreach`] ? [getBreach('12h')] : [])
  ];
  const allDatesInRange = getDatesInRange(startDate, endDate).reverse();
  const columns: Array<any> = [
    {
      field: 'name',
      headerName: 'Driver Name (id)',
      renderCell: (params: any) => {
        const { row } = params;
        const value = `${row.name} (${row.id})`;
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          </div>
        );
      },
      width: 250
    },
    {
      field: 'totalActualWorkingHours',
      headerName: 'Total Working hours',
      renderCell: (params: any) => {
        const { value } = params;
        if (!value) return '--';

        return (
          <Tooltip title={`${value} hours`}>
            <span style={{ fontWeight: 'bold' }}>{value?.toFixed(2)}</span>
          </Tooltip>
        );
      },
      width: 150
    },
    ...allDatesInRange.map((date) => ({
      field: `${date}-actualWorkingHours`,
      headerName: date,
      renderCell: (params: any) => {
        const { value, row } = params;
        if (!value) return '--';
        return (
          <div style={getBreaches(row, date).length < 3 ? { display: 'flex', width: '100%', justifyContent: 'space-between' } : undefined}>
            <Tooltip title={`${value} hours`}>
              <span>{value?.toFixed(2)}</span>
            </Tooltip>
            <div>
              {getBreaches(row, date)}
            </div>
          </div>
        );
      },
      width: 150
    }))
  ];

  useEffect(() => {
    if (!companyId) return;

    fetchAllRows(companyId, startDate, endDate);
  }, [companyId]);

  return (
    <>
      <Box>
        <Toolbar style={{ justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '1rem' }}>
          <DateRangeSelector startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} />
          <div style={{ display: 'flex' }}>
            <FilterBy
              label="Shift"
              options={[
                { value: 'all', text: 'All' },
                { value: 'am', text: 'AM' },
                { value: 'pm', text: 'PM' }
              ]}
              value={selectedShiftType}
              onChange={(event: any) => onFilterByShift(event.target.value)}
            />&nbsp;&nbsp;&nbsp;&nbsp;
            <FilterBy
              label="Breach"
              options={[
                { value: 'all', text: 'All' },
                { value: '12Hrs', text: '12 Hours' },
                { value: 'segment1', text: 'Segment 1' },
                { value: 'segment2', text: 'Segment 2' },
                { value: 'segment3', text: 'Segment 3' }
              ]}
              value={selectedBreachType}
              onChange={(event: any) => onFilterByBreach(event.target.value)}
            />&nbsp;&nbsp;&nbsp;&nbsp;
            <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
            <IconButton
              color="secondary"
              onClick={() => {
                resetPage();
                fetchAllRows(companyId);
              }}
              data-auto-id="shiftRefreshIconButton"
            >
              <RefreshIcon />
            </IconButton>
          </div>
        </Toolbar>
        <Box
          sx={{
            '& .breach': {
              backgroundColor: '#fab3ae!important',
              '&:hover': {
                backgroundColor: '#f0867f!important',
              }
            }
          }}
        >
          <Table
            hasPagination={false}
            rows={visibleRows}
            columns={columns}
            onCellClick={onCellClick}
            totalRows={totalRowCount}
            page={currentPage}
            onPageChange={onPageChange}
            sortModel={sortModel}
            onSortModelChange={onSort}
            showCellRightBorder
            getCellClassName={({ field, row }: { field: string, row: any }) => {
              if (field === 'name') {
                return 'clickableCell';
              }
              if (field === 'totalActualWorkingHours') {
                return '';
              }
              if (row[field]) {
                return 'clickableCell';
              }

              return '';
            }}
          />
        </Box>
      </Box>
      <Dialog fullWidth maxWidth="lg" open={openDriverDaySummary} onClose={handleDaySummaryClose}>
        <DialogTitle id="alert-dialog-title">{`${selectedCellShifts?.[0]?.date} - ${selectedCellShifts?.[0]?.name} (${selectedCellShifts?.[0]?.extDriverId})`}</DialogTitle>
        <DialogContent>
          {
            hasSelectedCell12HrBreach && (
              <div style={{ paddingBottom: '1rem' }}>
                <Alert severity="error">Driver has more than 12 hours driving time in a 13 hour working day - From Start Time to End Time</Alert>
              </div>
            )
          }
          <DataGrid
            rows={selectedCellShifts}
            columns={[
              {
                field: 'hasBreach',
                headerName: 'Breaches',
                renderCell: (params: any) => {
                  const { row } = params;
                  return (
                    <>
                      {(row.segment1WorkedForHours || 0) > 5 && getBreach('S1')}
                      {(row.segment2WorkedForHours || 0) > 5 && getBreach('S2')}
                      {(row.segment3WorkedForHours || 0) > 5 && getBreach('S3')}
                      {row.hasWorkedOver12Hours && getBreach('12h')}
                    </>
                  );
                },
                width: 150
              },
              { field: 'date', headerName: 'Date', type: 'date', width: 120 },
              { field: 'stateCode', headerName: 'State', width: 100 },
              {
                field: 'store',
                headerName: 'Store',
                renderCell: (params: any) => {
                  const { value } = params;
                  return (
                    <Tooltip title={value}>
                      <span>{value}</span>
                    </Tooltip>
                  );
                },
                width: 200
              },
              { field: 'rego', headerName: 'Rego', width: 150 },
              { field: 'amOrPM', headerName: 'Shift', width: 90 },
              {
                field: 'startTime',
                headerName: 'Start Time',
                type: 'date',
                renderCell: (params: any) => {
                  const { value } = params;
                  return formatDateToOnlyTime(value, true);
                },
                width: 120
              },
              {
                field: 'endTime',
                headerName: 'End Time',
                type: 'date',
                renderCell: (params: any) => {
                  const { value } = params;
                  return formatDateToOnlyTime(value, true);
                },
                width: 120
              },
              {
                field: 'actualWorkingHours',
                headerName: 'Actual Working Hours',
                type: 'number',
                renderCell: (params: any) => {
                  const { value } = params;
                  return value?.toFixed(2);
                },
                width: 180,
                filterable: false
              },
              { field: 'totalWorkingHours', headerName: 'Total Working Hours', type: 'number', width: 180, filterable: false },
              { field: 'restTaken', headerName: 'Rest Taken', width: 150, filterable: false }
            ]}
            autoHeight
            hideFooter
            onRowDoubleClick={onRowClick}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDaySummaryClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openDriverDetails} onClose={handleDriverDetailsClose}>
        <DialogTitle id="alert-dialog-title">{selectedDriver?.name} - {selectedDriver?.id} (From {allDatesInRange[allDatesInRange.length - 1]} to {allDatesInRange[0]})</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item xs={12}>
            <Typography variant="body1" margin="10px">
              Name: <b>{selectedDriver?.name}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" margin="10px">
              CSA Id: <b>{selectedDriver?.id}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" margin="10px">
              Total working hours: <b>{selectedDriver?.totalActualWorkingHours ?? 0}</b>
            </Typography>
          </Grid>
          {
            selectedDriver && (
              <DataGrid
                rows={[selectedDriver]}
                columns={
                  allDatesInRange.map((date) => (
                    {
                      field: `${date}-actualWorkingHours`,
                      headerName: date,
                      renderCell: (params: any) => {
                        const { value, row } = params;

                        if (!value) return '--';

                        return (
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <span>{value} Hours</span>
                            <div>
                              {row[`${date}-hasSegment1Breach`] && getBreach('S1')}
                              {row[`${date}-hasSegment2Breach`] && getBreach('S2')}
                              {row[`${date}-hasSegment3Breach`] && getBreach('S3')}
                              {row[`${date}-has12HrBreach`] && getBreach('12h')}
                            </div>
                          </div>
                        );
                      },
                      width: 200,
                      filterable: false,
                      sortable: false
                    }
                  ))
                }
                autoHeight
                hideFooter
              />
            )
          }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDriverDetailsClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  startDate: state.dashboardNR?.startDate,
  endDate: state.dashboardNR?.endDate,
  visibleRows: state.dashboardNR?.filteredRows,
  totalRowCount: state.dashboardNR?.totalRowCount,
  currentPage: state.dashboardNR?.currentPage,
  searchedText: state.dashboardNR?.searchedText,
  sortModel: state.dashboardNR?.sortModel,
  selectedBreachType: state.dashboardNR?.breachType,
  selectedShiftType: state.dashboardNR?.shiftType,
  companyId: state.profile?.company?.id
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: (companyId: number, startDate: string, endDate: string) => dispatch(refreshData(companyId, startDate, endDate)),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  onFilterByBreach: (text: string) => dispatch(filterByBreach(text)),
  onFilterByShift: (text: string) => dispatch(changeShiftType(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),
  onDateRangeChange: (startDate: string, endDate: string) => dispatch(changeDateRange(startDate, endDate)),
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNR);

import { PredefinedShiftCommentRes } from '../../proxy/proxy';
import {
  CLEAR_ALL_DATA,
  SHIFT_COMMENTS_LOAD_ALL,
  SHIFT_COMMENTS_RESET_PAGE,
  SHIFT_COMMENTS_GRID_SORT,
  SHIFT_COMMENTS_GRID_CURRENT_PAGE,
  SHIFT_COMMENTS_GRID_SEARCH_TEXT
} from '../actionTypes';

export interface StateType {
  allRows: Array<PredefinedShiftCommentRes>,
  filteredRows: Array<PredefinedShiftCommentRes>,
  searchedText: string,
  currentPage: number,
  sortModel: Array<any>
}

const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  currentPage: 0,
  sortModel: []
};

const filterRows = (allRows: Array<PredefinedShiftCommentRes> = [], searchText = '') => {
  const text = searchText.trim().toLowerCase();

  return text
    ? allRows.filter((row) => {
      const comment = row.comment?.toLowerCase() || '';
      return comment.indexOf(text) > -1;
    })
    : allRows;
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case SHIFT_COMMENTS_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.searchedText),
      };
    case SHIFT_COMMENTS_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case SHIFT_COMMENTS_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case SHIFT_COMMENTS_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case SHIFT_COMMENTS_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, action.data),
        currentPage: 0
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Grid, Card, CardHeader, CardContent, Divider, Tooltip, Hidden, Typography, IconButton, Toolbar } from '@mui/material';
import {
  UserCheck as UserCheckIcon,
  Edit as EditIcon
} from 'react-feather';
import GlobalSearch from '../GlobalSearch';
import FilterBy from '../FilterBy';
import Table from '../Table';
import MobileDataList from '../MobileDataList';
import { formatDateToLongDate, formatDateToCalendarTime, isDriver, isOwner, isManager, getJobSiteName } from '../../utils/common';
import LocalStorageService from '../../services/LocalStorageService';
import FormattedShift from '../../models/FormattedShift';
import MultiRowActionButton from '../MultiRowActionButton';
import EnumService from '../../services/EnumService';
import { EnumRes } from '../../proxy/proxy';

interface Props {
  shifts: Array<FormattedShift>,
  totalRowCount: number,
  currentPage: number;
  searchedText: string,
  sortModel: any,
  selectedWarning: string,
  onFilterByText: any,
  onFilterByWarning: any,
  filterByCol: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any,
  shiftStatus: number,
  selectionModel?: any,
  onSelectionModelChange?: any,
  selectableRows?: boolean,
  onBulkDelete?: any
}

const ShiftTable: React.FC<Props> = ({
  shifts = [],
  totalRowCount,
  currentPage,
  sortModel,
  searchedText,
  selectedWarning,
  filterByCol,
  shiftStatus,
  selectableRows = false,
  onFilterByText,
  onFilterByWarning,
  onPageChange,
  onSort,
  onChangeFilterByColumn,
  selectionModel,
  onSelectionModelChange = () => {},
  onBulkDelete = () => {},
}) => {
  const navigate = useNavigate();

  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();

  const [appUsageTypes, setAppUsageTypes] = React.useState<EnumRes[]>([]);

  const getAppUsageEnums = () => {
    EnumService.getEnums('appUsagePatterns').then(setAppUsageTypes);
  };

  useEffect(() => {
    getAppUsageEnums();
  }, []);

  const onRowClick = ({ id, extCompany }: any) => {
    if (isDriver(roles)) {
      if ((shiftStatus === 1 || shiftStatus === 2) && extCompany?.companyId) {
        navigate(`/employee/shift/${id}/submit`);
      } else {
        navigate(`/employee/shift/${id}`);
      }
    } else {
      navigate(`/app/shift/list/${id}`);
    }
  };

  const getDriverAcceptance = (userHasAccepted: boolean, withStyles = false) => {
    if (userHasAccepted === true) return 'Accepted';
    if (userHasAccepted === false) {
      if (withStyles) return <span style={{ fontWeight: 'bold', color: 'red', fontSize: '1rem' }}>Rejected</span>;
      return 'Rejected';
    }
    return 'Pending';
  };

  const getTimsheetStatusTooltip = (shift: FormattedShift) => {
    if (shift === null || shift.shiftNRs === null || shift.shiftNRs.length === 0) return 'No Timesheet submitted';
    if (shift.shiftNRs.length === 1) return '1 Timesheet submitted';
    return `${shift.shiftNRs.length} Timsheets submitted`;
  };

  const getAppUsagePatternTooltip = (shift: FormattedShift) => {
    if (appUsageTypes.find((type) => type.id === shift?.user?.appUsagePattern)?.id === null || appUsageTypes.find((type) => type.id === shift?.user?.appUsagePattern)?.id === undefined) return 'No status provided';
    if (appUsageTypes.find((type) => type.id === shift?.user?.appUsagePattern)?.id === 0) return 'The user will have the capability to access the trucksight mobile app to manage shifts and no sms notifications will be sent';
    if (appUsageTypes.find((type) => type.id === shift?.user?.appUsagePattern)?.id === 1) return 'The user will exclusively receive SMS notifications regarding shifts, they won`t have acess to the mobile application';
    return 'No status provided';
  };

  const columns = [
    ...(isDriver(roles) && shiftStatus === 1 && shifts.find((shift) => shift.isNew) ? [
      {
        field: 'isNew',
        renderHeader: () => <span />,
        type: 'boolean',
        width: 70,
        filterable: false,
        renderCell: ({ value }: any) => {
          if (value) {
            return <span style={{ backgroundColor: 'green', color: 'white', borderRadius: '5px', lineHeight: 1, padding: '5px', fontSize: '0.75rem' }}>New</span>;
          }
          return <span />;
        }
      }
    ] : []),
    ...(shiftStatus === 1 ? [
      {
        field: 'userHasAccepted',
        headerName: 'Driver Acceptance',
        type: 'boolean',
        flex: 1,
        filterable: false,
        renderCell: (params: any) => {
          const { value } = params;

          return <span>{getDriverAcceptance(value, true)}</span>;
        }
      }
    ] : []),
    ...((shiftStatus === 3) ? [
      {
        field: 'timesheetStatus',
        headerName: 'Timesheet Submitted?',
        flex: 1,
        filterable: false,
        renderCell: (params: any) => {
          const { value, row } = params;
          return (
            <Tooltip title={getTimsheetStatusTooltip(row)}>
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    ] : []),
    ...(isOwner(roles) || isManager(roles) ? [
      {
        field: 'userName',
        headerName: 'Driver',
        flex: 1,
        filterable: false,
        renderCell: (params: any) => {
          const { value, row } = params;
          return (
            <Tooltip title={value || row.userEmail || ''}>
              <span>{value || row.userEmail}</span>
            </Tooltip>
          );
        }
      },
      {
        field: 'userEmail',
        headerName: 'Driver Email',
        flex: 1,
        filterable: false,
        renderCell: (params: any) => {
          const { value } = params;
          return (
            <Tooltip title={value || ''}>
              <span>{value}</span>
            </Tooltip>
          );
        },
        hide: true
      },
      {
        field: 'userExtDriverId',
        headerName: 'Ext Driver Id',
        width: 150,
        filterable: false,
        renderCell: (params: any) => {
          const { value } = params;
          return (
            <Tooltip title={value || ''}>
              <span>
                {value}
              </span>
            </Tooltip>
          );
        }
      }
    ] : []),
    {
      field: 'vehicleRego',
      headerName: 'Vehicle',
      width: 150,
      filterable: false,
      renderCell: (params: any) => {
        const { value, row } = params;
        return (
          <Tooltip title={value || ''}>
            <span>
              {value || `${row.unlistedVehicleRego} (Not listed)`}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'stateCode',
      headerName: 'State',
      renderCell: ({ row }: {row: any}) => row?.jobsite?.address?.state?.value ?? '',
      width: 100
    },
    {
      field: 'appUsagePattern',
      headerName: 'appUsagePattern',
      renderCell: (params: any) => {
        const { value, row } = params;
        return (
          <Tooltip title={getAppUsagePatternTooltip(row)}>
            <span>
              {appUsageTypes.find((type) => type.id === row?.user?.appUsagePattern)?.value ?? ''}
            </span>
          </Tooltip>
        );
      },
      // renderCell: ({ row }: {row: any}) => appUsageTypes.find((type) => type.id === row?.user?.appUsagePattern)?.value ?? '',
      width: 100
    },
    {
      field: 'jobsiteName',
      headerName: 'Store/Jobsite',
      flex: 1,
      filterable: false,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value || ''}>
            <span>{value}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'scheduledStartUTC',
      headerName: 'Scheduled Start Time',
      flex: 1,
      type: 'date',
      filterable: false,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={formatDateToCalendarTime(value, true) || ''}>
            <span>
              {formatDateToLongDate(value, true)}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'scheduledEndUTC',
      headerName: 'Scheduled End Time',
      flex: 1,
      type: 'date',
      filterable: false,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={formatDateToCalendarTime(value, true) || ''}>
            <span>
              {value && formatDateToLongDate(value, true)}
            </span>
          </Tooltip>
        );
      }
    },
    ...(shiftStatus !== 1 ? [
      {
        field: 'startedOnUTC',
        headerName: 'Start Time',
        flex: 1,
        type: 'date',
        filterable: false,
        renderCell: (params: any) => {
          const { value } = params;
          return (
            <Tooltip title={formatDateToCalendarTime(value, true) || ''}>
              <span>
                {formatDateToLongDate(value, true)}
              </span>
            </Tooltip>
          );
        }
      }
    ] : []),
    ...(shiftStatus === 3 ? [
      {
        field: 'endedOnUTC',
        headerName: 'End Time',
        flex: 1,
        type: 'date',
        filterable: false,
        renderCell: (params: any) => {
          const { value } = params;
          return (
            <Tooltip title={formatDateToCalendarTime(value, true) || ''}>
              <span>
                {value && formatDateToLongDate(value, true)}
              </span>
            </Tooltip>
          );
        }
      },
    ] : []),
    {
      field: 'dock',
      headerName: 'Dock',
      flex: 1,
      filterable: false,
      hide: true
    },
    {
      field: 'routeId',
      headerName: 'Route ID',
      flex: 1,
      filterable: false,
      hide: true
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <>
            <IconButton data-auto-id="shiftDataGridEditRowButton" size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => (<span />)
    },
  ];

  const onSearchTextChange = (event: any) => {
    onFilterByText(event?.target?.value);
  };

  const onFilterByChange = (event: any) => {
    onFilterByWarning(event.target.value);
  };

  const filterOptions = [
    { value: 'all', text: 'Show All' },
    { value: 'breach', text: 'Breach' }
  ];

  const renderEachMobileCard = (row: FormattedShift) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={
          <UserCheckIcon />
        }
        action={
          (
            <>
              {
                isDriver(roles) && shiftStatus === 1 && row.isNew && (
                  <Typography variant="caption" sx={{ backgroundColor: 'green', color: 'white', borderRadius: '5px', lineHeight: 1, padding: '5px', fontSize: '0.75rem' }}>New</Typography>
                )
              }
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            </>
          )
        }
        title={row.userName || row.userEmail}
        subheader={row.shiftStatus}
        onClick={() => onRowClick(row)}
      />
      <Divider />
      <CardContent>
        <Grid container>
          {
            shiftStatus === 3 && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Timesheet Submitted?:&nbsp;</b>
                  {row.timesheetStatus}
                </Typography>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Driver Acceptance:&nbsp;</b>
              {getDriverAcceptance(row.userHasAccepted, true)}
            </Typography>
          </Grid>
          {
            row.userExtDriverId && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Ext Driver Id:</b> {row.userExtDriverId}
                </Typography>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Vehicle:</b> {row.vehicleRego || `${row.unlistedVehicleRego} (Not listed)`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Store/Jobsite:</b> {getJobSiteName(row?.jobsite)}
            </Typography>
          </Grid>
          {
            row.scheduledStartUTC && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Scheduled Start:</b> {formatDateToLongDate(row.scheduledStartUTC, true)}
                </Typography>
              </Grid>
            )
          }
          {
            row.scheduledEndUTC && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Scheduled End:</b> {formatDateToLongDate(row.scheduledEndUTC, true)}
                </Typography>
              </Grid>
            )
          }
          {
            row.startedOnUTC && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Started:</b> {formatDateToLongDate(row.startedOnUTC, true)}
                </Typography>
              </Grid>
            )
          }
          {
            row.endedOnUTC && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Ended:</b> {formatDateToLongDate(row.endedOnUTC, true)}
                </Typography>
              </Grid>
            )
          }
          {
            (row.startOdometerKms || row.startOdometerKms === 0) && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Start Odometer:</b> {row.startOdometerKms} kms
                </Typography>
              </Grid>
            )
          }
          {
            (row.endOdometerKms || row.endOdometerKms === 0) && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>End Odometer:</b> {row.endOdometerKms} kms
                </Typography>
              </Grid>
            )
          }
        </Grid>
        {
          row.breach?.type && (
            <Grid item xs={12} sx={{ paddingTop: 2 }}>
              <Alert severity={row.breach.type}>{row.breach.message}</Alert>
            </Grid>
          )
        }
      </CardContent>
    </Card>
  );

  return (
    <>
      <Hidden mdDown>
        <Toolbar style={{ justifyContent: 'flex-end' }}>
          {
            selectableRows && (
              <MultiRowActionButton
                selectedRowsCount={selectionModel?.length ?? 0}
                options={
                  [
                    {
                      label: 'Delete',
                      onClick: () => onBulkDelete(selectionModel)
                    }
                  ]
                }
                disabled={!selectionModel?.length}
              />
            )
          }
          <div style={{ flex: '1' }} />
          {
            shiftStatus === 2 && (
              <FilterBy options={filterOptions} value={selectedWarning} onChange={onFilterByChange} width="225px" />
            )
          }
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} width="400px" />
        </Toolbar>
        <div data-auto-id="shiftDataGrid">
          <Table
            rows={shifts}
            columns={columns}
            onRowDoubleClick={({ row }) => onRowClick(row)}
            totalRows={totalRowCount}
            page={currentPage}
            checkboxSelection={selectableRows}
            onPageChange={onPageChange}
            sortModel={sortModel}
            onSortModelChange={onSort}
            getRowClassName={(params: any) => params?.row.breach?.type && 'error'}
            selectionModel={selectionModel}
            onSelectionModelChange={(selectedIds) => onSelectionModelChange(shifts, selectedIds)}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        <Toolbar sx={{ flexDirection: 'column' }}>
          {
            shiftStatus === 2 && (
              <div style={{ width: '100%', padding: '5px 0' }}>
                <FilterBy options={filterOptions} value={selectedWarning} onChange={onFilterByChange} />
              </div>
            )
          }
          <div style={{ width: '100%', padding: '5px 0' }}>
            <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} />
          </div>
        </Toolbar>
        <MobileDataList
          rows={shifts}
          renderEachRow={renderEachMobileCard}
          totalRows={totalRowCount}
          page={currentPage}
          onPageChange={onPageChange}
        />
      </Hidden>
    </>
  );
};

export default ShiftTable;

import * as React from 'react';
import { Typography } from '@mui/material';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import AppConstants from '../../constants/AppConstants';
import Map from './Map';

interface Props {
  marks: Array<any>
}

const GoogleMaps: React.FC<Props> = ({
  marks
}) => {
  const render = (status: Status) => (<Typography variant="h4">{status}</Typography>);

  return (
    <div style={{ position: 'relative', height: '85vh' }}>
      <Wrapper apiKey={AppConstants.GOOGLE_MAPS_API_KEY} render={render}>
        <Map
          center={{ lat: -37.840935, lng: 144.946457 }}
          zoom={6}
          style={{ flexGrow: '1', height: '100%' }}
          markers={marks}
        />
      </Wrapper>
    </div>
  );
};

export default GoogleMaps;

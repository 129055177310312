import React from 'react';
import { connect } from 'react-redux';
import { Box, Alert, Snackbar } from '@mui/material';
import { hideAlert as hideGlobalAlert } from '../store/actions/alertActions';
import { RootState } from '../store/store';

interface Props {
  messages: Array<any>,
  hideAlert: Function
}

const AppSnackBar: React.FC<Props> = ({
  messages,
  hideAlert
}) => {
  if (!messages?.length) return null;

  return (
    <>
      {
        messages.map((message) => (
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} key={message.id} open autoHideDuration={message.severity !== 'error' ? 6000 : null} onClose={() => hideAlert(message.id)}>
            <Alert sx={{ marginBottom: 1 }} onClose={() => hideAlert(message.id)} severity={message.severity || 'info'}>
              {message.message}
            </Alert>
          </Snackbar>
        ))
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  messages: state.alert.messages,
});

const mapDispatchToProps = (dispatch: any) => ({
  hideAlert: (id: string) => dispatch(hideGlobalAlert(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSnackBar);

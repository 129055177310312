import React, { useEffect, useState } from 'react';
import { Paper, Typography, Divider } from '@mui/material';
import moment from 'moment';
import { convertToLocalDate, getDateDifferenceInMS } from '../utils/common';

interface Props {
  title: string,
  startDateTime: string,
  endDateTime?: string,
  children?: any
}

const Timer: React.FC<Props> = ({
  startDateTime,
  endDateTime,
  title,
  children
}) => {
  const [timerConfig, setTimerConfig] = useState<{
    prefix: string,
    months: string,
    days: string,
    hours: string,
    minutes: string,
    seconds: string
  }>({
    prefix: '',
    months: '00',
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [timerInterval, setTimerInterval] = useState<any>(null);

  const formatTimeDiff = (a: any, b: any) => {
    const diffInMS = getDateDifferenceInMS(a, b, true);
    const diff = moment.duration(diffInMS);
    const months = Math.abs(diff.months());
    const days = Math.abs(diff.days());
    const hours = Math.abs(diff.hours());
    const minutes = Math.abs(diff.minutes());
    const seconds = Math.abs(diff.seconds());
    setTimerConfig({
      prefix: diffInMS < 0 ? '-' : '',
      months: months.toString().length === 1 ? `0${months.toString()}` : months.toString(),
      days: days.toString().length === 1 ? `0${days.toString()}` : days.toString(),
      hours: hours.toString().length === 1 ? `0${hours.toString()}` : hours.toString(),
      minutes: minutes.toString().length === 1 ? `0${minutes.toString()}` : minutes.toString(),
      seconds: seconds.toString().length === 1 ? `0${seconds.toString()}` : seconds.toString()
    });
  };

  useEffect(() => {
    clearInterval(timerInterval);
    if (!startDateTime) {
      return;
    }

    if (endDateTime) {
      formatTimeDiff(startDateTime, endDateTime);
    } else {
      setTimerInterval(setInterval(() => {
        formatTimeDiff(startDateTime, convertToLocalDate(new Date().toISOString()));
      }, 1000));
    }
  }, [startDateTime, endDateTime]);

  return (
    <Paper variant="outlined" sx={{ margin: '10px', padding: '10px', textAlign: 'center' }}>
      <Typography variant="h4" margin="10px">{ title }</Typography>
      <Divider />
      <Typography color="text.secondary" sx={{ fontSize: '3rem' }}>
        {
          timerConfig?.prefix && (
            <span style={{ fontSize: '4rem' }}>{timerConfig?.prefix}</span>
          )
        }
        {
          Number(timerConfig?.months) !== 0 && (
            <>
              <span>{timerConfig.months}</span>
              <span>:</span>
            </>
          )
        }
        {
          Number(timerConfig?.days) !== 0 && (
            <>
              <span>{timerConfig.days}</span>
              <span>:</span>
            </>
          )
        }
        <span>{timerConfig?.hours}</span>
        <span>:</span>
        <span>{timerConfig?.minutes}</span>
        <span>:</span>
        <span>{timerConfig?.seconds}</span>
      </Typography>
      {children}
    </Paper>
  );
};

export default Timer;

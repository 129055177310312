import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert, Button, IconButton, InputAdornment, Box, Container, Card, CardHeader, Divider, CardContent, TextField, Typography, Grid, CardActions, Tooltip, ListItemText, MenuItem, Checkbox, Tab, Tabs, Hidden } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BackIcon from '@mui/icons-material/ChevronLeft';
import PhoneIcon from '@mui/icons-material/PhoneIphone';
import InfoIcon from '@mui/icons-material/InfoRounded';
import MailIcon from '@mui/icons-material/Mail';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, getIn } from 'formik';
import StaffService from '../services/StaffService';
import EnumService from '../services/EnumService';
import LocalStorageService from '../services/LocalStorageService';
import ConfirmButton from '../components/ConfirmButton';
import SubmitFormButton from '../components/SubmitFormButton';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatInputDate, formatISODate, isManager, isOwner, isAccountant, sortByKey } from '../utils/common';
import FormattedStaff from '../models/FormattedStaff';
import TabPanel from '../components/TabPanel';
import StaffPayRates from '../components/staff/StaffPayRates';
import UIStrings from '../UIStrings';
import { EnumRes, PayRateRes, StaffReq } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const StaffDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const staffId = !Number.isNaN(Number(id)) ? Number(id) : 0;
  const navigate = useNavigate();

  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();
  const [staffData, setStaffData] = useState<FormattedStaff | null>(null);
  const [payRatesData, setPayRatesData] = useState<PayRateRes>();
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);
  const [allRoles, setAllRoles] = useState<Array<string>>([]);
  const [dlClasses, setDlClasses] = useState<Array<any>>([]);
  const [employmentTypes, setEmploymentTypes] = useState<Array<any>>([]);
  const [appUsagePatterns, setAppUsagePatterns] = useState<Array<any>>([]);
  const [tab, setTab] = useState<number>(0);

  const showPayRates = isOwner(roles) || isAccountant(roles);
  const showSensitiveInfo = isOwner(roles);
  const showAddress = isOwner(roles) || isManager(roles);
  const isReadOnly = !(isOwner(roles) || isManager(roles));

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
      ...(staffId ? [
        StaffService.getStaffById(staffId),
        ...(showPayRates ? [StaffService.getStaffPayRatesById(staffId)] : [])
      ] : [])
    ];

    showBusyIndicator();
    Promise.all(promises)
      .then((result) => {
        setAllStates(sortByKey(result[0].states, 'value'));
        setAllRoles(result[0].staffRoles?.sort());
        setDlClasses(sortByKey(result[0].drivingLicenseClass, 'value'));
        setEmploymentTypes(sortByKey(result[0].employmentTypes, 'value'));
        setAppUsagePatterns(sortByKey(result[0].appUsagePatterns, 'value'));
        setStaffData(staffId ? result[1] : {});
        setPayRatesData(staffId ? (result[2] || {}) : {});
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    clearAllAlerts();
    refreshData();
  }, []);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload: StaffReq = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      dateOfBirthUtc: formatISODate(values.dateOfBirthUtc),
      role: values.role,
      employmentType: Number(values.employmentType),
      appUsagePattern: Number(values.appUsagePattern),
      emergencyContactFullName: values.emergencyContactFullName,
      emergencyContactPhone: values.emergencyContactPhone,
      address: {
        street: values.street || null,
        suburb: values.suburb || null,
        areaCode: values.areaCode?.toString()?.padStart(4, '0'),
        stateId: Number(values.stateId)
      },
      extDriverId: values.extDriverId,
      abn: values.abn,

      drivingLicenseNumber: values.drivingLicenseNumber,
      appUserLicenseClassMapReq: {
        drivingLicenseClasses: values.drivingLicenseClasses
      },
      drivingLicenseIssuedByStateId: values.drivingLicenseIssuedByStateId ? Number(values.drivingLicenseIssuedByStateId) : null,
      drivingLicenseRestriction: values.drivingLicenseRestriction,
      drivingLicenseFirstIssuedOnUtc: formatISODate(values.drivingLicenseFirstIssuedOnUtc),
      drivingLicenseExpiresOnUTC: formatISODate(values.drivingLicenseExpiresOnUTC),
      rsaCertificateNumber: values.rsaCertificateNumber,
      rsaExpiryDateUtc: formatISODate(values.rsaExpiryDateUtc),
      rsatCertificateNumber: values.rsatCertificateNumber,
      rsatExpiryDateUtc: formatISODate(values.rsatExpiryDateUtc),
      drivingRecordEmissionDateUtc: formatISODate(values.drivingRecordEmissionDateUtc),
      australianFederalPoliceCheckEmissionDateUtc: formatISODate(values.australianFederalPoliceCheckEmissionDateUtc)
    };

    const promise = staffId ? StaffService.updateStaff(staffId, payload) : StaffService.inviteStaff(payload);

    promise
      .then((response: any) => {
        if (response?.abn !== staffData?.abn) {
          StaffService.checkGST(staffId);
        }
        if (staffId) {
          refreshData();
        } else {
          navigate(`/app/staff/${response.staffUser.id}`, { replace: true });
        }
        showAlert(formatSuccessMessage(staffId ? 'Staff updated successfully.' : 'Invitation sent successfully.'));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    StaffService.deleteStaff(staffId)
      .then(() => {
        showAlert(formatSuccessMessage('Staff deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  const checkGSTStatus = () => {
    showBusyIndicator();
    clearAllAlerts();
    StaffService.checkGST(staffId)
      .then((staff: FormattedStaff) => {
        showAlert({
          message: 'GST Registration Status updated successfully',
          severity: 'success'
        });
        setStaffData(null);
        setStaffData(staff);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Helmet>
        {
          !!staffId && (
            <title>Staff Details | {AppConstants.APP_NAME}</title>
          )
        }
        {
          !staffId && (
            <title>Invite Staff | {AppConstants.APP_NAME}</title>
          )
        }
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            {
              !!staffId && (
                <CardHeader
                  id="pageHeader"
                  avatar={
                    (
                      <IconButton id="backBtn" aria-label="back" size="small" onClick={back}>
                        <BackIcon />
                      </IconButton>
                    )
                  }
                  title={UIStrings.staffDetails.pageHeader}
                  action={
                    !isReadOnly && (
                      <ConfirmButton
                        id="deleteStaffBtn"
                        buttonIcon={<DeleteIcon />}
                        isIconButton
                        dialogTitle="Are you sure?"
                        showCancelButton
                        message="Staff will be deleted permanently."
                        buttonColor="secondary"
                        onConfirm={handleDeleteClick}
                      />
                    )
                  }
                />
              )
            }
            {
              !staffId && (
                <CardHeader
                  id="pageHeader"
                  title={UIStrings.staffDetails.inviteStaff}
                  avatar={
                    (
                      <IconButton id="backBtn" aria-label="back" size="small" onClick={back}>
                        <BackIcon />
                      </IconButton>
                    )
                  }
                />
              )
            }
            <Divider />
            <CardContent id="pageContent">
              {
                staffData?.alerts?.map((alert) => (
                  <Alert sx={{ marginBottom: 1 }} key={alert.message} severity={alert.type}>{alert.message}</Alert>
                ))
              }
              {
                !!staffId && showPayRates && (
                  <>
                    <Hidden mdUp>
                      <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(e, val) => setTab(val)}
                      >
                        <Tab label="Details" />
                        <Tab label="Pay Rates" />
                      </Tabs>
                    </Hidden>
                    <Hidden mdDown>
                      <Tabs
                        data-auto-id="allShiftTabs"
                        value={tab}
                        onChange={(e, val) => setTab(val)}
                      >
                        <Tab label="Details" />
                        <Tab label="Pay Rates" />
                      </Tabs>
                    </Hidden>
                    <Divider />
                  </>
                )
              }
              {
                staffData && (
                  <TabPanel value={tab} index={0}>
                    <Formik
                      initialValues={{
                        firstName: staffData?.firstName || '',
                        middleName: staffData?.middleName || '',
                        lastName: staffData?.lastName || '',
                        phoneNumber: staffData?.phoneNumber || '',
                        emergencyContactFullName: staffData?.emergencyContactFullName || '',
                        emergencyContactPhone: staffData?.emergencyContactPhone || '',
                        email: staffData?.email || '',
                        dateOfBirthUtc: formatInputDate(staffData?.dateOfBirthUtc),
                        role: staffData?.role || AppConstants.USER_ROLES.DRIVER,
                        employmentType: staffData?.employmentType || 4,
                        street: staffData?.address?.street || '',
                        suburb: staffData?.address?.suburb || '',
                        areaCode: staffData?.address?.areaCode || '',
                        stateId: staffData?.address?.stateId || '',
                        extDriverId: staffData?.extDriverId || '',
                        appUsagePattern: staffData?.appUsagePattern || 0,
                        drivingLicenseNumber: staffData?.drivingLicenseNumber || '',
                        drivingLicenseClasses: staffData?.drivingLicenseClasses || [],
                        drivingLicenseIssuedByStateId: staffData?.drivingLicenseIssuedByStateId || '',
                        drivingLicenseRestriction: staffData?.drivingLicenseRestriction || '',
                        drivingLicenseFirstIssuedOnUtc: formatInputDate(staffData?.drivingLicenseFirstIssuedOnUtc),
                        drivingLicenseExpiresOnUTC: formatInputDate(staffData?.drivingLicenseExpiresOnUTC),
                        rsaCertificateNumber: staffData?.rsaCertificateNumber || '',
                        rsaExpiryDateUtc: formatInputDate(staffData?.rsaExpiryDateUtc),
                        rsatCertificateNumber: staffData?.rsatCertificateNumber || '',
                        rsatExpiryDateUtc: formatInputDate(staffData?.rsatExpiryDateUtc),
                        drivingRecordEmissionDateUtc: formatInputDate(staffData?.drivingRecordEmissionDateUtc),
                        australianFederalPoliceCheckEmissionDateUtc: formatInputDate(staffData?.australianFederalPoliceCheckEmissionDateUtc),

                        abn: staffData?.abn || '',
                        isRegisteredForGST: staffData?.isRegisteredForGST
                      }}
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().max(255).required('First name is required'),
                        middleName: Yup.string().max(255),
                        lastName: Yup.string().max(255).required('Last name is required'),
                        phoneNumber: Yup.string().matches(AppConstants.MOBILE_NO_REGEXP, 'Phone number is not valid').required('Phone number is required'),
                        email: Yup.string().email('Email is not valid').required('Email is required'),
                        employmentType: Yup.number().required('Employment Type'),
                        street: Yup.string().max(255),
                        suburb: Yup.string().max(255),
                        areaCode: Yup.number().min(0, 'Post Code should be between 0000 and 9999').max(9999, 'Post Code should be between 0000 and 9999').required('Post Code is required'),
                        stateId: Yup.number().min(1).required('State is required'),
                        extDriverId: Yup.string().max(255),
                        emergencyContactFullName: Yup.string().max(255),
                        emergencyContactPhone: Yup.string().matches(AppConstants.MOBILE_NO_REGEXP, 'Phone number is not valid'),
                        rsaExpiryDateUtc: Yup.date(),
                        drivingLicenseNumber: Yup.string(),
                        drivingLicenseClasses: Yup.array(),
                        drivingLicenseIssuedByStateId: Yup.number(),
                        drivingLicenseRestriction: Yup.string(),
                        drivingLicenseFirstIssuedOnUtc: Yup.date(),
                        drivingLicenseExpiresOnUTC: Yup.date(),
                        rsaCertificateNumber: Yup.string(),
                        dateOfBirthUtc: Yup.date(),
                        rsatCertificateNumber: Yup.string(),
                        rsatExpiryDateUtc: Yup.date(),
                        drivingRecordEmissionDateUtc: Yup.date(),
                        australianFederalPoliceCheckEmissionDateUtc: Yup.date(),

                        abn: Yup.string().matches(/^(\d *?){11}$/gm, 'Incorrect format').max(255),
                        isRegisteredForGST: Yup.boolean()
                      })}
                      onSubmit={handleFormSubmit}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        isValid,
                        dirty
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  id="txtFirstName"
                                  error={Boolean(touched.firstName && errors.firstName)}
                                  fullWidth
                                  helperText={touched.firstName && errors.firstName}
                                  label="First Name"
                                  margin="normal"
                                  name="firstName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.firstName}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  required
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  id="txtMiddleName"
                                  error={Boolean(touched.middleName && errors.middleName)}
                                  fullWidth
                                  helperText={touched.middleName && errors.middleName}
                                  label="Middle Name"
                                  margin="normal"
                                  name="middleName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.middleName}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  id="txtLastName"
                                  error={Boolean(touched.lastName && errors.lastName)}
                                  fullWidth
                                  helperText={touched.lastName && errors.lastName}
                                  label="Last Name"
                                  margin="normal"
                                  name="lastName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lastName}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  required
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  id="txtEmail"
                                  error={Boolean(touched.email && errors.email)}
                                  fullWidth
                                  helperText={touched.email && errors.email}
                                  label="Email"
                                  margin="normal"
                                  name="email"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  type="email"
                                  required
                                  disabled={Boolean(staffId) || isReadOnly}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <MailIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  id="txtPhoneNumber"
                                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                  fullWidth
                                  helperText={touched.phoneNumber && errors.phoneNumber}
                                  label="Mobile Number"
                                  margin="normal"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.phoneNumber}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  required
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                              >
                                <TextField
                                  id="txtDateOfBirth"
                                  error={Boolean(touched.dateOfBirthUtc && errors.dateOfBirthUtc)}
                                  fullWidth
                                  helperText={touched.dateOfBirthUtc && errors.dateOfBirthUtc}
                                  label="Date Of Birth"
                                  margin="normal"
                                  name="dateOfBirthUtc"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.dateOfBirthUtc}
                                  variant="outlined"
                                  type="date"
                                  InputLabelProps={{ shrink: true }}
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  id="drpRole"
                                  fullWidth
                                  label="Role"
                                  name="role"
                                  margin="normal"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  value={values.role}
                                  variant="outlined"
                                  error={Boolean(touched.role && errors.role)}
                                  helperText={touched.role && errors.role}
                                  disabled={isManager(roles) || isReadOnly || staffData.emailConfirmed}
                                >
                                  <MenuItem aria-label="None" value="">
                                    <ListItemText primary="&nbsp;" />
                                  </MenuItem>
                                  {allRoles.map((option) => (
                                    <MenuItem key={option} value={option} disabled={option === AppConstants.USER_ROLES.OWNER}>
                                      <ListItemText primary={option} />
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  id="employmentType"
                                  fullWidth
                                  label="Employment Type"
                                  name="employmentType"
                                  margin="normal"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  value={values.employmentType}
                                  variant="outlined"
                                  error={Boolean(touched.employmentType && errors.employmentType)}
                                  helperText={touched.employmentType && errors.employmentType}
                                  disabled={isReadOnly}
                                >
                                  <MenuItem aria-label="None" value="">
                                    <ListItemText primary="&nbsp;" />
                                  </MenuItem>
                                  {employmentTypes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      <ListItemText primary={option.value} />
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  id="txtExtDriverId"
                                  error={Boolean(touched.extDriverId && errors.extDriverId)}
                                  fullWidth
                                  helperText={touched.extDriverId && errors.extDriverId}
                                  label="Ext Driver Id"
                                  margin="normal"
                                  name="extDriverId"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.extDriverId}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <TextField
                                  id="txtABN"
                                  error={Boolean(touched.abn && errors.abn)}
                                  fullWidth
                                  helperText={touched.abn && errors.abn}
                                  label="ABN"
                                  margin="normal"
                                  name="abn"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.abn}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              {
                                !!staffId && (
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <TextField
                                      id="drpIsRegisteredForGST"
                                      fullWidth
                                      label="Is Registered For GST"
                                      name="isRegisteredForGST"
                                      margin="normal"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                      select
                                      InputLabelProps={{ shrink: true }}
                                      value={values.isRegisteredForGST}
                                      variant="outlined"
                                      error={Boolean(touched.isRegisteredForGST && errors.isRegisteredForGST)}
                                      helperText={touched.isRegisteredForGST && errors.isRegisteredForGST}
                                      disabled
                                    >
                                      <MenuItem value="true">
                                        <ListItemText primary="Yes" />
                                      </MenuItem>
                                      <MenuItem value="false">
                                        <ListItemText primary="No" />
                                      </MenuItem>
                                    </TextField>
                                    {
                                      !isReadOnly && (
                                        <Tooltip title="Check if registered for GST and update status">
                                          {
                                            staffData.abn ? (
                                              <Button
                                                color="primary"
                                                onClick={checkGSTStatus}
                                                sx={{ marginLeft: 2 }}
                                                variant="outlined"
                                                disabled={dirty}
                                              >
                                                Check
                                              </Button>
                                            ) : (
                                              <ConfirmButton
                                                buttonLabel="Check"
                                                buttonVariant="outlined"
                                                okButtonLabel="Ok"
                                                data-auto-id="rejectConfirmButton"
                                                dialogTitle="Check GST"
                                                showCancelButton={false}
                                                message="ABN is required."
                                                sx={{ marginLeft: 2 }}
                                                disabled={dirty}
                                              />
                                            )
                                          }
                                        </Tooltip>
                                      )
                                    }
                                  </Grid>
                                )
                              }
                              <Grid
                                item
                                md={5}
                                xs={11}
                              >
                                <TextField
                                  id="appUsagePattern"
                                  fullWidth
                                  label="App Usage Pattern"
                                  name="appUsagePattern"
                                  margin="normal"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  value={values.appUsagePattern}
                                  variant="outlined"
                                  error={Boolean(touched.appUsagePattern && errors.appUsagePattern)}
                                  helperText={touched.appUsagePattern && errors.appUsagePattern}
                                  disabled={isReadOnly}
                                >
                                  <MenuItem aria-label="None" value="">
                                    <ListItemText primary="&nbsp;" />
                                  </MenuItem>
                                  {appUsagePatterns.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      <ListItemText primary={option.value} />
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid
                                item
                                md={1}
                                xs={1}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Tooltip title="WebMobileApp : The user will have the capability to access the trucksight mobile app to manage shifts and no sms notifications will be sent.    SmsOnly : The user will exclusively receive SMS notifications regarding shifts, they won`t have acess to the mobile application">
                                    <InfoIcon className="success-icon" />
                                  </Tooltip>
                                </Box>
                              </Grid>
                              {
                                showSensitiveInfo && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                    >
                                      <Typography variant="h5" noWrap>
                                        Emergency Contact
                                      </Typography>
                                      <Divider />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtEmergencyContactFullName"
                                        error={Boolean(touched.emergencyContactFullName && errors.emergencyContactFullName)}
                                        fullWidth
                                        helperText={touched.emergencyContactFullName && errors.emergencyContactFullName}
                                        label="Emergency Contact (Full Name)"
                                        margin="normal"
                                        name="emergencyContactFullName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.emergencyContactFullName}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtEmergencyContactPhone"
                                        error={Boolean(touched.emergencyContactPhone && errors.emergencyContactPhone)}
                                        fullWidth
                                        helperText={touched.emergencyContactPhone && errors.emergencyContactPhone}
                                        label="Emergency Contact Phone Number"
                                        margin="normal"
                                        name="emergencyContactPhone"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.emergencyContactPhone}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <PhoneIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                    >
                                      <Typography variant="h5" noWrap>
                                        Licensing
                                      </Typography>
                                      <Divider />
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtDrivingLicenseNumber"
                                        error={Boolean(touched.drivingLicenseNumber && errors.drivingLicenseNumber)}
                                        fullWidth
                                        helperText={touched.drivingLicenseNumber && errors.drivingLicenseNumber}
                                        label="Driving License Number"
                                        margin="normal"
                                        name="drivingLicenseNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drivingLicenseNumber}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      xs={12}
                                    >
                                      <TextField
                                        id="drpDrivingLicenseClasses"
                                        fullWidth
                                        label="Driving License Classes"
                                        name="drivingLicenseClasses"
                                        margin="normal"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        select
                                        SelectProps={{
                                          multiple: true,
                                          renderValue: (selected: any) => selected.map((x: any) => dlClasses.find((cls: any) => cls.id === x)?.value).join(', ')
                                        }}
                                        value={values.drivingLicenseClasses}
                                        variant="outlined"
                                        error={Boolean(touched.drivingLicenseClasses && errors.drivingLicenseClasses)}
                                        helperText={touched.drivingLicenseClasses && errors.drivingLicenseClasses}
                                      >
                                        {dlClasses.map((option) => (
                                          <MenuItem key={option.id} value={option.id}>
                                            <Checkbox checked={getIn(values, 'drivingLicenseClasses').indexOf(option.id) > -1} />
                                            <ListItemText primary={option.value} />
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      xs={12}
                                    >
                                      <TextField
                                        id="drpDrivingLicenseIssuedByStateId"
                                        fullWidth
                                        label="Driving License Issued By State"
                                        name="drivingLicenseIssuedByStateId"
                                        margin="normal"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        value={values.drivingLicenseIssuedByStateId}
                                        variant="outlined"
                                        error={Boolean(touched.drivingLicenseIssuedByStateId && errors.drivingLicenseIssuedByStateId)}
                                        helperText={touched.drivingLicenseIssuedByStateId && errors.drivingLicenseIssuedByStateId}
                                      >
                                        <MenuItem aria-label="None" value="">
                                          <ListItemText primary="&nbsp;" />
                                        </MenuItem>
                                        {allStates.map((option) => (
                                          <MenuItem key={option.id} value={option.id}>
                                            <ListItemText primary={option.value} />
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtDrivingLicenseExpiresOn"
                                        error={Boolean(touched.drivingLicenseExpiresOnUTC && errors.drivingLicenseExpiresOnUTC)}
                                        fullWidth
                                        helperText={touched.drivingLicenseExpiresOnUTC && errors.drivingLicenseExpiresOnUTC}
                                        label="Driving License Expires On"
                                        margin="normal"
                                        name="drivingLicenseExpiresOnUTC"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drivingLicenseExpiresOnUTC}
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtDrivingLicenseRestriction"
                                        error={Boolean(touched.drivingLicenseRestriction && errors.drivingLicenseRestriction)}
                                        fullWidth
                                        helperText={touched.drivingLicenseRestriction && errors.drivingLicenseRestriction}
                                        label="Driving License Restriction"
                                        margin="normal"
                                        name="drivingLicenseRestriction"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drivingLicenseRestriction}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtDrivingLicenseFirstIssuedOn"
                                        error={Boolean(touched.drivingLicenseFirstIssuedOnUtc && errors.drivingLicenseFirstIssuedOnUtc)}
                                        fullWidth
                                        helperText={touched.drivingLicenseFirstIssuedOnUtc && errors.drivingLicenseFirstIssuedOnUtc}
                                        label="Driving License First Issued On"
                                        margin="normal"
                                        name="drivingLicenseFirstIssuedOnUtc"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drivingLicenseFirstIssuedOnUtc}
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtRsaCertificateNumber"
                                        error={Boolean(touched.rsaCertificateNumber && errors.rsaCertificateNumber)}
                                        fullWidth
                                        helperText={touched.rsaCertificateNumber && errors.rsaCertificateNumber}
                                        label="RSA Certificate Number"
                                        margin="normal"
                                        name="rsaCertificateNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.rsaCertificateNumber}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtRsaExpiryDate"
                                        error={Boolean(touched.rsaExpiryDateUtc && errors.rsaExpiryDateUtc)}
                                        fullWidth
                                        helperText={touched.rsaExpiryDateUtc && errors.rsaExpiryDateUtc}
                                        label="RSA Expiry Date"
                                        margin="normal"
                                        name="rsaExpiryDateUtc"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.rsaExpiryDateUtc}
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtRsatCertificateNumber"
                                        error={Boolean(touched.rsatCertificateNumber && errors.rsatCertificateNumber)}
                                        fullWidth
                                        helperText={touched.rsatCertificateNumber && errors.rsatCertificateNumber}
                                        label="RSAT Certificate Number (NSW only)"
                                        margin="normal"
                                        name="rsatCertificateNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.rsatCertificateNumber}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtRsatExpiryDateUtc"
                                        error={Boolean(touched.rsatExpiryDateUtc && errors.rsatExpiryDateUtc)}
                                        fullWidth
                                        helperText={touched.rsatExpiryDateUtc && errors.rsatExpiryDateUtc}
                                        label="RSAT Expiry Date (NSW only)"
                                        margin="normal"
                                        name="rsatExpiryDateUtc"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.rsatExpiryDateUtc}
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtDrivingRecordEmissionDateUtc"
                                        error={Boolean(touched.drivingRecordEmissionDateUtc && errors.drivingRecordEmissionDateUtc)}
                                        fullWidth
                                        helperText={touched.drivingRecordEmissionDateUtc && errors.drivingRecordEmissionDateUtc}
                                        label="Emission Date - Driving Record"
                                        margin="normal"
                                        name="drivingRecordEmissionDateUtc"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drivingRecordEmissionDateUtc}
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtAustralianFederalPoliceCheckEmissionDateUtc"
                                        error={Boolean(touched.australianFederalPoliceCheckEmissionDateUtc && errors.australianFederalPoliceCheckEmissionDateUtc)}
                                        fullWidth
                                        helperText={touched.australianFederalPoliceCheckEmissionDateUtc && errors.australianFederalPoliceCheckEmissionDateUtc}
                                        label="Emission Date - AFP ( Australian Federal Police Check)"
                                        margin="normal"
                                        name="australianFederalPoliceCheckEmissionDateUtc"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.australianFederalPoliceCheckEmissionDateUtc}
                                        variant="outlined"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                  </>
                                )
                              }
                              {
                                showAddress && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                    >
                                      <Typography variant="h5" noWrap>
                                        Address
                                      </Typography>
                                      <Divider />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtStreet"
                                        error={Boolean(touched.street && errors.street)}
                                        fullWidth
                                        helperText={touched.street && errors.street}
                                        label="Street"
                                        margin="normal"
                                        name="street"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.street}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtSuburb"
                                        error={Boolean(touched.suburb && errors.suburb)}
                                        fullWidth
                                        helperText={touched.suburb && errors.suburb}
                                        label="Suburb"
                                        margin="normal"
                                        name="suburb"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.suburb}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="txtPostCode"
                                        error={Boolean(touched.areaCode && errors.areaCode)}
                                        fullWidth
                                        helperText={touched.areaCode && errors.areaCode}
                                        label="Post Code"
                                        margin="normal"
                                        name="areaCode"
                                        type="number"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.areaCode}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                    >
                                      <TextField
                                        id="drpState"
                                        fullWidth
                                        label="State"
                                        name="stateId"
                                        margin="normal"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        value={values.stateId}
                                        variant="outlined"
                                        error={Boolean(touched.stateId && errors.stateId)}
                                        helperText={touched.stateId && errors.stateId}
                                      >
                                        <MenuItem aria-label="None" value="">
                                          <ListItemText primary="&nbsp;" />
                                        </MenuItem>
                                        {allStates.map((option) => (
                                          <MenuItem key={option.id} value={option.id}>
                                            <ListItemText primary={option.value} />
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                  </>
                                )
                              }
                            </Grid>
                          </CardContent>
                          <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined">
                              Cancel
                            </Button>
                            <SubmitFormButton
                              buttonLabel={staffId ? 'Save' : 'Invite'}
                              dirty={dirty}
                              isValid={isValid}
                              editMode={Boolean(staffId)}
                              onConfirm={handleSubmit}
                            />
                          </CardActions>
                        </form>
                      )}
                    </Formik>
                  </TabPanel>
                )
              }
              {
                showPayRates && !!staffId && (
                  <TabPanel value={tab} index={1}>
                    {
                      payRatesData && (
                        <StaffPayRates
                          ids={[staffId]}
                          payRates={payRatesData ?? {}}
                          isReadOnly={isReadOnly}
                          onCancel={back}
                        />
                      )
                    }
                  </TabPanel>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);

import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AccountProfileDetails from '../components/account/AccountProfileDetails';
import AppConstants from '../constants/AppConstants';

const Account = () => (
  <>
    <Helmet>
      <title>My Profile | {AppConstants.APP_NAME}</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <AccountProfileDetails />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Account;

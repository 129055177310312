import { JobsiteRes, EnumRes } from '../proxy/proxy';

class FormattedJobsite {
  id: number;
  name: string;
  areaCode: string;
  state: string;
  stateId: number;
  linfoxName: string;

  constructor(rawData: JobsiteRes, states: Array<EnumRes> = []) {
    this.id = rawData.id ?? 0;
    this.name = rawData.name ?? '';
    this.linfoxName = rawData.linfoxName ?? '';
    this.areaCode = rawData.address?.areaCode ?? '';
    this.stateId = rawData.address?.stateId ?? 0;
    this.state = rawData.address?.stateId ? states?.find((state: any) => state.id === rawData.address?.stateId)?.value ?? '' : '';
  }
}

export default FormattedJobsite;

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@mui/material';
import moment from 'moment';
import DateRangeSelector from '../DateRangeSelector';
import ExportButton from '../ExportButton';
import ShiftNRService from '../../services/ShiftNRService';

interface Props {
  isOpen: boolean,
  closeModal: () => any,
  startDate: string,
  endDate: string
}

const ExportShiftNRModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  startDate,
  endDate
}) => {
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);

  const handleDateRangeChange = (start: string, end: string) => {
    setFromDate(start);
    setToDate(end);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Export Linfox Timesheets</DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <DateRangeSelector startDate={fromDate} endDate={toDate} onChange={handleDateRangeChange} />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <ExportButton
          dataPromiseProvider={() => ShiftNRService.downloadLinfoxTimesheets(fromDate, toDate)}
          fileName="linfox_timesheets"
          type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ExportShiftNRModal;

import axios from 'axios';
import AppConstants from '../constants/AppConstants';
import { Client, CreatePayrollLineItemReq, PayrollLineItemRes, PayrollPeriodReq, PayrollPeriodRes, PayrollSummaryRes, UpdatePayrollLineItemReq } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const PayrollService = {
  getAllPayrollPeriods: () => configureAxios().pagePayrollPeriods2({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp) => {
    const result: Array<PayrollPeriodRes> = (resp?.items || []); // .filter((staff: PayrollPeriodRes) => !staff.isSoftDeleted);
    return result;
  }),
  getPayrollPeriodById: (id: number) => configureAxios().getPayrollPeriod(id).then((resp) => resp),
  addPayrollPeriod: (payload: PayrollPeriodReq) => configureAxios().createPayrollPeriod(payload),
  deletePayrollPeriod: (id: number) => configureAxios().deletePayrollPeriod(id),
  processPayroll: (id: number, payDate: string) => configureAxios().enqueuePayrollPeriod({
    id,
    payDateLocal: payDate
  }).then((resp) => resp),
  uploadLineItems: (id: number, file: any) => {
    const formData = new FormData();
    formData.append('ExcelFile', file);
    formData.append('PayrollPeriodId', id.toString());
    return axios.post(`${AppConstants.BASE_API_URL}/api/Payroll/UploadLineItems`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getLineItems: (id: number) => configureAxios().pageLineItems({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE,
    payrollPeriodId: Number(id)
  }).then((resp) => {
    const result: Array<PayrollLineItemRes> = (resp?.items || []);
    return result;
  }),
  getSummary: (id: number) => configureAxios().pageSummary({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE,
    payrollPeriodId: Number(id)
  }).then((resp) => {
    const result: Array<PayrollSummaryRes> = (resp?.items || []);
    return result;
  }),
  importLineItems: (id: number) => configureAxios().generatePayroll({ payrollPeriodId: id }).then((resp) => resp),
  createLineItem: (id: number, payload: CreatePayrollLineItemReq) => configureAxios().createLineItems(id, [payload]),
  updateLineItem: (id: number, payload: UpdatePayrollLineItemReq) => configureAxios().updateLineItems(id, [payload]),
  deleteLineItems: (id: number, lineItemIds: Array<number>) => configureAxios().deleteLineItems(id, lineItemIds).then((resp) => resp),
  downloadSummary: (id: number) => configureAxios().downloadSummary(id),
  downloadPayrollUploadFile: () => configureAxios().downloadPayrollUploadFile(),
  getPayMonthlySummary: (startTimeUtc: string, endTimeUtc: string) => configureAxios().getUserPayMonthlySummaries({ startTimeUtc, endTimeUtc }).then((response) => response),
  downloadPayMonthlySummary: (startTimeUtc: string, endTimeUtc: string) => configureAxios().downloadUserPayMonthlySummaries({ startTimeUtc, endTimeUtc })
};

export default PayrollService;

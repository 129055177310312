import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Typography, Grid, Box, Container, Card, CardHeader, Divider, CardContent, Hidden, IconButton, Toolbar, TextField, Checkbox, ListItemText, MenuItem } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import {
  Truck as TruckIcon,
  Edit as EditIcon
} from 'react-feather';
import GlobalSearch from '../components/GlobalSearch';
import FilterBy from '../components/FilterBy';
import Table from '../components/Table';
import MobileDataList from '../components/MobileDataList';
import AlertIndicatorIcon from '../components/AlertIndicatorIcon';
import AppConstants from '../constants/AppConstants';
import { refreshData, reset, searchByText, searchByWarning, changePage, changeSortModel, changeFilterByColumn, selectStates } from '../store/actions/vehicleActions';
import FormattedVehicle from '../models/FormattedVehicle';
import { RootState } from '../store/store';
import EnumService from '../services/EnumService';
import { EnumRes } from '../proxy/proxy';

interface Props {
  visibleRows: Array<FormattedVehicle>,
  totalRowCount: number,
  searchedText: string,
  selectedWarning: string,
  currentPage: number,
  sortModel: any,
  selectedStates: Array<number>,
  filterByCol: any,
  fetchAllRows: any,
  resetPage: any,
  filterByText: any,
  filterByWarning: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any,
  setStates: (states: Array<number>) => void,
}

const Vehicles: React.FC<Props> = ({
  visibleRows,
  totalRowCount,
  searchedText,
  selectedWarning,
  currentPage,
  sortModel,
  filterByCol,
  selectedStates,

  fetchAllRows,
  resetPage,
  filterByText,
  filterByWarning,
  onPageChange,
  onSort,
  onChangeFilterByColumn,
  setStates
}) => {
  const navigate = useNavigate();
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);

  const onRowClick = ({ id }: any) => {
    navigate(`/app/vehicle/${id}`);
  };

  const columns: Array<any> = [
    {
      field: 'registrationNumber',
      headerName: 'Registration Number',
      width: 250,
    },
    {
      field: 'vehicleType',
      headerName: 'Type',
      width: 200,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 200,
    },
    {
      field: 'vehicleStatus',
      headerName: 'Status',
    },
    { field: 'state', headerName: 'State', flex: 1 },
    {
      field: 'odometerKms',
      headerName: 'Odometer Reading',
      width: 200,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <span>
            {value || 0} kms
          </span>
        );
      }
    },
    {
      field: 'nextServiceDueKms',
      headerName: 'Next Service Due Kms',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <span>
            {value || 0} kms
          </span>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'number',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
            <EditIcon />
          </IconButton>
        );
      },
      renderHeader: () => (<span />)
    },
  ];

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const onWarningSelectChange = (event: any) => {
    filterByWarning(event.target.value);
  };

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  useEffect(() => {
    fetchAllRows();
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  const addVehicle = () => {
    navigate('/app/vehicle/add');
  };

  const onStatesSelect = (event: any) => {
    setStates(event?.target?.value ?? []);
  };

  const getRowClassName = (row: FormattedVehicle) => {
    if (row.alerts?.length) {
      return row.alerts.find((alert: any) => alert.type === 'error') ? 'error' : 'warning';
    }
    return '';
  };

  const filterOptions = [
    { value: 'all', text: 'Show All' },
    { value: 'service', text: 'Service' },
    { value: 'insurance', text: 'Insurance' },
    { value: 'registration', text: 'Registration' }
  ];

  const stateDropdown = (
    <TextField
      sx={{ width: '200px' }}
      size="small"
      label="States"
      name="states"
      onChange={onStatesSelect}
      onBlur={onStatesSelect}
      select
      SelectProps={{
        multiple: true,
        renderValue: (selected: any) => selected.map((x: any) => allStates.find((state: any) => state.id === x)?.value).join(', ')
      }}
      value={selectedStates}
    >
      {allStates.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <Checkbox checked={selectedStates.includes(option.id ?? 0)} />
          <ListItemText primary={option.value} />
        </MenuItem>
      ))}
    </TextField>
  );

  const renderEachMobileCard = (row: FormattedVehicle) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={
          <TruckIcon />
        }
        action={
          (
            <>
              <AlertIndicatorIcon alerts={row.alerts} />
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            </>
          )
        }
        title={row.registrationNumber}
        subheader={`${row.vehicleType} - ${row.vehicleStatus}`}
        onClick={() => onRowClick(row)}
      />
      <Divider />
      <CardContent>
        <Grid container>
          {
            row.vin && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>VIN:</b> {row.vin}
                </Typography>
              </Grid>
            )
          }
          {
            row.state && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>State:</b> {row.state}
                </Typography>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Odometer Reading:</b> {row.odometerKms || 0} kms
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Next Service Due kms:</b> {row.nextServiceDueKms || 0} kms
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>Vehicles | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              action={
                (
                  <>
                    <IconButton onClick={refresh}>
                      <RefreshIcon />
                    </IconButton>
                    <IconButton onClick={addVehicle}>
                      <AddIcon />
                    </IconButton>
                  </>
                )
              }
              title="Vehicles"
            />
            <Divider />
            <CardContent>
              <Hidden mdDown>
                <Toolbar style={{ justifyContent: 'flex-end' }}>
                  {stateDropdown}
                  <FilterBy
                    label="Filter by Alerts"
                    options={filterOptions}
                    value={selectedWarning}
                    onChange={onWarningSelectChange}
                    width="280px"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} width="400px" />
                </Toolbar>
                <Table
                  rows={visibleRows}
                  columns={columns}
                  onRowDoubleClick={onRowClick}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                  sortModel={sortModel}
                  onSortModelChange={onSort}
                  getRowClassName={(params: any) => getRowClassName(params?.row)}
                />
              </Hidden>
              <Hidden mdUp>
                <Toolbar sx={{ flexDirection: 'column' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {stateDropdown}
                  </div>
                  <div style={{ width: '100%', padding: '5px 0' }}>
                    <FilterBy label="Filter by Alerts" options={filterOptions} value={selectedWarning} onChange={onWarningSelectChange} />
                  </div>
                  <div style={{ width: '100%', padding: '5px 0' }}>
                    <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} showFilterByColumn columns={columns} filterByColumn={filterByCol} onFilterByColumnChange={onChangeFilterByColumn} />
                  </div>
                </Toolbar>
                <MobileDataList
                  rows={visibleRows}
                  renderEachRow={renderEachMobileCard}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.vehicle?.filteredRows,
  totalRowCount: state.vehicle?.allRows?.length,
  currentPage: state.vehicle?.currentPage,
  searchedText: state.vehicle?.searchedText,
  selectedWarning: state.vehicle?.filterBy,
  sortModel: state.vehicle?.sortModel,
  filterByCol: state.vehicle?.filterByCol,
  selectedStates: state.vehicle.states ?? [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => dispatch(refreshData()),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  filterByWarning: (text: string) => dispatch(searchByWarning(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),
  setStates: (states: Array<number>) => dispatch(selectStates(states)),
  onChangeFilterByColumn: (col: string) => dispatch(changeFilterByColumn(col)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);

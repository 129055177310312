import { PayRateJobsiteAddOnRes } from '../../proxy/proxy';
import {
  CLEAR_ALL_DATA,
  PAYROLL_ADDONS_LOAD_ALL,
  PAYROLL_ADDONS_RESET_PAGE,
  PAYROLL_ADDONS_GRID_SORT,
  PAYROLL_ADDONS_GRID_CURRENT_PAGE,
  PAYROLL_ADDONS_GRID_SEARCH_TEXT
} from '../actionTypes';

export interface AddonStateType {
  allRows: Array<PayRateJobsiteAddOnRes>,
  filteredRows: Array<PayRateJobsiteAddOnRes>,
  searchedText: string,
  currentPage: number,
  sortModel: Array<any>
}

export interface StateType {
  jobSite: AddonStateType,
  shiftTime: AddonStateType,
  shiftDuration: AddonStateType
}

const ADDON_INITIAL_STATE: AddonStateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  currentPage: 0,
  sortModel: []
};

const INITIAL_STATE: StateType = {
  jobSite: ADDON_INITIAL_STATE,
  shiftTime: ADDON_INITIAL_STATE,
  shiftDuration: ADDON_INITIAL_STATE
};

const getColValue = (row: any, col: string) => ((row[col] || '').toString()).toLowerCase();

const filterRows = (allRows: Array<PayRateJobsiteAddOnRes> = [], searchText = '') => {
  const text = searchText.trim().toLowerCase();
  return text ? allRows.filter((row) => {
    if (
      getColValue(row, 'name').indexOf(text) > -1
      || (row?.jobsite?.name?.toLowerCase()?.indexOf(text) ?? 0) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;
};

const updateState = (state: AddonStateType, action: { type: string, data: any }): AddonStateType => {
  switch (action.type) {
    case PAYROLL_ADDONS_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.searchedText),
      };
    case PAYROLL_ADDONS_RESET_PAGE:
      return {
        ...ADDON_INITIAL_STATE
      };
    case PAYROLL_ADDONS_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case PAYROLL_ADDONS_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case PAYROLL_ADDONS_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, action.data),
        currentPage: 0
      };
    case CLEAR_ALL_DATA:
      return ADDON_INITIAL_STATE;

    default: return state;
  }
};

const reducer = (state = INITIAL_STATE, action: { type: string, addOnType: 'jobSite' | 'shiftTime' | 'shiftDuration', data: any }): StateType => {
  if (action.addOnType === 'jobSite') {
    return {
      ...state,
      jobSite: updateState(state.jobSite, action)
    };
  }

  if (action.addOnType === 'shiftTime') {
    return {
      ...state,
      shiftTime: updateState(state.shiftTime, action)
    };
  }

  if (action.addOnType === 'shiftDuration') {
    return {
      ...state,
      shiftDuration: updateState(state.shiftDuration, action)
    };
  }

  return state;
};

export default reducer;

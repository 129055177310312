import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2'
    },
    text: {
      primary: '#000000',
      secondary: '#6b778c'
    }
  },
  components: {
    MuiPaginationItem: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            borderRadius: '50%!important'
          },
        }
      ]
    },
  },
  shadows,
  typography
});

export default theme;

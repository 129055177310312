import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  MenuItem,
  ListItemText,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SubmitFormButton from '../SubmitFormButton';
import EditPayrollLineItemReq from '../../models/EditPayrollLineItemReq';
import PayrollService from '../../services/PayrollService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatISODate, formatInputDate, getJobSiteName, getMinuteFromDate, getHourFromDate, setTimeInDate, convertToLocalDate } from '../../utils/common';
import FormattedStaff from '../../models/FormattedStaff';
import { JobsiteRes } from '../../proxy/proxy';

interface Props {
  open: boolean,
  handleModalClose: any,
  payrollId: number,
  lineItem: EditPayrollLineItemReq,
  allStaff: Array<FormattedStaff>,
  allStores: Array<JobsiteRes>,
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const EditPayrollLineItem: React.FC<Props> = ({
  open,
  handleModalClose,
  payrollId,
  lineItem,
  allStaff = [],
  allStores = [],

  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const getInitialValues = () => ({
    userId: lineItem.userId ?? '',
    // API will igonre the time component and consider on date component from the string
    date: formatInputDate(lineItem.dateLocal, true) || '',

    paidStartDateTimeLocalDate: formatInputDate(lineItem.paidStartDateTimeLocal, true) || '',
    paidStartDateTimeLocalHour: getHourFromDate(lineItem.paidStartDateTimeLocal, true) || 0,
    paidStartDateTimeLocalMinute: getMinuteFromDate(lineItem.paidStartDateTimeLocal, true) || 0,

    paidEndDateTimeLocalDate: formatInputDate(lineItem.paidEndDateTimeLocal, true) || '',
    paidEndDateTimeLocalHour: getHourFromDate(lineItem.paidEndDateTimeLocal, true) || 0,
    paidEndDateTimeLocalMinute: getMinuteFromDate(lineItem.paidEndDateTimeLocal, true) || 0,

    jobsiteId: lineItem.jobsiteId ?? '',
    shift: lineItem.shift ?? '',
    hours: lineItem.hours ?? '',
    adjustments: lineItem.adjustments ?? '',
    creditNote: lineItem.creditNote ?? '',
    comments: lineItem.comments ?? ''
  });

  const getPayload = (values: any) => {
    const payload: any = {
      userId: Number(values.userId),
      // API will igonre the time component and consider on date component from the string
      dateLocal: convertToLocalDate(values.date, 0, 0),
      paidStartDateTimeLocal: convertToLocalDate(values.paidStartDateTimeLocalDate, values.paidStartDateTimeLocalHour, values.paidStartDateTimeLocalMinute) || null,
      paidEndDateTimeLocal: convertToLocalDate(values.paidEndDateTimeLocalDate, values.paidEndDateTimeLocalHour, values.paidEndDateTimeLocalMinute) || null,
      jobsiteId: Number(values.jobsiteId),
      shift: values.shift,
      hours: Number(values.hours),
      adjustments: Number(values.adjustments),
      creditNote: values.creditNote,
      comments: values.comments
    };
    if (lineItem?.id) {
      payload.id = lineItem?.id;
    }

    return payload;
  };

  const getValidation = (type: string, name: string, isRequired: boolean) => {
    const yup: any = Yup;
    const allowNegativeCols = ['adjustments', 'hours', 'minute'];
    let validation = yup[type]?.();
    if (type === 'number') {
      validation = allowNegativeCols.indexOf(name.toLowerCase()) > -1 ? Yup.number() : Yup.number().min(0, 'Must be 0 or more');
    }

    if (isRequired) {
      if (name.toLocaleLowerCase() === 'adjustments') {
        return validation.required(`${name} is required. Set to 0 if not applicable.`);
      }
      return validation.required(`${name} is required`);
    }
    return validation;
  };

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    const promise = lineItem?.id ? PayrollService.updateLineItem(payrollId, getPayload(values)) : PayrollService.createLineItem(payrollId, getPayload(values));
    promise
      .then(() => {
        showAlert(formatSuccessMessage('Line Item updated successfully.'));
        handleModalClose(true);
      }).catch((err: any) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const getValidationSchema = () => ({
    userId: getValidation('number', 'USER', true),
    date: getValidation('date', 'DATE', true),

    paidStartDateTimeLocalDate: getValidation('date', 'DATE', false),
    paidStartDateTimeLocalHour: getValidation('number', 'HOUR', false),
    paidStartDateTimeLocalMinute: getValidation('number', 'MINUTE', false),

    paidEndDateTimeLocalDate: getValidation('date', 'DATE', false),
    paidEndDateTimeLocalHour: getValidation('number', 'HOUR', false),
    paidEndDateTimeLocalMinute: getValidation('number', 'MINUTE', false),

    jobsiteId: getValidation('number', 'JOBSITE', true),
    shift: getValidation('string', 'SHIFT', true),
    hours: getValidation('number', 'HOURS', true),
    adjustments: getValidation('number', 'ADJUSTMENTS', true),
    comments: getValidation('string', 'COMMENTS', false),
  });

  return (
    <Dialog
      open={open}
      onClose={() => handleModalClose(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">{lineItem.id ? 'Update' : 'Add'} Line Item</DialogTitle>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object().shape(getValidationSchema())}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          dirty,
          isValid
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="User"
                    name="userId"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    value={values.userId}
                    variant="outlined"
                    error={Boolean(touched.userId && errors.userId)}
                    helperText={touched.userId && errors.userId}
                    InputLabelProps={{ shrink: true }}
                    required
                  >
                    {allStaff.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={(option.name || option.email) + (option.extDriverId ? ` (${option.extDriverId})` : '')} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={2} xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Date"
                      value={values.date}
                      onChange={(newValue) => setFieldValue('date', formatInputDate(newValue))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.date && errors.date)}
                          fullWidth
                          helperText={touched.date && errors.date}
                          margin="normal"
                          name="date"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    label="Total Hours"
                    margin="normal"
                    name="hours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.hours}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    required
                    error={Boolean(touched.hours && errors.hours)}
                    helperText={touched.hours && errors.hours}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Paid Start Date"
                      value={values.paidStartDateTimeLocalDate}
                      onChange={(newValue) => setFieldValue('paidStartDateTimeLocalDate', formatInputDate(newValue))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.paidStartDateTimeLocalDate && errors.paidStartDateTimeLocalDate)}
                          fullWidth
                          helperText={touched.paidStartDateTimeLocalDate && errors.paidStartDateTimeLocalDate}
                          margin="normal"
                          name="paidStartDateTimeLocalDate"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="Paid Start Hour"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.paidStartDateTimeLocalHour}
                    name="paidStartDateTimeLocalHour"
                    variant="outlined"
                    error={Boolean(touched.paidStartDateTimeLocalHour && errors.paidStartDateTimeLocalHour)}
                    helperText={touched.paidStartDateTimeLocalHour && errors.paidStartDateTimeLocalHour}
                    SelectProps={{ native: true }}
                  >
                    {Array.from(Array(24).keys()).map((option) => (
                      <option
                        key={option}
                        value={option}
                      >
                        {String(option).padStart(2, '0')}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="Paid Start Minute"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.paidStartDateTimeLocalMinute}
                    name="paidStartDateTimeLocalMinute"
                    variant="outlined"
                    error={Boolean(touched.paidStartDateTimeLocalMinute && errors.paidStartDateTimeLocalMinute)}
                    helperText={touched.paidStartDateTimeLocalMinute && errors.paidStartDateTimeLocalMinute}
                    SelectProps={{ native: true }}
                  >
                    {Array.from(Array(60).keys()).map((option) => (
                      <option
                        key={option}
                        value={option}
                      >
                        {String(option).padStart(2, '0')}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={2} xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Paid End Date"
                      value={values.paidEndDateTimeLocalDate}
                      onChange={(newValue) => setFieldValue('paidEndDateTimeLocalDate', formatInputDate(newValue))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.paidEndDateTimeLocalDate && errors.paidEndDateTimeLocalDate)}
                          fullWidth
                          helperText={touched.paidEndDateTimeLocalDate && errors.paidEndDateTimeLocalDate}
                          margin="normal"
                          name="paidEndDateTimeLocalDate"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="Paid End Hour"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.paidEndDateTimeLocalHour}
                    name="paidEndDateTimeLocalHour"
                    variant="outlined"
                    error={Boolean(touched.paidEndDateTimeLocalHour && errors.paidEndDateTimeLocalHour)}
                    helperText={touched.paidEndDateTimeLocalHour && errors.paidEndDateTimeLocalHour}
                    SelectProps={{ native: true }}
                  >
                    {Array.from(Array(24).keys()).map((option) => (
                      <option
                        key={option}
                        value={option}
                      >
                        {String(option).padStart(2, '0')}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="Paid End Minute"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.paidEndDateTimeLocalMinute}
                    name="paidEndDateTimeLocalMinute"
                    variant="outlined"
                    error={Boolean(touched.paidEndDateTimeLocalMinute && errors.paidEndDateTimeLocalMinute)}
                    helperText={touched.paidEndDateTimeLocalMinute && errors.paidEndDateTimeLocalMinute}
                    SelectProps={{ native: true }}
                  >
                    {Array.from(Array(60).keys()).map((option) => (
                      <option
                        key={option}
                        value={option}
                      >
                        {String(option).padStart(2, '0')}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Store/Jobsite"
                    name="jobsiteId"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    value={values.jobsiteId}
                    variant="outlined"
                    error={Boolean(touched.jobsiteId && errors.jobsiteId)}
                    helperText={touched.jobsiteId && errors.jobsiteId}
                  >
                    {allStores.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={getJobSiteName(option)} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="Shift"
                    name="shift"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    value={values.shift}
                    variant="outlined"
                    error={Boolean(touched.shift && errors.shift)}
                    helperText={touched.shift && errors.shift}
                  >
                    <MenuItem value="AM">
                      <ListItemText primary="AM" />
                    </MenuItem>
                    <MenuItem value="PM">
                      <ListItemText primary="PM" />
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="Adjustments"
                    margin="normal"
                    name="adjustments"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adjustments}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    required
                    error={Boolean(touched.adjustments && errors.adjustments)}
                    helperText={touched.adjustments && errors.adjustments}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Comment"
                    margin="normal"
                    name="comments"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.comments}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    error={Boolean(touched.comments && errors.comments)}
                    helperText={touched.comments && errors.comments}
                  />
                </Grid>
              </Grid>
              <Alert severity="info">GST is not calculated on adjustments</Alert>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalClose(false)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <SubmitFormButton
                dirty={dirty}
                isValid={isValid}
                onConfirm={handleSubmit}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPayrollLineItem);

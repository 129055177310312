import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Box, Card, CardHeader, Divider, CardContent, IconButton, Toolbar } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import {
  MessageSquare as MessageIcon,
  Edit as EditIcon
} from 'react-feather';
import DeleteIcon from '@mui/icons-material/Delete';
import MobileDataList from '../components/MobileDataList';
import GlobalSearch from '../components/GlobalSearch';
import AppConstants from '../constants/AppConstants';
import { refreshData, reset, searchByText, changePage, changeSortModel } from '../store/actions/predefinedShiftCommentsActions';
import { RootState } from '../store/store';
import ConfirmButton from '../components/ConfirmButton';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import PredefinedShiftCommentService from '../services/PredefinedShiftCommentService';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';
import Table from '../components/Table';
import { PredefinedShiftCommentRes } from '../proxy/proxy';

interface Props {
  visibleRows?: Array<PredefinedShiftCommentRes>,
  totalRowCount?: number,
  searchedText?: string,
  currentPage?: number,
  sortModel?: any,
  fetchAllRows: any,
  resetPage: any,
  filterByText?: any,
  onPageChange?: (page: number) => any,
  onSort?: (model: any) => any,

  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const PredefinedShiftComment: React.FC<Props> = ({
  visibleRows = [],
  totalRowCount = 0,
  searchedText,
  currentPage = 0,
  sortModel,

  fetchAllRows,
  resetPage,
  filterByText,
  onPageChange = () => null,
  onSort = () => null,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator,
}) => {
  const navigate = useNavigate();

  const onRowClick = ({ id }: any) => {
    navigate(`/app/shift/comment/${id}`);
  };

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  useEffect(() => {
    fetchAllRows();
  }, []);

  const addComment = () => {
    navigate('/app/shift/comment/add');
  };

  const handleDeleteClick = (commentId: number) => {
    showBusyIndicator();
    clearAllAlerts();
    PredefinedShiftCommentService.deleteComment(commentId)
      .then(() => {
        showAlert(formatSuccessMessage('Comment deleted successfully'));
        fetchAllRows();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  const columns = [
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <>
            <ConfirmButton
              buttonIcon={<DeleteIcon />}
              isIconButton
              dialogTitle="Are you sure?"
              showCancelButton
              message="Shift comment will be deleted permanently."
              onConfirm={() => handleDeleteClick(row.id)}
              buttonColor="secondary"
            />
            <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => (<span />)
    }
  ];

  return (
    <>
      <Helmet>
        <title>Predefined Comments | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              action={
                (
                  <>
                    <IconButton onClick={refresh}>
                      <RefreshIcon />
                    </IconButton>
                    <IconButton onClick={addComment}>
                      <AddIcon />
                    </IconButton>
                  </>
                )
              }
              title="Predefined Comments"
            />
            <Divider />
            <CardContent>
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
              </Toolbar>
              <Table
                rows={visibleRows}
                columns={columns}
                onRowDoubleClick={onRowClick}
                totalRows={totalRowCount}
                page={currentPage}
                onPageChange={({ page }) => onPageChange(page)}
                sortModel={sortModel}
                onSortModelChange={onSort}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.shiftComments?.filteredRows,
  totalRowCount: state.shiftComments?.allRows?.length,
  currentPage: state.shiftComments?.currentPage,
  searchedText: state.shiftComments?.searchedText,
  sortModel: state.shiftComments?.sortModel,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => dispatch(refreshData()),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),

  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedShiftComment);

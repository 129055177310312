import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, CardContent, TextField, Grid, CardActions, ListItemText, MenuItem, Divider, Tooltip, IconButton, Typography, Toolbar, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import { connect } from 'react-redux';
import { Add as AddIcon, Delete as DeleteIcon, AttachMoney as DollarIcon } from '@mui/icons-material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SubmitFormButton from '../SubmitFormButton';
import Table from '../Table';
import AddOnsService from '../../services/AddOnsService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, isDriver, getJobSiteName, sortByKey } from '../../utils/common';
import EnumService from '../../services/EnumService';
import StaffService from '../../services/StaffService';
import StoresService from '../../services/StoresService';
import FormattedStaff from '../../models/FormattedStaff';
import StaffMultiSelectModal from '../StaffMultiSelectModal';
import GlobalSearch from '../GlobalSearch';
import { EnumRes, JobsiteRes, PayRateJobsiteAddOnAppUserMapRes, PayRateJobsiteAddOnRes } from '../../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const JobsiteAddOnDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const staffIds = query.get('staffIds')?.split(',').map(Number);
  const addOnId = !Number.isNaN(Number(id)) ? Number(id) : 0;
  const navigate = useNavigate();
  const [addOnData, setAddOnData] = useState<PayRateJobsiteAddOnRes | null>(null);
  const [assignedUsers, setAssignedUsers] = useState<Array<FormattedStaff>>([]);
  const [filteredAssignedUsers, setFilteredAssignedUsers] = useState<Array<FormattedStaff>>([]);
  const [allStaff, setAllStaffs] = useState<Array<FormattedStaff>>([]);
  const [allStores, setAllStores] = useState<Array<JobsiteRes>>([]);
  const [allPayRateTypes, setAllPayRateTypes] = useState<Array<EnumRes>>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortModel, setSortModel] = useState();
  const [addStaffModal, setAddStaffModal] = useState(false);
  const [isStaffListUpdated, setIsStaffListUpdated] = useState(false);
  const [searchedText, setSearchedText] = useState('');

  useEffect(() => {
    const current = assignedUsers.map((entry) => entry.id).sort();
    const existing = addOnId ? (addOnData?.payRateJobsiteAddOnAppUserMap ?? [])
      .map((entry: PayRateJobsiteAddOnAppUserMapRes) => entry?.user?.id).sort() : [];

    setIsStaffListUpdated(!(JSON.stringify(current) === JSON.stringify(existing)));
  }, [assignedUsers]);

  useEffect(() => {
    let users: any = [];

    if (addOnId) {
      users = (addOnData?.payRateJobsiteAddOnAppUserMap ?? []).map((entry: PayRateJobsiteAddOnAppUserMapRes) => {
        if (entry.user !== undefined) {
          return new FormattedStaff(entry.user);
        }
        return null;
      }).filter((formattedStaff: FormattedStaff | null | undefined) => formattedStaff !== null && formattedStaff !== undefined);
    } else if (staffIds?.length) {
      users = staffIds?.map((staffId) => allStaff.find((entry) => entry.id === staffId))?.filter(Boolean) ?? [];
    }

    setAssignedUsers(users);
  }, [addOnData, allStaff]);

  const getAvailableStaffs = () => {
    const selected = assignedUsers.map((entry) => entry.id);
    return allStaff.filter((staff) => !selected.includes(staff.id));
  };

  useEffect(() => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
      StaffService.getAllStaffs(),
      StoresService.getAllStores()
    ];
    if (addOnId) {
      promises.push(AddOnsService.getJobSiteAddOnById(addOnId ?? 0));
    }

    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then((result) => {
        const payRateTypes = sortByKey(result?.[0]?.payRateTypes ?? [], 'value');
        const drivers = sortByKey(result?.[1] || [], 'name');
        const stores = sortByKey(result?.[2] || [], 'name');
        setAllStaffs(drivers);
        setAllStores(stores);
        setAllPayRateTypes(payRateTypes);
        if (addOnId) {
          setAddOnData(result[3]);
        } else {
          setAddOnData({});
        }
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  }, []);

  useEffect(() => {
    const getColValue = (row: any, col: string) => ((row[col] || '').toString()).toLowerCase();

    const text = searchedText.trim().toLowerCase();

    let filteredRows = [];
    if (!text) {
      filteredRows = assignedUsers;
    } else {
      filteredRows = assignedUsers.filter((row) => {
        // Filter over all cols
        if (
          getColValue(row, 'name').indexOf(text) > -1
          || getColValue(row, 'phoneNumber').indexOf(text) > -1
          || getColValue(row, 'email').indexOf(text) > -1
          || getColValue(row, 'extDriverId').indexOf(text) > -1
          || getColValue(row, 'abn').indexOf(text) > -1
        ) {
          return true;
        }
        return false;
      });
    }
    setFilteredAssignedUsers(filteredRows);
  }, [assignedUsers, searchedText]);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload = {
      name: values.name.trim(),
      payRate: values.payRate,
      payRateType: values.payRateType,
      jobsiteId: values.jobsiteId
    };

    const existingUsers: number[] = (addOnData?.payRateJobsiteAddOnAppUserMap ?? [])
      .map((entry) => Number(entry?.user?.id))
      .filter((userid: number | undefined) => (typeof userid === 'number' && !Number.isNaN(userid)));
    const currentUsers = assignedUsers.map((entry) => (entry.id ?? 0)).filter(Boolean);
    const addedUsers = (currentUsers.filter((userId) => !existingUsers.includes(userId)) ?? []);
    const removedUsers = (existingUsers.filter((userId) => !currentUsers.includes(userId)) ?? []);

    let promise;
    if (addOnId) {
      promise = Promise.all([
        AddOnsService.updateJobSiteAddOn(addOnId, payload),
        ...(addedUsers.length > 0 ? [AddOnsService.assignUsersToJobSiteAddOn(addOnId, addedUsers)] : []),
        ...(removedUsers.length > 0 ? [AddOnsService.unassignUsersToJobSiteAddOn(addOnId, removedUsers)] : [])
      ]).then((results) => results[0]);
    } else {
      const createPayload = {
        userIds: currentUsers,
        payRateJobsiteAddOnReqs: [payload]
      };

      promise = AddOnsService.createJobSiteAddOn(createPayload);
    }

    promise.then((response) => {
      showAlert(formatSuccessMessage(addOnId ? 'Add-on updated successfully' : 'Add-on created successfully'));
      if (!addOnId) {
        navigate(`/app/payroll/addons/jobSite/${response.id}`, { replace: true });
      } else {
        AddOnsService.getJobSiteAddOnById(addOnId ?? 0).then((result) => {
          setAddOnData(result);
        }).catch((err) => {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        });
      }
    }).catch((err) => {
      showAlert(formatErrorMessage(JSON.parse(err.response)));
    }).then(() => {
      hideBusyIndicator();
    });
  };

  const back = () => {
    navigate(-1);
  };

  const deleteStaff = (staffId: number) => {
    setAssignedUsers((prev) => prev.filter((staff) => staff.id !== staffId));
  };

  const addStaff = (ids: Array<number>) => {
    setAssignedUsers((prev) => {
      const copy = [...prev];

      ids.forEach((staffId) => {
        const match = allStaff.find((staff) => staff.id === staffId);
        if (match) {
          copy.push(match);
        }
      });

      return copy;
    });
  };

  const onSearchTextChange = (event: any) => {
    const text = (event?.target?.value ?? '').trim();
    setSearchedText(text);
  };

  const staffColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'extDriverId',
      headerName: 'CSA ID',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'abn',
      headerName: 'ABN',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'number',
      width: 60,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <IconButton size="medium" aria-label="edit" onClick={() => deleteStaff(row.id)}>
            <DeleteIcon />
          </IconButton>
        );
      },
      renderHeader: () => (<span />)
    },
  ];

  if (!addOnData) {
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{
          name: addOnData.name ?? '',
          payRate: addOnData.payRate ?? '',
          payRateType: addOnData.payRateType ?? '',
          jobsiteId: addOnData.jobsiteId ?? ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Name is required'),
          payRate: Yup.number().min(0, 'Pay rate can not be negative').required('Pay rate is required'),
          payRateType: Yup.number().required('Pay rate category is required'),
          jobsiteId: Yup.number().required('Jobsite is required'),
        })}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          dirty,
          isValid
        }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    required
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Name"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    error={Boolean(touched.payRate && errors.payRate)}
                    required
                    fullWidth
                    helperText={touched.payRate && errors.payRate}
                    label="Pay Rate"
                    margin="normal"
                    name="payRate"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.payRate}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DollarIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    fullWidth
                    label="Pay Rate Category"
                    name="payRateType"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    value={values.payRateType}
                    variant="outlined"
                    error={Boolean(touched.payRateType && errors.payRateType)}
                    helperText={touched.payRateType && errors.payRateType}
                  >
                    <MenuItem aria-label="None" value="">
                      <ListItemText primary="&nbsp;" />
                    </MenuItem>
                    {allPayRateTypes.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    fullWidth
                    label="Store/Jobsite"
                    name="jobsiteId"
                    data-auto-id="jobsiteTextField"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    value={values.jobsiteId}
                    variant="outlined"
                    error={Boolean(touched.jobsiteId && errors.jobsiteId)}
                    helperText={touched.jobsiteId && errors.jobsiteId}
                  >
                    <MenuItem aria-label="None" value="">
                      <ListItemText primary="&nbsp;" />
                    </MenuItem>
                    {allStores.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={getJobSiteName(option)} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Toolbar>
                <Typography sx={{ marginTop: 2, marginBottom: 2, flex: 1 }} variant="h4">Assigned Staff</Typography>
                <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
                <IconButton onClick={() => setAddStaffModal(true)}>
                  <AddIcon />
                </IconButton>
              </Toolbar>
              <Table
                rows={filteredAssignedUsers}
                columns={staffColumns}
                totalRows={assignedUsers.length ?? 0}
                page={currentPage}
                onPageChange={(pageNo: number) => setCurrentPage(pageNo)}
                sortModel={sortModel}
                onSortModelChange={(value: any) => setSortModel(value)}
              />
            </CardContent>
            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined">
                Cancel
              </Button>
              <SubmitFormButton
                dirty={dirty || isStaffListUpdated}
                isValid={isValid}
                message={`You are about to Change the Pay rates for all ${addOnData.name} staff, Please review and confirm`}
                editMode={Boolean(id)}
                onConfirm={handleSubmit}
              />
            </CardActions>
          </form>
        )}
      </Formik>
      {
        addStaffModal && <StaffMultiSelectModal isOpen={addStaffModal} closeModal={() => setAddStaffModal(false)} allStaffs={getAvailableStaffs()} onStaffSelection={addStaff} />
      }
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsiteAddOnDetail);

import {
  PAYROLL_PERIOD_LOAD_ALL,
  PAYROLL_PERIOD_RESET_PAGE,
  PAYROLL_PERIOD_GRID_SORT,
  PAYROLL_PERIOD_GRID_CURRENT_PAGE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import { showAlert, clearAllAlerts } from './alertActions';
import PayrollService from '../../services/PayrollService';
import EnumService from '../../services/EnumService';
import { formatErrorMessage } from '../../utils/common';

export const refreshData = () => (dispatch) => {
  dispatch(showBusyIndicator());
  dispatch(clearAllAlerts());

  const promises = [
    PayrollService.getAllPayrollPeriods(),
    EnumService.getEnums('payrollPeriodStatus')
  ];
  Promise.all(promises)
    .then((result) => {
      const formattedRows = (result[0] || []).map((row) => ({
        ...row,
        payrollPeriodStatusId: row.payrollPeriodStatus,
        payrollPeriodStatus: (result[1] || []).find((status) => status.id === row.payrollPeriodStatus)?.value
      }));
      dispatch({
        type: PAYROLL_PERIOD_LOAD_ALL,
        data: formattedRows
      });
    }).catch((err) => {
      dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const reset = () => ({
  type: PAYROLL_PERIOD_RESET_PAGE
});

export const changePage = (page = 1) => ({
  type: PAYROLL_PERIOD_GRID_CURRENT_PAGE,
  data: page
});

export const changeSortModel = (sortModel = []) => ({
  type: PAYROLL_PERIOD_GRID_SORT,
  data: sortModel
});

import React, { useRef } from 'react';
import { Select, MenuItem, TextField, InputAdornment, Box } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

interface Props {
  searchedText?: string,
  onSearchTextChange: (event: any) => any,
  showFilterByColumn?: boolean,
  columns?: Array<any>,
  filterByColumn?: string,
  onFilterByColumnChange?: (col: any) => any,
  width?: string
}

const GlobalSearch: React.FC<Props> = ({
  searchedText,
  onSearchTextChange,
  showFilterByColumn = false,
  columns,
  filterByColumn,
  onFilterByColumnChange = () => null,
  width = '100%'
}) => {
  const inputEl: any = useRef(null);

  const setFocus = () => {
    // Grid automatically sets focus to currently sorted column header
    // Set focus to the input so that user can continuously type
    setTimeout(() => {
      inputEl?.current?.focus();
    });
  };

  const onChange = (event: any) => {
    onSearchTextChange(event);
    setFocus();
  };

  const onFilterColChange = (event: any) => {
    onFilterByColumnChange(event?.target?.value);
    setFocus();
  };

  return (
    <Box id="globalSearch" width={width} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
      <TextField
        id="searchTxt"
        fullWidth
        inputRef={inputEl}
        variant="standard"
        value={searchedText}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      {
        showFilterByColumn && (
          <Select
            id="globalSearchColumns"
            variant="filled"
            size="small"
            value={filterByColumn}
            onChange={onFilterColChange}
          >
            <MenuItem value="all_col">All Columns</MenuItem>
            { columns?.filter((col) => col.headerName !== 'Actions' && col.headerName !== 'Breach')?.map((col) => <MenuItem key={col.field} value={col.field}>{col.headerName}</MenuItem>) }
          </Select>
        )
      }
    </Box>
  );
};

export default GlobalSearch;

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  UPDATE_COMPANY_PROFILE,
  UPDATE_USER_PROFILE
} from '../actionTypes';
import { hide as hideBusyIndicator, show as showBusyIndicator } from './busyIndicatorActions';
import UserProfileService from '../../services/UserProfileService';
import FormattedUserProfile from '../../models/FormattedUserProfile';
import CompanyService from '../../services/CompanyService';
import LocalStorageService from '../../services/LocalStorageService';
import { CompanyRes } from '../../proxy/proxy';

const localStorageService = LocalStorageService.getService();

export const getUserProfile = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  UserProfileService.get()
    .then((result: FormattedUserProfile) => {
      dispatch({
        type: UPDATE_USER_PROFILE,
        data: result
      });
    }).catch(() => {
      // dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const updateUserProfile = (profile: FormattedUserProfile) => ({
  type: UPDATE_USER_PROFILE,
  data: profile
});

export const getCompanyProfile = (): ThunkAction<void, {}, {}, AnyAction> => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(showBusyIndicator());
  CompanyService.get()
    .then((result: CompanyRes) => {
      dispatch({
        type: UPDATE_COMPANY_PROFILE,
        data: result
      });
      localStorageService.setCompanyProfile(result);
    }).catch(() => {
      // dispatch(showAlert(formatErrorMessage(JSON.parse(err.response))));
    }).then(() => {
      dispatch(hideBusyIndicator());
    });
};

export const updateCompanyProfile = (profile: CompanyRes) => ({
  type: UPDATE_COMPANY_PROFILE,
  data: profile
});

import { ShiftNRRes } from '../proxy/proxy';
import { getDateDifferenceInMS } from '../utils/common';

class FormattedShiftNR {
  id: number | undefined;
  stateCode: string | undefined;
  date: string | undefined;
  dateLocal: string | undefined;
  name: string | undefined;
  store: string | undefined;
  extDriverId: string | undefined;
  rego: string | undefined;
  amOrPM: string | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  totalWorkingHours: string | undefined;
  pastConsecutiveDaysWorked: number | undefined;
  actualWorkingHours: number | undefined;
  restTaken: string | undefined;
  restTakenAfter: string | undefined;
  rest1StartTime: string | undefined;
  rest1EndTime: string | undefined;
  rest2StartTime: any;
  rest2EndTime: string | undefined;
  email: string | undefined;
  hasBreach: boolean = false;
  breaches: Array<any> = [];
  hasWorkedOver5HoursInAnySegment: boolean = false;
  hasWorkedOver12Hours: boolean = false;
  has12HrBreach: boolean = false;
  segment1WorkedForHours: number | undefined;
  hasSegment1Breach: boolean = false;
  segment2WorkedForHours: number | undefined;
  hasSegment2Breach: boolean = false;
  segment3WorkedForHours: number | undefined;
  hasSegment3Breach: boolean = false;

  comments: string | undefined;
  remarks: string | undefined;

  constructor(rawData: ShiftNRRes) {
    if (!rawData) return;

    this.id = rawData?.id;
    this.stateCode = rawData?.stateCode ?? undefined;
    this.date = rawData?.date ?? undefined;
    this.dateLocal = rawData?.dateLocal?.toString();
    this.name = rawData?.name ?? undefined;
    this.store = rawData?.store ?? undefined;
    this.extDriverId = rawData?.extDriverId ?? undefined;
    this.rego = rawData?.rego ?? undefined;
    this.amOrPM = rawData?.amOrPM ?? undefined;
    this.startTime = rawData?.startTimeLocal?.toString();
    this.endTime = rawData?.endTimeLocal?.toString();
    this.totalWorkingHours = rawData?.totalWorkingHours ?? undefined;
    this.pastConsecutiveDaysWorked = rawData?.pastConsecutiveDaysWorked;
    this.actualWorkingHours = rawData?.actualWorkingHours;
    this.restTaken = rawData?.restTaken ?? undefined;
    this.restTakenAfter = (rawData.rest1StartTimeLocal && getDateDifferenceInMS(rawData.startTimeLocal?.toString(), rawData.rest1StartTimeLocal?.toString()) / 60000 / 60)?.toString() || undefined;
    this.rest1StartTime = rawData?.rest1StartTimeLocal?.toString();
    this.rest1EndTime = rawData?.rest1EndTimeLocal?.toString();
    this.rest2StartTime = rawData?.rest2StartTimeLocal;
    this.rest2EndTime = rawData?.rest2EndTimeLocal?.toString();
    this.comments = rawData?.comments ?? undefined;
    this.remarks = rawData?.remark ?? undefined;
    this.email = rawData?.email ?? undefined;

    this.hasBreach = rawData.hasBreach ?? false;
    if (this.hasBreach) {
      this.breaches = this.getBreaches(rawData);
    }
    this.hasWorkedOver5HoursInAnySegment = rawData.hasWorkedOver5HoursInAnySegment ?? false;
    this.hasWorkedOver12Hours = rawData.hasWorkedOver12Hours ?? false;
    this.segment1WorkedForHours = rawData.segment1WorkedForHours;
    this.segment2WorkedForHours = rawData.segment2WorkedForHours;
    this.segment3WorkedForHours = rawData.segment3WorkedForHours;
  }

  getBreaches = (rawData: ShiftNRRes) => {
    const breaches: Array<any> = [];
    if (rawData.hasWorkedOver12Hours) {
      this.has12HrBreach = true;
      breaches.push({
        type: 'error',
        message: 'Driver has more than 12 hours driving time in a 13 hour working day - From Start Time to End Time.'
      });
    }
    if (rawData.segment1WorkedForHours ?? 0 > 5) {
      this.hasSegment1Breach = true;
      breaches.push({
        type: 'error',
        message: 'Driver worked for more than 5 hours without break on Segment 1'
      });
    }
    if (rawData.segment2WorkedForHours ?? 0 > 5) {
      this.hasSegment2Breach = true;
      breaches.push({
        type: 'error',
        message: 'Driver worked for more than 5 hours without break on Segment 2'
      });
    }
    if (rawData.segment3WorkedForHours ?? 0 > 5) {
      this.hasSegment3Breach = true;
      breaches.push({
        type: 'error',
        message: 'Driver worked for more than 5 hours without a break on Segment 3'
      });
    }

    return breaches;
  }
}

export default FormattedShiftNR;

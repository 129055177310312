import AppConstants from '../constants/AppConstants';
import configureAxios from '../proxy/ConfigureAxios';
import { Client, JobsiteReq, JobsiteRes } from '../proxy/proxy';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const StoresService = {
  getAllStores: (): Promise<Array<JobsiteRes>> => configureAxios().page5({
    pageNumber: 1,
    pageSize: AppConstants.MAX_PAGE_SIZE
  }).then((resp) => (resp?.items || []).filter((store: JobsiteRes) => !store.isSoftDeleted)),

  getStoreById: (id: number) => configureAxios().get8(id)
    .then((resp) => resp),

  createStore: (payload: JobsiteReq) => configureAxios().create5(payload),

  updateStore: (id: number, payload: JobsiteReq) => configureAxios().updatePOST6(id, payload),

  deleteStore: (id: number = 0) => configureAxios().softDelete(id)
};

export default StoresService;

import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: '10000!important',
    color: '#fff',
    position: 'absolute!important'
  },
}));

const BusyIndicator = ({ isBusyIndicatorVisible }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isBusyIndicatorVisible}>
      <CircularProgress color="inherit" data-auto-id="busyIndicator" />
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  isBusyIndicatorVisible: state.busyIndicator.visible
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(BusyIndicator);

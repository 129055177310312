import axios from 'axios';
import AppConstants from '../constants/AppConstants';
import FormattedShift from '../models/FormattedShift';
import EnumService from './EnumService';
import { getGeoLocation } from '../utils/common';
import {
  Client,
  ShiftStartLinfoxReq,
  StopShiftReq,
  StartShiftBreakReq,
  StopShiftBreakReq,
  ShiftPageRes,
  ShiftCommentReq,
  ScheduledShiftReq,
  QuickShiftReq,
  CheckinRes,
  CheckInReq,
  FileParameter,
  ImportFromGoogleSheetReq
} from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const ShiftsService = {
  // Driver
  getMyOngoingShift: () => configureAxios().latest().then((resp) => resp),

  // Driver
  startScheduledShift: (
    id: number,
    startOdometerKms: number
  ) =>
    getGeoLocation().then((geoLocation) => {
      const payload: ShiftStartLinfoxReq = {
        startOdometerKms: Number(startOdometerKms)
      };
      return configureAxios().shiftStartLinfox(id, payload).then((resp) => resp);
    }),

  // Driver
  startAdhocShift: (shiftInfo: any, acceptedQuestionIds: Array<number>) =>
    getGeoLocation().then((geoLocation) => {
      const payload = {
        ...shiftInfo,
        preCheckAnswers: acceptedQuestionIds?.map((id) => ({
          answer: true,
          preCheckQuestionId: id
        })),
        geoLocation
      };
      return configureAxios().schedule(true, payload).then((resp) => resp);
    }),

  // Driver
  stopShift: (id: number, data: any) =>
    getGeoLocation().then((geoLocation) => {
      const payload: StopShiftReq = {
        ...data,
        geoLocation
      };
      return configureAxios()
        .quickStopLinfox(id, payload)
        .then((resp) => new FormattedShift(resp));
    }),

  // Owner/Manager
  stopShiftSmsOnly: (id: number) =>
    configureAxios().quickStopLinfoxSmsOnly(id).then((resp) => new FormattedShift(resp)),

  // Driver
  startBreak: (id: number) =>
    getGeoLocation().then((geoLocation) => {
      const payload: StartShiftBreakReq = {
        shiftId: Number(id),
        geoLocation: {
          latitude: geoLocation?.latitude,
          longitude: geoLocation?.longitude
        }
      };
      if (geoLocation !== null) {
        return configureAxios().startBreak(payload);
      }
      throw new Error('Unable to retrieve geolocation data.');
    }),

  // Driver
  stopBreak: (id: number) =>
    getGeoLocation().then((geoLocation) => {
      const payload: StopShiftBreakReq = {
        shiftId: Number(id),
        geoLocation: {
          latitude: geoLocation?.latitude,
          longitude: geoLocation?.longitude
        }
      };
      if (geoLocation !== null) {
        return configureAxios().stopBreak(payload);
      }
      return null;
    }),

  getAllShifts: () =>
    Promise.all([
      EnumService.getEnums('shiftStatus'),
      configureAxios().page22({
        pageNumber: 1,
        pageSize: AppConstants.MAX_PAGE_SIZE,
        dateTimeInterval: {
          // provide values for dateTimeInterval if required
        }
      })
    ]).then((resp) => {
      const shiftStatusTypes = resp[0];
      const result: Array<ShiftPageRes> = resp[1]?.items || [];
      return result.map(
        (shift: any) => new FormattedShift(shift, shiftStatusTypes)
      );
    }),

  getAllShifts2: (startDate: string, endDate: string) =>
    Promise.all([
      EnumService.getEnums('shiftStatus'),
      configureAxios().page22({
        pageNumber: 1,
        pageSize: AppConstants.MAX_PAGE_SIZE,
        dateTimeInterval: {
          startTimeUtc: startDate,
          endTimeUtc: endDate
        }
      })
    ]).then((resp) => {
      const shiftStatusTypes = resp[0];
      const result: Array<ShiftPageRes> = resp[1]?.items || [];
      return result.map(
        (shift: any) => new FormattedShift(shift, shiftStatusTypes)
      );
    }),

  getShiftById: (id: number) =>
    configureAxios().get13(id).then((resp) => new FormattedShift(resp)),

  // Driver
  acceptShift: (id: number) =>
    configureAxios().accept(id).then((resp) => new FormattedShift(resp)),

  checkIn: async () => {
    const shift = await ShiftsService.getMyOngoingShift();
    if (shift?.shiftStatus === 2) {
      const geoLocation = await getGeoLocation(); // Ensure you're awaiting the promise
      if (geoLocation !== null) {
        const response = await configureAxios().ongoingShiftCheckin({
          shiftId: Number(shift.id),
          geoLocation
        });
        return response;
      }
    }
    return null;
  },

  // Driver
  // rejectShift: (id: number) => configureAxios().shiftsRejectPOST(id),

  // Owner / Manager
  notifyShift: (id: number) => configureAxios().notify(id),

  pushNotify: (id: number) => configureAxios().pushNotify(id),

  // Owner / Manager
  deleteShift: (id: number) => configureAxios().delete9([id]),

  // Owner / Manager
  bulkDeleteShifts: (ids: Array<number>) => configureAxios().delete9(ids),

  // Driver
  addShiftComment: (text: string, id: number, shiftCommentTypeId = 0) =>
    getGeoLocation().then((geoLocation) => {
      const payload: ShiftCommentReq = {
        text,
        shiftId: Number(id),
        shiftCommentTypeId,
        geoLocation: geoLocation || undefined // Convert null to undefined
      };
      return configureAxios().shiftComment(payload);
    }),

  // Driver
  markJotFormSubmitted: (id: number) =>
    configureAxios().jotformSubmitted(id).then((resp) => new FormattedShift(resp)),

  // Owner / Manager
  scheduleShift: (payload: ScheduledShiftReq) => configureAxios().schedule(true, payload),

  // Owner / Manager
  updateShift: (id: number, payload: ScheduledShiftReq) =>
    configureAxios().updatePOST10(id, payload),

  // Driver
  submitShift: (id: number, payload: QuickShiftReq) =>
    configureAxios().quickStopLinfox(id, payload),

  // Owner / Manager
  uploadShiftSchedules: (
    file: any,
    startDateUtc: string,
    endDateUtc: string,
    notifyAssignedDrivers: boolean
  ) => {
    const formData = new FormData();
    formData.append('ExcelFile', file);
    formData.append('StartDateUtc', startDateUtc);
    formData.append('EndDateUtc', endDateUtc);
    formData.append('NotifyAssignedDrivers', notifyAssignedDrivers.toString());
    return axios.post(
      `${AppConstants.BASE_API_URL}/api/Shifts/UploadSchedule`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },

  getAllOngoingShiftCheckIns: () =>
    configureAxios()
      .pageRecentCheckins({
        pageNumber: 1,
        pageSize: AppConstants.MAX_PAGE_SIZE
      })
      .then((response) => response?.items ?? [])
      .then((checkIns: Array<CheckinRes>) =>
        checkIns.filter(
          (checkIn: CheckinRes) => checkIn?.shift?.shiftStatus === 2
        )),

  // Driver
  preStart30MinuteCheckin: (id: number) =>
    getGeoLocation().then((geoLocation) => {
      const payload: CheckInReq = {
        geoLocation: geoLocation || undefined // Convert null to undefined
      };
      return configureAxios()
        .shiftPreStart30MinuteCheckin(id, payload)
        .then((resp) => new FormattedShift(resp));
    }),

  // Driver
  // linfoxAnswerPreCheckQuestions: (id: number, startOdometerKms: number, acceptedQuestionIds: Array<number>) => getGeoLocation().then((geoLocation) => {
  //   const payload = {
  //     startOdometerKms,
  //     preCheckAnswers: acceptedQuestionIds?.map((preCheckQuestionId) => ({ answer: true, preCheckQuestionId })),
  //     geoLocation
  //   };
  //   return configureAxios().shiftPreStartPrecheckAnswerLinfox().then((resp) => new FormattedShift(resp));
  // }),

  shiftsShiftPreStartPrecheckAnswerLinfox: (
    answer?: boolean,
    photo?: FileParameter,
    requiresPhoto?: boolean,
    preCheckQuestionId?: number,
    shiftId?: number,
    options: any = {}
  ) =>
    getGeoLocation().then((geoLocation) =>
      configureAxios()
        .shiftPreStartPrecheckAnswerLinfox(
          shiftId,
          answer,
          photo,
          geoLocation?.latitude,
          geoLocation?.longitude,
          requiresPhoto,
          preCheckQuestionId
          // options
        )
        .then((resp) => new FormattedShift(resp))),

  downloadShiftUploadFile: () => configureAxios().downloadShiftUploadFile(),

  // shiftsImportFromGoogleSheet: (data: {
  //   stateId: number,
  //   startDateUtc: string,
  //   endDateUtc: string,
  //   instructions: string,
  //   enforceState: boolean
  // }) => configureAxios().importFromGoogleSheet({
  //   ...data,
  //   notifyAssignedDrivers: true
  // })

  shiftsImportFromGoogleSheet: (data: ImportFromGoogleSheetReq) =>
    configureAxios().importFromGoogleSheet({
      ...data,
      notifyAssignedDrivers: true
    })
};

export default ShiftsService;

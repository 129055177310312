import { PayrollPeriodRes } from '../../proxy/proxy';
import {
  CLEAR_ALL_DATA,
  PAYROLL_PERIOD_LOAD_ALL,
  PAYROLL_PERIOD_RESET_PAGE,
  PAYROLL_PERIOD_GRID_SORT,
  PAYROLL_PERIOD_GRID_CURRENT_PAGE
} from '../actionTypes';

export interface StateType {
  allRows: Array<PayrollPeriodRes>,
  currentPage: number,
  sortModel: Array<any>
}

const INITIAL_STATE: StateType = {
  allRows: [],
  currentPage: 0,
  sortModel: []
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case PAYROLL_PERIOD_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
      };
    case PAYROLL_PERIOD_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case PAYROLL_PERIOD_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case PAYROLL_PERIOD_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;

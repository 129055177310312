import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IconButton, Link, Box, Container, Card, CardHeader, Divider, CardContent, Grid, Typography, Alert, Tooltip, TextField } from '@mui/material';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import ShiftNRService from '../services/ShiftNRService';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatDateToLongDate, formatDateToOnlyDate, formatDateToOnlyTime, formatErrorMessage, formatSuccessMessage } from '../utils/common';
import { RootState } from '../store/store';
import FormattedShiftNR from '../models/FormattedShiftNR';
import ConfirmButton from '../components/ConfirmButton';

interface Props {
  companyId: number | undefined,
  showAlert: (message: any) => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
  clearAllAlerts: () => any
}

const ShiftNRDetail: React.FC<Props> = ({
  companyId,
  showAlert,
  showBusyIndicator,
  hideBusyIndicator,
  clearAllAlerts
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [shiftData, setShiftData] = useState<FormattedShiftNR>();
  const [remarksUpdating, setRemarksUpdating] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>('');

  useEffect(() => {
    if (!companyId || Number.isNaN(Number(id))) {
      return;
    }
    showBusyIndicator();
    ShiftNRService.getShiftById(Number(id), companyId)
      .then((shift) => {
        setShiftData(shift || {});
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  }, [id, companyId]);

  const back = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftNRService.deleteShiftById(Number(id))
      .then(() => {
        showAlert(formatSuccessMessage('Shift deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  const updateRemarks = () => {
    showBusyIndicator();
    clearAllAlerts();
    ShiftNRService.updateRemarks(Number(id), remarks)
      .then((shift) => {
        setShiftData(shift);
        setRemarksUpdating(false);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  const cancelRemarksUpdate = () => {
    setRemarksUpdating(false);
    setRemarks(shiftData?.remarks || '');
  };

  const startRemarksUpdate = () => {
    setRemarksUpdating(true);
    setRemarks(shiftData?.remarks || '');
  };

  return (
    <>
      <Helmet>
        <title>Shift Details | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title={`Shift Details (${shiftData?.name ?? shiftData?.email ?? '--'})`}
              action={
                id && (
                  <ConfirmButton
                    buttonIcon={
                      (
                        <DeleteIcon />
                      )
                    }
                    isIconButton
                    dialogTitle="Are you sure?"
                    showCancelButton
                    message="Shift will be deleted permanently."
                    buttonColor="secondary"
                    onConfirm={handleDeleteClick}
                  />
                )
              }
            />
            <Divider />
            <CardContent>
              <CardContent>
                <Grid container spacing={3}>
                  {
                    shiftData?.hasBreach && shiftData?.breaches?.length && (
                      shiftData.breaches.map((breach) => (
                        <Grid key={breach.message} item xs={12}>
                          <Alert sx={{ marginBottom: 1 }} severity={breach.type}>{breach.message}</Alert>
                        </Grid>
                      ))
                    )
                  }
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Date: <b>{formatDateToOnlyDate(shiftData?.dateLocal, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body1">
                      State: <b>{shiftData?.stateCode || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Store: <b>{shiftData?.store || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body1">
                      Rego: <b>{shiftData?.rego || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Driver Name: <b>{shiftData?.name || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Driver Id: <b>{shiftData?.extDriverId || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Email: <b>{shiftData?.email || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Start Time: <b>{formatDateToOnlyTime(shiftData?.startTime, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      End Time: <b>{formatDateToOnlyTime(shiftData?.endTime, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Shift: <b>{shiftData?.amOrPM || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Actual Working Hours: <b>{shiftData?.actualWorkingHours?.toFixed(2) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Total Working Hours: <b>{shiftData?.totalWorkingHours || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Consecutive Days Worked: <b>{shiftData?.pastConsecutiveDaysWorked || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Tooltip title="Segment 1 = StartShift to StartBreak 1">
                      <Typography variant="body1">
                        Segment 1 Total Working Hours: <b>{shiftData?.segment1WorkedForHours ? shiftData?.segment1WorkedForHours?.toFixed(2) : '--'}</b>
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Tooltip title={`Segment 2 = End Break 1 to ${shiftData?.segment3WorkedForHours ? 'StartBreak 2' : 'EndShift'}`}>
                      <Typography variant="body1">
                        Segment 2 Total Working Hours: <b>{shiftData?.segment2WorkedForHours ? shiftData?.segment2WorkedForHours?.toFixed(2) : '--'}</b>
                      </Typography>
                    </Tooltip>
                  </Grid>
                  {
                    !!shiftData?.segment3WorkedForHours && (
                      <Grid item xs={12} md={4}>
                        <Tooltip title="Segment 3 = EndBreak 2 to EndShift">
                          <Typography variant="body1">
                            Segment 3 Total Working Hours: <b>{shiftData?.segment3WorkedForHours ? shiftData?.segment3WorkedForHours?.toFixed(2) : '--'}</b>
                          </Typography>
                        </Tooltip>
                      </Grid>
                    )
                  }
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Rest Taken: <b>{shiftData?.restTaken || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Rest 1 Start Time: <b>{formatDateToOnlyTime(shiftData?.rest1StartTime, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body1">
                      Rest 1 End Time: <b>{formatDateToOnlyTime(shiftData?.rest1EndTime, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                      Rest 2 Start Time: <b>{formatDateToOnlyTime(shiftData?.rest2StartTime, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body1">
                      Rest 2 End Time: <b>{formatDateToOnlyTime(shiftData?.rest2EndTime, true) || '--'}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <b>Comments:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <pre style={{ whiteSpace: 'break-spaces', fontSize: 16 }}>{shiftData?.comments || '--'}</pre>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <b>Remarks:</b>&nbsp;&nbsp;
                      {
                        remarksUpdating ? (
                          <>
                            <Link component="button" disabled={(shiftData?.remarks ?? '') === remarks} variant="body1" onClick={updateRemarks}>Save</Link>&nbsp;&nbsp;
                            <Link component="button" variant="body1" onClick={cancelRemarksUpdate}>Cancel</Link>
                          </>
                        ) : (
                          <Link component="button" variant="body1" onClick={startRemarksUpdate}>Update</Link>
                        )
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      remarksUpdating ? (
                        <TextField multiline fullWidth value={remarks} onChange={(event) => setRemarks(event.target.value)} />
                      ) : (
                        <pre style={{ whiteSpace: 'break-spaces', fontSize: 16 }}>{shiftData?.remarks || '--'}</pre>
                      )
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyId: state.profile?.company?.id
});

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftNRDetail);

import React, { useState, Fragment, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../../store/actions/alertActions';
import { FormQuestionNumeric, } from '../../proxy/proxy';
import FormsService from '../../services/FormsService';
import { formatErrorMessage, formatSuccessMessage } from '../../utils/common';
import { hide, show } from '../../store/actions/busyIndicatorActions';

interface NumberFieldPreviewProps {
  currentFieldState: FormQuestionNumeric;
  form: number;
  answer?: number;

  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const NumberFieldPreview: React.FC<NumberFieldPreviewProps> = ({
  currentFieldState, form, answer,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionNumeric>(currentFieldState);
  const [formId, setFormId] = useState<number>(form);
  const [inputValue, setInputValue] = useState<string>();
  const [error, setError] = useState<string>('');

  const validateInput = (value: string) => {
    const numericValue = parseFloat(value);

    if (Number.isNaN(numericValue)) {
      setError('Invalid input. Please enter a numeric value.');
      return false;
    }

    switch (fieldState.formQuestionNumericRestrictionType) {
      case 1: // GreaterThan
        if (numericValue <= (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be greater than ${fieldState.restrictionInput1}`);
          return false;
        }
        setError('');
        return true;

      case 2: // GreaterThanOrEqualTo
        if (numericValue < (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be greater than or equal to ${fieldState.restrictionInput1}`);
          return false;
        }
        setError('');
        return true;

      case 3: // LessThan
        if (numericValue >= (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be less than ${fieldState.restrictionInput1}`);
          return false;
        }
        setError('');
        return true;

      case 4: // LessThanOrEqualTo
        if (numericValue > (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be less than or equal to ${fieldState.restrictionInput1}`);
          return false;
        }
        setError('');
        return true;

      case 5: // EqualTo
        if (numericValue !== (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be equal to ${fieldState.restrictionInput1}`);
          return false;
        }
        setError('');
        return true;

      case 6: // NotEqualTo
        if (numericValue === (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must not be equal to ${fieldState.restrictionInput1}`);
          return false;
        }
        setError('');
        return true;

      case 7: // Between
        if (numericValue < (fieldState.restrictionInput1 ?? 0) || numericValue > (fieldState.restrictionInput2 ?? 0)) {
          setError(`Value must be between ${fieldState.restrictionInput1} and ${fieldState.restrictionInput2}`);
          return false;
        }
        setError('');
        return true;

      case 8: // NotBetween
        if (numericValue >= (fieldState.restrictionInput1 ?? 0) && numericValue <= (fieldState.restrictionInput2 ?? 0)) {
          setError(`Value must not be between ${fieldState.restrictionInput1} and ${fieldState.restrictionInput2}`);
          return false;
        }
        setError('');
        return true;

      default:
        setError('Invalid restriction type');
        return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    console.log(value, error);
    setInputValue(value);

    if (validateInput(value)) {
      // Prepare the payload for FormAnswerNumericReq
      const payload = {
        __discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
        discriminator: fieldState.discriminator, // Placeholder - adjust according to your structure
        formId,
        formQuestionId: fieldState.id, // Assuming you have formQuestionId in fieldState
        answer: value // Capture the input value as the answer
      };

      console.log(payload);

      const promise = FormsService.answer(payload);

      promise
        .then((response: any) => {
          showAlert(formatSuccessMessage('Answer saved'));
        })
        .catch((err) => {
          if (err?.errors?.length > 0 && !error?.includes(err?.errors[0])) {
            setError(`${error} ${err.errors[0]}`);
          }
          // showAlert(formatErrorMessage(err));
        })
        .then(() => {
          // hideBusyIndicator();
        });
    }
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <TextField
          fullWidth
          name={fieldState.title}
          placeholder={fieldState.subTitle ?? ''}
          variant="outlined"
          type="number"
          InputLabelProps={{ shrink: true }}
          required={fieldState.required}
          value={inputValue ?? answer}
          onChange={handleChange}
          error={!!error}
          helperText={error}
        />
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(NumberFieldPreview);

import React from 'react';
import { connect } from 'react-redux';
import ShiftTable from './ShiftTable';
import { searchByTextOngoing, searchByWarningOngoing, changePageOngoing, changeSortModelOngoing, changeFilterByColumnOngoing } from '../../store/actions/shiftActions';
import FormattedShift from '../../models/FormattedShift';
import { RootState } from '../../store/store';

interface Props {
  visibleRows: Array<FormattedShift>,
  totalRowCount: number,
  currentPage: number;
  searchedText: string,
  sortModel: any,
  selectedWarning: string,
  filterByText: any,
  filterByWarning: any,
  filterByCol: any,
  onPageChange: any,
  onSort: any,
  onChangeFilterByColumn: any
}

const OngoingShifts: React.FC<Props> = ({
  visibleRows,
  totalRowCount,
  currentPage,
  searchedText,
  sortModel,
  selectedWarning,
  filterByText,
  filterByWarning,
  filterByCol,
  onPageChange,
  onSort,
  onChangeFilterByColumn
}) => (
  <ShiftTable
    shifts={visibleRows}
    totalRowCount={totalRowCount}
    shiftStatus={2}
    currentPage={currentPage}
    searchedText={searchedText}
    sortModel={sortModel}
    filterByCol={filterByCol}
    selectedWarning={selectedWarning}
    onFilterByText={filterByText}
    onFilterByWarning={filterByWarning}
    onPageChange={onPageChange}
    onSort={onSort}
    onChangeFilterByColumn={onChangeFilterByColumn}
  />
);

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.shift?.ongoing?.filteredRows,
  totalRowCount: state.shift?.ongoing?.allRows?.length,
  currentPage: state.shift?.ongoing?.currentPage,
  searchedText: state.shift?.ongoing?.searchedText,
  selectedWarning: state.shift?.ongoing?.filterBy,
  sortModel: state.shift?.ongoing?.sortModel,
  filterByCol: state.shift?.ongoing?.filterByCol,
});

const mapDispatchToProps = (dispatch: any) => ({
  filterByText: (text: string) => dispatch(searchByTextOngoing(text)),
  filterByWarning: (text: string) => dispatch(searchByWarningOngoing(text)),
  onPageChange: (page: any) => dispatch(changePageOngoing(page)),
  onSort: (sortModel: any) => dispatch(changeSortModelOngoing(sortModel)),
  onChangeFilterByColumn: (col: any) => dispatch(changeFilterByColumnOngoing(col)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OngoingShifts);

import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Pagination from './Pagination';

interface Props {
  rows: Array<any>,
  page: number,
  pageSize?: number,
  totalRows: number,
  renderEachRow: any,
  onPageChange: any
}

const MobileDataList: React.FC<Props> = ({
  rows = [],
  page,
  pageSize,
  totalRows,
  renderEachRow,

  onPageChange
}) => {
  const [mobileCurrentPageData, setMobileCurrentPageData] = useState([]);

  useEffect(() => {
    if (!rows.length) {
      setMobileCurrentPageData([]);
    }
  }, [rows]);

  return (
    <div>
      {mobileCurrentPageData.map(renderEachRow)}
      {
        rows.length ? (
          <Pagination
            pageSize={pageSize}
            currentPage={page}
            rows={rows}
            totalRows={totalRows}
            onPageChange={onPageChange}
            onCurrentPageDataChange={(data: any) => setMobileCurrentPageData(data || [])}
          />
        ) : null
      }
      {
        rows.length === 0 && (
          <Typography variant="h3" sx={{ textAlign: 'center' }}>No data found</Typography>
        )
      }
    </div>
  );
};

export default MobileDataList;

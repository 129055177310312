import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  Toolbar,
  Badge
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FormattedStaff from '../models/FormattedStaff';
import GlobalSearch from './GlobalSearch';

interface Props {
  isOpen: boolean,
  closeModal: () => any,
  allStaffs?: Array<FormattedStaff>,
  onStaffSelection: (staff: Array<number>) => any
}

const StaffMultiSelectModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  allStaffs: allStaff = [],
  onStaffSelection
}) => {
  const [selected, setSelected] = useState<Array<any>>([]);
  const [searchedText, setSearchedText] = useState('');
  const [filteredStaff, setFilteredStaff] = useState<Array<FormattedStaff>>([]);

  const onSearchTextChange = (event: any) => {
    const text = (event?.target?.value ?? '').trim();
    setSearchedText(text);
  };

  const onSelectionModelChange = (selectedRows: Array<any> = []) => {
    const notSelectedRows = filteredStaff.filter((staff) => !selectedRows.includes(staff.id))?.map((staff) => staff.id) ?? [];

    let allSelectedList = [...selected];
    // Remove not sleeted rows
    allSelectedList = allSelectedList.filter((id) => !notSelectedRows.includes(id));
    // Add selected rows
    selectedRows.forEach((id) => {
      if (!allSelectedList.includes(id)) {
        allSelectedList.push(id);
      }
    });
    setSelected(allSelectedList);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'extDriverId',
      headerName: 'CSA ID',
      flex: 1,
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Tooltip title={value}>
            <span>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'abn',
      headerName: 'ABN',
      flex: 1
    }
  ];

  const submit = () => {
    onStaffSelection(selected);
    setSelected([]);
    setSearchedText('');
    closeModal();
  };

  useEffect(() => {
    const getColValue = (row: any, col: string) => ((row[col] || '').toString()).toLowerCase();

    const text = searchedText.trim().toLowerCase();

    let filteredRows = [];
    if (!text) {
      filteredRows = allStaff;
    } else {
      filteredRows = allStaff.filter((row) => {
        // Filter over all cols
        if (
          getColValue(row, 'name').indexOf(text) > -1
          || getColValue(row, 'phoneNumber').indexOf(text) > -1
          || getColValue(row, 'email').indexOf(text) > -1
          || getColValue(row, 'extDriverId').indexOf(text) > -1
          || getColValue(row, 'abn').indexOf(text) > -1
        ) {
          return true;
        }
        return false;
      });
    }
    setFilteredStaff(filteredRows);
  }, [allStaff, searchedText]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">Select Staff</DialogTitle>
      <DialogContent>
        <Toolbar style={{ justifyContent: 'flex-end' }}>
          <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
        </Toolbar>
        <DataGrid
          pageSize={10}
          pagination
          rows={filteredStaff}
          columns={columns}
          hideFooterSelectedRowCount
          autoHeight
          disableColumnFilter
          checkboxSelection
          selectionModel={selected}
          onSelectionModelChange={onSelectionModelChange}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ marginRight: 2 }} color="secondary" onClick={closeModal} variant="outlined">
          Cancel
        </Button>
        <Badge badgeContent={selected.length} color="secondary">
          <Button variant="contained" color="primary" onClick={submit} disabled={selected.length === 0}>
            Add
          </Button>
        </Badge>
      </DialogActions>
    </Dialog>
  );
};

export default StaffMultiSelectModal;

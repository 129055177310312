import { AxiosResponse } from 'axios';
import FormattedVehicle from '../models/FormattedVehicle';
import AppConstants from '../constants/AppConstants';
import EnumService from './EnumService';
import { Client, FileParameter, FormAnswerBaseReq, FormQuestionTextualReq, FormReq, FormSubmissionDeleteMediaFileReq, GetFormSubmissionsReq, VehicleReq, VehicleRes, VehicleResPage } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const FormsService = {
  createForm: (data: FormReq) => configureAxios().create3(data),
  updateForm: (formId: number, data: FormReq) => configureAxios().updatePOST4(formId, data),
  addQuestion: (id: number, data: FormQuestionTextualReq) =>
    configureAxios().addQuestion(id, data),
  deleteQuestion: (formId: number, questionId: string) =>
    configureAxios().deleteQuestion(formId, questionId),
  replaceQuestion: (formId: number, questionId: string, data: FormQuestionTextualReq) =>
    configureAxios().replaceQuestion(formId, questionId, data),
  getFormById: (id: number) =>
    configureAxios()
      .get5(id)
      .then((resp) => resp),
  getFormByRole: (id: number) =>
    configureAxios()
      .getByRole(id)
      .then((resp) => resp),
  getAllForms: () =>
    configureAxios()
      .allPOST()
      .then((resp) => resp),
  allFormsByRole: () =>
    configureAxios()
      .allByRole()
      .then((resp) => resp),
  getAllRegularExpressions: () =>
    configureAxios()
      .allGET3()
      .then((resp) => resp),
  deleteForm: (id: number = 0) => configureAxios().softDelete(id),
  setIsActive: (id: number = 0, isActive: boolean) =>
    configureAxios().setIsActive(id, isActive),

  // SUBMISSION
  answer: (data: FormAnswerBaseReq) => configureAxios().answer(data),
  uploadPhoto: (
    formId: number | undefined,
    formQuestionId: string | undefined,
    mediaFileDescription: string | undefined,
    mediaFileFile: FileParameter | undefined
  ) =>
    configureAxios().uploadPhoto(
      formId,
      formQuestionId,
      mediaFileDescription,
      mediaFileFile
    ),
  deletePhoto: (media: FormSubmissionDeleteMediaFileReq) => configureAxios().deletePhoto(media),
  getLatestByUser: (formId: number) => configureAxios().getLatestByUser(formId),
  getSubmission: (submissionId: number) => configureAxios().get6(submissionId),
  getSubmissionByUser: (submissionId: number) => configureAxios().getByUser(submissionId),
  submit: (formSubmissionId: number) => configureAxios().submit(formSubmissionId),
  between: (data: GetFormSubmissionsReq) => configureAxios().between(data),
  betweenByUser: (data: GetFormSubmissionsReq) => configureAxios().betweenByUser(data),
};

export default FormsService;

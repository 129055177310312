import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, IconButton, Box, Container, Card, CardHeader, Divider, CardContent, TextField, Grid, CardActions, ListItemText, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EnumService from '../services/EnumService';
import ConfirmButton from '../components/ConfirmButton';
import SubmitFormButton from '../components/SubmitFormButton';
import StoresService from '../services/StoresService';
import AppConstants from '../constants/AppConstants';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, sortByKey } from '../utils/common';
import { JobsiteRes, EnumRes } from '../proxy/proxy';

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const StoreDetail: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const jobsiteId = !Number.isNaN(id) ? Number(id) : 0;
  const [storeData, setStoreData] = useState<JobsiteRes | null>(null);
  const [allStates, setAllStates] = useState<EnumRes[]>([]);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      EnumService.getEnums('states'),
      ...(jobsiteId ? [StoresService.getStoreById(jobsiteId)] : [])
    ];

    showBusyIndicator();
    clearAllAlerts();
    Promise.all(promises)
      .then((result) => {
        setAllStates(sortByKey(result[0], 'value'));
        setStoreData(jobsiteId ? result[1] : {});
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();

    const payload = {
      name: values?.name,
      linfoxName: values?.linfoxName,
      address: {
        street: values?.street,
        areaCode: values?.areaCode?.toString()?.padStart(4, '0'),
        suburb: values?.suburb,
        city: values?.city,
        stateId: Number(values?.stateId)
      }
    };

    const promise = jobsiteId > 0 ? StoresService.updateStore(jobsiteId, payload) : StoresService.createStore(payload);

    promise
      .then((response) => {
        if (jobsiteId > 0) {
          refreshData();
        } else {
          navigate(`/app/jobsite/${response.id}`, { replace: true });
        }
        showAlert(formatSuccessMessage(jobsiteId ? 'Store/Jobsite updated successfully' : 'Store/Jobsite created successfully'));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const back = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    showBusyIndicator();
    clearAllAlerts();
    StoresService.deleteStore(jobsiteId)
      .then(() => {
        showAlert(formatSuccessMessage('Store deleted successfully'));
        back();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });
  };

  return (
    <>
      <Helmet>
        <title>Store/Jobsite Details | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              avatar={
                (
                  <IconButton aria-label="back" size="small" onClick={back}>
                    <BackIcon />
                  </IconButton>
                )
              }
              title="Store/Jobsite Details"
              action={
                !!jobsiteId && (
                  <ConfirmButton
                    buttonIcon={
                      (
                        <DeleteIcon />
                      )
                    }
                    isIconButton
                    dialogTitle="Are you sure?"
                    showCancelButton
                    message="Store will be deleted permanently."
                    buttonColor="secondary"
                    onConfirm={handleDeleteClick}
                  />
                )
              }
            />
            <Divider />
            <CardContent>
              {
                storeData && (
                  <Formik
                    initialValues={{
                      name: storeData.name || '',
                      linfoxName: storeData.linfoxName || '',
                      street: storeData?.address?.street || '',
                      suburb: storeData?.address?.suburb || '',
                      city: storeData?.address?.city || '',
                      areaCode: storeData?.address?.areaCode || '',
                      stateId: storeData?.address?.stateId || '',
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().max(255).required('Name is required'),
                      linfoxName: Yup.string(),
                      street: Yup.string().max(255),
                      suburb: Yup.string().max(255),
                      city: Yup.string().max(255),
                      areaCode: Yup.number().min(0, 'Post Code should be between 0000 and 9999').max(9999, 'Post Code should be between 0000 and 9999').required('Post Code is required'),
                      stateId: Yup.number().min(1).required('State is required'),
                    })}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      dirty,
                      isValid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={6}
                            >
                              <TextField
                                error={Boolean(touched.name && errors.name)}
                                fullWidth
                                helperText={touched.name && errors.name}
                                label="Name"
                                margin="normal"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                            >
                              <TextField
                                error={Boolean(touched.linfoxName && errors.linfoxName)}
                                fullWidth
                                helperText={touched.linfoxName && errors.linfoxName}
                                label="Linfox Dock Name"
                                margin="normal"
                                name="linfoxName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.linfoxName}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                            >
                              <Divider />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.street && errors.street)}
                                fullWidth
                                helperText={touched.street && errors.street}
                                label="Street"
                                margin="normal"
                                name="street"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.street}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.suburb && errors.suburb)}
                                fullWidth
                                helperText={touched.suburb && errors.suburb}
                                label="Suburb"
                                margin="normal"
                                name="suburb"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.suburb}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.areaCode && errors.areaCode)}
                                fullWidth
                                helperText={touched.areaCode && errors.areaCode}
                                label="Post Code"
                                margin="normal"
                                name="areaCode"
                                type="number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.areaCode}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                label="State"
                                name="stateId"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                select
                                InputLabelProps={{ shrink: true }}
                                value={values.stateId}
                                variant="outlined"
                                error={Boolean(touched.stateId && errors.stateId)}
                                helperText={touched.stateId && errors.stateId}
                              >
                                <MenuItem aria-label="None" value="">
                                  <ListItemText primary="&nbsp;" />
                                </MenuItem>
                                {allStates.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    <ListItemText primary={option.value} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button sx={{ marginRight: 2 }} color="secondary" onClick={back} variant="outlined">
                            Cancel
                          </Button>
                          <SubmitFormButton
                            dirty={dirty}
                            isValid={isValid}
                            editMode={Boolean(jobsiteId)}
                            onConfirm={handleSubmit}
                          />
                        </CardActions>
                      </form>
                    )}
                  </Formik>
                )
              }
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetail);

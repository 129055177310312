import { SHOW_ALERT, HIDE_ALERT, CLEAR_ALL_ALERTS } from '../actionTypes';

export interface StateType {
  messages: Array<any>
}

const INITIAL_STATE: StateType = {
  messages: []
};

const formatMessages = (messages: Array<any>) => {
  if (!Array.isArray(messages)) {
    messages = [messages];
  }

  return messages.map((message) => ({
    id: new Date().getUTCMilliseconds() + Math.random(),
    message: message.message,
    severity: message.severity || 'info'
  }));
};

const reducer = (state = INITIAL_STATE, action: any): StateType => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        messages: formatMessages(action.messages)
      };

    case HIDE_ALERT:
      return {
        ...state,
        messages: [...state.messages].filter((message) => message.id !== action.id),
      };

    case CLEAR_ALL_ALERTS:
      return {
        ...state,
        messages: [],
      };

    default: return state;
  }
};

export default reducer;

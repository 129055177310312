import React, { useState, useEffect } from 'react';
import { Alert, Typography, Dialog, DialogContentText, DialogActions, DialogContent, DialogTitle, Divider, Button, TextField, ListItemText, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ShiftsService from '../../services/ShiftsService';
import { hide, show } from '../../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../../store/actions/alertActions';
import { formatSuccessMessage, formatErrorMessage, formatISODate } from '../../utils/common';
import ExportButton from '../ExportButton';

interface Props {
  open: boolean,
  handleModalClose: (data: any) => any,
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const UploadSchedules: React.FC<Props> = ({
  open,
  handleModalClose,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const [validationErrors, setValidationErrors] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState();

  const onFileSelectionChange = (event: any) => {
    setSelectedFile(event?.target?.files[0]);
  };

  const onUploadFile = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    setValidationErrors(null);
    ShiftsService.uploadShiftSchedules(selectedFile, formatISODate(values?.startDateUtc, true) || '', formatISODate(values?.endDateUtc, true) || '', values.notifyAssignedDrivers === 'true')
      .then((result) => {
        showAlert(formatSuccessMessage('Schedules uploaded successfully'));
        handleModalClose(result);
      }).catch((err) => {
        const error = err?.response?.data;
        if (error?.messages?.length) {
          setValidationErrors(error);
        } else {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        }
      }).then(() => {
        hideBusyIndicator();
      });
  };

  useEffect(() => {
    clearAllAlerts();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleModalClose(null)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Upload Schedules</DialogTitle>
        <Formik
          initialValues={{
            startDateUtc: '',
            endDateUtc: '',
            notifyAssignedDrivers: 'false',
            file: null
          }}
          validationSchema={Yup.object().shape({
            startDateUtc: Yup.date().required('Start Date is required'),
            endDateUtc: Yup.date().required('End Date is required'),
            notifyAssignedDrivers: Yup.boolean().required()
          })}
          onSubmit={onUploadFile}
        >
          {
            ({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              dirty,
              isValid
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  {
                    validationErrors && (
                      <>
                        <Typography sx={{ paddingBottom: 2 }} variant="body1">{validationErrors?.title}</Typography>
                        {
                          (validationErrors?.messages || []).map((message: string) => (
                            <Alert sx={{ marginBottom: 1 }} key={message} severity="error">
                              {message}
                            </Alert>
                          ))
                        }
                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                      </>
                    )
                  }
                  <DialogContentText sx={{ marginBottom: 2 }}>
                    Please select an Excel file in desired schedule format.
                  </DialogContentText>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ExportButton
                      buttonType="outlined"
                      label="Download template"
                      dataPromiseProvider={ShiftsService.downloadShiftUploadFile}
                      fileName="shift_upload_template"
                      type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                  <TextField
                    error={Boolean(touched.file && !selectedFile)}
                    fullWidth
                    helperText={touched.file && !selectedFile}
                    label="Excel file"
                    margin="normal"
                    name="file"
                    onBlur={handleBlur}
                    onChange={(...props) => {
                      onFileSelectionChange(...props);
                      handleChange(...props);
                    }}
                    variant="outlined"
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { accept: '.xls,.xlsx' } }}
                    required
                  />
                  <TextField
                    error={Boolean(touched.startDateUtc && errors.startDateUtc)}
                    fullWidth
                    helperText={touched.startDateUtc && errors.startDateUtc}
                    label="Start Date"
                    margin="normal"
                    name="startDateUtc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.startDateUtc}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  <TextField
                    error={Boolean(touched.endDateUtc && errors.endDateUtc)}
                    fullWidth
                    helperText={touched.endDateUtc && errors.endDateUtc}
                    label="End Date"
                    margin="normal"
                    name="endDateUtc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.endDateUtc}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Notify Assigned Drivers?"
                    name="notifyAssignedDrivers"
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    InputLabelProps={{ shrink: true }}
                    value={values.notifyAssignedDrivers}
                    variant="outlined"
                    error={Boolean(touched.notifyAssignedDrivers && errors.notifyAssignedDrivers)}
                    helperText={touched.notifyAssignedDrivers && errors.notifyAssignedDrivers}
                  >
                    <MenuItem value="true">
                      <ListItemText primary="Yes" />
                    </MenuItem>
                    <MenuItem value="false">
                      <ListItemText primary="No" />
                    </MenuItem>
                  </TextField>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button
                    onClick={() => handleModalClose(null)}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )
          }
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadSchedules);

import moment from 'moment';
import { getDaysFromNow } from '../utils/common';
import AppConstants from '../constants/AppConstants';
import { EnumRes, VehicleRes } from '../proxy/proxy';

class FormattedVehicle {
  id: number;
  registrationNumber: string;
  vin: string;
  insuranceExpiryDateUTC: string;
  nextServiceDueDateUTC: string;
  nextServiceDueKms: number;
  odometerKms: number;
  registrationExpiryDateUTC: string;
  vehicleStatusId: number;
  vehicleStatus: string = '';
  location: string = '';
  vehicleTypeId: number;
  vehicleType: string = '';
  state: string;
  stateId: number;
  alerts: Array<any>;

  constructor(rawData: VehicleRes | null = null, vehicleStatuses: Array<any> = [], vehicleTypes: Array<any> = [], states: Array<EnumRes> = []) {
    this.id = rawData?.id ?? 0;
    this.registrationNumber = rawData?.registrationNumber ?? '';
    this.vin = rawData?.vin ?? '';

    this.insuranceExpiryDateUTC = rawData?.insuranceExpiryDateUTC?.toString() ?? '';

    this.nextServiceDueDateUTC = rawData?.nextServiceDueDateUTC?.toString() ?? '';
    this.nextServiceDueKms = rawData?.nextServiceDueKms ?? 0;

    this.odometerKms = rawData?.odometerKms ?? 0;
    this.registrationExpiryDateUTC = rawData?.registrationExpiryDateUTC?.toString() ?? '';

    this.vehicleStatusId = rawData?.vehicleStatus || 0;
    this.vehicleTypeId = rawData?.vehicleTypeId ?? 0;
    this.location = rawData?.location ?? '';

    if (this.vehicleStatusId && vehicleStatuses) {
      this.vehicleStatus = vehicleStatuses.find((status) => status.id === rawData?.vehicleStatus)?.value;
    }
    if (this.vehicleTypeId && vehicleTypes) {
      this.vehicleType = vehicleTypes.find((type) => type.id === rawData?.vehicleTypeId)?.value;
    }

    this.alerts = [];

    const insuranceExpieryAlert = this.generateInsuranceExpiryAlert();
    if (insuranceExpieryAlert) {
      this.alerts.push(insuranceExpieryAlert);
    }

    const registrationExpieryAlert = this.generateRegistrationExpiryAlert();
    if (registrationExpieryAlert) {
      this.alerts.push(registrationExpieryAlert);
    }

    const serviceDueAlert = this.generateServiceDueAlert();
    if (serviceDueAlert) {
      this.alerts.push(serviceDueAlert);
    }

    this.stateId = rawData?.stateId ?? 0;
    this.state = rawData?.stateId ? states?.find((state: any) => state.id === rawData?.stateId)?.value ?? '' : '';
  }

  generateInsuranceExpiryAlert() {
    if (this.insuranceExpiryDateUTC) {
      const expiresInDays = getDaysFromNow(this.insuranceExpiryDateUTC);
      if (expiresInDays === 0) {
        return {
          type: 'warning',
          category: 'insurance',
          message: 'Insurance expires today'
        };
      }
      if (expiresInDays < 0) {
        return {
          type: 'error',
          category: 'insurance',
          message: `Insurance expired ${moment.duration(expiresInDays, 'days').humanize()} ago`
        };
      }
      if (expiresInDays < AppConstants.VEHICLE_INSURANCE_EXPIERY_WARNING_BEFORE_DAYS) {
        return {
          type: 'warning',
          category: 'insurance',
          message: `Insurance will expire in ${moment.duration(expiresInDays, 'days').humanize()}`
        };
      }
    }
    return null;
  }

  generateRegistrationExpiryAlert() {
    if (this.registrationExpiryDateUTC) {
      const expiresInDays = getDaysFromNow(this.registrationExpiryDateUTC);
      if (expiresInDays === 0) {
        return {
          type: 'warning',
          category: 'registration',
          message: 'Registration expires today'
        };
      }
      if (expiresInDays < 0) {
        return {
          type: 'error',
          category: 'registration',
          message: `Registration expired ${moment.duration(expiresInDays, 'days').humanize()} ago`
        };
      }
      if (expiresInDays < AppConstants.VEHICLE_REGISTRATION_EXPIERY_WARNING_BEFORE_DAYS) {
        return {
          type: 'warning',
          category: 'registration',
          message: `Registration will expire in ${moment.duration(expiresInDays, 'days').humanize()}`
        };
      }
    }
    return null;
  }

  generateServiceDueAlert() {
    if (this.nextServiceDueKms) {
      if (this.odometerKms > this.nextServiceDueKms) {
        return {
          type: 'error',
          category: 'service',
          message: 'Vehicle is due for service'
        };
      }
      if (this.nextServiceDueKms - this.odometerKms < AppConstants.VEHICLE_SERVICE_DUE_WARNING_BEFORE_KMS) {
        return {
          type: 'warning',
          category: 'service',
          message: `Vehicle is due for service in ${this.nextServiceDueKms - this.odometerKms} Kms`
        };
      }
    }
    if (this.nextServiceDueDateUTC) {
      const expiresInDays = getDaysFromNow(this.nextServiceDueDateUTC);
      if (expiresInDays === 0) {
        return {
          type: 'error',
          category: 'service',
          message: 'Vehicle is due for service'
        };
      }
      if (expiresInDays < 0) {
        return {
          type: 'error',
          category: 'service',
          message: `Vehicle is due for service by ${moment.duration(expiresInDays, 'days').humanize()}`
        };
      }
      if (expiresInDays < AppConstants.VEHICLE_SERVICE_DUE_WARNING_BEFORE_DAYS) {
        return {
          type: 'warning',
          category: 'service',
          message: `Vehicle will be due for (a) service in ${moment.duration(expiresInDays, 'days').humanize()}`
        };
      }
    }
    return null;
  }
}

export default FormattedVehicle;

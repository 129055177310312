import moment from 'moment';
import { getFullName, getDaysFromNow } from '../utils/common';
import AppConstants from '../constants/AppConstants';
import { AddressRes, PayRateRes, StaffRes } from '../proxy/proxy';

class FormattedStaff {
  id: number | undefined;
  role: string | undefined;
  employmentType: string | undefined;
  firstName: string | undefined;
  middleName: string | undefined;
  lastName: string | undefined;
  name: string | undefined;
  dateOfBirthUtc: any;
  phoneNumber: string | undefined;
  emergencyContactFullName: string | undefined;
  emergencyContactPhone: string | undefined;
  email: string | undefined;
  emailConfirmed: boolean | undefined;
  abn: string | undefined;
  address: AddressRes | undefined;
  extDriverId: string | undefined;
  drivingLicenseNumber: string | undefined;
  drivingLicenseClasses: Array<any> = [];
  drivingLicenseIssuedByStateId: number | undefined;
  drivingLicenseRestriction: string | undefined;
  drivingLicenseFirstIssuedOnUtc: any;
  drivingLicenseExpiresOnUTC: any;
  rsaCertificateNumber: string | undefined;
  rsaExpiryDateUtc: any;
  rsatCertificateNumber: string | undefined;
  rsatExpiryDateUtc: any;
  drivingRecordEmissionDateUtc: any;
  australianFederalPoliceCheckEmissionDateUtc: any;
  payRate: PayRateRes | undefined;
  isRegisteredForGST: boolean | undefined;
  alerts: Array<any> | undefined;
  appUsagePattern: number | undefined;

  constructor(rawData: StaffRes) {
    this.id = rawData?.id;
    this.role = rawData?.role ?? undefined;
    this.employmentType = rawData?.employmentType?.toString();

    this.firstName = rawData?.firstName ?? undefined;
    this.middleName = rawData?.middleName ?? undefined;
    this.lastName = rawData?.lastName ?? undefined;
    this.name = getFullName(rawData?.firstName ?? '', rawData?.middleName ?? '', rawData?.lastName ?? '');

    this.phoneNumber = rawData?.phoneNumber ?? undefined;
    this.email = rawData?.email ?? undefined;
    this.emailConfirmed = rawData?.emailConfirmed;
    this.abn = rawData?.abn ?? undefined;
    this.dateOfBirthUtc = rawData?.dateOfBirthUtc;

    this.emergencyContactFullName = rawData.emergencyContactFullName ?? undefined;
    this.emergencyContactPhone = rawData.emergencyContactPhone ?? undefined;

    this.address = rawData?.address;
    this.extDriverId = rawData?.extDriverId ?? undefined;
    this.drivingLicenseExpiresOnUTC = rawData.drivingLicenseExpiresOnUTC;
    this.drivingLicenseNumber = rawData.drivingLicenseNumber ?? undefined;
    this.drivingLicenseClasses = (rawData.appUserLicenseClassMap || []).map((x: any) => x.drivingLicenseClass);
    this.drivingLicenseIssuedByStateId = rawData.drivingLicenseIssuedByStateId ?? undefined;
    this.drivingLicenseRestriction = rawData.drivingLicenseRestriction ?? undefined;
    this.drivingLicenseFirstIssuedOnUtc = rawData.drivingLicenseFirstIssuedOnUtc;
    this.drivingLicenseExpiresOnUTC = rawData.drivingLicenseExpiresOnUTC;
    this.rsaCertificateNumber = rawData.rsaCertificateNumber ?? undefined;
    this.rsaExpiryDateUtc = rawData.rsaExpiryDateUtc;
    this.rsatCertificateNumber = rawData.rsatCertificateNumber ?? undefined;
    this.rsatExpiryDateUtc = rawData.rsatExpiryDateUtc;
    this.drivingRecordEmissionDateUtc = rawData.drivingRecordEmissionDateUtc;
    this.australianFederalPoliceCheckEmissionDateUtc = rawData.australianFederalPoliceCheckEmissionDateUtc;

    this.payRate = rawData.payRate;
    this.isRegisteredForGST = rawData.isRegisteredForGST;

    this.appUsagePattern = rawData.appUsagePattern;

    this.alerts = [];
    const expieryAlert = this.generateDrivingLicenceExpiryAlert();
    if (expieryAlert) {
      this.alerts.push(expieryAlert);
    }
  }

  generateDrivingLicenceExpiryAlert() {
    if (this.drivingLicenseExpiresOnUTC) {
      const expiresInDays = getDaysFromNow(this.drivingLicenseExpiresOnUTC);
      if (expiresInDays === 0) {
        return {
          type: 'warning',
          category: 'license',
          message: 'Driving License Expires Today'
        };
      }
      if (expiresInDays < 0) {
        return {
          type: 'error',
          category: 'license',
          message: `Driving License Expired ${moment.duration(expiresInDays, 'days').humanize()} ago`
        };
      }
      if (expiresInDays < AppConstants.DRIVING_LICENSE_EXPIERY_WARNING_BEFORE_DAYS) {
        return {
          type: 'warning',
          category: 'license',
          message: `Driving License will expire in ${moment.duration(expiresInDays, 'days').humanize()}`
        };
      }
    }
    return null;
  }
}

export default FormattedStaff;

import { Typography } from '@mui/material';
import React, { useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';

interface Props {
  onChange: any,
  privImageUrl: string,
  defaultImageUrl: string,
  selectButtonText?: string
}

const ImageSelector: React.FC<Props> = ({
  onChange,
  privImageUrl,
  defaultImageUrl,
  selectButtonText = 'Select'
}) => {
  const [images, setImages] = useState<any>(null);

  const handleFileSelection = (
    imageList: ImageListType
  ) => {
    setImages(imageList);
    onChange(imageList?.[0]?.file);
  };

  const renderDefaultImage = (isDragging: boolean) => (
    <img
      style={
        {
          maxHeight: '100px',
          maxWidth: '100px',
          border: `1px solid ${isDragging ? 'red' : 'lightgrey'}`,
          padding: '7px',
          borderRadius: '10px',
          opacity: 0.4
        }
      }
      src={defaultImageUrl}
      alt="company logo"
    />
  );

  const renderPreviousImage = (isDragging: boolean) => (
    <img
      style={
        {
          maxHeight: '200px',
          maxWidth: '200px',
          border: `1px solid ${isDragging ? 'red' : 'lightgrey'}`,
          padding: '7px',
          borderRadius: '10px'
        }
      }
      src={privImageUrl}
      alt="company logo"
    />
  );

  const renderSelectedImage = (isDragging: boolean) => {
    const image = images[0];
    return (
      <img
        style={
          {
            maxHeight: '200px',
            maxWidth: '200px',
            border: `1px solid ${isDragging ? 'red' : 'lightgrey'}`,
            padding: '7px',
            borderRadius: '10px'
          }
        }
        src={image.dataURL}
        alt="company logo"
      />
    );
  };

  const renderPreview = (isDragging: boolean) => {
    if (images?.length > 0) {
      return renderSelectedImage(isDragging);
    }
    if (privImageUrl) {
      return renderPreviousImage(isDragging);
    }
    return renderDefaultImage(isDragging);
  };

  return (
    <ImageUploading
      value={images}
      onChange={handleFileSelection}
    >
      {({
        onImageUpload,
        isDragging,
        dragProps
      }) => (
        <button onClick={onImageUpload} style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              border: 'none',
              background: 'transparent'
            }
          }
          {...dragProps}
          type="button"
        >
          { renderPreview(isDragging) }
          <Typography
            sx={
              {
                padding: 0,
                fontSize: '0.75rem',
                fontWeight: 'bold',
                ':hover': {
                  textDecoration: 'underline',
                  color: 'blue',
                }
              }
            }
            variant="button"
          >
            {selectButtonText}
          </Typography>
        </button>
      )}
    </ImageUploading>
  );
};

export default ImageSelector;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material';
import AccountService from '../services/AccountService';
import LocalStorageService from '../services/LocalStorageService';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';

interface Props {
  showBusyIndicator: any,
  hideBusyIndicator: any,
  showAlert: any,
  clearAllAlerts: any
}

const ChangePassword: React.FC<Props> = ({
  showBusyIndicator,
  hideBusyIndicator,
  showAlert,
  clearAllAlerts
}) => {
  const navigate = useNavigate();
  const localStorageService = LocalStorageService.getService();

  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  const formInitialValues = {
    oldPassword: '',
    newPassword: ''
  };
  const formValidationSchema = Yup.object({
    oldPassword: Yup.string().max(255).required('Old password is required'),
    newPassword: Yup.string().max(255).required('New password is required')
  });
  const handleFormSubmit = (values: any) => {
    showBusyIndicator();
    clearAllAlerts();
    AccountService.changePassword(values.oldPassword, values.newPassword)
      .then((resp) => {
        setIsPasswordResetSuccess(true);
        showAlert(formatSuccessMessage(resp));
        localStorageService.clearToken();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };
  const redirectToLogin = () => {
    navigate('/login');
  };

  return (
    <>
      <Helmet>
        <title>Change Password | Truck Sight</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        {
          !isPasswordResetSuccess && (
            <Container maxWidth="sm">
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Change Password
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        pb: 1,
                        pt: 3
                      }}
                    >
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="body1"
                      >
                        Enter your old and new password
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      fullWidth
                      helperText={touched.oldPassword && errors.oldPassword}
                      label="Old Password"
                      margin="normal"
                      name="oldPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.oldPassword}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      fullWidth
                      helperText={touched.newPassword && errors.newPassword}
                      label="New Password"
                      margin="normal"
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.newPassword}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Change
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          )
        }

        {
          isPasswordResetSuccess && (
            <Container maxWidth="sm">
              <Box sx={{ mb: 3 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="h2"
                >
                  Change Password Successful
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 1,
                  pt: 3
                }}
              >
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body1"
                >
                  Please login with your new password
                </Typography>
              </Box>
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={redirectToLogin}
                >
                  Sign in
                </Button>
              </Box>
            </Container>
          )
        }
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

import { PayrollSummaryRes } from '../proxy/proxy';
import { getFullName } from '../utils/common';

export default class PayrollSummaryItem implements PayrollSummaryRes {
  id: number | undefined;
  user: any;
  name: string | undefined;
  extDriverId: string | undefined;
  hours: number | undefined = 0;
  timeSheetHours: number | undefined = 0;
  payrollTimesheetHoursMismatchType: number | undefined = 0;
  subtotal: number | undefined = 0
  adjustments: number | undefined = 0;
  gst: number | undefined = 0;
  total: number | undefined = 0;
  invoiceEmailed: boolean | undefined = false;

  constructor(data: PayrollSummaryRes | null = null) {
    if (!data) return;

    this.id = data.id;
    this.name = data?.user ? getFullName(data.user.firstName ?? '', data.user.middleName ?? '', data.user.lastName ?? '') : '';
    this.extDriverId = data?.user?.extDriverId ?? undefined;
    this.hours = data.hours;
    this.timeSheetHours = data.timesheetHours ?? undefined;
    this.payrollTimesheetHoursMismatchType = data.payrollTimesheetHoursMismatchType ?? 0;
    this.subtotal = data.subtotal;
    this.adjustments = data.adjustments;
    this.gst = data.gst;
    this.total = data.total;
    this.invoiceEmailed = data.invoiceEmailed;
  }
}

import FormattedVehicle from '../../models/FormattedVehicle';
import {
  CLEAR_ALL_DATA,
  VEHICLE_LOAD_ALL,
  VEHICLE_RESET_PAGE,
  VEHICLE_GRID_SORT,
  VEHICLE_GRID_CURRENT_PAGE,
  VEHICLE_GRID_SEARCH_TEXT,
  VEHICLE_GRID_SEARCH_FILTER_BY, VEHICLE_GRID_SEARCH_FILTER_BY_COLUMN, VEHICLE_CHANGE_STATE
} from '../actionTypes';

export interface StateType {
  allRows: Array<FormattedVehicle>,
  filteredRows: Array<FormattedVehicle>,
  searchedText: string,
  filterBy: string,
  filterByCol: string,
  currentPage: number,
  sortModel: Array<any>,
  states: Array<number>,
}

const INITIAL_STATE: StateType = {
  allRows: [],
  filteredRows: [],
  searchedText: '',
  filterBy: 'all',
  filterByCol: 'all_col',
  currentPage: 0,
  sortModel: [],
  states: []
};

const getColValue = (row: any, col: string) => {
  if (col === 'odometerKms' || col === 'nextServiceDueKms') {
    return row[col]?.toString()?.toLowerCase() || '0';
  }

  return ((row[col] || '').toString()).toLowerCase();
};

const filterRows = (allRows: Array<any> = [], states: Array<number> = [], searchText = '', filterBy = 'all', filterByCol = 'all_col') => {
  const text = searchText.trim().toLowerCase();
  let result = text ? allRows.filter((row) => {
    // Filter only via selected col
    if (filterByCol !== 'all_col') {
      return getColValue(row, filterByCol).indexOf(text) > -1;
    }

    // Filter over all cols
    if (
      getColValue(row, 'registrationNumber').indexOf(text) > -1
      || getColValue(row, 'vin').indexOf(text) > -1
      || getColValue(row, 'odometerKms').indexOf(text) > -1
      || getColValue(row, 'nextServiceDueKms').indexOf(text) > -1
      || getColValue(row, 'vehicleType').indexOf(text) > -1
      || getColValue(row, 'vehicleStatus').indexOf(text) > -1
    ) {
      return true;
    }
    return false;
  }) : allRows;
  if (filterBy !== 'all') {
    result = result.filter((staff) => staff.alerts?.find((alert: any) => alert.category === filterBy));
  }
  if (states.length > 0) {
    result = result.filter((vehicle) => states.includes(vehicle.stateId));
  }
  return result;
};

const reducer = (state = INITIAL_STATE, action: { type: string, data: any }): StateType => {
  switch (action.type) {
    case VEHICLE_LOAD_ALL:
      return {
        ...state,
        allRows: action.data || [],
        filteredRows: filterRows(action.data || [], state.states, state.searchedText, state.filterBy, state.filterByCol),
      };
    case VEHICLE_RESET_PAGE:
      return {
        ...INITIAL_STATE
      };
    case VEHICLE_GRID_SORT:
      return {
        ...state,
        sortModel: action.data || []
      };
    case VEHICLE_GRID_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      };
    case VEHICLE_GRID_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.data,
        filteredRows: filterRows(state.allRows, state.states, action.data, state.filterBy, state.filterByCol),
        currentPage: 0
      };
    case VEHICLE_GRID_SEARCH_FILTER_BY_COLUMN:
      return {
        ...state,
        filterByCol: action.data,
        filteredRows: filterRows(state.allRows, action.data, state.searchedText, state.filterBy, action.data),
        currentPage: 0
      };
    case VEHICLE_GRID_SEARCH_FILTER_BY:
      return {
        ...state,
        filterBy: action.data,
        filteredRows: filterRows(state.allRows, state.states, state.searchedText, action.data, state.filterByCol),
        currentPage: 0
      };
    case VEHICLE_CHANGE_STATE:
      return {
        ...state,
        states: action.data,
        filteredRows: filterRows(state.allRows, action.data, state.searchedText, state.filterBy, state.filterByCol),
        currentPage: 0
      };
    case CLEAR_ALL_DATA:
      return INITIAL_STATE;

    default: return state;
  }
};

export default reducer;
